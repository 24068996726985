import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from "rxjs";
import {CurrentProjectService} from "../current-project.service";
import {MessageService} from "../message.service";
import {DurationPipe} from "../duration.pipe";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpErrorResponse} from "@angular/common/http";
import {
  ParameterTable,
} from "../parameter-configuration"
import { ProfileData, ProjectData, TrafficData} from "../data-definitions";
import { BaseProjectService } from '../base-project.service';
import { BaseTrafficService } from '../base-traffic.service';
import { BaseDefaultTrafficService } from '../base-default-traffic.service';

@Component({
  selector: 'app-traffic-modification',
  templateUrl: './traffic-modification.component.html',
  styleUrls: ['./traffic-modification.component.css',
              '../../assets/standard-page.css',
              '../../assets/buttons.css',
              '../../assets/select.css']
})
export class TrafficModificationComponent implements OnInit, OnDestroy, OnChanges {

  @Input() parameterTable: ParameterTable = ParameterTable.PROJECT;
  @Input() profile?: ProfileData;

  project?: ProjectData;

  subscriptions: Subscription = new Subscription();

  fixedTraffics: TrafficData[] = [];
  traffics: TrafficData[] = [];

  concernedService?: BaseTrafficService | BaseDefaultTrafficService;

  constructor(private baseTrafficService: BaseTrafficService,
              private baseDefaultTrafficService: BaseDefaultTrafficService,
              private currentProjectService: CurrentProjectService,
              private baseProjectService: BaseProjectService,
              private messageService: MessageService,
              private durationPipe: DurationPipe,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    if (this.parameterTable == ParameterTable.DEFAULT) {
      this.concernedService = this.baseDefaultTrafficService
    } else {
      this.concernedService = this.baseTrafficService;
      this.subscriptions.add(this.baseProjectService.listen().subscribe(project => {
        this.project = project;
      }));
    }
    this.subscriptions.add(this.concernedService.listenAll().subscribe(traffics => {
      if (traffics.length == 0) return;
      this.fixedTraffics = traffics;
      this.traffics = this.fixedTraffics.map(t => ({...t}));
    }));
    if (this.parameterTable != ParameterTable.DEFAULT) {
      this.currentProjectService.findProjectId().subscribe(projectId => {
        if (projectId) this.loadData();
      });
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.concernedService) return
    let profileChange = changes["profile"]
    if (profileChange) {
      let service = <BaseDefaultTrafficService>this.concernedService;
      service.setProfileId(profileChange.currentValue.id);
      await service.clearAndReloadAll()
    }
  }

  setTrafficValue(traffic: TrafficData, $event: any) {
    if ($event >= -100 && $event <= 100) traffic.value = $event/100;
  }

  getTrafficValue(traffic: TrafficData) {
    return Math.round(traffic.value*100);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async loadData() {
    try {
      await this.concernedService!.loadAll();
      if (this.parameterTable != ParameterTable.DEFAULT) await this.baseProjectService.load();
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  revertChanges() {
    this.traffics = this.fixedTraffics.map(t => ({...t}));
  }

  async applyChanges() {
    let promises = [];
    console.log(this.traffics);
    for (let i = 0; i < this.traffics.length; i++) {
      if (this.traffics[i].value != this.fixedTraffics[i].value) {
        let promise = this.concernedService!.update(this.traffics[i]);
        promises.push(promise);
      }
    }
    await Promise.all(promises);
    this.snackBar.open('Les modifications ont bien été enregistrées', 'Fermer', {
      horizontalPosition: "right",
      verticalPosition: "top",
      duration: 3000,
      panelClass: "success-snack-bar"
    });
  }

  hourFormat(hour: string) : string {
    const split = hour.split(":");
    return split[0] + "h" + split[1];
  }
}
