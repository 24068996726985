import {Component, Input, OnInit} from '@angular/core';
import {AssociationRuleData, EmployeeData, RuleType, Status, TransportData} from "../data-definitions";
import {DateService} from "../date.service";
import {ToStringService} from "../to-string.service";
import {BaseAssociationRuleService} from "../base-association-rule.service";
import {AssociationRulesService} from "../association-rules.service";

@Component({
  selector: 'app-association-rules-lock[transport][employee]',
  templateUrl: './association-rules-lock.component.html',
  styleUrls: ['./association-rules-lock.component.css',
              '../../assets/buttons.css',
              '../../assets/containers.css']
})
export class AssociationRulesLockComponent implements OnInit {

  @Input() transport?: TransportData
  @Input() employee?: EmployeeData

  disabled = false;

  patientName = "";
  constraintHour = ""

  rule?: AssociationRuleData

  constructor(private dateService: DateService,
              private toStringService: ToStringService,
              private associationRuleService: AssociationRulesService,
              private baseAssociationRuleService: BaseAssociationRuleService) {
  }

  ngOnInit(): void {
    this.patientName = this.toStringService.transportPatientName(this.transport);
    this.constraintHour = this.toStringService.transportConstraintTimeRange(this.transport);

    this.associationRuleService.listen(this.employee!.id).subscribe(employeeWithRules => {
      for (let rule of employeeWithRules.lockedTransportRules) {
        if (rule.transportId == this.transport!.id) {
          this.rule = rule;
          return;
        }
      }
      this.rule = undefined;
      let found = false;
      for (let rule of employeeWithRules.forbiddenTransportRules) {
        if (rule.transportId == this.transport!.id) {
          found = true
          break
        }
      }
      this.disabled = found || this.transport!.status == Status.EXCLUDED
    })
  }

  async addRule() {
    let associationRule: AssociationRuleData = {
      id: -1,
      projectId: -1,
      ruleType: RuleType.LOCK_IN,
      employeeId: this.employee!.id,
      transportId: this.transport?.id,
    }
    await this.baseAssociationRuleService.add(associationRule);
  }

  async deleteRule() {
    await this.baseAssociationRuleService.remove(this.rule!);
    this.rule = undefined;
  }

}
