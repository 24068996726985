import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BaseRouteTaskActionService } from './base-route-task-action.service';
import { BaseService, DalConfiguration } from './base-service';
import { CurrentProjectService } from './current-project.service';
import {LocationData, TransportData} from './data-definitions';
import { HttpDalService } from './http-dal.service';

@Injectable({
  providedIn: 'root'
})
export class BaseTransportService extends BaseService<TransportData> {
  constructor(
      http: HttpDalService,
      taskActionService: BaseRouteTaskActionService,
      private currentProjectService: CurrentProjectService) {
    super(http);

    this._addStoreDefinition(
      'by-project-id',
       mission => [mission.projectId]
    );

    this._addDeleteCascadeRelation(
      taskActionService,
      ta => ta.deliveryTransportId!
    );

    this._addDeleteCascadeRelation(
      taskActionService,
      ta => ta.pickupTransportId!
    );
  }

  _extractParentUrl(context: any): string {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/transports`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/transports/${context.transportId}`;
  }

  _extractElementId(transport: TransportData) {
    return transport.id;
  }

  async update(transport: TransportData) {
    await this._updateElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      transportId: transport.id
    }, transport);
  }

  async updateMultiple(transports: TransportData[]) {
    await this._updateElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    }, transports);
  }


  load(transportId: number) {
    return this._loadElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      transportId: transportId
    });
  }

  loadAll() {
    return this._loadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  clearAndReloadAll() {
    return this._clearAndReloadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  listen(missionId: number) {
    return this._listenElement(missionId);
  }

  listenAll() {
    return this.currentProjectService.findProjectId().pipe(
      mergeMap(projectId => projectId === undefined ? EMPTY : this._listenElements('by-project-id', [projectId]))
    );
  }
}
