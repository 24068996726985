import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { CurrentUserService } from '../current-user.service';
import { AuthenticationData } from '../data-definitions';
import * as formUtils from '../form-utils';
import { MessageService } from '../message.service';
import { productFruits } from 'product-fruits';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  authData: AuthenticationData = {username: "", password: "", keepConnected: false};
  hidePassword = true;
  waitLogin = false;

  constructor(private authenticationService: AuthenticationService,
              private currentUserService: CurrentUserService,
              private messageService: MessageService,
              private router: Router) { }

  ngOnInit(): void {
    this.waitLogin = false;
  }

  canLogin() {
    return this.authData.username.length !== 0 && this.authData.password.length !== 0 && !this.waitLogin;
  }

  async login() {
    this.waitLogin = true;
    try {
      let user = await this.authenticationService.login(this.authData);
      this.currentUserService.login(user.id);
      if (environment.production)
        productFruits.init("t3Y7MXn8oI5tNnXr", "fr", {username: user.name})
      this.router.navigateByUrl("/environment/" + String(user.environmentId) + "/project-pick")
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
    this.waitLogin = false;
  }

  getRequiredErrorMessage(): string {
    return formUtils.getRequiredErrorMessage();
  }
}
