<span class="error-snack-bar">
  <p class="content">
    {{data}}
  </p>
  <button class="simple-button more" [routerLink]="computeUrl()">
    <mat-icon>more_horiz</mat-icon>
  </button>
  <button class="simple-button close" (click)="clearError()">
    <mat-icon>close</mat-icon>
  </button>
</span>