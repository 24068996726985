import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {DefaultAssociationRuleData, EmployeeData, EventhubSectorData, RuleType, VehicleData} from "../data-definitions";
import {EventHubData} from "../event-hub-data.service";
import {ToStringService} from "../to-string.service";
import {BaseDefaultAssociationRuleService} from "../base-default-association-rule.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-admin-default-association-rules-creation-modal',
  templateUrl: './admin-default-association-rules-creation-modal.component.html',
  styleUrls: ['./admin-default-association-rules-creation-modal.component.css',
              '../../assets/standard-page.css',
              '../../assets/buttons.css',
              '../../assets/forms.css',
              '../../assets/modal.css',
              '../../assets/select.css']
})
export class AdminDefaultAssociationRulesCreationModalComponent implements OnInit {

  rulePossibilities = [
    {type: RuleType.FORCE_IN, name: "Imposer"},
    {type: RuleType.FORCE_OUT, name: "Interdire"}
  ]

  ruleType?: RuleType
  employee?: EmployeeData
  vehicle?: VehicleData

  sector: EventhubSectorData;
  eventHubData: EventHubData;

  imposedVehiclesToEmployee: Map<string, string> = new Map<string, string>();
  employeeForbiddenVehicles: Map<string, Set<string>> = new Map<string, Set<string>>()
  employeeHavingImposedVehicle: Set<string> = new Set<string>()

  creatingRule = false;

  @Output() closeModal = new EventEmitter<boolean>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public toStringService: ToStringService,
              private baseDefaultAssociationRuleService: BaseDefaultAssociationRuleService) {
    this.sector = data.sector;
    this.eventHubData = data.eventHubData;
    this.imposedVehiclesToEmployee = data.imposedVehiclesToEmployee
    this.employeeHavingImposedVehicle = new Set(this.imposedVehiclesToEmployee.values());
    this.employeeForbiddenVehicles = data.employeeForbiddenVehicles;
    this.eventHubData.employees.sort((e1,e2) => {
      return e1.lastName.localeCompare(e2.lastName)
    })
    this.eventHubData.vehicles.sort((v1, v2) => {
      return this.toStringService.vehicleName(v1)
        .localeCompare(this.toStringService.vehicleName(v2))
    })
  }

  async ngOnInit() {
  }

  isRuleComplete() {
    if (!this.ruleType) return false;
    if (!this.employee) return false;
    if (!this.vehicle) return false;
    return true;
  }

  isRuleValid() {
    if (!this.isRuleComplete()) return true
    if (this.ruleType == RuleType.FORCE_IN) {
      if (this.imposedVehiclesToEmployee.has(this.vehicle!.apiId)) return false;
      if (this.employeeHavingImposedVehicle.has(this.employee!.apiId)) return false;
      let forbiddenVehicles = this.employeeForbiddenVehicles.get(this.employee!.apiId)
      if (forbiddenVehicles && forbiddenVehicles.has(this.vehicle!.apiId)) return false;
    } else if (this.ruleType == RuleType.FORCE_OUT) {
      let imposedEmployee = this.imposedVehiclesToEmployee.get(this.vehicle!.apiId)
      if (imposedEmployee != null && imposedEmployee == this.employee!.apiId) return false
    }
    return true;
  }

  async createAssociationRule() {
    this.creatingRule = true
    let associationRule: DefaultAssociationRuleData = {
      id: -1,
      sectorApiId: this.sector!.apiId,
      employeeApiId: this.employee!.apiId,
      vehicleApiId: this.vehicle!.apiId,
      ruleType: this.ruleType!,
    }
    await this.baseDefaultAssociationRuleService.add(associationRule);
    this.close();
  }

  close() {
    this.closeModal.next(true);
  }

}
