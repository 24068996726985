import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BaseMeasuresService } from './base-measures.service';
import { BaseRouteTaskActionService } from './base-route-task-action.service';
import { BaseService, DalConfiguration } from './base-service';
import { CurrentProjectService } from './current-project.service';
import { RouteSolution } from './data-definitions';
import { HttpDalService } from './http-dal.service';

@Injectable({
  providedIn: 'root'
})
export class BaseRouteSolutionService extends BaseService<RouteSolution> {
  constructor(
      http: HttpDalService,
      routeTaskActionService: BaseRouteTaskActionService,
      private measuresService: BaseMeasuresService,
      private currentProjectService: CurrentProjectService) {
    super(http);

    this._addStoreDefinition(
      'by-project-id',
      routeSolution => [routeSolution.projectId]
    );

    this._addTreeCascadeRelation(
      routeTaskActionService,
      'by-route-id',
      si => si[0],
      r => r.taskActions,
      (r, ta) => <RouteSolution>{ ... r, taskActions: ta}
    );
  }

  _extractParentUrl(context: any): string {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/routes`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/routes/${context.routeId}`;
  }

  _extractElementId(route: RouteSolution) {
    return route.id;
  }

  async update(route: RouteSolution) {
    await this._updateElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      routeId: route.id
    }, route);
    // Ignore errors coming from the measures.
    await this.measuresService.reload().catch(_ => {});
  }

  async remove(route: RouteSolution) {
    await this._removeElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      routeId: route.id
    }, route);
    // Ignore errors coming from the measures.
    await this.measuresService.reload().catch(_ => {});
  }

  async add(route: RouteSolution) {
    await this._addElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    }, route);
    // Ignore errors coming from the measures.
    await this.measuresService.reload().catch(_ => {});
  }

  clone(route: RouteSolution) {
    return this._clone(route);
  }

  load(routeId: number) {
    return this._loadElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      routeId: routeId
    });
  }

  loadAll() {
    return this._loadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  clearAndReloadAll() {
    return this._clearAndReloadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  listen(routeId: number) {
    return this._listenElement(routeId);
  }

  listenAll() {
    return this.currentProjectService.findProjectId().pipe(
      mergeMap(projectId => projectId === undefined ? EMPTY : this._listenElements('by-project-id', [projectId]))
    );
  }
}
