import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentProjectService {

  private environmentId = new BehaviorSubject<number | undefined>(undefined);
  private projectId = new BehaviorSubject<number | undefined>(undefined);

  constructor(private messageService: MessageService) { }

  changeEnvironement(environmentId: number) {
    if (environmentId !== this.environmentId.getValue()) {
      this.environmentId.next(environmentId);
      this.projectId.next(undefined);
    }
    this.messageService.add("Pick environment " + String(environmentId) + ".");
  }

  changeProject(environmentId: number, projectId: number) {
    if (environmentId !== this.environmentId.getValue()) this.environmentId.next(environmentId);
    if (projectId !== this.projectId.getValue()) {
      this.projectId.next(projectId);
    }
    this.messageService.add("Pick project " + String(projectId) + " of environment " + String(environmentId) + ".");
  }

  switchProject(projectId: number) {
    if (projectId !== this.projectId.getValue()) {
      this.projectId.next(projectId);
    }
    this.messageService.add("Pick project " + String(projectId) + " of environment " + String(this.environmentId) + ".");
  }

  closeProject() {
    if (this.projectId.getValue() !== undefined)
      this.projectId.next(undefined);
  }

  logout() {
    this.environmentId.next(undefined);
    this.projectId.next(undefined);
    this.messageService.add("Logout.");
  }

  findEnvironmentId() {
    return this.environmentId.asObservable();
  }

  findProjectId() {
    return this.projectId.asObservable();
  }

  getEnvironmentId() {
    return this.environmentId.getValue();
  }

  getProjectId() {
    return this.projectId.getValue();
  }
}
