import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BaseService, DalConfiguration } from './base-service';
import { CurrentProjectService } from './current-project.service';
import { EmployeeData } from './data-definitions';
import { HttpDalService } from './http-dal.service';

@Injectable({
  providedIn: 'root'
})
export class BaseEmployeeService extends BaseService<EmployeeData> {
  constructor(
    http: HttpDalService,
    private currentProjectService: CurrentProjectService) {
    super(http);

    this._addStoreDefinition(
      'by-project-id',
      employee => [employee.projectId]
    );

    // TODO NB: add a deletion here ? not sure that it is needed
  }

  _extractParentUrl(context: any): string {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/employees`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/employees/${context.employeeId}`;
  }

  _extractElementId(employee: EmployeeData) {
    return employee.id;
  }

  async update(employee: EmployeeData) {
    await this._updateElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      employeeId: employee.id
    }, employee);
  }

  async updateMultiple(employees: EmployeeData[]) {
    await this._updateElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    }, employees);
  }


  load(employeeId: number) {
    return this._loadElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      employeeId: employeeId
    });
  }

  loadAll() {
    return this._loadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  clearAndReloadAll() {
    return this._clearAndReloadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  listen(employeeId: number) {
    return this._listenElement(employeeId);
  }

  listenAll() {
    return this.currentProjectService.findProjectId().pipe(
      mergeMap(projectId => projectId === undefined ? EMPTY : this._listenElements('by-project-id', [projectId]))
    );
  }
}
