import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BaseAuthorizationService } from './authorization.service';
import { BaseService, DalConfiguration } from './base-service';
import { CurrentProjectService } from './current-project.service';
import { CurrentUserService } from './current-user.service';
import { UserData } from './data-definitions';
import { HttpDalService } from './http-dal.service';

@Injectable({
  providedIn: 'root'
})
export class BaseUserService extends BaseService<UserData> {
  constructor(
      http: HttpDalService,
      authorizationService: BaseAuthorizationService,
      private currentProjectService: CurrentProjectService,
      private currentUserService: CurrentUserService) {
    super(http);

    this._addStoreDefinition(
      'by-environment-id',
      user => [user.environmentId]
    );

    this._addDeleteCascadeRelation(
      authorizationService,
      upa => upa.userId
    );
  }

  _extractParentUrl(context: any): string {
    return `${DalConfiguration.url }/environments/${context.environmentId}/users`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url }/environments/${context.environmentId}/users/${context.userId}`;
  }

  _extractElementId(user: UserData) {
    return user.id;
  }

  async update(user: UserData) {
    await this._updateElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      userId: user.id
    }, user);
  }

  async remove(user: UserData) {
    await this._removeElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      userId: user.id
    }, user);
  }

  add(user: UserData) {
    return this._addElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
    }, user);
  }

  clone(user: UserData) {
    return this._clone(user);
  }

  load() {
    return this._loadElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      userId: this.currentUserService.getUserId()
    });
  }

  reload() {
    return this._reloadElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      userId: this.currentUserService.getUserId()
    });
  }

  loadAll() {
    return this._loadElements({
      environmentId: this.currentProjectService.getEnvironmentId()
    });
  }

  listen() {
    return this.currentUserService.findUserId().pipe(
      mergeMap(userId => userId === undefined ? EMPTY : this._listenElement(userId))
    );
  }

  listenAll() {
    return this.currentProjectService.findEnvironmentId().pipe(
      mergeMap(environmentId => environmentId === undefined ? EMPTY : this._listenElements('by-environment-id', [environmentId]))
    );
  }
}
