import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import { EmployeeData, VehicleData, DepotData } from '../data-definitions';
import { BaseEmployeeService } from '../base-employee.service';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ToStringService} from "../to-string.service";
import {DateService} from "../date.service";
import {HttpErrorResponse} from "@angular/common/http";
import { BaseDepotService } from '../base-depot.service';
import { CurrentProjectService } from '../current-project.service';
import { MessageService } from '../message.service';
import { BaseVehicleService } from '../base-vehicle.service';

@Component({
    selector: 'app-depot-modification-modal',
    templateUrl: './depot-modification-modal.component.html',
    styleUrls: ['./depot-modification-modal.component.css',
                '../../assets/standard-page.css',
                '../../assets/buttons.css',
                '../../assets/forms.css',
                '../../assets/modal.css',
                '../../assets/select.css']
})
export class DepotModificationModalComponent implements OnInit {
    @Output() closeModal = new EventEmitter<boolean>();

    initialEmployees: EmployeeData[] = [];
    modifiedEmployees: EmployeeData[] = [];

    initialVehicles: VehicleData[] = [];
    modifiedVehicles: VehicleData[] = [];

    depots: DepotData[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public toStringService: ToStringService, 
                public dateService: DateService,
                private messageService: MessageService,
                private baseEmployeeService: BaseEmployeeService,
                private baseDepotService: BaseDepotService,
                private baseVehicleService: BaseVehicleService,
                private currentProjectService: CurrentProjectService) {
        
        if (data.employees) {
            for (let employee of data.employees) {
                this.initialEmployees.push(employee);
                this.modifiedEmployees.push({...employee});
            }
        } else {
            for (let vehicle of data.vehicles) {
                this.initialVehicles.push(vehicle);
                this.modifiedVehicles.push({...vehicle});
            }
        }
    }

    ngOnInit(): void {
        this.currentProjectService.findProjectId().subscribe(projectId => {
            if (projectId) this.loadData();
        });
        this.baseDepotService.listenAll().subscribe(depots => {
            this.depots = depots;
        })
    }

    async loadData() {
        try {
          await Promise.all([this.baseDepotService.loadAll()]);
        } catch (error) {
          if (error instanceof HttpErrorResponse) {
            this.messageService.addHttpError(error);
          } else {
            this.messageService.addErrorMessage("Unknown error");
          }
        }
      }

    async applyModifications() {
        if (this.initialEmployees.length > 0) {
            let actuallyModifiedEmployees = [];
            for (let i = 0; i < this.initialEmployees.length; i++) {
                let initialEmployee = this.initialEmployees[i];
                let possiblyModifiedEmployee = this.modifiedEmployees[i];
                if (JSON.stringify(initialEmployee) === JSON.stringify(possiblyModifiedEmployee)) continue;
                actuallyModifiedEmployees.push(possiblyModifiedEmployee);
            }
            if (actuallyModifiedEmployees.length > 0) {
                let promise = this.baseEmployeeService.updateMultiple(actuallyModifiedEmployees);
                await promise;
                actuallyModifiedEmployees.forEach(e => this.baseDepotService.load(e.id));
            }
        } else {
            let actuallyModifiedVehicles = [];
            for (let i = 0; i < this.initialVehicles.length; i++) {
                let initialVehicle = this.initialVehicles[i];
                let possiblyModifiedVehicle = this.modifiedVehicles[i];
                if (JSON.stringify(initialVehicle) === JSON.stringify(possiblyModifiedVehicle)) continue;
                actuallyModifiedVehicles.push(possiblyModifiedVehicle);
            }
            if (actuallyModifiedVehicles.length > 0) {
                let promise = this.baseVehicleService.updateMultiple(actuallyModifiedVehicles);
                await promise;
            }
        }
        this.close();
    }

    close() {
        this.closeModal.next(true);
    }

}