import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CurrentTypeService {
    private projectType = new BehaviorSubject<string | undefined>(undefined);

    changeType(newType: string | undefined) {
        if (newType !== this.projectType.getValue()) {
            this.projectType.next(newType);
        }
    }

    getProjectType() {
        return this.projectType.getValue();
    }
}