import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DefaultAssociationRuleData, EmployeeData, EventhubSectorData, RuleType, VehicleData} from "../data-definitions";
import {MessageService} from "../message.service";
import {DateService} from "../date.service";
import {BaseDefaultAssociationRuleService} from "../base-default-association-rule.service"
import {HttpErrorResponse} from "@angular/common/http";
import {EventHubData, EventHubDataService} from "../event-hub-data.service";
import {defaultDialogConfig} from "../dialog-data";
import {
  AdminDefaultAssociationRulesCreationModalComponent
} from "../admin-default-association-rules-creation-modal/admin-default-association-rules-creation-modal.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ToStringService} from "../to-string.service";

interface DefaultAssociationRuleInformation {
  ruleTypeStr: string,
  employee: EmployeeData,
  vehicle: VehicleData,
  rule: DefaultAssociationRuleData
}

@Component({
  selector: 'app-admin-default-association-rules',
  templateUrl: './admin-default-association-rules.component.html',
  styleUrls: ['./admin-default-association-rules.component.css',
              '../../assets/containers.css',
              '../../assets/buttons.css']
})
export class AdminDefaultAssociationRulesComponent implements OnInit, OnChanges {

  @Input() sector?: EventhubSectorData;

  eventHubData?: EventHubData;
  employeeMapper: Map<string, EmployeeData> = new Map<string, EmployeeData>();
  vehicleMapper: Map<string, VehicleData> = new Map<string, VehicleData>();

  defaultAssociationRulesInformation: DefaultAssociationRuleInformation[] = [];
  imposedVehiclesToEmployee: Map<string, string> = new Map<string, string>();
  employeeForbiddenVehicles: Map<string, Set<string>> = new Map<string, Set<string>>()

  matDialogRef?: MatDialogRef<AdminDefaultAssociationRulesCreationModalComponent>;

  constructor(private baseDefaultAssociationRuleService: BaseDefaultAssociationRuleService,
              private eventHubDataService: EventHubDataService,
              private dateService: DateService,
              private messageService: MessageService,
              public toStringService: ToStringService,
              public dialog: MatDialog) { }

  async ngOnInit() {
    console.assert(this.sector != undefined)
    this.baseDefaultAssociationRuleService.setSectorApiId(this.sector!.apiId)
    await this.loadAndInitEventHubData(this.sector!.apiId);
    this.baseDefaultAssociationRuleService.listenAll().subscribe(defaultAssociationRules => {
      this.defaultAssociationRulesInformation = []
      this.imposedVehiclesToEmployee.clear()
      this.employeeForbiddenVehicles.clear()
      for (let rule of defaultAssociationRules) {
        let employee = this.employeeMapper.get(rule.employeeApiId)
        let vehicle = this.vehicleMapper.get(rule.vehicleApiId)
        if (!employee || !vehicle) continue
        let ruleInformation: DefaultAssociationRuleInformation = {
          ruleTypeStr: rule.ruleType == RuleType.FORCE_IN ? "Imposer" : "Interdire",
          employee: employee,
          vehicle: vehicle,
          rule: rule
        }
        this.defaultAssociationRulesInformation.push(ruleInformation)
        if (rule.ruleType == RuleType.FORCE_IN) {
          this.imposedVehiclesToEmployee.set(rule.vehicleApiId, rule.employeeApiId)
        } else {
          let forbiddenVehicles = this.employeeForbiddenVehicles.get(rule.employeeApiId);
          if (forbiddenVehicles == undefined) {
            forbiddenVehicles = new Set<string>();
            this.employeeForbiddenVehicles.set(rule.employeeApiId, forbiddenVehicles)
          }
          forbiddenVehicles.add(rule.vehicleApiId)
        }
      }
    })
    await this.loadData();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.baseDefaultAssociationRuleService) return
    let sectorChange = changes["sector"]
    if (sectorChange && sectorChange.currentValue) {
      this.baseDefaultAssociationRuleService.setSectorApiId(sectorChange.currentValue.apiId)
      await this.loadAndInitEventHubData(sectorChange.currentValue.apiId)
      await this.baseDefaultAssociationRuleService.clearAndReloadAll()
    }
  }

  async loadData() {
    try {
      await this.baseDefaultAssociationRuleService!.loadAll();
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  async loadAndInitEventHubData(sectorApiId: string) {
    this.eventHubData = await this.eventHubDataService.loadData(sectorApiId);
    this.employeeMapper.clear()
    this.vehicleMapper.clear()
    for (let employee of this.eventHubData.employees)
      this.employeeMapper.set(employee.apiId, employee)
    for (let vehicle of this.eventHubData.vehicles)
      this.vehicleMapper.set(vehicle.apiId, vehicle)
  }

  createNewDefaultAssociationRule() {
    let dialogConfig = defaultDialogConfig();
    let data = {
      sector: this.sector,
      eventHubData: this.eventHubData,
      imposedVehiclesToEmployee: this.imposedVehiclesToEmployee,
      employeeForbiddenVehicles: this.employeeForbiddenVehicles,
    }
    dialogConfig.data = data;
    this.matDialogRef = this.dialog.open(AdminDefaultAssociationRulesCreationModalComponent, dialogConfig);
    this.matDialogRef.componentInstance.closeModal.subscribe(
      value => {
        if (value)
          this.matDialogRef!.close();
      });
  }

  async deleteRule(defaultAssociationRule: DefaultAssociationRuleData) {
    await this.baseDefaultAssociationRuleService.remove(defaultAssociationRule);
  }
}
