<button class="rounded-button first-type-button close-button" (click)="close()">
  <img src="assets/images/cross.svg">
</button>

<div class="container">
  <h1>Créer une nouvelle exception</h1>


  <div class="input__inner">
    <ng-select class="required rule-type"
               [(ngModel)]="ruleType"
               placeholder="Type de règle"
               [disabled]="creationData.ruleType !== undefined">
      <ng-option *ngFor="let rulePossibility of rulePossibilities" [value]="rulePossibility.type">
        {{rulePossibility.name}}
      </ng-option>
    </ng-select>
  </div>

  <div class="input__inner">
    <ng-select class="required employee"
               [(ngModel)]="employee"
               placeholder="Employé"
               [disabled]="creationData.employeeWithRules !== undefined">
      <ng-option *ngFor="let possibleEmployee of possibleEmployees" [value]="possibleEmployee">
        {{toStringService.employeeName(possibleEmployee)}}
      </ng-option>
    </ng-select>
  </div>

  <div class="input__inner" *ngIf="creationData.choice == AssociationRuleCreationChoice.TRANSPORT">
    <ng-select class="required transport"
               [(ngModel)]="transport"
               placeholder="Transport"
               appendTo="body"
               [disabled]="creationData.transport !== undefined || forceAllOut">
      <ng-option *ngFor="let possibleTransport of possibleTransports" [value]="possibleTransport">
        <div class="possible-transport">
          <span>{{toStringService.transportPatientName(possibleTransport)}}</span>
          <span>-</span>
          <img *ngIf="possibleTransport!.timeWindowType == 'origin'" src="assets/images/ambulance-aller.svg">
          <img *ngIf="possibleTransport!.timeWindowType == 'destination'" src="assets/images/ambulance-retour.svg">
          <span>{{toStringService.transportConstraintTimeRange(possibleTransport)}}</span>
        </div>
      </ng-option>
    </ng-select>
  </div>

  <div class="input__inner" *ngIf="creationData.choice == AssociationRuleCreationChoice.VEHICLE">
    <ng-select class="required vehicle"
               [(ngModel)]="vehicle"
               placeholder="Véhicule"
               appendTo="body"
               [disabled]="creationData.vehicle !== undefined">
      <ng-option *ngFor="let possibleVehicle of possibleVehicles" [value]="possibleVehicle">
          {{toStringService.vehicleName(possibleVehicle)}}
          <img *ngIf="differentDepot(possibleVehicle)" src="assets/images/warning.svg">
      </ng-option>
    </ng-select>
  </div>

  <label *ngIf="creationData.choice == AssociationRuleCreationChoice.TRANSPORT && creationData.ruleType == RuleType.FORCE_OUT">
    <input class="input__inner" type="checkbox"
           [ngModel]="forceAllOut"
           (ngModelChange)="modifyForceAllOut($event)"
           />
    Interdire toutes les missions non forcées
  </label>

  <div class="button-container">
    <button class="rectangular-button first-type-button" (click)="close()">
      Annuler
    </button>
    <button class="rectangular-button important-button create-button"
            (click)="createAssociationRule()"
            [disabled]="!isRuleComplete()">
      <div class="start-button-label">
        Créer
      </div>
      <img src="assets/images/arrow-right.svg">
    </button>
  </div>
</div>
