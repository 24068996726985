import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SolveParameters, MeasuredSolution } from './data-definitions';
import { CurrentProjectService } from './current-project.service';
import { DalConfiguration } from './base-service';
import { BaseMeasuresService } from './base-measures.service';
import { BaseRouteSolutionService } from './base-route-solution.service';
import { BaseDepotInfoService } from './base-depot-info.service';


@Injectable({
  providedIn: 'root'
})
export class SolveService {
  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
  };
  private stopAsked: boolean = false;

  constructor(private http: HttpClient,
              private measuresService: BaseMeasuresService,
              private routeService: BaseRouteSolutionService,
              private depotInfoService: BaseDepotInfoService,
              private currentProjectService: CurrentProjectService) {
  }

  _extractSolveUrl(context: any) {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/solve`;
  }

  _extractStopUrl(context: any) {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/solve/stop`;
  }

  async solve(parameters: SolveParameters) {
    this.stopAsked = false;
    let url = this._extractSolveUrl({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
    let measuredSolution = await this.http.post<MeasuredSolution>(url, parameters, this.httpOptions).toPromise();
    if (this.stopAsked) return;
    this.measuresService._fill([measuredSolution.measures]);
    this.depotInfoService.clearAndReloadAll();
    this.routeService._fill(measuredSolution.solution.routeSolutions);
    this.routeService._takeOffFilter((id, element) =>
      element.projectId === this.currentProjectService.getProjectId() &&
      measuredSolution.solution.routeSolutions.findIndex(e => this.routeService._extractElementId(e) === id) === -1
    );
  }

  async stop() {
    this.stopAsked = true;
    let url = this._extractStopUrl({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
    await this.http.post<any>(url, null, this.httpOptions).toPromise();
  }

}
