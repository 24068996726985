import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { AuthorizationCheckService } from "./authorization-check.service";
import { BaseAuthorizationService } from "./authorization.service";
import { CurrentProjectService } from "./current-project.service";
import { CurrentUserService } from "./current-user.service";
import { MessageService } from "./message.service";
import { BaseUserService } from "./user.service";

@Injectable()
export class AuthGuard implements CanActivate {

  pendingModifications: boolean = false;

  constructor(private userService: BaseUserService,
              private authorizationService: BaseAuthorizationService,
              private authorizationCheckService: AuthorizationCheckService,
              private currentProjectService: CurrentProjectService,
              private currentUserService: CurrentUserService,
              private messageService: MessageService,
              private cookieService: CookieService,
              private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot) {
    let environmentId = route.params['environmentId'];
    let projectId = route.params['projectId'];
    let isAdminRequired = route.data.isAdminRequired;
    this.pendingModifications = false;
    if (this.cookieService.get("jwtToken")) {
      let cookieInfo = this.parseJwt(this.cookieService.get("jwtToken"));
      if (cookieInfo.environmentId == environmentId) this.currentUserService.login(cookieInfo.userId);
    }
    let userId = this.currentUserService.getUserId();
    if (userId === undefined) {
      this.messageService.addErrorMessage("You must be logged in to access this URL.");
      this.router.navigateByUrl("/login");
      return false;
    } else if (environmentId === null) {
      this.messageService.addErrorMessage("Environment id not found in the URL.");
      let currentUser = await this.userService.reload();
      if (currentUser === undefined || currentUser.environmentId === -1) {
        this.router.navigateByUrl("/login");
        return false;
      }
      this.router.navigateByUrl("environment/" + currentUser.environmentId + "/project-pick");
      return false;
    } else {
      if (projectId === undefined || projectId === null || isNaN(Number(projectId))) {
        this.currentProjectService.changeEnvironement(Number.parseInt(environmentId));
      } else {
        this.currentProjectService.changeProject(Number.parseInt(environmentId), Number.parseInt(projectId));
      }
      if (isAdminRequired) {
        let currentUser = await this.userService.reload();
        if (!currentUser) return false;
        if (!this.authorizationCheckService.checkAdminAuthorization(currentUser)) {
          this.router.navigateByUrl("environment/" + currentUser.environmentId + "/project-pick");
          return false;
        }
        return true;
      }
      let currentUserAuthorizations = await this.authorizationService.reloadAllFromUser();
      if(!this.pendingModifications) {
        this.pendingModifications = true;
        window.setTimeout(() => {
          this.pendingModifications = false;
          if (!this.authorizationCheckService.checkAuthorization(currentUserAuthorizations)) {
            this.router.navigateByUrl("environment/" + environmentId + "/project-pick");
            return false;
          }
          return true;
        }, 0);
      }
      return true;
    }
  }

  parseJwt(token: string) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  };
}