import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BaseAuthorizationService } from './authorization.service';
import { BaseLocationService } from './base-location.service';
import { BaseMeasuresService } from './base-measures.service';
import { BaseTransportService } from './base-transport.service';
import { BaseEmployeeService } from './base-employee.service';
import { BaseRouteSolutionService } from './base-route-solution.service';
import { BaseService, DalConfiguration } from './base-service';
import { BaseVehicleService } from './base-vehicle.service';
import { CurrentProjectService } from './current-project.service';
import { ProjectData } from './data-definitions';
import { HttpDalService } from './http-dal.service';
import {BaseDepotService} from "./base-depot.service";
import {BaseParameterService} from "./base-parameter.service";
import {BaseErrorService} from "./base-error.service";
import {BaseAssociationRuleService} from "./base-association-rule.service";

@Injectable({
  providedIn: 'root'
})
export class BaseProjectService extends BaseService<ProjectData> {
  constructor(
        http: HttpDalService,
        private vehicleService: BaseVehicleService,
        private locationService: BaseLocationService,
        private depotService: BaseDepotService,
        private transportService: BaseTransportService,
        private employeeService: BaseEmployeeService,
        private associationRuleService: BaseAssociationRuleService,
        private parameterService: BaseParameterService,
        private baseErrorService: BaseErrorService,
        private authorizationService: BaseAuthorizationService,
        private routeService: BaseRouteSolutionService,
        private measuresService: BaseMeasuresService,
        private currentProjectService: CurrentProjectService) {
    super(http);

    this._addStoreDefinition(
      'by-environment-id',
       project => [project.environmentId]
    );

    this._addDeleteCascadeRelation(
      vehicleService,
      v => v.projectId
    );

    this._addDeleteCascadeRelation(
      depotService,
      l => l.projectId
    );

    this._addDeleteCascadeRelation(
      locationService,
      l => l.projectId
    );

    this._addDeleteCascadeRelation(
      routeService,
      r => r.projectId
    );

    this._addDeleteCascadeRelation(
      transportService,
      t => t.projectId
    );

    this._addDeleteCascadeRelation(
      employeeService,
      e => e.projectId
    );

    this._addDeleteCascadeRelation(
      associationRuleService,
      p => p.projectId
    );

    this._addDeleteCascadeRelation(
      parameterService,
      p => p.projectId
    );

    this._addDeleteCascadeRelation(
      authorizationService,
      upa => upa.projectId
    );

    this._addDeleteCascadeRelation(
      baseErrorService,
      e => e.projectId
    );
  }

  _extractParentUrl(context: any): string {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/simple`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/simple/${context.projectId}`;
  }

  _extractElementId(project: ProjectData) {
    return project.id;
  }

  async updateName(project: ProjectData) {
    await this._updateElementProperty(
      this._extractElementUrl({
        environmentId: project.environmentId,
        projectId: project.id
      }) + '/name',
      project, project.name);
  }

  async updateLastModificationDate(project: ProjectData) {
    let newDate = new Date(Date.now());
    let modifiedProject: ProjectData = {...project, lastModificationDate:  newDate.toISOString()};
    await this._updateElementProperty(
      this._extractElementUrl({
        environmentId: project.environmentId,
        projectId: project.id
      }) + '/modification-date',
      modifiedProject, modifiedProject.lastModificationDate);
  }

  async remove(project: ProjectData) {
    await this._removeElement({
      environmentId: project.environmentId,
      projectId: project.id
    }, project);
  }

  add(project: ProjectData) {
    return this._addElement({
      environmentId: this.currentProjectService.getEnvironmentId()
    }, project);
  }

  clone(project: ProjectData) {
    return this._clone(project);
  }

  load() {
    return this._loadElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  async reload() {
    await this._reloadElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
    await this.measuresService.reload();
    await this.routeService.clearAndReloadAll();
    await this.transportService.clearAndReloadAll();
    await this.depotService.clearAndReloadAll();
    await this.vehicleService.clearAndReloadAll();
    await this.employeeService.clearAndReloadAll();
    await this.locationService.clearAndReloadAll();
    await this.associationRuleService.clearAndReloadAll();
  }

  listen() {
    return this.currentProjectService.findProjectId().pipe(
      mergeMap(projectId => projectId === undefined ? EMPTY : this._listenElement(projectId))
    );
  }

  loadAll() {
    return this._loadElements({
      environmentId: this.currentProjectService.getEnvironmentId()
    });
  }

  reloadAll() {
    return this._clearAndReloadElements({
      environmentId: this.currentProjectService.getEnvironmentId()
    });
  }

  listenAll() {
    return this.currentProjectService.findEnvironmentId().pipe(
      mergeMap(environmentId => environmentId === undefined ? EMPTY : this._listenElements('by-environment-id', [environmentId]))
    );
  }

}
