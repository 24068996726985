<form id="solve-information"></form>

<button class="rounded-button first-type-button close-button" (click)="close()">
  <img src="assets/images/cross.svg">
</button>

<div class="container">
  <h1>Optimiser la planification</h1>
  <div class="input__inner">
    <input id="time-limit" name="time-limit" type="number"
           form="solve-information" min=1 required
           class="input__field" [class.input__field--has-value]="parameters.timeLimit"
           [(ngModel)]="parameters.timeLimit">
    <label for="time-limit" class="input__label">
      <img src="assets/images/sync.svg" class="input__label__icon">
      Temps de calcul souhaité (s)
    </label>
  </div>
  <div class="button-container">
    <button class="rectangular-button first-type-button" (click)="cancel()">
      Annuler
    </button>
    <button class="rectangular-button important-button start-button" (click)="solve()" [disabled]="this.isRunning || parameters.timeLimit <= 0">
      <div class="start-button-label">
        Lancer l'optimisation
      </div>
      <img src="assets/images/arrow-right.svg">
    </button>
  </div>
  <app-modal-progress-bar></app-modal-progress-bar>
</div>
