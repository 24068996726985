import { NgxMatMomentAdapter } from "@angular-material-components/moment-adapter";
import {Injectable} from "@angular/core";

@Injectable()
export class CustomDateAdapter extends NgxMatMomentAdapter {

  /** Start week on Monday */
  getFirstDayOfWeek(): number {
   return 1;
  }

}
