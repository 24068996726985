import {Injectable} from "@angular/core";
import {BehaviorSubject, combineLatest} from "rxjs";
import {EventhubSectorData, ProjectData} from "./data-definitions";
import {BaseProjectService} from "./base-project.service";
import {HttpErrorResponse} from "@angular/common/http";
import {MessageService} from "./message.service";
import { BaseSectorService } from "./base-sector.service";

export interface ProjectInformation {
  project: ProjectData,
  title: string,
  trafficProfileName: string,
  parameterProfileName: string,
  lastModificationString: string
}

@Injectable({
  providedIn: 'root',
})
export class ProjectInformationService {
  projects = new BehaviorSubject<ProjectData[]>([]);
  sectors = new BehaviorSubject<EventhubSectorData[]>([]);

  projectInformationArray = new BehaviorSubject<ProjectInformation[]>([]);

  constructor(private baseProjectService: BaseProjectService,
              private baseSectorService: BaseSectorService,
              private messageService: MessageService) {

    combineLatest([this.projects, this.sectors]).subscribe(
      ([projects, sectors]) => {
        this.projectInformationArray.next(projects.map(p => <ProjectInformation>{
          project: p,
          title: this.createProjectTitle(p, sectors),
          trafficProfileName: p.trafficProfileName,
          parameterProfileName: p.parameterProfileName,
          lastModificationString: this.createLastModificationString(p)
        }));
      });
    this.baseProjectService.listenAll().subscribe(projects => {
      this.projects.next(projects);
    })
    this.baseSectorService.listenAll().subscribe(sectors => {
      this.sectors.next(sectors);
    })
  }

  async loadAll() {
    try {
      await Promise.all([this.baseProjectService.reloadAll()]);
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  createLastModificationString(project: ProjectData): string {
    const options: Intl.DateTimeFormatOptions = { day: "numeric", month: "numeric", year: "numeric"};
    let date: Date = new Date(project.lastModificationDate);
    let minutes = date.getMinutes();
    let minutesStr = minutes < 10 ? "0" + date.getMinutes().toString() : date.getMinutes().toString();
    return "Modifié le " + date.toLocaleDateString("fr", options) + " à " + date.getHours()
      + "h" + minutesStr;
  }

  createProjectTitle(project: ProjectData, sectors: EventhubSectorData[]): string {
    const options: Intl.DateTimeFormatOptions = { month: "long", day: "numeric"};
    let date: Date = new Date(project.planningDate!)
    let dateStr = date.toLocaleDateString("fr", options);
    let title = dateStr;
    return title;
  }

  getProjectInformationArray() {
    return this.projectInformationArray.asObservable();
  }
}
