import {Component, EventEmitter, Inject, Output} from '@angular/core';
import { EmployeeData } from '../data-definitions';
import { BaseEmployeeService } from '../base-employee.service';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ToStringService} from "../to-string.service";
import {DateService} from "../date.service";

@Component({
    selector: 'app-employee-modification-modal',
    templateUrl: './employee-modification-modal.component.html',
    styleUrls: ['./employee-modification-modal.component.css',
                '../../assets/standard-page.css',
                '../../assets/buttons.css',
                '../../assets/forms.css',
                '../../assets/modal.css',
                '../../assets/select.css']
})
export class EmployeeModificationModalComponent {
    @Output() closeModal = new EventEmitter<boolean>();

    initialEmployees: EmployeeData[] = [];
    modifiedEmployees: EmployeeData[] = [];
    validWishEmployees: Map<number, boolean> = new Map<number, boolean>();
    validAmplitudeEmployees: Map<number, boolean> = new Map<number, boolean>();
    validServiceEmployees: Map<number, boolean> = new Map<number, boolean>();

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public toStringService: ToStringService, 
                public dateService: DateService,
                private baseEmployeeService: BaseEmployeeService) {
        for (let employee of data.employees) {
            this.initialEmployees.push(employee);
            this.modifiedEmployees.push({...employee});
            this.validWishEmployees.set(employee.id, true);
            this.validAmplitudeEmployees.set(employee.id, true);
            this.validServiceEmployees.set(employee.id, true);
        }
    }

    checkDates(date1: string, date2: string) : boolean {
        date1 = this.dateService.dayTimeToSQLTime(date1);
        date2 = this.dateService.dayTimeToSQLTime(date2);
        return this.dateService.timeStringToDate(date1) <= 
            this.dateService.timeStringToDate(date2);
    }

    changeFirstPickupTime(employee: EmployeeData, $event: string) {
        if ($event.split(":").length == 2) {
            employee.serviceFirstPickup = $event;
        } else {
            employee.serviceFirstPickup = undefined;
        }
        this.validServiceEmployees.set(employee.id, 
            employee.serviceFirstPickup == undefined || 
            employee.serviceLastDropoff == undefined || 
            this.checkDates(employee.serviceFirstPickup, employee.serviceLastDropoff)
        );
    }

    changePriority(employee: EmployeeData, $event: any) {
        if ($event < 0) employee.servicePriority = 0;
        else employee.servicePriority = $event;
    }

    changeLastDropoffTime(employee: EmployeeData, $event: string) {
        if ($event.split(":").length == 2) {
            employee.serviceLastDropoff = $event;
        } else {
            employee.serviceLastDropoff = undefined;
        }
        this.validServiceEmployees.set(employee.id, 
            employee.serviceFirstPickup == undefined ||
            employee.serviceLastDropoff == undefined ||
            this.checkDates(employee.serviceFirstPickup, employee.serviceLastDropoff)
        );

    }

    changeServiceStartTime(employee: EmployeeData, $event: string) {
        if ($event.split(":").length == 2) {
            employee.serviceStart = $event;
        } else {
            employee.serviceStart = undefined;
        }
        this.validWishEmployees.set(employee.id, 
            employee.serviceStart == undefined ||
            employee.serviceEnd == undefined ||
            this.checkDates(employee.serviceStart, employee.serviceEnd)
        );
    }

    changeServiceEndTime(employee: EmployeeData, $event: string) {
        if ($event.split(":").length == 2) {
            employee.serviceEnd = $event;
        } else {
            employee.serviceEnd = undefined;
        }
        this.validWishEmployees.set(employee.id, 
            employee.serviceStart == undefined ||
            employee.serviceEnd == undefined ||
            this.checkDates(employee.serviceStart, employee.serviceEnd)
        );
    }

    changeAmplitudeMin(employee: EmployeeData, $event: string) {
        if ($event.split(":").length == 2) {
            employee.serviceAmplitudeMin = $event;
        } else {
            employee.serviceAmplitudeMin = undefined;
        }
        this.validAmplitudeEmployees.set(employee.id, 
            employee.serviceAmplitudeMin == undefined ||
            employee.serviceAmplitudeMax == undefined ||
            this.checkDates(employee.serviceAmplitudeMin, employee.serviceAmplitudeMax)
        );
    }

    changeAmplitudeMax(employee: EmployeeData, $event: string) {
        if ($event.split(":").length == 2) {
            employee.serviceAmplitudeMax = $event;
        } else {
            employee.serviceAmplitudeMax = undefined;
        }
        this.validAmplitudeEmployees.set(employee.id, 
            employee.serviceAmplitudeMin == undefined ||
            employee.serviceAmplitudeMax == undefined ||
            this.checkDates(employee.serviceAmplitudeMin, employee.serviceAmplitudeMax)
        );
    }

    areAllEmployeesWishValid() : boolean{
        return Array.from(this.validWishEmployees.values()).every(b => b);
    }

    areAllEmployeesServiceValid() : boolean {
        return Array.from(this.validServiceEmployees.values()).every(b => b);
    }

    areAllEmployeesAmplitudeValid() : boolean {
        return Array.from(this.validAmplitudeEmployees.values()).every(b => b);
    }

    areAllEmployeesValid() : boolean {
        return this.areAllEmployeesAmplitudeValid() && this.areAllEmployeesServiceValid()
            && this.areAllEmployeesWishValid()
    }

    updateEmployeeTime(e: EmployeeData) {
        if(e.serviceFirstPickup)
            e.serviceFirstPickup = this.dateService.dayTimeToSQLTime(e.serviceFirstPickup);
        if(e.serviceLastDropoff)
            e.serviceLastDropoff = this.dateService.dayTimeToSQLTime(e.serviceLastDropoff);
        if(e.serviceStart)
            e.serviceStart = this.dateService.dayTimeToSQLTime(e.serviceStart);
        if(e.serviceEnd)
            e.serviceEnd = this.dateService.dayTimeToSQLTime(e.serviceEnd);
        if(e.serviceAmplitudeMin)
            e.serviceAmplitudeMin = this.dateService.dayTimeToSQLTime(e.serviceAmplitudeMin);
        if(e.serviceAmplitudeMax)
            e.serviceAmplitudeMax = this.dateService.dayTimeToSQLTime(e.serviceAmplitudeMax);
    }

    async applyModifications() {
        let actuallyModifiedEmployees = [];
        for (let i = 0; i < this.initialEmployees.length; i++) {
            let initialEmployee = this.initialEmployees[i];
            let possiblyModifiedEmployee = this.modifiedEmployees[i];
            if (JSON.stringify(initialEmployee) === JSON.stringify(possiblyModifiedEmployee)) continue;
            this.updateEmployeeTime(possiblyModifiedEmployee);
            actuallyModifiedEmployees.push(possiblyModifiedEmployee);
        }
        if (actuallyModifiedEmployees.length > 0) {
            let promise = this.baseEmployeeService.updateMultiple(actuallyModifiedEmployees);
            await promise;
        }
        this.close();
    }

    close() {
        this.closeModal.next(true);
    }

}