import {Component, EventEmitter, Inject, Output} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfileData } from '../data-definitions';
import {MessageService} from "../message.service";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CurrentProjectService } from '../current-project.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-profile-modal',
    templateUrl: './profile-modal.component.html',
    styleUrls: ['./profile-modal.component.css',
                '../../assets/standard-page.css',
                '../../assets/forms.css',
                '../../assets/buttons.css',
                '../../assets/modal.css']
})
export class ProfileModalComponent {
    @Output() closeModal = new EventEmitter<boolean>();

    creating = false;

    name: string;
    id?: number;
    sectorApiId: string;
    projectType: string;
    profileType: string;
    default: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data :any,
                private messageService: MessageService,
                private http: HttpClient) {
        this.id = data.id;
        this.name = data.name;
        this.sectorApiId = data.sectorApiId;
        this.projectType = data.projectType;
        this.profileType = data.profileType.toUpperCase();
        this.default = data.default;
    }

    close() {
        this.closeModal.next(true);
    }

    toggleDefault($event: any) {
        this.default = $event.checked;
    }

    async createOrUpdateProfile() {
        let profile = {
            name: this.name?.trim(),
            profileType: this.profileType,
            sectorApiId: this.sectorApiId,
            projectType : this.projectType,
            isDefault: this.default
        }

        this.creating = true;
        document.body.style.cursor
        let url = `${environment.apiUrl}/sectors/${this.sectorApiId}/profiles/`
        try {
            if (this.id) {
                url += this.id;
                await this.http.put<ProfileData>(url, profile, {withCredentials:true}).toPromise();
            } else {
                await this.http.post<ProfileData>(url, profile, {withCredentials:true}).toPromise();
            }
            this.close();
        } catch (error) {
            if (error instanceof HttpErrorResponse)
                this.messageService.addHttpError(error);
        } finally {
            document.body.style.cursor = "auto"
            this.creating = false;
        }
    }
}