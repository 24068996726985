import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SolveStatusService {
  timeLimit: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  isSolving_: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isCancelled_: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  startSolving(timeLimit: number) {
    this.timeLimit.next(timeLimit);
    this.isSolving_.next(true);
    this.isCancelled_.next(false);
  }

  endSolving() {
    this.isSolving_.next(false);
    this.isCancelled_.next(false);
  }

  cancel() {
    this.isCancelled_.next(true);
  }

  getTimeLimit(): Observable<number> {
    return this.timeLimit.asObservable();
  }

  isSolving(): Observable<boolean> {
    return this.isSolving_.asObservable();
  }

  isCancelled(): Observable<boolean> {
    return this.isCancelled_.asObservable();
  }

}
