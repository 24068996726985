import { BehaviorSubject, Observable, Subject, Subscription, Unsubscribable } from "rxjs";

export interface DataMergerIndexable {
  id: number
}

export class DataMerger<TMainData extends DataMergerIndexable & TMergedData[keyof TMergedData], TMergedData extends DataMergerIndexable> implements Unsubscribable {
  _mainKeyName: keyof TMergedData;
  _subscriptions: Subscription;
  _lastSubElements: Map<keyof TMergedData, (DataMergerIndexable & TMergedData[keyof TMergedData])[]>;
  _subKeyMappers: Map<keyof TMergedData, (mainData: TMainData) => number>;
  _reverseKeyMappers: Map<keyof TMergedData, (subData: DataMergerIndexable & TMergedData[keyof TMergedData]) => number>;
  _mappedMergedElements: Map<number, TMergedData>;
  _mergedElements: TMergedData[];
  _subject: Subject<TMergedData[]>;

  constructor(
      keyName: keyof TMergedData,
      mainObs: Observable<TMainData[]>) {
    this._mainKeyName = keyName;
    this._subscriptions = new Subscription();
    this._lastSubElements = new Map<keyof TMergedData, (DataMergerIndexable & TMergedData[keyof TMergedData])[]>();
    this._subKeyMappers = new Map<keyof TMergedData, (mainData: TMainData) => number>();
    this._reverseKeyMappers = new Map<keyof TMergedData, (subData: DataMergerIndexable & TMergedData[keyof TMergedData]) => number>();
    this._mappedMergedElements = new Map<number, TMergedData>();
    this._mergedElements = [];
    this._subject = new BehaviorSubject<TMergedData[]>([]);

    let subscription = mainObs.subscribe(mainElements => {
      this._mergedElements = [];
      for(let mainElement of mainElements) {
        let mergedElement = this._mappedMergedElements.get(mainElement.id);
        if(!mergedElement) mergedElement = <TMergedData>{};
        mergedElement.id = mainElement.id;
        (<any>mergedElement[keyName]) = mainElement;
        this._mergedElements.push(mergedElement);
      }

      this._mappedMergedElements.clear();
      this._mergedElements.forEach(e => this._mappedMergedElements.set(e.id, e));
      let nbModifications = this._fillProperties();
      if(nbModifications == 0) this._subject.next(this._mergedElements);
    });

    this._subscriptions.add(subscription);
  }

  _fillProperties() {
    let nbModifications = 0;
    this._subKeyMappers.forEach((mapper, subKey) => {
      let subElements = this._lastSubElements.get(subKey);
      if(!subElements) return;
      let mappedSubElements = new Map<number, DataMergerIndexable>();
      subElements.forEach(se => mappedSubElements.set(se.id, se));
      for(let mergedElement of this._mergedElements) {
        let mainElement = mergedElement[this._mainKeyName];
        let subElementId = mapper(<TMainData>mainElement);
        let newSubElement = mappedSubElements.get(subElementId);
        let prevSubElement = mergedElement[subKey];
        if(<any>newSubElement !== prevSubElement) {
          (<any>mergedElement[subKey]) = newSubElement;
          nbModifications++;
        }
      }
    });

    this._reverseKeyMappers.forEach((mapper, subKey) => {
      let subElements = this._lastSubElements.get(subKey);
      if(!subElements) return;
      let visitedMergedElements = new Set<TMergedData>();
      for(let subElement of subElements) {
        let mainId = mapper(subElement);
        let mergedElement = this._mappedMergedElements.get(mainId);
        if(!mergedElement) continue;
        let prevSubElement = mergedElement[subKey];
        if(prevSubElement !== subElement) {
          mergedElement[subKey] = subElement;
          nbModifications++;
        }
        visitedMergedElements.add(mergedElement);
      }

      for(let mergedElement of this._mergedElements) {
        if(visitedMergedElements.has(mergedElement)) continue;
        (<any>mergedElement[subKey]) = undefined;
        nbModifications++;
      }
    });

    if(nbModifications > 0)
      this._subject.next(this._mergedElements);
    return nbModifications;
  }

  unsubscribe(): void {
    this._subscriptions.unsubscribe();
    this._subject.complete();
    this._subject.unsubscribe();
  }

  listen(): Observable<TMergedData[]> {
    return this._subject.asObservable();
  }

  addSubMapper<TSubData extends DataMergerIndexable & TMergedData[keyof TMergedData]>(
      subKeyName: keyof TMergedData,
      subObs: Observable<TSubData[]>,
      mainMapper: (mainData: TMainData) => number) {

    this._subKeyMappers.set(subKeyName, mainMapper);
    let subscription = subObs.subscribe(subElements => {
      this._lastSubElements.set(subKeyName, subElements);
      this._fillProperties();
    });
    this._subscriptions.add(subscription);
  }

  addReverseMapper<TSubData extends DataMergerIndexable & TMergedData[keyof TMergedData]>(
      subKeyName: keyof TMergedData,
      subObs: Observable<TSubData[]>,
      reverseMapper: (subData: TSubData) => number) {

    this._reverseKeyMappers.set(subKeyName, <any>reverseMapper);
    let subscription = subObs.subscribe(subElements => {
      this._lastSubElements.set(subKeyName, subElements);
      this._fillProperties();
    });
    this._subscriptions.add(subscription);
  }
}

