
<button class="rounded-button first-type-button close-button" (click)="close()">
  <img src="assets/images/cross.svg">
</button>

<div class="container">
  <h1>Créer une nouvelle planification</h1>
  <div class="creation_modal_container">
    <div class="creation_modal_row">
      <div class="input__inner">
        <input id="planning-name" name="planning-name" type="text"
              class="input__field" [class.input__field--has-value]="name"
              [(ngModel)]="name">
        <label for="planning-name" class="input__label">
          <img src="assets/images/document.svg">
          <span>&nbsp;Nom de la nouvelle planification</span>
        </label>
      </div>

      <div class="input__inner">
        <input id="planning-date" name="planning-date" type="date"
              required
              class="input__field" [class.input__field--has-value]="date"
              [ngModel]="date | date:'yyyy-MM-dd'" (ngModelChange)="date = $event">
        <label for="planning-name" class="input__label">
          <img src="assets/images/calendar.svg">
          <span>Date de planification</span>
        </label>
      </div>

      <div class="input__inner">
        <input id="planning-time-limit" name="planning-time-limit" type="time"
              class="input__field" [class.input__field--has-value]="planningTimeLimit"
              [(ngModel)]="planningTimeLimit">
        <label for="planning-time-limit" class="input__label">
          <img src="assets/images/clock.svg">
          <span>Planifier jusqu'à</span>
        </label>
      </div>

      <div class="input__inner">
        <ng-select class="planning-pool-select"
                    [items]="projectTypes"
                    [(ngModel)]="selectedType"
                    (ngModelChange)="resetProfiles()"
                    placeholder="Type de planification"
                    [clearable]="false"
        >
        </ng-select>
      </div>
    </div>
    <div class="creation_modal_row area_creation">
      <div class="input__inner">
        <ng-select class="planning-pool-select required"
                  [items]="regulations"
                  bindValue="apiId"
                  bindLabel="name"
                  [groupBy]="getSectorName"
                  [(ngModel)]="selectedRegulations"
                  [selectableGroup]="true"
                  [selectableGroupAsModel]="false"
                  [multiple]="true"
                  appendTo="body"
                  [closeOnSelect]="false"
                  (ngModelChange)="toggleRegulation()"
                  placeholder="Régulations"
        >
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-icon left" (click)="clear(item)">×</span>
            <span class="ng-value-label">{{getRegulationCount(item)}}</span>
          </ng-template>
        </ng-select>
      </div>

      <div class="input__inner">
        <ng-select 
                  class="planning-pool-select required"
                  [items]="showableTerritories"
                  bindValue="apiId"
                  bindLabel="name"
                  [groupBy]="getRegulationName"
                  [selectableGroup]="true"
                  [selectableGroupAsModel]="false"
                  [(ngModel)]="selectedTerritories"
                  [multiple]="true"
                  appendTo="body"
                  [disabled]="showableTerritories.length === 0"
                  [closeOnSelect]="false"
                  (ngModelChange)="toggleTerritory()"
                  placeholder="Territoires"
          >
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-icon left" (click)="clear(item)">×</span>
            <span class="ng-value-label">{{getTerritoryCount(item)}}</span>
          </ng-template>
        </ng-select>
      </div>

      <div class="input__inner">
        <ng-select class="planning-pool-select required"
                  [items]="showableDepots"
                  bindValue="apiId"
                  bindLabel="name"
                  [groupBy]="getTerritoryName"
                  [selectableGroup]="true"
                  [selectableGroupAsModel]="false"
                  [(ngModel)]="selectedDepots"
                  [multiple]="true"
                  appendTo="body"
                  [disabled]="showableDepots.length === 0"
                  (ngModelChange)="toggleDepot()"
                  [closeOnSelect]="false"
                  placeholder="Bureaux">
        </ng-select>
      </div>
    </div>    
  </div>
  <button class="rectangular-button important-button start-button"
          [disabled]="(selectedDepots.length == 0 || !date) || creating"
          (click)="showProfiles()"
  >
    <div class="start-button-label">
      Confirmer la sélection
    </div>
  </button>
  <div class="profiles">
    <div>
      <label class="profile-type">Profil de paramètres</label>
      <div class="input-profile">
        <ng-select class="planning-pool-select required"
                    [items]="defaultProfiles"
                    bindValue="id"
                    bindLabel="name"
                    [(ngModel)]="selectedDefaultProfile"
                    placeholder="Profil de paramètres"
                    appendTo="body"
                    [disabled]="confirmation === false"
                    [clearable]="false"
                    (ngModelChange)="onParameterProfileChange($event)"
        >
        </ng-select>
      </div>
    </div>
    <div>
      <label class="profile-type">Profil de trafic</label>
      <div class="input-profile">
        <ng-select class="planning-pool-select required"
                    [items]="trafficProfiles"
                    bindValue="id"
                    bindLabel="name"
                    [(ngModel)]="selectedTrafficProfile"
                    placeholder="Profil de trafic"
                    appendTo="body"
                    [disabled]="confirmation === false"
                    [clearable]="false"
                    (ngModelChange)="onTrafficProfileChange($event)"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div class="button-container">
    <button class="rectangular-button first-type-button" (click)="close()">
      Annuler
    </button>
    <button class="rectangular-button important-button start-button"
            (click)="createProject()"
            [disabled]="!confirmation || creating"
    >
      <div class="start-button-label">
        Créer
      </div>
      <img src="assets/images/arrow-right.svg">
    </button>
  </div>
  <app-modal-progress-bar></app-modal-progress-bar>
</div>