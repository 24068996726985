<div class="header">
  <section class="page-title-container">
    <h1 class="page-title">Administration</h1>
  </section>
  <div class="planning-pool-information">
    <ng-select
      [disabled]="selection === 'users'"
      class="planning-pool-select"
      [(ngModel)]="selectedSector"
      placeholder="Secteur"
      [clearable]="false"
    >
      <ng-option *ngFor="let sector of sectors" [value]="sector">
      {{sector.name}}
      </ng-option>
    </ng-select>
    <ng-select
      [disabled]="selection !== 'traffic' && selection !== 'parameters'"
      class="planning-pool-select"
      [items]="types"
      [(ngModel)]="selectedType"
      placeholder="Type de projet"
      [clearable]="false">
    </ng-select>  

  </div>

</div>

<ul>
  <li>
    <button class="bar-element underline-element" [class.active]="selection == 'parameters'"
            (click)="selectTab('parameters')">
      Profils de paramètres
    </button>
  </li>
  <li>
    <button class="bar-element underline-element" [class.active]="selection == 'traffic'"
            (click)="selectTab('traffic')">
      Profils de trafic
    </button>
  </li>
  <li>
    <button class="bar-element underline-element" [class.active]="selection == 'association-rules'"
            (click)="selectTab('association-rules')">
      Exceptions permanentes
    </button>
  </li>
  <li>
    <button class="bar-element underline-element" [class.active]="selection == 'crew-rules'"
            (click)="selectTab('crew-rules')">
      Équipages permanents
    </button>
  </li>
  <li>
    <button class="bar-element underline-element" [class.active]="selection == 'users'"
            (click)="selectTab('users')">
      Utilisateurs
    </button>
  </li>
</ul>

<hr class="seperation">

<app-profile-parameter-modification *ngIf="selection == 'parameters' && selectedSector"
                            [sector]="selectedSector"
                            [projectType]="selectedType"
                            [profileType]="'default'">
</app-profile-parameter-modification>
<app-profile-parameter-modification *ngIf="selection == 'traffic' && selectedSector"
                            [sector]="selectedSector"
                            [projectType]="selectedType"
                            [profileType]="'traffic'">
</app-profile-parameter-modification>
<app-admin-default-association-rules *ngIf="selection == 'association-rules' && selectedSector"
                                      [sector]="selectedSector">
</app-admin-default-association-rules>
<app-admin-default-crew-rules *ngIf="selection == 'crew-rules' && selectedSector"
                                      [sector]="selectedSector">
</app-admin-default-crew-rules>
<app-admin-user *ngIf="selection == 'users'">
</app-admin-user>
