import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { InternalError} from '../data-definitions';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-page-internal-error',
  templateUrl: './page-internal-error.component.html',
  styleUrls: ['./page-internal-error.component.css']
})
export class PageInternalErrorComponent implements OnInit {

  error?: InternalError;

  constructor(private messageService: MessageService,
              private location: Location) {
  }

  ngOnInit(): void {
    this.messageService.getErrorMessage().subscribe(
      error => {
        this.error = error;
      }
    )
  }

  clearError(): void {
    this.messageService.clearError();
    this.error = undefined;
    this.location.back();
  }

}

