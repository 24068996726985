import { Component, OnInit } from '@angular/core';
import {RouteSolutionWithMeasures} from "../data-definitions";
import {MapService} from "../map.service";
import {DateService} from "../date.service";
import {ToStringService} from "../to-string.service";

@Component({
  selector: 'app-map-current-route',
  templateUrl: './map-current-route.component.html',
  styleUrls: ['./map-current-route.component.css']
})
export class MapCurrentRouteComponent implements OnInit {

  routeWithMeasures?: RouteSolutionWithMeasures

  constructor(private mapService: MapService,
              private toStringService: ToStringService) { }

  ngOnInit(): void {
    this.mapService.getSelectedRoute().subscribe(
      route => {
        if (!route) this.routeWithMeasures = undefined;
        else {
          this.routeWithMeasures = route.route;
        }
      }
    )
  }

  getCurrentVehicle(): string {
    if (this.routeWithMeasures) {
      return this.toStringService.vehicleName(this.routeWithMeasures.vehicle)
    } else {
      return "-";
    }
  }
}
