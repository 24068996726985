import { Component, OnInit } from '@angular/core';
import { ParameterTable} from "../parameter-configuration";
import {EventhubSectorData} from "../data-definitions";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {MessageService} from "../message.service";
import { BaseSectorService } from '../base-sector.service';
import { CurrentProjectService } from '../current-project.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css',
              '../../assets/standard-page.css',
              '../../assets/menu.css',
              '../../assets/buttons.css',
              '../../assets/select.css']
})
export class AdminComponent implements OnInit {
  ParameterTable = ParameterTable;

  selection = "parameters"
  updating = false;

  selectedSector?: EventhubSectorData;
  sectors: EventhubSectorData[] = [];

  selectedType: string = "TAP";
  types = ["TAP", "AMBU"];

  savedType?: string;
  savedSector?: EventhubSectorData;

  constructor(private baseSectorService: BaseSectorService,
              private messageService: MessageService,
              private currentProjectService: CurrentProjectService,
              private http: HttpClient) { }

  ngOnInit(): void {
    this.baseSectorService.listenAll().subscribe(sectors => {
      this.sectors = sectors;
      if (sectors.length == 0) return;
      this.selectedSector = sectors[0];
    })
    this.loadData();
  }

  async loadData() {
    try {
      await this.baseSectorService.loadAll();
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  selectTab(name: string) {
    if (this.savedType) {
      this.selectedType = this.savedType;
      this.savedType = undefined;
    } 
    if (this.savedSector) {
      this.selectedSector = this.savedSector;
      this.savedSector = undefined;
    }
    if (name === 'crew-rules') this.selectedType = 'AMBU';
    if (name === 'association-rules') {
      this.savedType = this.selectedType;
      this.selectedType = '';
    }
    if (name === 'users') {
      this.savedType = this.selectedType;
      this.savedSector = this.selectedSector;
      this.selectedType = '';
      this.selectedSector = {
        id: 0,
        name: '',
        apiId: ''
      }
    }
    this.selection = name
  }
}
