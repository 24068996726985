<div class="container">
  <mat-form-field appearance="fill" class="data-selector">
    <mat-select [(value)]="selected" (selectionChange)="changeDataDisplayed()">
      <mat-option value="transports">Missions</mat-option>
      <mat-option value="employees">Employés</mat-option>
      <mat-option value="fleet">Véhicules</mat-option>
      <mat-option value="depots">Bureaux</mat-option>
      <mat-option *ngIf="project?.type === 'AMBU'" value="crews">Équipages</mat-option>
      <mat-option value="locations">Lieux</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="action-buttons-container">
    <button class="rectangular-button first-type-button push-solution"
            [disabled]="!hasSolution || jsonDownloading"
            (click)="downloadJsonFile()">
      Injecter dans la régulation
      <img src="assets/images/upload.svg">
    </button>
    <div class="update-content">
      <div class="button-shift"></div>
      <button class="rectangular-button secondary-type-button update-data"
              [disabled]="projectUpdating"
              (click)="updateProject()">
        Mettre à jour les données
        <img src="assets/images/sync.svg">
      </button>
      <span class="update-date">
        {{lastDataDateString}}
      </span>
    </div>
  </div>
</div>
