import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MessageService} from '../message.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  ProjectData,
  RequirementData,
  RouteActionMeasures,
  RouteSolution,
  RouteSolutionWithLinks,
  RouteTaskActionSolution,
  RouteTaskActionWithLinks,
  Status,
  TransportData,
  TransportDataWithSolution,
  ViolationMeasures
} from '../data-definitions';
import {DataMerger} from '../data-merger';
import {BaseLocationService} from '../base-location.service';
import {Subscription} from 'rxjs';
import {BaseMeasuresService} from '../base-measures.service';
import {map} from 'rxjs/operators';
import {BaseRouteSolutionService} from '../base-route-solution.service';
import {DateService} from '../date.service';
import {BaseVehicleService} from '../base-vehicle.service';
import {BaseRouteTaskActionService} from '../base-route-task-action.service';
import {CurrentProjectService} from '../current-project.service';
import {HttpErrorResponse} from '@angular/common/http';
import {SelectionModel} from "@angular/cdk/collections";
import {BaseTransportService} from "../base-transport.service";
import {BaseProjectService} from "../base-project.service";
import {MatPaginator} from '@angular/material/paginator';
import {ToStringService} from "../to-string.service";
import {BaseEmployeeService} from "../base-employee.service";
import {BaseParameterService} from "../base-parameter.service";
import {Parameter, ParameterType} from "../parameter-configuration";
import {FilterService} from "../filter.service";
import {defaultDialogConfig} from "../dialog-data";
import {
  TransportModificationModalComponent
} from "../transport-modification-modal/transport-modification-modal.component";
import { BaseRequirementService } from '../base-requirement.service';

@Component({
  selector: 'app-transport-table',
  templateUrl: './transport-table.component.html',
  styleUrls: ['./transport-table.component.css',
              '../../assets/tables.css',
              '../../assets/standard-page.css',
              '../../assets/buttons.css',
              '../../assets/tooltip.css']
})
export class TransportTableComponent implements OnInit, OnDestroy, AfterViewInit  {
  Status = Status;

  project?: ProjectData;
  requirements: RequirementData[] = [];

  taskActionDataMerger: DataMerger<RouteTaskActionSolution, RouteTaskActionWithLinks>;
  routeDataMerger: DataMerger<RouteSolution, RouteSolutionWithLinks>;
  dataMerger: DataMerger<TransportData, TransportDataWithSolution>;

  subscriptions: Subscription = new Subscription();

  violationMeasures: ViolationMeasures[] = [];
  dataSource = new MatTableDataSource(<TransportDataWithSolution[]>[]);
  selection = new SelectionModel<TransportDataWithSolution>(true, []);
  nbSelected = 0;

  marginParameter?: Parameter;

  displayedColumns = ['select', 'patient', 'locations', 'direction',
    'time-window-location', 'time-window', 'accuracy', 'status',
    'pickup-start', 'delivery-start', 'employee'];

  @ViewChild(MatSort) sort = new MatSort();
  @ViewChild(MatPaginator) paginator?: MatPaginator;

  filteredValues: Map<string, any> = new Map([
    ["patient", ""],
    ["locations", ""],
    ["direction", ""],
    ["exigency", ""],
    ["constraint", ""],
    ["employee", ""],
    ["status", ""],
  ]);

  matDialogRef?: MatDialogRef<TransportModificationModalComponent>;

  constructor(private baseTransportService: BaseTransportService,
              private baseRequirementService: BaseRequirementService,
              private baseLocationService: BaseLocationService,
              private baseMeasuresService: BaseMeasuresService,
              private baseRouteService: BaseRouteSolutionService,
              private baseTaskActionService: BaseRouteTaskActionService,
              private baseVehicleService: BaseVehicleService,
              private baseEmployeeService: BaseEmployeeService,
              private baseParameterService: BaseParameterService,
              private currentProjectService: CurrentProjectService,
              private baseProjectService: BaseProjectService,
              private messageService: MessageService,
              private dateService: DateService,
              private filterService: FilterService,
              public toStringService: ToStringService,
              public dialog: MatDialog) {
    this.dataMerger = new DataMerger("transport", this.baseTransportService.listenAll());
    this.dataMerger.addSubMapper("pickupLocation", this.baseLocationService.listenAll(), t => t.originId!);
    this.dataMerger.addSubMapper("deliveryLocation", this.baseLocationService.listenAll(), t => t.destinationId);

    this.routeDataMerger = new DataMerger("route", this.baseRouteService.listenAll());
    this.routeDataMerger.addSubMapper("vehicle", this.baseVehicleService.listenAll(), r => r.vehicleId);
    this.routeDataMerger.addSubMapper("employee", this.baseEmployeeService.listenAll(), r => r.employeeId);

    this.taskActionDataMerger = new DataMerger("taskAction", this.baseTaskActionService.listenAll());
    this.taskActionDataMerger.addReverseMapper("routeActionMeasures", this.baseMeasuresService.listen().pipe<RouteActionMeasures[]>(
      map(measures => Array.prototype.concat.apply([],
        measures!.routeMeasures.map(rm => rm.routeActionMeasures)))), ram => ram.routeTaskActionId!);
    this.taskActionDataMerger.addSubMapper("routeWithLinks", this.routeDataMerger.listen(), ta => ta.routeId);

    this.dataMerger.addReverseMapper("deliveryAction", this.taskActionDataMerger.listen().pipe(
      map(taskActions => taskActions.filter(ta => ta.taskAction.deliveryTransportId != null))
    ), ram => ram.taskAction.deliveryTransportId!);
    this.dataMerger.addReverseMapper("pickupAction", this.taskActionDataMerger.listen().pipe(
      map(taskActions => taskActions.filter(ta => ta.taskAction.pickupTransportId != null))
    ), ram => ram.taskAction.pickupTransportId!);
  }

  ngOnInit(): void {
    this.dataMerger.listen().subscribe(transportsWithSolution => {
      this.dataSource.data = transportsWithSolution.sort((a,b) => 
        Number((b.transport.status === Status.SELECTED)) - Number((a.transport.status === Status.SELECTED))
      );

      this.nbSelected = transportsWithSolution.filter(t => t.transport.status == Status.SELECTED).length;
    });
    this.subscriptions.add(this.baseMeasuresService.listen().subscribe(measures => {
      this.violationMeasures = measures!.violationMeasures;
    }));
    this.subscriptions.add(this.baseProjectService.listen().subscribe(project => {
      this.project = project;
    }));
    this.subscriptions.add(this.baseRequirementService.listenAll().subscribe(requirements => {
      this.requirements = requirements;
    }));
    this.subscriptions.add(this.baseParameterService.listenAll().subscribe(parameters => {
      for (let param of parameters) {
        if (param.name == ParameterType[ParameterType.MARGIN])
          this.marginParameter = param;
      }
    }));
    this.dataSource.sortingDataAccessor = this.customSortingDataAccessor();
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.currentProjectService.findProjectId().subscribe(projectId => {
      if (projectId) this.loadData();
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator!;
  }

  ngOnDestroy() {
    this.dataMerger.unsubscribe();
    this.taskActionDataMerger.unsubscribe();
    this.routeDataMerger.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  hasRequirements(requirementsIds: string) {
    if (requirementsIds.trim().length == 0) return false;
    return true;
  }

  countRequirements(requirementsIds: string) {
    return requirementsIds.split(";").length;
  }

  getRequirement(requirementId: number) {
    const requirement = this.requirements.find(r => r.apiId == requirementId);
    return requirement?.name;
  }

  customSortingDataAccessor() {
    return (item: TransportDataWithSolution, property: string) => {
      switch(property) {
        case 'patient': return item.transport.patientLastName
        case 'locations': return item.transport.originId != undefined ?
          this.toStringService.locationAddress(item.pickupLocation!).toLowerCase() : "";
        case 'direction': return this.toStringService.transportDirection(item.transport);
        case 'time-window-location': return item.transport.timeWindowType == 'origin' ? "P" : "D";
        case 'time-window': return this.dateService.iso8601StringOrDateToDate(item.transport.timeWindowStart).getTime();
        case 'accuracy': return item.transport.timeWindowAccuracy ? 1 : 0;
        case 'status': return item.transport.status;
        case 'pickup-start': return item.pickupAction && item.pickupAction.routeActionMeasures
          ? this.dateService.iso8601StringOrDateToDate(item.pickupAction!.routeActionMeasures.start).getTime() : -1;
        case 'delivery-start': return item.deliveryAction && item.deliveryAction.routeActionMeasures
          ? this.dateService.iso8601StringOrDateToDate(item.deliveryAction!.routeActionMeasures.start).getTime() : -1;
        case 'employee': return item.deliveryAction ? item.deliveryAction.routeWithLinks.employee.lastName.toLowerCase() : "";
        default: return "";
      }
    };
  }

  directionFilter(data: TransportData, key: string) {
    switch (key) {
      case "Aller": return data.direction == 'GO';
      case "Retour": return data.direction == 'RETURN';
      case "Transfert": return data.direction == 'TRANSFER';
      default: return false;
    }
  }

  exigencyFilter(data: TransportData, key: string) {
    switch (key) {
      case "Prise en charge": return data.timeWindowType == 'origin';
      case "Dépose": return data.timeWindowType == 'destination';
      default: return false;
    }
  }

  timeWindowAccuracyFilter(data: TransportData, key: string) {
    switch (key) {
      case "Certain": return data.timeWindowAccuracy;
      case "Incertain": return !data.timeWindowAccuracy;
      default: return false;
    }
  }

  customFilterPredicate() {
    return (data: TransportDataWithSolution, _: string): boolean => {
      let patientCondition = this.filteredValues.get("patient")
        ? this.toStringService.transportPatientName(data.transport).trim().toLowerCase().indexOf(this.filteredValues.get("patient")!) !== -1
        : true;
      let locationsCondition = this.filteredValues.get("locations")
        ? this.toStringService.locationAddress(data.pickupLocation!).trim().toLowerCase().indexOf(this.filteredValues.get("locations")!) !== -1
          || this.toStringService.locationAddress(data.deliveryLocation!).trim().toLowerCase().indexOf(this.filteredValues.get("locations")!) !== -1
        : true;
      let directionCondition = this.filteredValues.get("direction") ?
        this.filterService.checkFilterMatch(data.transport, this.filteredValues.get("direction"), this.directionFilter)
        : true;
      let exigencyCondition = this.filteredValues.get("exigency") ?
        this.filterService.checkFilterMatch(data.transport, this.filteredValues.get("exigency"), this.exigencyFilter)
        : true;
      let constraintCondition = this.filteredValues.get("constraint")
        ? this.filterService.checkTimeWindowFilter(Array.of(data.transport.timeWindowStart, data.transport.timeWindowEnd), this.filteredValues.get("constraint") as [string, string])
        : true;
      let timeWindowAccuracyCondition = this.filteredValues.get("accuracy")
        ? this.filterService.checkFilterMatch(data.transport, this.filteredValues.get("accuracy"), this.timeWindowAccuracyFilter)
        : true;
      let employeeCondition = this.filteredValues.get("employee")
        ? data.deliveryAction != null && this.toStringService.employeeName(data.deliveryAction!.routeWithLinks.employee).trim().toLowerCase().indexOf(this.filteredValues.get("employee")!) !== -1
        : true;
      let statusCondition = this.filteredValues.get("status")
        ? this.filterService.checkFilterMatch(data.transport, this.filteredValues.get("status"), this.filterService.statusFilter)
        : true;
      return patientCondition && locationsCondition && directionCondition
        && exigencyCondition && constraintCondition && timeWindowAccuracyCondition
        && employeeCondition && statusCondition;
    };
  }

  async loadData() {
    try {
      await Promise.all([this.baseTransportService.loadAll(), this.baseLocationService.loadAll(),
        this.baseMeasuresService.load(), this.baseRouteService.loadAll(),
        this.baseVehicleService.loadAll(), this.baseEmployeeService.loadAll(),
        this.baseTaskActionService.loadAll(), this.baseParameterService.loadAll(),
        this.baseRequirementService.loadAll()]);
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.filteredData);
  }

  areSelectedTransportSomeSelected() {
    return this.selection.selected.filter(t => t.transport.status == Status.SELECTED).length > 0;
  }

  areSelectedTransportSomeExcluded() {
    return this.selection.selected.filter(t => t.transport.status == Status.EXCLUDED).length > 0;
  }

  async modifyCurrentSelectionStatus(newStatus: Status) {
    let selectedTransports = this.selection.selected;
    let transports = [];
    for (let transport of selectedTransports.map(t => t.transport)) {
      let modifiedTransport = {...transport};
      modifiedTransport.status = newStatus;
      transports.push(modifiedTransport);
      if (newStatus == Status.SELECTED)
        this.project!.nbTransports++;
      else if (newStatus == Status.EXCLUDED)
        this.project!.nbTransports--;
    }
    let promise = this.baseTransportService.updateMultiple(transports);
    await promise;
    this.selection.clear();
  }

  getStatusString(status: Status) {
    switch (status) {
      case Status.EXCLUDED: return "Exclue";
      case Status.ERROR: return "Erreur";
      default: return "";
    }
  }

  getNonNullPipeDateString(date: Date | string, format: string) {
    return this.dateService.getNonNullPipeDateString(date, format);
  }

  getTimeWindowString(start: Date, end: Date) {
    return this.dateService.extractDayTimeFromDate(start) + " - " + this.dateService.extractDayTimeFromDate(end);
  }

  isPickupConstraintViolated(transport: TransportDataWithSolution) {
    if (!transport.pickupAction?.routeActionMeasures) return false;
    if (transport.transport.timeWindowType != "origin") return false;
    let pickupDate = this.dateService.iso8601StringOrDateToDate(transport.pickupAction.routeActionMeasures.start);
    let latestPickupConstraintDate = this.dateService.iso8601StringOrDateToDate(transport.transport.timeWindowEnd);
    return pickupDate > latestPickupConstraintDate;
  }

  isDeliveryConstraintViolated(transport: TransportDataWithSolution) {
    if (!transport.deliveryAction?.routeActionMeasures) return false;
    if (transport.transport.timeWindowType != "destination") return false;
    let deliveryDate = this.dateService.iso8601StringOrDateToDate(transport.deliveryAction.routeActionMeasures.end);
    let latestDeliveryConstraintDate = this.dateService.iso8601StringOrDateToDate(transport.transport.timeWindowEnd);
    let marginValue = this.marginParameter?.intValue ?? 0;
    latestDeliveryConstraintDate.setSeconds(latestDeliveryConstraintDate.getSeconds() - marginValue);
    return deliveryDate > latestDeliveryConstraintDate;
  }

  noData(): boolean {
    return this.dataSource.data.length == 0;
  }

  openEditConstraintModal() {
    let dialogConfig = defaultDialogConfig();
    dialogConfig.width = '820px'
    let data = {
      transports: this.selection.selected.map(t => t.transport)
    }
    dialogConfig.data = data;
    this.matDialogRef = this.dialog.open(TransportModificationModalComponent, dialogConfig);
    this.matDialogRef.componentInstance.closeModal.subscribe(
      value => {
        if (value)
          this.matDialogRef!.close();
      });
  }
}
