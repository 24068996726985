import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataMerger} from "../data-merger";
import {
  DepotData,
  EmployeeData, EmployeeDataWithSolution,
  LocationData, ProjectData, RequirementData, RouteSolution, RouteSolutionWithMeasures, Status, TransportDataWithSolution
} from "../data-definitions";
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {CurrentProjectService} from "../current-project.service";
import {MessageService} from "../message.service";
import {DateService} from "../date.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {HttpErrorResponse} from "@angular/common/http";
import {BaseEmployeeService} from "../base-employee.service";
import {map} from "rxjs/operators";
import {BaseRouteSolutionService} from "../base-route-solution.service";
import {BaseMeasuresService} from "../base-measures.service";
import {BaseVehicleService} from "../base-vehicle.service";
import {MatPaginator} from "@angular/material/paginator";
import {BaseProjectService} from "../base-project.service";
import {SelectionModel} from "@angular/cdk/collections";
import {ToStringService} from "../to-string.service";
import {FilterService} from "../filter.service";
import { defaultDialogConfig } from '../dialog-data';
import {
  EmployeeModificationModalComponent
} from "../employee-modification-modal/employee-modification-modal.component";
import { BaseDepotService } from '../base-depot.service';
import { DepotModificationModalComponent } from '../depot-modification-modal/depot-modification-modal.component';
import { BaseRequirementService } from '../base-requirement.service';
import { BaseDepotInfoService } from '../base-depot-info.service';

@Component({
  selector: 'app-employee-table',
  templateUrl: './employee-table.component.html',
  styleUrls: ['./employee-table.component.css',
    '../../assets/tables.css',
    '../../assets/standard-page.css',
    '../../assets/buttons.css',
    '../../assets/tooltip.css']
})
export class EmployeeTableComponent implements OnInit, OnDestroy, AfterViewInit {
  Status = Status;

  project?: ProjectData;
  requirements: RequirementData[] = [];

  subscriptions: Subscription = new Subscription();
  routeDataMerger: DataMerger<RouteSolution, RouteSolutionWithMeasures>;
  dataMerger: DataMerger<EmployeeData, EmployeeDataWithSolution>;

  locations: LocationData[] = [];

  dataSource = new MatTableDataSource(<EmployeeDataWithSolution[]>[]);
  selection = new SelectionModel<EmployeeDataWithSolution>(true, []);
  nbSelected = 0;

  displayedColumns = ['select', 'name', 'assignment', 'diploma', 'depot',
    'depot-return', 'service-priority', 'employee-service-first-pickup', 'employee-service-last-dropoff',
    'status', 'solution-earliest-service-start', 'solution-service-start', 'solution-service-end', 'vehicle'];

  @ViewChild(MatSort) sort = new MatSort();
  @ViewChild(MatPaginator) paginator?: MatPaginator;

  filteredValues: Map<string, any> = new Map([
    ["name", ""],
    ["diploma", ""],
    ["service-priority", ""],
    ["depot-return", ""],
    ["vehicle", ""],
    ["status", ""],
    ["assignment", ""],
    ["depot", ""]
  ]);

  matDialogEmployeeRef?: MatDialogRef<EmployeeModificationModalComponent>;
  matDialogDepotRef?: MatDialogRef<DepotModificationModalComponent>;

  constructor(private baseEmployeeService: BaseEmployeeService,
              private baseRequirementService: BaseRequirementService,
              private baseVehicleService: BaseVehicleService,
              private baseDepotService: BaseDepotService,
              private baseDepotInfoService: BaseDepotInfoService,
              private currentProjectService: CurrentProjectService,
              private messageService: MessageService,
              private baseMeasuresService: BaseMeasuresService,
              private baseRouteService: BaseRouteSolutionService,
              private baseProjectService: BaseProjectService,
              private dateService: DateService,
              private filterService: FilterService,
              public toStringService: ToStringService,
              public dialog: MatDialog) {
    this.routeDataMerger = new DataMerger("routeSolution", this.baseRouteService.listenAll());
    this.routeDataMerger.addSubMapper("employee", baseEmployeeService.listenAll(), r => r.employeeId)
    this.routeDataMerger.addSubMapper("vehicle", baseVehicleService.listenAll(), r => r.vehicleId)
    this.routeDataMerger.addReverseMapper("routeMeasures",
      this.baseMeasuresService.listen().pipe(map(measures => measures!.routeMeasures)),
      r => r.routeId);

    this.dataMerger = new DataMerger("employee", this.baseEmployeeService.listenAll());
    this.dataMerger.addSubMapper("depot", this.baseDepotService.listenAll(), e => e.depotId);
    this.dataMerger.addReverseMapper("routeSolWithMeasures", this.routeDataMerger.listen(), route => route.routeSolution.employeeId);
  }

  ngOnInit(): void {
    this.dataMerger.listen().subscribe(employeeDayaWithSolution => {
      this.dataSource.data = employeeDayaWithSolution.sort((a,b) => 
      Number((b.employee.status === Status.SELECTED)) - Number((a.employee.status === Status.SELECTED))
    );
      this.nbSelected = employeeDayaWithSolution.filter(e => e.employee.status == Status.SELECTED).length;
    });
    this.subscriptions.add(this.baseProjectService.listen().subscribe(project => {
      this.project = project;
    }));
    this.dataSource.sortingDataAccessor = this.customSortingDataAccessor();
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.currentProjectService.findProjectId().subscribe(projectId => {
      if (projectId) this.loadData();
    });
    this.subscriptions.add(this.baseRequirementService.listenAll().subscribe(requirements => {
      this.requirements = requirements;
    }));
  }

  ngOnDestroy() {
    this.dataMerger.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  hasRequirements(requirementsIds: any) {
    if (requirementsIds.trim().length == 0) return false;
    return true;
  }

  countRequirements(requirementsIds: string) {
    return requirementsIds.split(";").length;
  }

  getRequirement(requirementId: number) {
    const requirement = this.requirements.find(r => r.apiId == requirementId);
    return requirement?.name;
  }

  customSortingDataAccessor() {
    return (item: EmployeeDataWithSolution, property: string) => {
      switch(property) {
        case 'name': return item.employee.lastName.toLowerCase();
        case 'diploma': return item.employee.diploma.trim().toLowerCase();
        case 'depot': return item.depot.name.trim().toLowerCase();
        case 'service-priority': return item.employee.servicePriority;
        case 'assignment': return this.toStringService.employeeAssignment(item.employee)
        case 'depot-return': return item.employee.planDepotReturn ? "Oui" : "Non;"
        case 'employee-service-first-pickup': return item.employee.serviceFirstPickup ?
          this.dateService.timeStringToDate(item.employee.serviceFirstPickup).getTime() : 0
        case 'employee-service-last-dropoff': return item.employee.serviceLastDropoff ?
          this.dateService.timeStringToDate(item.employee.serviceLastDropoff).getTime() : 0
        case 'status': return item.employee.status
        case 'solution-earliest-service-start': return item.employee.serviceStart ?
          this.dateService.timeStringToDate(item.employee.serviceStart).getTime() : 0
        case 'solution-service-start': return item.routeSolWithMeasures?.routeMeasures?.start.toString() ?? "ZZZ";
        case 'solution-service-end': return item.routeSolWithMeasures?.routeMeasures?.end.toString() ?? "ZZZ";
        case 'vehicle': return item.routeSolWithMeasures?.vehicle?.licensePlate ?? "ZZZ"  ;
        default: return "";
      }
    };
  }

  filterExigencyMatchFunction(data: EmployeeDataWithSolution, checked: string[]) {
    let exigencyAssociator: ((item: EmployeeDataWithSolution, key: string) => boolean)
      = (item: EmployeeDataWithSolution, key: string) => {
      switch (key) {
        case "Oui": return item.employee.planDepotReturn;
        case "Non": return !item.employee.planDepotReturn;
        default: return false;
      }
    }

    for (let element of checked) {
      if (exigencyAssociator(data, element)) return true;
    }
    return false;
  }

  customFilterPredicate() {
    return (data: EmployeeDataWithSolution, _: string): boolean => {
      let nameCondition = this.filteredValues.get("name")
        ? this.toStringService.employeeName(data.employee).trim().toLowerCase()
          .indexOf(this.filteredValues.get("name")!.toLowerCase()) !== -1
        : true;
      let depotCondition = this.filteredValues.get("depot")
        ? data.depot.name.trim().toLowerCase()
          .indexOf(this.filteredValues.get("depot")!.toLowerCase()) !== -1
        : true;
      let diplomaCondition = this.filteredValues.get("diploma")
        ? data.employee.diploma.trim().toLowerCase()
          .indexOf(this.filteredValues.get("diploma")!.toLowerCase()) !== -1
        : true;
      let priorityCondition = this.filteredValues.get("service-priority")
        ? data.employee.servicePriority == this.filteredValues.get("service-priority").trim()
        : true;
      let assignmentCondition = this.filteredValues.get("assignment")
        ? this.filterService.checkFilterMatch(data.employee, this.filteredValues.get("assignment"), this.filterService.assignmentFilter)
        :true;
      let planDepotCondition = this.filteredValues.get("depot-return") ?
        this.filterExigencyMatchFunction(data, this.filteredValues.get("depot-return"))
        : true;
      let vehicleCondition = this.filteredValues.get("vehicle")
        ? data.routeSolWithMeasures?.vehicle !== undefined && (data.routeSolWithMeasures!.vehicle!.licensePlate.trim().toLowerCase()
            .indexOf(this.filteredValues.get("vehicle")!.toLowerCase()) !== -1)
        : true;
      let statusCondition = this.filteredValues.get("status")
        ? this.filterService.checkFilterMatch(data.employee, this.filteredValues.get("status"), this.filterService.statusFilter)
        : true;
      return nameCondition && depotCondition && diplomaCondition && assignmentCondition && planDepotCondition && priorityCondition
              && vehicleCondition && statusCondition;
    };
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator!;
  }

  async loadData() {
    try {
      await Promise.all([this.baseEmployeeService.loadAll(), this.baseVehicleService.loadAll(),
        this.baseRouteService.loadAll(), this.baseMeasuresService.load(), this.baseDepotService.loadAll(),
        this.baseRequirementService.loadAll()]);
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.filteredData);
  }

  areSelectedTransportSomeSelected() {
    return this.selection.selected.filter(e => e.employee.status == Status.SELECTED).length > 0;
  }

  areSelectedTransportSomeExcluded() {
    return this.selection.selected.filter(e => e.employee.status == Status.EXCLUDED).length > 0;
  }

  areSelectedTransportAllReturnToDepot() {
    return this.selection.selected.filter(e => e.employee.planDepotReturn).length == this.selection.selected.length;
  }

  areSelectedTransportAllNotReturnToDepot() {
    return this.selection.selected.filter(e => !e.employee.planDepotReturn).length == this.selection.selected.length;
  }

  async modifyCurrentSelectionStatus(newStatus: Status) {
    let selectedElements = this.selection.selected;
    let employees = [];
    for (let element of selectedElements.map(e => e.employee)) {
      let modifiedElement = {...element};
      modifiedElement.status = newStatus;
      employees.push(modifiedElement);
      if (newStatus == Status.SELECTED)
        this.project!.nbVehicles++;
      else if (newStatus == Status.EXCLUDED)
        this.project!.nbVehicles--;
    }
    let promise = this.baseEmployeeService.updateMultiple(employees);
    await promise;
    this.selection.clear();
  }

  async modifyCurrentSelectionDepotReturn(planDepotReturn: boolean) {
    let selectedElements = this.selection.selected;
    let employees = [];
    for (let element of selectedElements.map(e => e.employee)) {
      let modifiedElement = {...element};
      modifiedElement.planDepotReturn = planDepotReturn;
      employees.push(modifiedElement);
    }
    let promise = this.baseEmployeeService.updateMultiple(employees);
    await promise;
    this.selection.clear();
  }

  getStatusString(status: Status) {
    switch (status) {
      case Status.EXCLUDED: return "Exclu";
      case Status.ERROR: return "Erreur";
      default: return "";
    }
  }

  getNonNullPipeDateString(date: Date | string, format: string) {
    return this.dateService.getNonNullPipeDateString(date, format);
  }

  noData(): boolean {
    return this.dataSource.data.length == 0;
  }

  isLate(employee: EmployeeDataWithSolution): boolean {
    if (!employee.routeSolWithMeasures?.routeMeasures?.employeeServiceLateness) return false;
    return employee.routeSolWithMeasures.routeMeasures?.employeeServiceLateness > 0;
  }

  hasSolution(employee: EmployeeDataWithSolution): boolean {
    return employee.routeSolWithMeasures !== undefined;
  }

  openEditConstraintModal() {
    let dialogConfig = defaultDialogConfig();
    dialogConfig.width = '1100px';
    let data = {
      employees: this.selection.selected.map(e => e.employee)
    }
    dialogConfig.data = data;
    this.matDialogEmployeeRef = this.dialog.open(EmployeeModificationModalComponent, dialogConfig);
    this.matDialogEmployeeRef.componentInstance.closeModal.subscribe(
      value => {
        if (value)
          this.matDialogEmployeeRef!.close();
      });
  }

  openEditDepotModal() {
    let dialogConfig = defaultDialogConfig();
    let data = {
      employees: this.selection.selected.map(e => e.employee),
    }
    dialogConfig.data = data;
    this.matDialogDepotRef = this.dialog.open(DepotModificationModalComponent, dialogConfig);
    this.matDialogDepotRef.componentInstance.closeModal.subscribe(
      value => {
        if (value)
          this.matDialogDepotRef!.close();
          this.baseDepotInfoService.clearAndReloadAll();
      });
  }
}
