<form id="authorization-form"></form>

<div>
  <table mat-table class="table-container" [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="user">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Utilisateur</th>
      <ng-container *matCellDef="let authorization">
        <td mat-cell class="view-cell">
          <div class="view-field">
            {{authorization.user?.name}}
          </div>
        </td>
      </ng-container>
      <td mat-footer-cell *matFooterCellDef>
        <mat-form-field *ngIf="isAdding()" floatLabel="never">
          <mat-label for="user-select">Utilisateur</mat-label>
          <mat-select class="form-select" name="user-select" [(ngModel)]="authorizationToAdd!.userId"
              form="authorization-form" required>
            <mat-option class="form-select-option" *ngFor="let user of users" [value]="user.id">
              {{user.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="project">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Projet</th>
      <ng-container *matCellDef="let authorization">
        <td mat-cell class="view-cell">
          <div class="view-field">
            {{authorization.project?.name}}
          </div>
        </td>
      </ng-container>
      <td mat-footer-cell *matFooterCellDef>
        <mat-form-field *ngIf="isAdding()" floatLabel="never">
          <mat-label for="project-select">Projet</mat-label>
          <mat-select class="form-select" name="project-select" [(ngModel)]="authorizationToAdd!.projectId"
              form="authorization-form" required>
            <mat-option class="form-select-option" *ngFor="let project of projects" [value]="project.id">
              {{project.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <th class="cell-button" mat-header-cell *matHeaderCellDef>
      </th>
      <ng-container *matCellDef="let authorization">
        <td mat-cell class="cell-button">
          <button class="simple-button" title="Delete" [disabled]="isAdding()" [class.disabled]="isAdding()"
              (click)="deleteAuthorization(authorization)">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </td>
      </ng-container>
      <td class="cell-button" mat-footer-cell *matFooterCellDef>
        <ng-container *ngIf="isAdding()">
          <button class="simple-button" title="Save" (click)="saveAdd()" form="authorization-form" type="submit" [disabled]="!canAdd()" [class.disabled]="!canAdd()">
            <mat-icon>check</mat-icon>
          </button>
          <button class="simple-button" title="Cancel" (click)="endAdd()" form="authorization-form" type="button">
            <mat-icon>close</mat-icon>
          </button>
        </ng-container>

        <ng-container *ngIf="!isAdding()">
          <button class="simple-button" title="Add new" [disabled]="isAdding()" [class.disabled]="isAdding()"
              (click)="addAuthorization()">
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>

  </table>
</div>
