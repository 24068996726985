<table class="table">
  <tr class="table-header">
    <th>Identifiant</th>
    <th>Objet</th>
    <th>Message</th>
  </tr>
  <tr *ngFor="let error of errors" class="table-row">
    <td class="table-data">{{error.apiId}}</td>
    <td class="table-data">{{error.objectType}}</td>
    <td class="table-data">{{error.message}}</td>
  </tr>
</table>

