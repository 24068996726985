<div class="filter-group">
  <button class="simple-button filter-button" mat-icon-button [matMenuTriggerFor]="filterMenu"
          title="Filter">
    <mat-icon [class.filter-active]="filter != ''">{{filter == '' ? 'filter_list' : 'filter_alt'}}</mat-icon>
  </button>
  <mat-menu class="filter-menu" #filterMenu="matMenu">
    <div class="main-container">
      <div class="text-input" (click)="$event.stopPropagation()" floatLabel="always">
        <label>Filtre</label>
        <input (keyup)="applyFilter($event)" placeholder="valeur" [(ngModel)]="filter">
      </div>
      <button mat-icon-button class="simple-button" (click)="suppressFilter()"><mat-icon>delete_sweep</mat-icon></button>
    </div>
  </mat-menu>
</div>
