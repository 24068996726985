import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BaseMeasuresService } from './base-measures.service';
import { BaseTransportService } from './base-transport.service';
import { BaseService, DalConfiguration } from './base-service';
import { BaseVehicleService } from './base-vehicle.service';
import { CurrentProjectService } from './current-project.service';
import { LocationData } from './data-definitions';
import { HttpDalService } from './http-dal.service';
import {BaseDepotService} from "./base-depot.service";

@Injectable({
  providedIn: 'root'
})
export class BaseLocationService extends BaseService<LocationData> {
  constructor(
      http: HttpDalService,
      vehicleService: BaseVehicleService,
      missionService: BaseTransportService,
      depotService: BaseDepotService,
      private currentProjectService: CurrentProjectService,
      private measuresService: BaseMeasuresService) {
    super(http);

    this._addStoreDefinition(
      'by-project-id',
      location => [location.projectId]
    );

    this._addDeleteCascadeRelation(
      missionService,
      t => t.originId
    );

    this._addDeleteCascadeRelation(
      missionService,
      t => t.destinationId
    );

    this._addDeleteCascadeRelation(
      depotService,
      d => d.locationId
    );
  }

  _extractParentUrl(context: any) {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/location`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/location/${context.locationId}`;
  }

  _extractElementId(location: LocationData) {
    return location.id;
  }

  async update(location: LocationData) {
    await this._updateElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      locationId: location.id
    }, location);
    // Ignore errors coming from the measures.
    await this.measuresService.reload().catch(_ => {});
  }

  async remove(location: LocationData) {
    await this._removeElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      locationId: location.id
    }, location);
    // Ignore errors coming from the measures.
    await this.measuresService.reload().catch(_ => {});
  }

  async add(location: LocationData) {
    let result = await this._addElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      locationId: location.id
    }, location);
    return result;
  }

  clone(location: LocationData) {
    return this._clone(location);
  }

  load(locationId: number) {
    return this._loadElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      locationId: locationId
    });
  }

  loadAll() {
    return this._loadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  clearAndReloadAll() {
    return this._clearAndReloadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  listen(locationId: number) {
    return this._listenElement(locationId);
  }

  listenAll() {
    return this.currentProjectService.findProjectId().pipe(
      mergeMap(projectId => projectId === undefined ? EMPTY : this._listenElements('by-project-id', [projectId]))
    );
  }
}
