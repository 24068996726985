import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, Output} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData} from '../dialog-data';

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.css',
              '../../assets/modal.css',
              '../../assets/buttons.css',
              '../../assets/standard-page.css']
})
export class CustomDialogComponent {
  constructor (@Inject(MAT_DIALOG_DATA) public data: DialogData, private dialogRef: MatDialogRef<CustomDialogComponent>) {
  }

  closeDialog(action: { returnValue?: boolean }) {
    const result = {
      returnValue: action.returnValue,
      checkboxes: this.data.checkboxes,
    };
    this.dialogRef.close(result);
  }
}
