
<div class="page-error" *ngIf="error!==undefined">
  <h3>
    Error details :
    <button class="close" (click)="clearError()"><mat-icon>arrow_back</mat-icon></button>
  </h3>

  <h5>Operation</h5>
  <p class="content">
    {{error.operation}}
  </p>

  <h5>Type</h5>
  <p class="content">
    {{error.type}}
  </p>

  <h5>Message</h5>
  <p class="content">
    {{error.message}}
  </p>

  <h5>Trace</h5>
  <p class="content">
    {{error.trace}}
  </p>

  <h5>InnerTrace</h5>
  <p class="content">
    {{error.innerTrace}}
  </p>

  <h5>Cause</h5>
  <p class="content">
    {{error.cause}}
  </p>
</div>
