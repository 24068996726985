<div class="container" #container [class.highlighted]="isSelected">
  <div class="route-header" (click)="onHeaderClick()">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
         class="collapse-icon" [class.collapse-rotation]="isSelected">
      <path d="M17.5607 12.3107C18.1464 11.7249 18.1464 10.7751 17.5607 10.1893C16.9749 9.60355 16.0251 9.60355 15.4393 10.1893L13.5 12.1287L12 13.5L10.5 12.1287L8.56066 10.1893C7.97487 9.60355 7.02513 9.60355 6.43934 10.1893C5.85355 10.7751 5.85355 11.7249 6.43934 12.3107L10.9393 16.8107C11.5251 17.3964 12.4749 17.3964 13.0607 16.8107L17.5607 12.3107Z" fill="#53649F"/>
    </svg>
    <div class="vehicle-information">
      <div class="vehicle-title">{{vehicleString}}</div>
      <div class="vehicle-crew">{{vehicleCrew}}</div>
    </div>
    <div class="vehicle-extra-information">
      <div class="vehicle-nb-missions">
        <span *ngIf="nbMissions > 1">{{nbMissions}} missions</span>
        <span *ngIf="nbMissions <= 1">{{nbMissions}} mission</span>
      </div>
      <div class="icons">
        <img *ngIf="returnToDepot" src="assets/images/circle-check.svg" />
        <img *ngIf="hasViolation" src="assets/images/warning.svg" class="warning"/>
      </div>
    </div>
  </div>
  <div class="route-detail" *ngIf="isSelected || wasPreviouslySelected">
    <div class="route-element" *ngFor="let action of aggregatedActions" (click)="selectionAction(action)"
      [ngClass]="{
        'empty-trip': action.type == AggregatedActionType.MOVE_EMPTY,
        'loaded-trip': action.type == AggregatedActionType.MOVE,
        'wait': action.type == AggregatedActionType.WAIT,
        'parking-access': action.type == AggregatedActionType.PARKING_ACCESS,
        'break': action.type == AggregatedActionType.REGULATORY_BREAK || action.type == AggregatedActionType.MEAL_BREAK,
        'pickup': action.type == AggregatedActionType.PICKUP,
        'delivery': action.type == AggregatedActionType.DELIVERY,
        'service-start-end': action.type == AggregatedActionType.SERVICE_START || action.type == AggregatedActionType.SERVICE_END,
        'route-element-selected': action == selectedAction
        }">
      <div *ngIf="action.type == AggregatedActionType.MOVE_EMPTY" class="icon-background">
        <img src="assets/images/route.svg">
      </div>
      <div *ngIf="action.type == AggregatedActionType.MOVE" class="icon-background">
        <img src="assets/images/ambulance.svg">
      </div>
      <div *ngIf="action.type == AggregatedActionType.REGULATORY_BREAK
          || action.type == AggregatedActionType.MEAL_BREAK" class="icon-background">
        <img src="assets/images/break.svg">
      </div>
      <div *ngIf="action.type == AggregatedActionType.WAIT" class="icon-background">
        <img src="assets/images/clock.svg">
      </div>
      <div *ngIf="action.type == AggregatedActionType.PICKUP" class="icon-background">
        <img src="assets/images/ambulance-retour.svg">
      </div>
      <div *ngIf="action.type == AggregatedActionType.DELIVERY" class="icon-background">
        <img src="assets/images/ambulance-aller.svg">
      </div>
      <div *ngIf="action.type == AggregatedActionType.SERVICE_START
          || action.type == AggregatedActionType.SERVICE_END" class="icon-background">
        <img src="assets/images/loader.svg">
      </div>
      <div *ngIf="action.type == AggregatedActionType.PARKING_ACCESS" class="icon-background">
        <img src="assets/images/parking.svg">
      </div>
      <span class="start-end">
            {{(action.start | date: "HH:mm") + " - " + (action.end | date: "HH:mm")}}
      </span>
      <div class="route-element-description">
        <span *ngIf="action.type == AggregatedActionType.MOVE_EMPTY">Trajet à vide</span>
        <span *ngIf="action.type == AggregatedActionType.MOVE">
          Trajet
          <img id="person-img" *ngFor="let _ of [].constructor(action.mapElement!.nbTransportedPatient)"
            src="assets/images/person.svg">
        </span>
        <span *ngIf="action.type == AggregatedActionType.REGULATORY_BREAK">Pause réglementaire</span>
        <span *ngIf="action.type == AggregatedActionType.MEAL_BREAK">Pause repas</span>
        <span *ngIf="action.type == AggregatedActionType.SERVICE_START">Début de service</span>
        <span *ngIf="action.type == AggregatedActionType.SERVICE_END">Fin de service</span>
        <span *ngIf="action.type == AggregatedActionType.WAIT">Attente</span>
        <span *ngIf="action.type == AggregatedActionType.PICKUP">
          {{ action.mapElement!.patientLastName + " " + action.mapElement!.patientFirstName }}
        </span>
        <span *ngIf="action.type == AggregatedActionType.DELIVERY">
          {{ action.mapElement!.patientLastName + " " + action.mapElement!.patientFirstName }}
        </span>
        <span *ngIf="action.type == AggregatedActionType.PARKING_ACCESS">Accès Parking</span>
      </div>
    </div>
  </div>
</div>
