<div class="profile-parameters-container">
    <div class="list-profiles">
      <h1 class="profile-title"> Profils </h1>
      <div class="profile" *ngFor="let profile of profiles">
          <div
              (click)="selectProfile(profile)"
              [ngClass]="{
                  'profile-button': true,
                  'profile-button-activated': selectedProfile?.id === profile.id
              }"
          >
            {{profile.name}}
            <img 
                *ngIf="profile.default === true"
                [ngClass]="{
                    'profile-check': selectedProfile?.id !== profile.id,
                    'profile-check-white': selectedProfile?.id === profile.id
                }"
                src="assets/images/circle-check.svg"
            />
            <div *ngIf="selectedProfile?.id === profile.id" class="profile-modification">
              <div class="profile-modification-buttons">
                <img 
                  class="profile-modification-button-1" 
                  src="assets/images/edit.svg"
                  (click)="openCreateModal(true)"
                />
                <img
                  *ngIf="profiles.length > 1"
                  class="profile-modification-button-2" 
                  src="assets/images/bin.svg"
                  (click)="deleteProfile()"
                />
              </div>
            </div>
          </div>
        </div>
      <button type="button" class="rounded-button secondary-type-button new-planning" (click)="openCreateModal(false)">
        <img src="assets/images/plus.svg">
      </button>
    </div>
    <app-parameter-modification *ngIf="profileType === 'default'"
                                [parameterTable]="ParameterTable.DEFAULT"
                                [profile]="selectedProfile">
    </app-parameter-modification>
    <app-traffic-modification *ngIf="profileType === 'traffic'"
                                [parameterTable]="ParameterTable.DEFAULT"
                                [profile]="selectedProfile">
    </app-traffic-modification>
</div>