<div class="container">
  <h1 [ngClass]="{'warning': data.warning === true}"
      *ngIf="data.title">{{data.title}}</h1>
  <span>{{data.msg}}</span>
  <div *ngIf="data.submsg?.length">
    <span class="submsg-container" *ngFor="let submsg of data.submsg">• {{submsg}}</span>
  </div>
  <div class="checkbox-container" *ngIf="data.checkboxes?.length">
    <mat-checkbox *ngFor="let checkbox of data.checkboxes" [(ngModel)]="checkbox.isChecked" color="primary">
      {{checkbox.label}}
    </mat-checkbox>
  </div>
  <div class="button-container">
      <button *ngFor="let action of data.actions" class="rectangular-button"
              [ngClass]="{'first-type-button': !action.important,'important-button':action.important}"
              [class.important-button]="action.important"
              (click)="closeDialog(action)">
        {{action.name}}
      </button>
  </div>
</div>
