import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseMeasuresService } from '../base-measures.service';
import { Measures } from '../data-definitions';
import { MessageService } from '../message.service';
import { DistancePipe } from '../distance.pipe';
import { DurationPipe } from '../duration.pipe';

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.css',
              '../../assets/standard-page.css']
})
export class IndicatorsComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();

  measures?: Measures;

  constructor(private baseMeasuresService: BaseMeasuresService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.subscription.add(this.baseMeasuresService.listen().subscribe(measures => {
      this.measures = measures;
    }));
    this.loadData();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async loadData() {
    try {
      await this.baseMeasuresService.load();
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  computeDistanceAndDurationString(distance: number, duration: number) {
    return new DistancePipe().transform(distance) + " - " +
        new DurationPipe().transform(duration);
  }

  computeDistanceAndDistanceString(distance1: number, distance2: number) {
    return new DistancePipe().transform(distance1) + " - " +
        new DistancePipe().transform(distance2);
  }

}
