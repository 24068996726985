import { Parameter } from "./parameter-configuration";

export interface ErrorData {
  id: number;
  projectId: number;
  apiId: string;
  type: string;
  objectType: string;
  message: string;
  trace: string | null;
  innerTrace: string | null;
  cause: string | null;
}

export interface InternalError {
  type: string | null;
  message: string | null;
  trace: string | null;
  innerTrace: string | null;
  cause: string | null;
  operation: string | null;
}

export interface ProjectData {
  id: number;
  environmentId: number;
  name: string;
  planningDate: string;
  lastModificationDate: string;
  nbTransports: number;
  nbEmployees: number;
  nbVehicles :number;
  kScore?: number;
  mutualisationRate?: number;
  totalTaskLateness?: number;
  totalTaskAuthorisedLateness?: number;
  totalTaskUnauthorisedLateness?: number;
  deadMileageRate?: number;
  lastDataDate: string;
  type: string;
  regulationNames: string;
  trafficProfileName: string;
  parameterProfileName: string;
}

export interface ProjectInfo {
  date: Date;
  name: string;
  planningTimeLimit: string;
  areaInfo: AreaInfo;
  type: string;
  trafficProfileId: number;
  trafficProfileName: string;
  parameterProfileName: string;
  defaultProfileId: number;
  regulationNames: string;
}

export interface AreaInfo {
  sectorApisId: string[];
  regulationApisId: string[];
  territoryApisId: string[];
  depotApisId: string[];
}

export interface VehicleData {
  id: number;
  apiId: string;
  projectId: number;
  status: Status;
  licensePlate: string;
  type: string;
  depotId: number;
  requirementIds: string;
}

export interface VehicleDataWithSolution {
  id: number;
  vehicle: VehicleData;
  routeSolWithMeasures?: RouteSolutionWithMeasures;
  depot?: DepotData;
}

export interface LocationData {
  id: number;
  projectId: number;
  apiId: string
  name: string;
  address: string;
  city: string;
  zipCode : string
  country: string
  longitude: number;
  latitude: number;
}

export interface LocationDataWithLinks {
  id: number;
  location: LocationData;
  transports: TransportData[];
}

export enum ObjectiveType {
  REQUIREMENT = "REQUIREMENT",
  TASK_LATENESS = "TASK_LATENESS",
  RETURN_LATENESS = "RETURN_LATENESS",
  SERVICE_LATENESS = "SERVICE_LATENESS",
  KSCORE = "KSCORE",
  DISTANCE = "DISTANCE",
  NUMBER_OF_VEHICLES = "NUMBER_OF_VEHICLES"
}

export enum Status {
  SELECTED = "SELECTED",
  EXCLUDED = "EXCLUDED",
  ERROR = "ERROR",
}

export interface TransportData {
  id: number;
  apiId: string
  projectId: number;
  status: Status;
  patientFirstName: string;
  patientLastName: string;
  originId: number;
  destinationId: number;
  timeWindowType: "origin" | "destination";
  timeWindowStart: string;
  timeWindowEnd: string;
  timeWindowAccuracy: boolean;
  direction: "GO" | "RETURN" | "TRANSFER"
  pickupDuration: number;
  dropoffDuration: number;
  numberSeatsNeeded: number;
  sharedTransportAllowed: boolean;
  requirementsIds: string;
  possibleVehicleTypes: string;
}

export interface RequirementData {
  id: number;
  apiId: number;
  projectId: number;
  name: string;
}

export interface EmployeeData {
  id: number;
  apiId: string
  projectId: number;
  status: Status;
  firstName: string;
  lastName: string;
  diploma: string;
  servicePriority: number;
  serviceStart?: string;
  serviceEnd?:  string;
  serviceFirstPickup?: string;
  serviceLastDropoff?: string;
  serviceAmplitudeMin?: string;
  serviceAmplitudeMax?: string;
  serviceAssignments: string
  remainingWeekTime: number
  depotId: number;
  requirementIds: string;
  planDepotReturn: boolean;
}

export interface EventhubEmployeeData {
  id: number;
  apiId: string
  status: Status;
  firstName: string;
  lastName: string;
  diploma: string
  serviceStart?: string;
  serviceEnd?:  string;
  serviceFirstPickup?: string;
  serviceLastDropoff?: string;
  serviceAmplitudeMin?: string;
  serviceAmplitudeMax?: string;
  serviceAssignments: string
  remainingWeekTime: number
  depotId: string;
  requirementIds: string;
  planDepotReturn: boolean;
}

export interface DepotInfo {
  id: number;
  sectorName: string;
  regulationName: string;
  territoryName: string;
  depotName: string;
  projectId: number;
  nbVehicles: number;
  nbEmployees: number;
  nbUnassigned: number;
  nbTransports: number;
}

export interface DepotData {
  id: number;
  apiId: string;
  projectId: number;
  name: string;
  locationId: number;
  territoryId: number;
}

export interface EventhubSectorData {
  id: number;
  apiId: string;
  name: string;
}

export interface SectorData {
  id: number;
  apiId: string;
  name: string;
  projectId: number;
}

export interface EventhubRegulationData {
  id: number;
  apiId: string;
  name: string;
  sectorId: string;
}

export interface EventhubTerritoryData {
  id: number;
  apiId: string;
  name: string;
  regulationId: string;
}

export interface EventhubDepotData {
  id: number;
  apiId: string;
  territoryId: string;
  name: string;
}


export interface AssociationRuleData {
  id: number
  projectId: number
  employeeId: number
  transportId?: number
  vehicleId?: number
  ruleType: RuleType
}

export interface DefaultAssociationRuleData {
  id: number
  sectorApiId: string
  employeeApiId: string
  vehicleApiId: string
  ruleType: RuleType
}

export interface DefaultCrewRuleData {
  id: number
  deaApiId: string
  crewManApiId: string
  sectorApiId: string
}

export interface ProfileData {
  id: number,
  name: string,
  sectorId: string,
  default: boolean,
  profileType: string,
  projectType: string
}

export interface CrewData {
  id: number,
  deaId: number,
  crewManId: number,
  projectId: number
}

export interface TrafficData {
  id: number;
  timeIndex: number;
  startTime: string;
  endTime: string;
  value: number;
  profileId?: number;
  projectId?: number;
}

export enum RuleType {
  FORCE_IN = "FORCE_IN",
  FORCE_OUT = "FORCE_OUT",
  LOCK_IN = "LOCK_IN",
  FORCE_ALL_OUT = "FORCE_ALL_OUT"
}

export interface RouteSolution {
  id: number;
  projectId: number;
  vehicleId: number;
  employeeId: number;
  taskActions: RouteTaskActionSolution[];
}

export interface RouteSolutionWithLinks {
  id: number;
  route: RouteSolution;
  vehicle: VehicleData;
  employee: EmployeeData;
}

export interface RouteTaskActionSolution {
  id: number;
  position: number;
  projectId: number;
  routeId: number;
  pickupTransportId?: number;
  deliveryTransportId?: number;
}

export interface RouteTaskActionWithLinks {
  id: number;
  routeWithLinks: RouteSolutionWithLinks;
  taskAction: RouteTaskActionSolution;
  routeActionMeasures: RouteActionMeasures;
}

export interface MeasuredSolution {
  solution: Solution,
  measures: Measures
}

export interface Solution {
  routeSolutions: RouteSolution[]
}

export interface Measures {
  projectId: number,
  indicatorMeasures: IndicatorMeasures,
  routeMeasures: RouteMeasures[],
  objectiveMeasures: ObjectiveMeasures[],
  violationMeasures: ViolationMeasures[]
}

export interface IndicatorMeasures {
  nbUsedVehicles: number;
  totalDuration: number;
  totalWaitingTime: number;
  totalTravelTime: number;
  totalTaskTime: number;
  totalBreakTime: number;
  totalDistance: number;
  minDistance: number;
  maxDistance: number;
  totalReturnLateness: number;
  totalTaskLateness: number;
  totalTaskAuthorisedLateness: number;
  totalTaskUnauthorisedLateness: number;
  totalServiceLateness: number;
  totalDeadMileage: number;
  totalDeadMileageTime: number;
  kScore: number;
  operatingSpeed: number;
  hourlyProductivity: number;
  deadMileageRate: number;
  mutualisationRate: number;
}

export interface RouteMeasures {
  id: number,
  routeId: number,
  distance: number,
  nbTasks: number,
  nbTransports : number,
  start: Date | string,
  end: Date | string,
  totalDuration: number,
  waitingTime: number,
  breakTime: number,
  travelTime: number,
  taskTime: number,
  effectiveWorkTime: number,
  returnLateness: number,
  employeeServiceLateness: number,
  taskLateness: number,
  deadMileage: number,
  deadMileageTime: number,
  deadMileageDepot: number,
  deadMileageDepotTime: number,
  operatingSpeed: number;
  hourlyProductivity: number;
  deadMileageRate: number;
  kScore: number;
  routeActionMeasures: RouteActionMeasures[],
  routeMissionMeasures: RouteMissionMeasures[]
}

export interface RouteMissionMeasures {
  id: number;
  missionId: number,
  routePosition: number,
  pickupLocationId: number,
  deliveryLocationId: number,
  pickupTime: Date,
  deliveryTime: Date,
  waitingTime: number,
  lateness: number,
}

export interface RouteMissionMeasuresWithLinks {
  id: number;
  routeMissionMeasures: RouteMissionMeasures;
  mission?: TransportData;
  pickupLocation?: LocationData;
  deliveryLocation?: LocationData;
}

export interface RouteSolutionWithMeasures {
  id: number;
  routeSolution: RouteSolution,
  vehicle?: VehicleData,
  employee?: EmployeeData,
  routeMeasures?: RouteMeasures
}

export enum RouteActionType {
  WAIT = "WAIT",
  REGULATORY_BREAK = "REGULATORY_BREAK",
  MEAL_BREAK = "MEAL_BREAK",
  MOVE = "MOVE",
  PICKUP = "PICKUP",
  DROPOFF = "DROPOFF",
  PARKING_ACCESS = "PARKING_ACCESS",
  SERVICE_START = "SERVICE_START",
  SERVICE_END = "SERVICE_END"
}

export interface RouteActionMeasures {
  id: number;
  index: number;
  duration: number;
  start: Date | string;
  end: Date | string;
  type: RouteActionType;
  routeTaskActionId?: number;
  originLocationId?: number;
  destinationLocationId?: number;
  deadMileageMove?: boolean;
  polyline?: string;
}

export interface ObjectiveMeasures {
  objectiveId: number,
  value: number
}

export interface ViolationMeasures {
  id: number;
  message: string,
  routeId: number,
  objectId: number,
  objectType: ViolationObjectType
}

export enum ViolationObjectType {
  VEHICLE = "VEHICLE",
  PICKUP_DELIVERY_TASK = "PICKUP_DELIVERY_TASK"
}

export interface SolveParameters {
  iterationLimit?: number;
  timeLimit: number;
  verbosity?: number;
}

export interface AuthenticationData {
  username: string,
  password: string,
  keepConnected: boolean
}

export interface TransportDataWithSolution {
  id: number;
  transport: TransportData;
  requirements?: RequirementData[],
  deliveryLocation?: LocationData,
  pickupLocation?: LocationData,
  deliveryAction?: RouteTaskActionWithLinks;
  pickupAction?: RouteTaskActionWithLinks;
}

export interface EmployeeDataWithSolution {
  id: number;
  employee: EmployeeData
  depot: DepotData
  routeSolWithMeasures?: RouteSolutionWithMeasures
}

export interface Environment {
  id: number,
  name: string,
  projects: ProjectData[],
  users: UserData[],
  userProjectAuthorizations: UserProjectAuthorization[]
}

export interface UserData {
  id: number,
  environmentId: number,
  name: string,
  roles: UserRole[],
  hashedPassword?: string,
  passwordChanged?: boolean,
  password?: string,
}

export enum UserRole {
  LOGGED_IN = "LOGGED_IN",
  ADMINISTRATION = "ADMINISTRATION"
}

export interface UserProjectAuthorization {
  id: number,
  userId: number,
  projectId: number,
  environmentId: number
}

export interface UserProjectAuthorizationWithLinks {
  id: number,
  userProjectAuthorization: UserProjectAuthorization,
  user: UserData,
  project: ProjectData
}

export interface Configuration {
  vectorTileUrl: string;
}

export interface Version {
  version: string;
}
