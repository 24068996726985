<div class="container">
  <div class="route-information">
    <div class="vehicle-title">
      <span class="vehicle-name">{{getCurrentVehicle()}}</span>
      <span></span>
    </div>
    <app-map-route-indicators></app-map-route-indicators>
  </div>
  <app-map class="map"></app-map>
</div>
