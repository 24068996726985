import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MapService } from '../map.service';
import { MapRoute } from '../map-types'
import {VehicleData} from "../data-definitions";
import {ToStringService} from "../to-string.service";

@Component({
  selector: 'app-map-route-selection',
  templateUrl: './map-route-selection.component.html',
  styleUrls: ['./map-route-selection.component.css',
              '../../assets/standard-page.css',
              '../../assets/forms.css']
})
export class MapRouteSelectionComponent implements OnInit {

  searchInput = new FormControl();
  routes: MapRoute[] = [];
  filteredRoutes: MapRoute[] = [];
  selectedRoute: MapRoute | undefined;
  previouslySelectedRoute: MapRoute | undefined;

  constructor(private mapService: MapService,
              private toStringService: ToStringService) { }

  ngOnInit() {
    this.mapService.getRoutes().subscribe(
      routes => {
        this.routes = routes;
        this.routes.sort((r1, r2) => r1.route.vehicle && r2.route.vehicle ?
          r1.route.vehicle.licensePlate.localeCompare(r2.route.vehicle.licensePlate) : 0)
        this.filterRoutes(this.searchInput.value);
      }
    );
    this.mapService.getSelectedRoute().subscribe(
      selectedRoute => {
        this.previouslySelectedRoute = this.selectedRoute;
        this.selectedRoute = selectedRoute;
      }
    )
    this.searchInput.valueChanges.subscribe(
      value => this.filterRoutes(value));
  }

  filterRoutes(value: string) {
    if (!value || value === "") {
      this.filteredRoutes = this.routes;
      return;
    }
    let valueLowerCased = value.toLowerCase();
    this.filteredRoutes = this.routes.filter(x => {
      if (x.route.vehicle && this.toStringService.vehicleName(x.route.vehicle).toLowerCase().indexOf(valueLowerCased) >= 0)
        return true;
      if (x.route.employee && (this.toStringService.employeeName(x.route.employee).toLowerCase().indexOf(valueLowerCased) >= 0))
        return true;
      return false;
    });
  }
}
