import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {AssociationRuleData, EmployeeData, RuleType, Status, TransportData, VehicleData} from "../data-definitions";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {
  AssociationRuleCreationChoice,
  AssociationRuleCreationData
} from "../association-rules-element/association-rules-element.component";
import {AssociationRulesService, EmployeeWithRules} from "../association-rules.service";
import {ToStringService} from "../to-string.service";
import {BaseAssociationRuleService} from "../base-association-rule.service";
import {DateService} from "../date.service";

@Component({
  selector: 'app-association-rules-creation-modal',
  templateUrl: './association-rules-creation-modal.component.html',
  styleUrls: ['./association-rules-creation-modal.component.css',
              '../../assets/standard-page.css',
              '../../assets/buttons.css',
              '../../assets/forms.css',
              '../../assets/modal.css',
              '../../assets/select.css']
})
export class AssociationRulesCreationModalComponent implements OnInit {
  AssociationRuleCreationChoice = AssociationRuleCreationChoice;

  rulePossibilities = [{type: RuleType.FORCE_IN, name: "Imposer"}, {type: RuleType.FORCE_OUT, name: "Interdire"}]

  ruleType?: RuleType
  transport?: TransportData
  employee?: EmployeeData
  vehicle?: VehicleData

  employeeWithRules?: EmployeeWithRules

  creationData: AssociationRuleCreationData;

  possibleTransports: TransportData[] = [];
  possibleEmployees: EmployeeData[] = [];
  possibleVehicles: VehicleData[] = [];

  forceAllOut = false;

  @Output() associationRule = new EventEmitter<AssociationRuleData>();
  @Output() closeModal = new EventEmitter<boolean>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: AssociationRuleCreationData,
              private associationRulesService: AssociationRulesService,
              private baseAssociationRuleService: BaseAssociationRuleService,
              private dateService: DateService,
              public toStringService: ToStringService) {
    this.ruleType = data.ruleType;
    this.transport = data.transport;
    this.employee = data.employeeWithRules?.employee;
    this.vehicle = data.vehicle;
    this.creationData = data;

    this.employeeWithRules = data.employeeWithRules;

    this.initPossibleTransports();
    this.initPossibleEmployees();
    this.initPossibleVehicles();
  }

  ngOnInit(): void {
  }

  initPossibleTransports() {
    let possibleTransportIds: Set<number> = new Set()

    if (this.ruleType == RuleType.FORCE_IN) {
      for (let transportId of this.associationRulesService.imposableTransportIds)
        possibleTransportIds.add(transportId);
    } else if (this.ruleType == RuleType.FORCE_OUT) {
      for (let transportId of this.associationRulesService.transports.keys())
        possibleTransportIds.add(transportId);
    }

    for (let transportId of [...possibleTransportIds]) {
      let transport = this.associationRulesService.transports.get(transportId)!;
      if (transport.status != Status.SELECTED)
        possibleTransportIds.delete(transportId)
    }

    if (this.employeeWithRules) {
      for (let forbiddenTransportRule of this.employeeWithRules.forbiddenTransportRules)
        possibleTransportIds.delete(forbiddenTransportRule.transportId!);
      if (this.ruleType == RuleType.FORCE_OUT) {
        for (let imposedTransportRule of this.employeeWithRules.imposedTransportRules) {
          possibleTransportIds.delete(imposedTransportRule.transportId!);
        }
        for (let lockedTransportRule of this.employeeWithRules.lockedTransportRules) {
          possibleTransportIds.delete(lockedTransportRule.transportId!);
        }
      }
    }

    this.possibleTransports = []
    for (let transportId of possibleTransportIds)
      this.possibleTransports.push(this.associationRulesService.transports.get(transportId)!)

    this.possibleTransports.sort((t1, t2) => {
      return this.dateService.iso8601StringOrDateToDate(t1.timeWindowStart).getTime()
        -  this.dateService.iso8601StringOrDateToDate(t2.timeWindowStart).getTime()
    })
  }

  initPossibleEmployees() {
    for (let employee of this.associationRulesService.employees.values()) {
      this.possibleEmployees.push(employee);
    }
  }

  initPossibleVehicles() {
    let possibleVehicleIds: Set<number> = new Set()
    for (let vehicleId of this.associationRulesService.imposableVehicleIds)
      possibleVehicleIds.add(vehicleId);
    if (this.ruleType == RuleType.FORCE_OUT) {
      for (let vehicleId of this.associationRulesService.vehicles.keys())
        possibleVehicleIds.add(vehicleId);
    }

    for (let possibleVehicleId of [...possibleVehicleIds]) {
      let vehicle = this.associationRulesService.vehicles.get(possibleVehicleId)!;
      if (vehicle.status != Status.SELECTED)
        possibleVehicleIds.delete(possibleVehicleId)
    }

    if (this.employeeWithRules) {
      for (let forbiddenVehicleRule of this.employeeWithRules.forbiddenVehicleRules)
        possibleVehicleIds.delete(forbiddenVehicleRule.vehicleId!);
      if (this.ruleType == RuleType.FORCE_OUT) {
        if (this.employeeWithRules.imposedVehicleRule)
          possibleVehicleIds.delete(this.employeeWithRules.imposedVehicleRule.vehicleId!);
      }
    }
    this.possibleVehicles = []
    for (let vehicleId of possibleVehicleIds)
      this.possibleVehicles.push(this.associationRulesService.vehicles.get(vehicleId)!)

    this.possibleVehicles.sort((v1, v2) => {
      return this.toStringService.vehicleName(v1)
        .localeCompare(this.toStringService.vehicleName(v2))
    })

    this.possibleVehicles.sort((v1, v2) => {
      return Number(this.differentDepot(v1)) - Number(this.differentDepot(v2));
    })


  }

  differentDepot(vehicle : VehicleData) {
    return vehicle.depotId != this.employee?.depotId;
  }

  close() {
    this.closeModal.next(true);
  }

  isRuleComplete() {
    if (this.creationData.choice == AssociationRuleCreationChoice.TRANSPORT) {
      if (this.creationData.ruleType == RuleType.FORCE_IN)
        return this.transport != null;
      else
        return this.transport != null || this.forceAllOut;
    }
    if (this.creationData.choice == AssociationRuleCreationChoice.EMPLOYEE)
      return this.employee != null;
    if (this.creationData.choice == AssociationRuleCreationChoice.VEHICLE)
      return this.vehicle != null;
    return false;
  }

  modifyForceAllOut($event: boolean) {
    this.forceAllOut = $event;
    if (this.forceAllOut)
      this.transport = undefined;
  }

  async createAssociationRule() {
    let ruleType = this.forceAllOut ? RuleType.FORCE_ALL_OUT : this.ruleType!;
    let associationRule: AssociationRuleData = {
      id: -1,
      projectId: -1,
      ruleType: ruleType,
      employeeId: this.employee!.id,
      transportId: this.transport?.id,
      vehicleId: this.vehicle?.id
    }
    await this.baseAssociationRuleService.add(associationRule);
    this.close();
  }

  protected readonly RuleType = RuleType;
}
