<form id="login-form"></form>

<div class="container-box">
  <div class="login-box">
    <mat-form-field class="login-input" floatLabel="always">
      <mat-label for="username"> Utilisateur </mat-label>
      <input matInput id="username" autocomplete="username" type="string"
             [(ngModel)]="authData.username" form="login-form" required>
      <mat-error> {{getRequiredErrorMessage()}} </mat-error>
    </mat-form-field>


    <mat-form-field class="login-input" floatLabel="always">
      <mat-label for="password"> Mot de passe </mat-label>
      <input matInput id="password" autocomplete="current-password"
             [type]="hidePassword ? 'password' : 'text'" [(ngModel)]="authData.password" form="login-form" required>

      <mat-icon matSuffix class="hide-password-button" (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-error> {{getRequiredErrorMessage()}} </mat-error>
    </mat-form-field>

    <mat-checkbox class="keep-connected-checkbox" [(ngModel)]="authData.keepConnected">Rester connecté</mat-checkbox>

    <button class="login-button" title="Login" (click)="login()" form="login-form" type="submit"
        [disabled]="!canLogin()" [class.disabled]="!canLogin()">
      Se connecter
      <mat-spinner class="wait-spinner" diameter="15" *ngIf="waitLogin"></mat-spinner>
    </button>

  </div>

</div>

<div class="layer"></div>
