import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DalConfiguration } from './base-service';
import { AuthenticationData, UserData } from './data-definitions';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
  };

  constructor(private http: HttpClient) {
  }

  login(authData: AuthenticationData) {
    let url = `${DalConfiguration.url}/authentication/login`;
    return this.http.put<UserData>(url, authData, this.httpOptions).toPromise();
  }

}
