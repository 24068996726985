<!--
TODO : would it simplier to make a table or a grid since we added a header ?
Not easy because of hover / box-shadow effects on each row
-->
<div>
  <div class="project-pick-view">
    <section class="page-title-container">
      <div class="project-pick-title">
        <h1>Mes planifications</h1>
        <app-buttons-select 
          [firstChoice] = "selectedType"
          (outChoiceEvent)="setChoice($event)"
          [inChoices]="['AMBU','TAP']"
        >
        </app-buttons-select>
      </div>
    </section>
    <div class="project-list-header">
      <div class="project-name">
        <img src="assets/images/folder.svg" />
        Nom de la planification
      </div>
      <div class="project-kpi">
        <img src="assets/images/ambulance.svg" />
        Type
      </div>
      <div class="project-kpi">
        <img src="assets/images/document.svg" />
        Détails
      </div>
      <div class="project-kpi">
        <img src="assets/images/award.svg" />
        K-score
      </div>
      <div class="project-kpi">
        <img src="assets/images/award.svg" />
        Haut-le-pied
      </div>
      <div class="project-kpi">
        <img src="assets/images/award.svg" />
        Simultanée
      </div>
      <div class="project-kpi">
        <img src="assets/images/award.svg" />
        Retards cum.
      </div>
      <div class="project-kpi">
        <img src="assets/images/award.svg" />
        Autorisés
      </div>
      <div class="project-kpi">
        <img src="assets/images/award.svg" />
        Interdits
      </div>
      <div class="buttons-shift">
      </div>
    </div>
    <div class="project-item"
        [class.highlighted-item]="isCurrentProject(projectInfo.project.id)"
        *ngFor="let projectInfo of filteredProjects" >
      <div class="project-description project-name">
        <div class="project-title">
          <h2>
            {{ projectInfo.title }} 
            <ng-container *ngIf="projectInfo.project.regulationNames !== ''"> 
              - {{projectInfo.project.regulationNames }}
            </ng-container> 
            <ng-container *ngIf="projectInfo.project.name !== ''"> 
              - {{projectInfo.project.name }}
            </ng-container> 
          </h2>
        </div>
        <div class="project-profiles">
          <ng-container *ngIf="projectInfo.parameterProfileName !== ''"> 
            {{projectInfo.parameterProfileName }}
          </ng-container>
          <ng-container *ngIf="projectInfo.trafficProfileName !== ''"> 
            - {{projectInfo.trafficProfileName }}
          </ng-container> 
        </div>
        <div class="project-modification">
          {{ projectInfo.lastModificationString }}
        </div>
      </div>
      <div class="project-kpi project-type">
        <span *ngIf="projectInfo.project.type === 'AMBU'">AMBU</span>
        <span *ngIf="projectInfo.project.type == 'TAP'">TAP</span>
      </div>
      <div class="project-kpi project-detail">
        <div class="project-detail-content">
          <div class="project-nb-items">
            <span>{{projectInfo.project.nbTransports}}&nbsp;</span>
            <img src="assets/images/route.svg">
          </div>
          <div class="project-nb-items">
            <span>{{projectInfo.project.nbEmployees}}&nbsp;</span>
            <img src="assets/images/person.svg">
          </div>
          <div class="project-nb-items">
            <span>{{projectInfo.project.nbVehicles}}&nbsp;</span>
            <img src="assets/images/ambulance.svg">
          </div>
        </div>
      </div>
      <div class="project-kpi indicator-value">
        {{projectInfo.project.kScore != null ? (projectInfo.project.kScore | number: '1.0-2')?.toString() : ""}}
        <span *ngIf="projectInfo.project.kScore" class="indicator-unit"> €/h</span>
      </div>
      <div class="project-kpi indicator-value">
        {{projectInfo.project.deadMileageRate != null ? (projectInfo.project.deadMileageRate! * 100 | number: '1.0-1')?.toString() : ""}}
        <span *ngIf="projectInfo.project.kScore" class="indicator-unit"> %</span>
      </div>
      <div class="project-kpi indicator-value">
        {{projectInfo.project.mutualisationRate != null ? (projectInfo.project.mutualisationRate! * 100 | number: '1.0-1')?.toString() : ""}}
        <span *ngIf="projectInfo.project.kScore" class="indicator-unit"> %</span>
      </div>
      <div class="project-kpi indicator-value">
        {{projectInfo.project.totalTaskLateness != null ? (projectInfo.project.totalTaskLateness! | duration)?.toString() : ""}}
      </div>
      <div class="project-kpi indicator-value">
        {{projectInfo.project.totalTaskAuthorisedLateness != null ? projectInfo.project.totalTaskAuthorisedLateness!.toString() : ""}}
      </div>
      <div class="project-kpi indicator-value">
        {{projectInfo.project.totalTaskUnauthorisedLateness != null ? projectInfo.project.totalTaskUnauthorisedLateness!.toString() : ""}}
        <img *ngIf="projectInfo.project.totalTaskUnauthorisedLateness != null && projectInfo.project.totalTaskUnauthorisedLateness! > 0" src="assets/images/warning.svg" class="warning"/>
      </div>
      <div class="project-actions">
        <button class="rounded-button first-type-button" (click)="openCopyModal(projectInfo)" mat-icon-button>
          <img src="assets/images/copy.svg">
        </button>
        <button class="rounded-button first-type-button" (click)="navigateToProject(projectInfo.project.id)" mat-icon-button>
          <img src="assets/images/eye.svg">
        </button>
        <button class="rounded-button first-type-button" (click)="deleteProject(projectInfo.project)" mat-icon-button>
          <img src="assets/images/bin.svg">
        </button>
      </div>
    </div>
  </div>
</div>
