import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BaseService, DalConfiguration } from './base-service';
import { CurrentProjectService } from './current-project.service';
import { DepotData } from './data-definitions';
import { HttpDalService } from './http-dal.service';
import {BaseVehicleService} from "./base-vehicle.service";

@Injectable({
  providedIn: 'root'
})
export class BaseDepotService extends BaseService<DepotData> {
  constructor(
    http: HttpDalService,
    private currentProjectService: CurrentProjectService,
    private vehicleService: BaseVehicleService) {
    super(http);

    this._addStoreDefinition(
      'by-project-id',
      employee => [employee.projectId]
    );

    this._addDeleteCascadeRelation(
      vehicleService,
      v => v.depotId
    );
  }

  _extractParentUrl(context: any): string {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/depots`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/depots/${context.depotId}`;
  }

  _extractElementId(depot: DepotData) {
    return depot.id;
  }

  load(depotId: number) {
    return this._loadElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      depotId: depotId
    });
  }

  loadAll() {
    return this._loadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  clearAndReloadAll() {
    return this._clearAndReloadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  listen(depotId: number) {
    return this._listenElement(depotId);
  }

  listenAll() {
    return this.currentProjectService.findProjectId().pipe(
      mergeMap(projectId => projectId === undefined ? EMPTY : this._listenElements('by-project-id', [projectId]))
    );
  }
}