import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {AssociationRuleData, EmployeeData, RuleType, TransportData, VehicleData} from "../data-definitions";
import {AssociationRulesService, EmployeeWithRules} from "../association-rules.service";
import {defaultDialogConfig} from "../dialog-data";
import {
  AssociationRulesCreationModalComponent
} from "../association-rules-creation-modal/association-rules-creation-modal.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ToStringService} from "../to-string.service";
import {BaseAssociationRuleService} from "../base-association-rule.service";
import {Subscription} from "rxjs";

export enum AssociationRuleCreationChoice {
  TRANSPORT,
  EMPLOYEE,
  VEHICLE,
  ALL
}

export interface AssociationRuleCreationData {
  ruleType?: RuleType,
  employeeWithRules?: EmployeeWithRules
  transport?: TransportData,
  vehicle?: VehicleData,
  choice: AssociationRuleCreationChoice,
}

@Component({
  selector: 'app-association-rules-element[employee]',
  templateUrl: './association-rules-element.component.html',
  styleUrls: ['./association-rules-element.component.css',
              '../../assets/buttons.css',
              '../../assets/containers.css']
})
export class AssociationRulesElementComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
  RuleType = RuleType;

  @Input() employee?: EmployeeData; // required
  @Input() isSelected: boolean = false;
  @Input() wasPreviouslySelected: boolean = false;
  @Input() crewMan?: EmployeeData;

  @Output() employeeSelection = new EventEmitter<EmployeeData>();

  @ViewChild('container') container: ElementRef = new ElementRef(null);
  maxHeightUpdateRequest = false;

  nbAssociationRules = 0;
  nbLocks = 0;

  employeeWithRules?: EmployeeWithRules;

  employeeName = ""

  matDialogRef?: MatDialogRef<AssociationRulesCreationModalComponent>;

  subscribed = false;
  subscriptions: Subscription = new Subscription();

  static elementHeight = 78;

  constructor(public associationRulesService: AssociationRulesService,
              private baseAssociationRuleService: BaseAssociationRuleService,
              private toStringService: ToStringService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.associationRulesService.structureInitialized.asObservable().subscribe(value => {
      if (this.subscribed) return;
      if (!this.associationRulesService.employeeWithRulesMap.has(this.employee!.id)) return
      this.subscriptions.add(this.associationRulesService.listen(this.employee!.id).subscribe(employeeWithRules => {
        this.employeeWithRules = employeeWithRules;
        this.nbAssociationRules = employeeWithRules.imposedTransportRules.length + employeeWithRules.forbiddenTransportRules.length
          + employeeWithRules.forbiddenVehicleRules.length
          + (employeeWithRules.imposedVehicleRule ? 1 : 0) + (employeeWithRules.forbidAllOtherTransportsRule ? 1 : 0)
        this.nbLocks = employeeWithRules.lockedTransportRules.length
      }))
      this.subscribed = true;
    })
    this.employeeName = this.toStringService.employeeName(this.employee);
  }

  ngOnChanges(changes: SimpleChanges) {
    let selectedChange = changes["isSelected"];
    if (selectedChange) {
      let isUnselecting = selectedChange.previousValue && !selectedChange.currentValue;
      if (isUnselecting) {
        this.container.nativeElement.style.maxHeight = `${AssociationRulesElementComponent.elementHeight}px`;
      }
      else {
        this.maxHeightUpdateRequest = true;
      }
    }
  }

  ngAfterViewChecked() {
    if (!this.container) return;
    if (this.maxHeightUpdateRequest || (this.isSelected
      && this.container.nativeElement.style.maxHeight != AssociationRulesElementComponent.elementHeight)) {
      this.container.nativeElement.style.maxHeight = this.container.nativeElement.scrollHeight + "px";
      this.maxHeightUpdateRequest = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  onHeaderClick() {
    this.employeeSelection.emit(this.employee!);
  }

  createNewTransportRule(ruleType: RuleType) {
    let dialogConfig = defaultDialogConfig();
    let data: AssociationRuleCreationData = {
      ruleType: ruleType,
      employeeWithRules: this.employeeWithRules,
      choice: AssociationRuleCreationChoice.TRANSPORT
    }
    dialogConfig.data = data;
    this.matDialogRef = this.dialog.open(AssociationRulesCreationModalComponent, dialogConfig);
    this.matDialogRef.componentInstance.closeModal.subscribe(
      value => {
        if (value)
          this.matDialogRef!.close();
      });
  }

  createNewVehicleRule(ruleType: RuleType) {
    let dialogConfig = defaultDialogConfig();
    let data: AssociationRuleCreationData = {
      ruleType: ruleType,
      employeeWithRules: this.employeeWithRules,
      choice: AssociationRuleCreationChoice.VEHICLE
    }
    dialogConfig.data = data;
    this.matDialogRef = this.dialog.open(AssociationRulesCreationModalComponent, dialogConfig);
    this.matDialogRef.componentInstance.closeModal.subscribe(
      value => {
        if (value)
          this.matDialogRef!.close();
      });
  }

  async deleteRule(rule: AssociationRuleData) {
    await this.baseAssociationRuleService.remove(rule);
  }
}
