import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Subscription} from "rxjs";
import {environment} from '../../environments/environment';
import {ProjectData, SolveParameters} from "../data-definitions";
import {BaseProjectService} from "../base-project.service";
import {BaseMeasuresService} from "../base-measures.service";
import {BaseVehicleService} from "../base-vehicle.service";
import {BaseLocationService} from "../base-location.service";
import {MessageService} from "../message.service";
import {SolveService} from "../solve.service";
import {SolveStatusService} from "../solve-status.service";
import {CurrentProjectService} from "../current-project.service";
import {ModalProgressBarComponent} from "../modal-progress-bar/modal-progress-bar.component";
import { BaseCrewService } from '../base-crew.service';

@Component({
  selector: 'app-solve-modal',
  templateUrl: './solve-modal.component.html',
  styleUrls: ['./solve-modal.component.css',
              '../../assets/standard-page.css',
              '../../assets/forms.css',
              '../../assets/buttons.css',
              '../../assets/modal.css']
})
export class SolveModalComponent implements OnInit {
  subscriptions: Subscription = new Subscription();

  @Output() closeModal = new EventEmitter<boolean>();

  project?: ProjectData;

  emptyVehicles = true;
  emptyLocations = true;

  hasBeenLaunched = false;
  hasPendingSolve = false;
  isRunning = false;
  isCanceled = false;

  @ViewChild(ModalProgressBarComponent)  progressBar?: ModalProgressBarComponent;

  parameters: SolveParameters = {timeLimit: 300, verbosity: 1};

  constructor(private baseProjectService: BaseProjectService,
              private baseMeasuresService: BaseMeasuresService,
              private baseVehicleService: BaseVehicleService,
              private baseLocationService: BaseLocationService,
              private baseCrewService: BaseCrewService,
              private http: HttpClient,
              private messageService: MessageService,
              private solveService: SolveService,
              private solveStatusService: SolveStatusService,
              private currentProjectService: CurrentProjectService) { }

  ngOnInit(): void {
    this.subscriptions.add(this.baseProjectService.listen().subscribe(project => {
      this.project = project;
    }));
    this.subscriptions.add(this.baseVehicleService.listenAll().subscribe(vehicles => {
      this.emptyVehicles = vehicles.length === 0;
    }));
    this.subscriptions.add(this.baseLocationService.listenAll().subscribe(locations => {
      this.emptyLocations = locations.length === 0;
    }));
    this.currentProjectService.findProjectId().subscribe(projectId => {
      if (projectId) this.loadData();
    });
    this.solveStatusService.isCancelled().subscribe( isCanceled => {
      this.isCanceled = isCanceled;
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async loadData() {
    try {
      let environmentId = this.currentProjectService.getEnvironmentId();
      let projectId = this.currentProjectService.getProjectId();
      let downloadUrl = `${environment.apiUrl}/environments/${environmentId}/projects/${projectId}/parameters/computation-time`;
      const response = await this.http.get(downloadUrl, {responseType: 'json', withCredentials: true}).toPromise();
      const computationTime = parseInt(JSON.stringify(response));
      this.parameters.timeLimit = this.project?.nbTransports ? Math.max(Math.round(this.project?.nbTransports/10)*computationTime, computationTime) : 0;
      await Promise.all([this.baseProjectService.load(), this.baseMeasuresService.load(),
        this.baseLocationService.loadAll(), this.baseVehicleService.loadAll()]);
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  async solve() {
    try {
      this.startSolve();
      this.hasPendingSolve = true;
      await this.solveService.solve(this.parameters);
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
    this.hasPendingSolve = false;
    if (!this.isCanceled) {
      this.closeModal.next(true);
    }
    await this.finishSolve();
  }

  async close() {
    await this.cancel();
    this.closeModal.next(true);
  }

  async cancel() {
    if (!this.isRunning) return;
    this.progressBar!.cancel();
    await this.stop();
    // Wait until server response to avoid having potential concurrent resolutions from same client
    while (this.hasPendingSolve) {
      await new Promise( resolve => setTimeout(resolve, 100));
    }
  }

  private startSolve() {
    this.solveStatusService.startSolving(this.parameters.timeLimit);
    this.openModalProgressBar();
    this.messageService.clearError();
    this.isRunning = true;
    this.hasBeenLaunched = true;
  }

  async stop() {
    try {
      await this.solveService.stop();
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  openModalProgressBar() {
    this.progressBar!.startTimer();
  }

  async finishSolve() {
    await this.baseProjectService.updateLastModificationDate(this.project!);
    if (this.project?.type === "AMBU") await this.baseCrewService.clearAndReloadAll();
    this.solveStatusService.endSolving();
    this.isRunning = false;
  }
}
