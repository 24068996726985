import {Injectable} from "@angular/core";
import {BaseTransportService} from "./base-transport.service";
import {BaseVehicleService} from "./base-vehicle.service";
import {BaseEmployeeService} from "./base-employee.service";
import {MessageService} from "./message.service";
import {HttpErrorResponse} from "@angular/common/http";
import {BaseAssociationRuleService} from "./base-association-rule.service";
import {BehaviorSubject, combineLatest} from "rxjs";
import {
  AssociationRuleData,
  EmployeeData,
  RouteSolution,
  RuleType,
  TransportData,
  VehicleData
} from "./data-definitions";
import {BaseRouteSolutionService} from "./base-route-solution.service";
import {DateService} from "./date.service";
import {CurrentProjectService} from "./current-project.service";

export interface IndexedData {
  id: number
}

export interface EmployeeWithRules {
  employee: EmployeeData
  imposedVehicleRule?: AssociationRuleData
  imposedTransportRules: AssociationRuleData[]
  forbiddenTransportRules: AssociationRuleData[]
  forbiddenVehicleRules: AssociationRuleData[],
  forbidAllOtherTransportsRule?: AssociationRuleData
  lockedTransportRules: AssociationRuleData[]
}

// TODO: this service is overly complicated, it should be simplified by design
// Maybe data should be loaded in two phases ?
// We have a precedence constraint between transports, employee, vehicles and rules / locks
@Injectable({
  providedIn: 'root'
})
export class AssociationRulesService {

  structureInitialized = new BehaviorSubject<boolean>(false);

  transportSubject = new BehaviorSubject<Map<number, TransportData>>(new Map<number, TransportData>());
  employeeSubject = new BehaviorSubject<Map<number, EmployeeData>>(new Map<number, EmployeeData>());
  vehicleSubject = new BehaviorSubject<Map<number, VehicleData>>(new Map<number, VehicleData>());
  associationRuleSubject = new BehaviorSubject<Map<number, AssociationRuleData>>(new Map<number, AssociationRuleData>());

  transports = new Map<number, TransportData>();
  employees = new Map<number, EmployeeData>();
  vehicles = new Map<number, VehicleData>();
  associationRules = new Map<number, AssociationRuleData>();
  routeSolutions : RouteSolution[] = []

  imposableTransportIds = new Set<number>();
  imposableVehicleIds = new Set<number>();
  lockableTransportByEmployee = new Map<number, TransportData[]>();

  employeeWithRulesMap = new Map<number, EmployeeWithRules>();
  employeeWithRulesSubjectMap = new Map<number, BehaviorSubject<EmployeeWithRules>>();

  constructor(private baseTransportService: BaseTransportService,
              private baseVehicleService: BaseVehicleService,
              private baseEmployeeService: BaseEmployeeService,
              private baseAssociationRuleService: BaseAssociationRuleService,
              private baseRouteSolutionService: BaseRouteSolutionService,
              private currentProjectService: CurrentProjectService,
              private dateService: DateService,
              private messageService: MessageService,
              ) {
    this.currentProjectService.findProjectId().subscribe(async projectId => {
      this.clearAll();
      if (projectId) await this.loadData();
    })
    combineLatest([this.transportSubject, this.employeeSubject, this.vehicleSubject,
      this.associationRuleSubject, this.baseRouteSolutionService.listenAll()]).subscribe(
      ([transports, employees, vehicles,
         associationRules, routeSolutions]) => {
        let completeData = transports.size > 0 && employees.size > 0 && vehicles.size > 0;
        if (!completeData) return;

        let diffNbTransports = transports.size - this.transports.size;
        let diffNbEmployees = employees.size - this.employees.size;
        let diffNbVehicles = vehicles.size - this.vehicles.size;
        let addedOrRemovedData = diffNbTransports != 0 || diffNbEmployees != 0 || diffNbVehicles != 0;
        if (addedOrRemovedData) {
          this.initStructures(transports, employees, vehicles);
        }

        const eqSet = (xs: Set<number>, ys: Set<number>) =>
          xs.size === ys.size &&
          [...xs].every((x) => ys.has(x));
        let previousRouteSolutionIds = new Set(this.routeSolutions.map(r => r.id));
        let newRouteSolutionIds = new Set(routeSolutions.map(r => r.id));
        if (!eqSet(previousRouteSolutionIds, newRouteSolutionIds)) {
          for (let routeSolution of routeSolutions) {
            if (!this.employees.has(routeSolution.employeeId))
              // We wait for employees update before updating the locks (when changing project, routes may be updated
              // before the employees)
              return
          }
          this.routeSolutions = routeSolutions;
          this.initPossibleLocks();
        }

        let diffNbAssociationRules = associationRules.size - this.associationRules.size;
        if (diffNbTransports + diffNbEmployees + diffNbVehicles + diffNbAssociationRules == 0) {
          // As the front do not add or remove transport, employee or vehicle, and do not modify rules,
          // if we reach this point, it means that a transport, employee or vehicle was updated (its status)
          // so we make sure than the maps store the latest reference of objects
          // TODO: remove this requirement by design
          this.updateMaps(transports, employees, vehicles);
          this.initPossibleLocks();
          for (let employee of employees.values()) {
            let employeeWithRules = this.employeeWithRulesMap.get(employee.id);
            if (employeeWithRules != undefined) {
              employeeWithRules.employee = employee;
              this.employeeWithRulesSubjectMap.get(employee.id)!.next(employeeWithRules)
            }
          }
          return;
        }

        let previousIndex = new Set(this.associationRules.keys())
        let newIndex = new Set(associationRules.keys())
        let addedAssociatedRulesIndex= new Set([...newIndex].filter(i => !previousIndex.has(i)));
        let removedAssociatedRulesIndex= new Set([...previousIndex].filter(i => !newIndex.has(i)));
        let addedRules: AssociationRuleData[] = []
        addedAssociatedRulesIndex.forEach(i => addedRules.push(associationRules.get(i)!))
        let removedRules: AssociationRuleData[] = []
        removedAssociatedRulesIndex.forEach(i => removedRules.push(this.associationRules.get(i)!))
        this.applyChanges(addedRules, removedRules)
        this.associationRules = associationRules;
      }
    )
    this.baseTransportService.listenAll().subscribe(
      transports => {
        this.updateData(transports, this.transportSubject);
        this.imposableTransportIds = new Set(transports.map(t => t.id));
      }
    );
    this.baseEmployeeService.listenAll().subscribe(
      employees => {
        this.updateData(employees, this.employeeSubject);
      }
    );
    this.baseVehicleService.listenAll().subscribe(
      vehicles => {
        this.updateData(vehicles, this.vehicleSubject);
        this.imposableVehicleIds = new Set(vehicles.map(t => t.id));
      }
    );
    this.baseAssociationRuleService.listenAll().subscribe(
      associationRules => {
          this.updateData(associationRules, this.associationRuleSubject);
      }
    );
  }

  clearAll() {
    this.transports.clear()
    this.employees.clear()
    this.vehicles.clear()
    this.associationRules.clear()
    this.routeSolutions = []
    this.transports.clear()
    this.transports.clear()
    this.imposableTransportIds.clear()
    this.imposableVehicleIds.clear()
    this.lockableTransportByEmployee.clear()
    this.employeeWithRulesMap.clear()
    this.employeeWithRulesSubjectMap.clear()
  }

  updateData<TData extends IndexedData>(data: TData[], subject: BehaviorSubject<Map<number, TData>>) {
    let map: Map<number, TData> = new Map<number, TData>();
    data.forEach(
      element => map.set(element.id, element)
    )
    subject.next(map);
  }

  updateMaps(transports: Map<number, TransportData>, employees: Map<number, EmployeeData>,
             vehicles: Map<number, VehicleData>) {
    this.transports = transports;
    this.employees = employees;
    this.vehicles = vehicles;
  }

  initStructures(transports: Map<number, TransportData>, employees: Map<number, EmployeeData>,
                 vehicles: Map<number, VehicleData>) {
    this.updateMaps(transports, employees, vehicles)
    this.employeeWithRulesMap.clear();
    for (let employee of this.employees.values()) {
      let employeeWithRules: EmployeeWithRules = {
        employee: employee,
        imposedTransportRules: [],
        forbiddenTransportRules: [],
        forbiddenVehicleRules:  [],
        lockedTransportRules: []
      }
      this.employeeWithRulesMap.set(employee.id, employeeWithRules)
      let subject = new BehaviorSubject<EmployeeWithRules>(employeeWithRules);
      this.employeeWithRulesSubjectMap.set(employee.id, subject);
    }
    this.structureInitialized.next(true)
  }

  initPossibleLocks() {
    this.lockableTransportByEmployee.clear()
    for (let routeSolution of this.routeSolutions) {
      let employeeWithRules = this.employeeWithRulesMap.get(routeSolution.employeeId)!;
      let transportIds = routeSolution.taskActions.filter(ta => ta.pickupTransportId != null)
        .map(ta => ta.pickupTransportId!);
      let transports: TransportData[] = [];
      transportIds.forEach(id => transports.push(this.transports.get(id)!))
      transports.sort((t1, t2) => {
        return this.dateService.iso8601StringOrDateToDate(t1.timeWindowStart).getTime()
          -  this.dateService.iso8601StringOrDateToDate(t2.timeWindowStart).getTime()
      })
      this.lockableTransportByEmployee.set(employeeWithRules.employee.id, transports);
    }
  }

  applyChanges(addedRules: AssociationRuleData[], removedRules: AssociationRuleData[]) {
    this.addRules(addedRules)
    this.removeRules(removedRules)
  }

  addRules(addedRules: AssociationRuleData[]) {
    for (let associationRule of addedRules) {
      let employeeWithRules = this.employeeWithRulesMap.get(associationRule.employeeId)!;
      if (associationRule.transportId != null && associationRule.ruleType == RuleType.FORCE_IN) {
        employeeWithRules.imposedTransportRules.push(associationRule);
        this.imposableTransportIds.delete(associationRule.transportId);
      } else if (associationRule.transportId != null && associationRule.ruleType == RuleType.FORCE_OUT) {
        employeeWithRules.forbiddenTransportRules.push(associationRule);
      } else if (associationRule.vehicleId != null && associationRule.ruleType == RuleType.FORCE_IN) {
        employeeWithRules.imposedVehicleRule = associationRule;
        this.imposableVehicleIds.delete(associationRule.vehicleId)
      } else if (associationRule.vehicleId != null && associationRule.ruleType == RuleType.FORCE_OUT) {
        employeeWithRules.forbiddenVehicleRules.push(associationRule)
      } else if (associationRule.ruleType == RuleType.LOCK_IN) {
        employeeWithRules.lockedTransportRules.push(associationRule)
      } else if (associationRule.ruleType == RuleType.FORCE_ALL_OUT) {
        employeeWithRules.forbidAllOtherTransportsRule = associationRule;
      }
      this.employeeWithRulesSubjectMap.get(associationRule.employeeId)!.next(employeeWithRules)
    }
  }

  removeRules(removedRules: AssociationRuleData[]) {
    for (let associationRule of removedRules) {
      let employeeWithRules = this.employeeWithRulesMap.get(associationRule.employeeId);
      if (employeeWithRules == null) continue; // Project change
      if (associationRule.transportId != null && associationRule.ruleType == RuleType.FORCE_IN) {
        const index = employeeWithRules.imposedTransportRules.indexOf(associationRule)
        employeeWithRules.imposedTransportRules.splice(index, 1)
        this.imposableTransportIds.add(associationRule.transportId);
      } else if (associationRule.transportId != null && associationRule.ruleType == RuleType.FORCE_OUT) {
        const index = employeeWithRules.forbiddenTransportRules.indexOf(associationRule)
        employeeWithRules.forbiddenTransportRules.splice(index, 1)
      } else if (associationRule.vehicleId != null && associationRule.ruleType == RuleType.FORCE_IN) {
        employeeWithRules.imposedVehicleRule = undefined;
        this.imposableVehicleIds.add(associationRule.vehicleId);
      } else if (associationRule.vehicleId != null && associationRule.ruleType == RuleType.FORCE_OUT) {
        const index = employeeWithRules.forbiddenVehicleRules.indexOf(associationRule)
        employeeWithRules.forbiddenVehicleRules.splice(index, 1)
      } else if (associationRule.ruleType == RuleType.LOCK_IN) {
        const index = employeeWithRules.lockedTransportRules.indexOf(associationRule)
        employeeWithRules.lockedTransportRules.splice(index, 1)
      } else if (associationRule.ruleType == RuleType.FORCE_ALL_OUT) {
        employeeWithRules.forbidAllOtherTransportsRule = undefined
      }
      this.employeeWithRulesSubjectMap.get(associationRule.employeeId)!.next(employeeWithRules)
    }
  }

  async loadData() {
    try {
      await Promise.all([this.baseTransportService.loadAll(), this.baseVehicleService.loadAll(),
        this.baseEmployeeService.loadAll(), this.baseAssociationRuleService.loadAll(),
        this.baseRouteSolutionService.loadAll()]);
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  listen(employeeId: number) {
    return this.employeeWithRulesSubjectMap.get(employeeId)!.asObservable();
  }
}
