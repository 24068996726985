<div class= "duration-input">
  <mat-form-field class="duration-input-field">
    <input matInput [(ngModel)]="strHours" name="hours" type="text" (ngModelChange)="check()">
  </mat-form-field>
</div>
<span>:</span>
<div class= "duration-input">
  <mat-form-field class="duration-input-field">
    <input matInput [(ngModel)]="strMinutes" name="minutes" type="text" (ngModelChange)="check()">
  </mat-form-field>
</div>
<span>:</span>
<div class= "duration-input">
  <mat-form-field class="duration-input-field">
    <input matInput [(ngModel)]="strSeconds" name="seconds" type="text" (ngModelChange)="check()">
  </mat-form-field>
</div>