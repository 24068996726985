import { Injectable } from "@angular/core";
import { DepotInfo } from "./data-definitions";
import { mergeMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { HttpDalService } from "./http-dal.service";
import { BaseService, DalConfiguration } from './base-service';
import { CurrentProjectService } from "./current-project.service";

@Injectable({
    providedIn: 'root'
})
export class BaseDepotInfoService extends BaseService<DepotInfo> {
    constructor(
        http: HttpDalService,
        private currentProjectService: CurrentProjectService) {
        super(http);

        this._addStoreDefinition(
            'by-project-id',
            depotInfo => [depotInfo.projectId]
        )
    }

    _extractParentUrl(context: any): string {
        return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/depots/infos/`;
    }

    _extractElementUrl(context: any) {
        return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/depots/infos/${context.depotId}`;
    }

    _extractElementId(depot: DepotInfo) {
        return depot.id;
    }

    load(depotId: number) {
        return this._loadElement({
            environmentId: this.currentProjectService.getEnvironmentId(),
            projectId: this.currentProjectService.getProjectId(),
            depotId: depotId
        });
    }

    loadAll() {
        return this._loadElements({
            environmentId: this.currentProjectService.getEnvironmentId(),
            projectId: this.currentProjectService.getProjectId()
        });
    }

    clearAndReloadAll() {
        return this._clearAndReloadElements({
            environmentId: this.currentProjectService.getEnvironmentId(),
            projectId: this.currentProjectService.getProjectId()
        });
    }

    listen(depotId: number) {
        return this._listenElement(depotId);
    }

    listenAll() {
        return this.currentProjectService.findProjectId().pipe(
            mergeMap(projectId => projectId === undefined ? EMPTY : this._listenElements('by-project-id', [projectId]))
        );
    }
}