<button class="rounded-button first-type-button close-button" (click)="close()">
    <img src="assets/images/cross.svg">
</button>

<div class="container">
    <h1 *ngIf="id == undefined">Créer un nouveau profil</h1>
    <h1 *ngIf="id != undefined">Modifier un profil</h1>
<div class="input__inner">
    <input id="planning-name" name="planning-name" type="text"
            class="input__field" [class.input__field--has-value]="name"
            [(ngModel)]="name">
    <label for="planning-name" class="input__label">
        Nom du <span *ngIf="id == undefined">nouveau</span> profil
    </label>
    <mat-checkbox
        [checked]="default"
        (change)="toggleDefault($event)"
        class="profile-default-checkbox"
        color="primary"
    >
        Par défaut
    </mat-checkbox>
</div>
<div class="button-container">
    <button class="rectangular-button first-type-button" (click)="close()">
    Annuler
    </button>
    <button class="rectangular-button important-button start-button"
            (click)="createOrUpdateProfile()"
            [disabled]="creating">
    <div *ngIf="id == undefined" class="start-button-label">
        Créer
    </div>
    <div *ngIf="id != undefined" class="start-button-label">
        Modifier
    </div>
    <img src="assets/images/arrow-right.svg">
    </button>
</div>
<app-modal-progress-bar></app-modal-progress-bar>
</div>
  