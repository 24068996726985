<div class="filter-group">
  <button class="simple-button filter-button" mat-icon-button [matMenuTriggerFor]="filterMenu"
          title="Filter">
    <mat-icon [class.filter-active]="hasUnchecked()">{{hasUnchecked() ? 'filter_alt' : 'filter_list'}}</mat-icon>
  </button>
  <mat-menu class="filter-menu" #filterMenu="matMenu">
    <div *ngFor="let element of elements" class="filter checkbox-container"
         (click)="$event.stopPropagation()" floatLabel="always">
      <mat-checkbox
             [checked]="checkedElements.has(element)"
             (change)="changeFunction(element)"
             color="primary">
        {{element}}
      </mat-checkbox>
    </div>
    <button mat-icon-button class="simple-button" (click)="suppressFilter()"><mat-icon>delete_sweep</mat-icon></button>
  </mat-menu>
</div>
