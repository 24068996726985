import {Component, Input, OnInit} from '@angular/core';
import {AssociationRuleData, Status, VehicleData} from "../data-definitions";
import {DateService} from "../date.service";
import {ToStringService} from "../to-string.service";
import {BaseAssociationRuleService} from "../base-association-rule.service";

@Component({
  selector: 'app-association-rules-vehicle[vehicle][rule]',
  templateUrl: './association-rules-vehicle.component.html',
  styleUrls: ['./association-rules-vehicle.component.css',
              '../../assets/buttons.css',
              '../../assets/containers.css']
})
export class AssociationRulesVehicleComponent implements OnInit {

  @Input() vehicle?: VehicleData
  @Input() rule?: AssociationRuleData

  vehicleName = "";

  constructor(private dateService: DateService,
              private toStringService: ToStringService,
              private baseAssociationRuleService: BaseAssociationRuleService) { }

  ngOnInit(): void {
    this.vehicleName = this.toStringService.vehicleName(this.vehicle);
  }

  async deleteRule() {
    await this.baseAssociationRuleService.remove(this.rule!);
  }

    protected readonly Status = Status;
}
