import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from "rxjs";
import {BaseParameterService} from "../base-parameter.service";
import {CurrentProjectService} from "../current-project.service";
import {MessageService} from "../message.service";
import {DurationPipe} from "../duration.pipe";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpErrorResponse} from "@angular/common/http";
import {
  Parameter,
  ParameterType,
  DurationElements,
  ParameterCategory,
  ParameterEntry,
  tapParametersConfiguration,
  ambuParametersConfiguration,
  ParameterTable,
  ParameterValueType, ParameterEnum
} from "../parameter-configuration"
import moment from "moment/moment";
import {BaseDefaultParameterService} from "../base-default-parameter.service";
import {EventhubSectorData, ProfileData, ProjectData} from "../data-definitions";
import { BaseProjectService } from '../base-project.service';

@Component({
  selector: 'app-parameter-modification',
  templateUrl: './parameter-modification.component.html',
  styleUrls: ['./parameter-modification.component.css',
              '../../assets/standard-page.css',
              '../../assets/buttons.css',
              '../../assets/select.css']
})
export class ParameterModificationComponent implements OnInit, OnDestroy, OnChanges {

  @Input() parameterTable: ParameterTable = ParameterTable.PROJECT;
  @Input() profile?: ProfileData;
  
  project?: ProjectData;

  ParameterValueType = ParameterValueType
  parametersConfiguration?: ParameterCategory[];
  selectedCategory?: ParameterCategory;

  subscriptions: Subscription = new Subscription();

  parameters: Parameter[] = [];
  longDelay?: number = 0;
  mediumDelay?: number = 0;
  shortDelay?: number = 0;
  pendingParameterMap?: Map<string, Parameter>;

  concernedService?: BaseParameterService | BaseDefaultParameterService;

  constructor(private baseParameterService: BaseParameterService,
              private baseDefaultParameterService: BaseDefaultParameterService,
              private currentProjectService: CurrentProjectService,
              private baseProjectService: BaseProjectService,
              private messageService: MessageService,
              private durationPipe: DurationPipe,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    if (this.parameterTable == ParameterTable.DEFAULT) {
      this.concernedService = this.baseDefaultParameterService
    } else {
      this.concernedService = this.baseParameterService;
      this.subscriptions.add(this.baseProjectService.listen().subscribe(project => {
        this.project = project;
      }));
    }
    this.subscriptions.add(this.concernedService.listenAll().subscribe(parameters => {
      if (parameters.length == 0) return;
      this.initializeParameters(parameters);
    }));
    if (this.parameterTable != ParameterTable.DEFAULT) {
      this.currentProjectService.findProjectId().subscribe(projectId => {
        if (projectId) this.loadData();
      });
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.concernedService) return
    let profileChange = changes["profile"]
    if (profileChange) {
      let service = <BaseDefaultParameterService>this.concernedService;
      service.setProfileId(profileChange.currentValue.id);
      this.parametersConfiguration = this.profile?.projectType === "TAP" ? tapParametersConfiguration : ambuParametersConfiguration;
      this.selectedCategory = this.profile?.projectType === "TAP" ? tapParametersConfiguration[0] : ambuParametersConfiguration[0];
      await service.clearAndReloadAll()
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async loadData() {
    try {
      await this.concernedService!.loadAll();
      let type = "";
      if (this.parameterTable != ParameterTable.DEFAULT) {
        await this.baseProjectService.load();
        type = this.project?.type!;
      } else {
        type = this.profile?.projectType!;
      }
      this.parametersConfiguration = type === "TAP" ? tapParametersConfiguration : ambuParametersConfiguration;
      this.selectedCategory = type === "TAP" ? tapParametersConfiguration[0] : ambuParametersConfiguration[0];
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  initializeParameters(parameters: Parameter[]) {
    this.parameters = parameters;
    this.pendingParameterMap = new Map<string, Parameter>();
    for (let param of parameters) {
      this.pendingParameterMap.set(param.name, {...param});
      if (param.name == "LONG_DELAY") this.longDelay = param.intValue;
      else if (param.name == "MEDIUM_DELAY") this.mediumDelay = param.intValue;
      else if (param.name == "SHORT_DELAY") this.shortDelay = param.intValue;
    }
  }

  getDelayValue(parameter: ParameterEntry) {
    switch (parameter.type) {
      case ParameterType.OVER_LONG_DELAY_IN: return this.longDelay + "h avant"
      case ParameterType.OVER_LONG_DELAY_OUT: return this.longDelay + "h avant"
      case ParameterType.UNDER_LONG_DELAY_IN: return this.longDelay + "h avant"
      case ParameterType.UNDER_LONG_DELAY_OUT: return this.longDelay + "h avant"
      case ParameterType.UNDER_MEDIUM_DELAY_IN: return this.mediumDelay + "h avant"
      case ParameterType.UNDER_MEDIUM_DELAY_OUT: return this.mediumDelay + "h avant"
      case ParameterType.UNDER_SHORT_DELAY_IN: return this.shortDelay + "h avant"
      case ParameterType.UNDER_SHORT_DELAY_OUT: return this.shortDelay + "h avant"
      default: return ""
    }
  }

  getParameterValue(parameter: ParameterEntry) {
    let param = this.getParameter(parameter.type);
    if (!param) return;
    let scaling = parameter.scaling ?? 1;
    switch (parameter.valueType) {
      case ParameterValueType.DOUBLE: return Math.round(param.doubleValue! * scaling * 100) / 100;
      case ParameterValueType.INTEGER: return Math.round(param.intValue! * scaling);
      case ParameterValueType.STRING: return param.stringValue!;
      case ParameterValueType.DURATION:
      case ParameterValueType.DURATION_WITH_STRING:
        if (parameter.unit == "min")
          return this.durationPipe.transform(param.intValue!, "m:ss");
        else
          return this.durationPipe.transform(param.intValue!, "H:mm");
      case ParameterValueType.BOOLEAN:
        return param.boolValue!;
    }
  }

  getParameterEnumValue(parameter: ParameterEnum) {
    let param = this.getParameter(parameter.type);
    if (!param) return "";
    let value = param.stringValue!
    for (let possibility of parameter.possibilities) {
      if (value == possibility.value)
        return possibility.title
    }
    return ""
  }

  setParameterValue(parameter: ParameterEntry | ParameterEnum, $event: any) {
    let param = this.getParameter(parameter.type);
    if (!param) return;
    if ('valueType' in parameter) {
      let scaling = parameter.scaling ?? 1;
      switch (parameter.valueType) {
        case ParameterValueType.DOUBLE:
          param.doubleValue = $event / scaling;
          break;
        case ParameterValueType.INTEGER:
          param.intValue = Math.round($event / scaling);
          break;
        case ParameterValueType.STRING:
          param.stringValue = $event;
          break;
        case ParameterValueType.DURATION:
          param.intValue = ParameterModificationComponent.extractDuration(parameter, $event as string);
          break;
        case ParameterValueType.BOOLEAN:
          param.boolValue = $event;
          break;
      }
    } else {
      param.stringValue = $event;
      this.runPostSetEnumHooks(parameter, $event);
    }
  }

  runPostSetEnumHooks(parameter: ParameterEnum, $event: any) {
    if (parameter.type == ParameterType.SIMULTANEOUS_CONFIGURATION) {
      let tripleSimultaneousParam = this.getParameter(ParameterType.TRIPLE_SIMULTANEOUS_CONFIGURATION)!;
      let tripleSimultaneousParamValue = tripleSimultaneousParam.stringValue!;
      if ($event == "FORBIDDEN") {
        tripleSimultaneousParam.stringValue = "FORBIDDEN"
      } else if ($event == "ALLOWED_IF_SAME_DIRECTION" && tripleSimultaneousParamValue == "ALLOWED") {
        tripleSimultaneousParam.stringValue = "ALLOWED_IF_SAME_DIRECTION"
      }
    } else if (parameter.type == ParameterType.TRIPLE_SIMULTANEOUS_CONFIGURATION) {
      let doubleSimultaneousParam = this.getParameter(ParameterType.SIMULTANEOUS_CONFIGURATION)!;
      let doubleSimultaneousParamValue = doubleSimultaneousParam.stringValue!;
      if ($event == "ALLOWED") {
        doubleSimultaneousParam.stringValue = "ALLOWED"
      } else if ($event == "ALLOWED_IF_SAME_DIRECTION" && doubleSimultaneousParamValue == "FORBIDDEN") {
        doubleSimultaneousParam.stringValue = "ALLOWED_IF_SAME_DIRECTION"
      }
    }
  }

  static extractDuration(parameter: ParameterEntry, $event: any) {
    let eventStr = $event as string
    let elements = eventStr.split(":").map(Number);
    let durationElements: DurationElements = {hours: 0, minutes: 0, seconds: 0};
    if (parameter.unit == "min") {
      if (elements.length >= 1) durationElements.minutes = elements[0];
      if (elements.length >= 2) durationElements.seconds = elements[1];
    } else {
      if (elements.length >= 1) durationElements.hours = elements[0];
      if (elements.length >= 2) durationElements.minutes = elements[1];
    }
    let duration = moment.duration(durationElements);
    return duration.asSeconds();
  }

  getParameter(paramType: ParameterType) {
    if (this.pendingParameterMap) {
      return this.pendingParameterMap!.get(ParameterType[paramType])
    } else {
      return undefined;
    }
  }

  revertChanges() {
    for (let param of this.parameters) {
      this.pendingParameterMap!.set(param.name, {...param});
    }
  }

  async applyChanges() {
    let oldParameters = new Map<string, Parameter>();
    for (let param of this.parameters) {
      oldParameters.set(param.name, param);
    }
    let promises = [];
    for (let param of this.pendingParameterMap!.values()) {
      let oldParam = oldParameters.get(param.name);
      console.assert(oldParam !== undefined);
      if (!ParameterModificationComponent.areParameterEquals(param, oldParam!)) {
        let promise = this.concernedService!.update(param);
        promises.push(promise);
      }
    }
    await Promise.all(promises);
    this.snackBar.open('Les modifications ont bien été enregistrées', 'Fermer', {
      horizontalPosition: "right",
      verticalPosition: "top",
      duration: 3000,
      panelClass: "success-snack-bar"
    });
  }

  static areParameterEquals(param1: Parameter, param2: Parameter) {
    console.assert(param1.name == param2.name);
    console.assert(param1.id == param2.id);
    if (param1.doubleValue != param2.doubleValue) return false;
    if (param1.intValue != param2.intValue) return false;
    if (param1.stringValue != param2.stringValue) return false;
    if (param1.boolValue != param2.boolValue) return false;
    return true;
  }

  isEnumParameter(parameter: ParameterEntry | ParameterEnum) {
    return 'possibilities' in parameter;
  }

  castAsParameterEntry(parameter: ParameterEntry | ParameterEnum) {
    return parameter as ParameterEntry
  }

  castAsParameterEnum(parameter: ParameterEntry | ParameterEnum) {
    return parameter as ParameterEnum
  }
}
