import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ToStringService} from "../to-string.service";
import {BaseTransportService} from "../base-transport.service";
import {TransportData} from "../data-definitions";
import {DateService} from "../date.service";

@Component({
  selector: 'app-transport-modification-modal',
  templateUrl: './transport-modification-modal.component.html',
  styleUrls: ['./transport-modification-modal.component.css',
              '../../assets/standard-page.css',
              '../../assets/buttons.css',
              '../../assets/forms.css',
              '../../assets/modal.css',
              '../../assets/select.css']
})
export class TransportModificationModalComponent implements OnInit {


  @Output() closeModal = new EventEmitter<boolean>();

  initialTransports: TransportData[] = [];
  modifiedTransports: TransportData[] = [];
  validTransports: Map<number, boolean> = new Map<number, boolean>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public toStringService: ToStringService,
              public dateService: DateService,
              private baseTransportService: BaseTransportService) {
    for (let transport of data.transports) {
      this.initialTransports.push(transport)
      this.modifiedTransports.push({...transport})
      this.validTransports.set(transport.id, true);
    }
  }

  ngOnInit(): void {
  }

  changeStartTime(transport: TransportData, $event: string) {
    if ($event.split(":").length == 2) {
      let newStart = this.dateService.changeTimeOfDate(transport.timeWindowStart, $event)
      transport.timeWindowStart = this.dateService.dateToIso8601String(newStart);
      this.validTransports.set(transport.id, newStart <= this.dateService.iso8601StringOrDateToDate(transport.timeWindowEnd))
    } else {
      this.validTransports.set(transport.id, false)
    }
  }

  changeEndTime(transport: TransportData, $event: string) {
    if ($event.split(":").length == 2) {
      let newEnd = this.dateService.changeTimeOfDate(transport.timeWindowEnd, $event)
      transport.timeWindowEnd = this.dateService.dateToIso8601String(newEnd);
      this.validTransports.set(transport.id, this.dateService.iso8601StringOrDateToDate(transport.timeWindowStart) <= newEnd)
    } else {
      this.validTransports.set(transport.id, false)
    }
  }

  areAllTransportValid() {
    return Array.from(this.validTransports.values()).every(b => b);
  }

  async applyModifications() {
    let actuallyModifiedTransports = [];
    for (let i = 0; i < this.initialTransports.length; i++) {
      let initialTransport = this.initialTransports[i];
      let possiblyModifiedTransport = this.modifiedTransports[i];
      if (JSON.stringify(initialTransport) === JSON.stringify(possiblyModifiedTransport)) continue;
      actuallyModifiedTransports.push(possiblyModifiedTransport);
    }
    if (actuallyModifiedTransports.length > 0) {
      let promise = this.baseTransportService.updateMultiple(actuallyModifiedTransports);
      await promise;
    }
    this.close();
  }

  close() {
    this.closeModal.next(true);
  }
}
