import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ErrorData, InternalError} from '../data-definitions';
import { MessageService } from '../message.service';
import { SnackBarErrorComponent } from '../snack-bar-error/snack-bar-error.component';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.css']
})
export class ModalErrorComponent implements OnInit {

  error?: InternalError;
  listening = false;

  constructor(private _snackBar: MatSnackBar, private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.messageService.getNbListeningToError().subscribe(
      nbListeningToError => {
        this.listening = nbListeningToError === 0;
        if (!this.listening || this.error === undefined) this.closeSnackBar();
        else if (this.error !== undefined) this.openSnackBar(this.error.message!);
      }
    )
    this.messageService.getErrorMessage().subscribe(
      error => {
        this.error = error;
        if (!this.listening || this.error === undefined) this.closeSnackBar();
        else if (this.error !== undefined) this.openSnackBar(this.error.message!);
      }
    )
  }

  openSnackBar(message: string) {
    this.messageService.add("Open snack bar");
    this._snackBar.openFromComponent(SnackBarErrorComponent, {
      data: message,
      panelClass: ['error-snack-bar']
    });
  }

  closeSnackBar() {
    this.messageService.add("Close snack bar");
    this._snackBar.dismiss();
  }

}
