<app-project-view></app-project-view>
<app-data-selection></app-data-selection>

<mat-card class="page-container">
  <section class="content-container">

    <div class="overview">
      <span>{{dataSource.data.length}}&nbsp;</span>
      <span>lieu</span>
      <span *ngIf="dataSource.data.length > 1">x</span>
      <span>&nbsp;au total</span>
    </div>

    <table mat-table class="table-container" [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="address">
        <th mat-header-cell mat-sort-header class="data-cell" *matHeaderCellDef>Adresse</th>
        <td mat-cell class="view-cell data-cell" *matCellDef="let location">
          <div class="view-field">{{toStringService.locationAddress(location.location)}}</div>
        </td>
        <td class="cell-button" mat-footer-cell *matFooterCellDef>
        </td>
      </ng-container>

      <ng-container matColumnDef="tasks">
        <th mat-header-cell mat-sort-header  class="data-cell" *matHeaderCellDef>Missions</th>
        <td mat-cell class="view-cell data-cell" *matCellDef="let location">
          <hr *ngIf="nbTransports(location) == 0">
          <div class="info-container" *ngIf="nbTransportsPickedUpAtLocation(location) > 0">
            <div class="task-container tooltip-activator">
              <div class="task pickup">
                P
              </div>
              <span class="task-badge">{{nbTransportsPickedUpAtLocation(location)}}</span>
              <div class="tooltip">
                <span>Patients: </span>
                <ul>
                  <li *ngFor="let transport of getTransportsPickedUpAtLocation(location)">
                    {{toStringService.transportPatientName(transport)}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="info-container" *ngIf="nbTransportsDeliveredAtLocation(location) > 0">
            <div class="task-container tooltip-activator">
              <div class="task pickup">
                D
              </div>
              <span class="task-badge">{{nbTransportsDeliveredAtLocation(location)}}</span>
              <div class="tooltip">
                <span>Patients: </span>
                <ul>
                  <li *ngFor="let transport of getTransportsDeliveredAtLocation(location)">
                    {{toStringService.transportPatientName(transport)}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </td>
        <td class="cell-button" mat-footer-cell *matFooterCellDef>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr [class.footer-no-data]="noData()" mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[50, 100, 500, 1000]"
                   [pageSize]="1000"
                   showFirstLastButtons>
    </mat-paginator>

  </section>
</mat-card>

