<button class="rounded-button first-type-button close-button" (click)="close()">
  <img src="assets/images/cross.svg">
</button>

<div class="container">
  <h1>Créer une nouvelle exception permanente</h1>

  <span>
    Les exceptions permanentes imposant un véhicule à un employé qui ne partent pas
    du même dépôt ne seront pas considérées lors de la création d'un projet.
  </span>

  <div class="input__inner">
    <ng-select class="rule-type required"
               [(ngModel)]="ruleType"
               placeholder="Type de règle">
      <ng-option *ngFor="let rulePossibility of rulePossibilities" [value]="rulePossibility.type">
        {{rulePossibility.name}}
      </ng-option>
    </ng-select>
  </div>

  <div class="input__inner">
    <ng-select class="employee required"
               [(ngModel)]="employee"
               placeholder="Employé"
               appendTo="body">
      <ng-option *ngFor="let possibleEmployee of eventHubData!.employees" [value]="possibleEmployee">
        {{toStringService.employeeName(possibleEmployee)}}
      </ng-option>
    </ng-select>
  </div>

  <div class="input__inner">
    <ng-select class="vehicle required"
               [(ngModel)]="vehicle"
               placeholder="Véhicule"
               appendTo="body">
      <ng-option *ngFor="let possibleVehicle of eventHubData!.vehicles" [value]="possibleVehicle">
          {{toStringService.vehicleName(possibleVehicle)}}
      </ng-option>
    </ng-select>
  </div>

  <span *ngIf="!isRuleValid() && !creatingRule" class="error">
    Cette exception est en conflit avec une exception permanente existante.
  </span>

  <div class="button-container">
    <button class="rectangular-button first-type-button" (click)="close()">
      Annuler
    </button>
    <button class="rectangular-button important-button create-button"
            (click)="createAssociationRule()"
            [disabled]="!isRuleComplete() || !isRuleValid()">
      <div class="start-button-label">
        Créer
      </div>
      <img src="assets/images/arrow-right.svg">
    </button>
  </div>
</div>
