import { Injectable } from "@angular/core";
import { BaseService, DalConfiguration } from "./base-service";
import { EventhubSectorData } from "./data-definitions";
import { HttpDalService } from "./http-dal.service";

@Injectable({
    providedIn: 'root'
})
export class BaseSectorService extends BaseService<EventhubSectorData> {
    constructor(
        http: HttpDalService) {
        super(http);
        
        this._addStoreDefinition(
            'global',
            sector => [1]
        )
    }

    _extractParentUrl(context: any): string {
        return `${DalConfiguration.url }/sectors`;
    }

    _extractElementUrl(context: any) {
        return `${DalConfiguration.url }/sectors/${context.sectorId}`;
    }

    _extractElementId(sector: EventhubSectorData) {
        return sector.id;
    }

    load(sectorId: number) {
        return this._loadElement({
            sectorId: sectorId
        });
    }

    loadAll() {
        return this._loadElements({});
    }

    listen(sectorId: number) {
        return this._listenElement(sectorId);
    }

    listenAll() {
        return this._listenElements('global', [1]);
    }

}
