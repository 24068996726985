import {Component, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {BaseEmployeeService} from "../base-employee.service";
import {HttpErrorResponse} from "@angular/common/http";
import {MessageService} from "../message.service";
import {Subscription} from "rxjs";
import {CurrentProjectService} from "../current-project.service";
import {AssociationRulesService} from "../association-rules.service";
import {AssociationRuleData, CrewData, EmployeeData, ProjectData, RuleType, Status, TransportData} from "../data-definitions";
import {BaseAssociationRuleService} from "../base-association-rule.service";
import {BaseTransportService} from "../base-transport.service";
import {ToStringService} from "../to-string.service";
import { BaseProjectService } from '../base-project.service';
import { BaseCrewRuleService } from '../base-crew-rule.service';

@Component({
  selector: 'app-association-rules-view',
  templateUrl: './association-rules-view.component.html',
  styleUrls: ['./association-rules-view.component.css',
              '../../assets/forms.css',
              '../../assets/buttons.css']
})
export class AssociationRulesViewComponent implements OnInit {

  searchInput = new FormControl();

  project: ProjectData | undefined;

  subscriptions: Subscription = new Subscription();

  filteredEmployees: EmployeeData[] = [];
  employees: EmployeeData[] = [];
  crewMans: EmployeeData[] = [];

  selectedEmployee: EmployeeData | undefined;
  previouslySelectedEmployee: EmployeeData | undefined;

  crewRules: Map<number, EmployeeData | undefined> = new Map();

  nbTransports = 0;
  nbLocks = 0

  constructor(private baseTransportService: BaseTransportService,
              private baseEmployeeService: BaseEmployeeService,
              private currentProjectService: CurrentProjectService,
              private baseProjectService: BaseProjectService,
              public associationRulesService: AssociationRulesService,
              private baseAssociationRuleService: BaseAssociationRuleService,
              private baseCrewRuleService: BaseCrewRuleService,
              private messageService: MessageService,
              private toStringService: ToStringService) { }

  ngOnInit(): void {
    this.currentProjectService.findProjectId().subscribe(projectId => {
      if (projectId) this.loadData();
    });
    
    this.baseProjectService.listen().subscribe(project => {
      this.project = project;
      if (this.project?.type === "AMBU" && this.employees) {
        this.filteredEmployees = this.employees.filter(e => e.diploma === "DEA");
      }
    });

    this.baseTransportService.listenAll().subscribe(transports => {
      this.nbTransports = transports.filter(t => t.status == Status.SELECTED).length
    })

    this.baseEmployeeService.listenAll().subscribe(employees => {
      let selectedEmployees = employees.filter(e => e.status == Status.SELECTED);
      selectedEmployees.sort((e1, e2) => e1.lastName.localeCompare(e2.lastName));
      if (this.project && this.project.type === "AMBU") {
        this.employees = selectedEmployees.filter(e => e.diploma === "DEA");
        this.crewMans = selectedEmployees;
        this.filteredEmployees = this.employees;
      } else {
        this.employees = selectedEmployees;
        this.filteredEmployees = selectedEmployees;
      }
    })
    
    this.searchInput.valueChanges.subscribe(
      value => this.filter(value));
    this.baseAssociationRuleService.listenAll().subscribe(rules => {
      this.nbLocks = rules.filter(r => r.ruleType == RuleType.LOCK_IN).length;
    })
    this.baseCrewRuleService.listenAll().subscribe(rules => {
      if (this.crewMans == undefined) return;
      rules.forEach(rule => this.crewRules.set(rule.deaId, this.crewMans.find(e => e.id == rule.crewManId)));
    })
  }

  async loadData() {
    try {
      await Promise.all([this.baseProjectService.load(), this.baseEmployeeService.loadAll(), 
                         this.baseTransportService.loadAll(), this.baseCrewRuleService.loadAll()]);
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  getCrewMan(deaId: number) {
    if (this.crewRules.has(deaId)) {
      return this.crewRules.get(deaId);
    }
    return undefined;
  }
  changeSelection($event: EmployeeData) {
    let selectionChanged = $event != this.selectedEmployee;
    this.previouslySelectedEmployee = this.selectedEmployee;
    if (selectionChanged)
      this.selectedEmployee = $event;
    else
      this.selectedEmployee = undefined;
  }

  filter(value: string) {
    if (!value || value === "") {
      this.filteredEmployees = this.employees;
      return;
    }
    let valueLowerCased = value.toLowerCase();
    this.filteredEmployees = this.employees.filter(x => {
      if (this.toStringService.employeeName(x).toLowerCase().indexOf(valueLowerCased) >= 0)
        return true;
      return false;
    });
  }

  async lockCurrentSolution() {
    let associationRules = []
    for (let employeeWithRules of this.associationRulesService.employeeWithRulesMap.values()) {
      let employee = employeeWithRules.employee;
      let possibleTransportLocks = this.associationRulesService.lockableTransportByEmployee.get(employee.id);
      if (possibleTransportLocks == undefined) continue;
      let lockedTransportIds = new Set(employeeWithRules.lockedTransportRules.map(r => r.transportId));
      let forbiddenTransportIds = new Set(employeeWithRules.forbiddenTransportRules.map(r => r.transportId));
      for (let transport of possibleTransportLocks) {
        if (lockedTransportIds.has(transport.id)) continue;
        if (forbiddenTransportIds.has(transport.id)) continue;

        let associationRule: AssociationRuleData = {
          id: -1,
          projectId: -1,
          ruleType: RuleType.LOCK_IN,
          employeeId: employee!.id,
          transportId: transport!.id
        }
        associationRules.push(associationRule);
      }
    }
    await this.baseAssociationRuleService.addAll(associationRules);
  }

  async unlockCurrentSolution() {
    let associationRules: AssociationRuleData[] = []
    for (let employeeWithRules of this.associationRulesService.employeeWithRulesMap.values()) {
      associationRules.push(...employeeWithRules.lockedTransportRules)
    }
    await this.baseAssociationRuleService.removeAll(associationRules);
  }
}
