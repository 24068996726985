<app-project-view></app-project-view>
<app-data-selection></app-data-selection>

<mat-card class="page-container">
  <section class="content-container">

    <div class="overview">
      <div *ngIf="selection.hasValue()">
        <div class="nb-selected">
          <span>{{selection.selected.length}}&nbsp;</span>
          <span *ngIf="selection.selected.length == 1">employé sélectionnée</span>
          <span *ngIf="selection.selected.length > 1">employés sélectionnées</span>
        </div>
        <button *ngIf="selection.selected.length >= 1 && areSelectedTransportSomeSelected()"
                class="important-button exclude-button" (click)="modifyCurrentSelectionStatus(Status.EXCLUDED)">
          Exclure de la planification
          <img src="assets/images/cross.svg">
        </button>
        <button *ngIf="selection.selected.length >= 1 && areSelectedTransportSomeExcluded()"
                class="important-button exclude-button" (click)="modifyCurrentSelectionStatus(Status.SELECTED)">
          Inclure dans planification
          <img src="assets/images/plus.svg">
        </button>
        <button *ngIf="selection.selected.length >= 1"
                class="first-type-button exclude-button" (click)="openEditConstraintModal()">
          Modifier les contraintes
          <img src="assets/images/edit.svg">
        </button>
        <button *ngIf="selection.selected.length >= 1 && areSelectedTransportAllReturnToDepot()"
                class="first-type-button exclude-button" (click)="modifyCurrentSelectionDepotReturn(false)">
          Ne pas planifier les retours au depot
          <img src="assets/images/edit.svg">
        </button>
        <button *ngIf="selection.selected.length >= 1 && areSelectedTransportAllNotReturnToDepot()"
                class="first-type-button exclude-button" (click)="modifyCurrentSelectionDepotReturn(true)">
          Planifier les retours au depot
          <img src="assets/images/edit.svg">
        </button>
        <button *ngIf="selection.selected.length >= 1"
          class="first-type-button exclude-button" (click)="openEditDepotModal()">
          Modifier le bureau logistique
          <img src="assets/images/edit.svg">
        </button>
      </div>
      <div *ngIf="selection.isEmpty()">
        <span>{{nbSelected}}&nbsp;</span>
        <span>employé</span>
        <span *ngIf="nbSelected > 1">s</span>
        <span>&nbsp;au total</span>
      </div>
    </div>

    <table mat-table class="table-container" [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        color="primary">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        color="primary">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Nom</div>
            <app-filter-table-text [dataSource]="dataSource" [filteredValues]="filteredValues" [typeToFilter]="'name'"></app-filter-table-text>
          </div>
        </th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell data-cell" [class.excluded]="employee.employee.status == Status.EXCLUDED">
            <div class="patient-div">
              <div class="patient-name">
                {{toStringService.employeeName(employee.employee)}}
              </div>
              <div *ngIf="hasRequirements(employee.employee.requirementIds)" class="patient-requirements tooltip-activator">
                <img src="assets/images/document.svg" />
                <span class="requirements-count">&nbsp;{{countRequirements(employee.employee.requirementIds)}}</span>
                <div class="tooltip">
                  <span>Exigences :</span>
                  <ul>
                    <li *ngFor="let requirement of employee.employee.requirementIds.trim().split(';')">
                      {{getRequirement(requirement)}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="diploma">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Diplôme</div>
            <app-filter-table-text [dataSource]="dataSource" [filteredValues]="filteredValues" [typeToFilter]="'diploma'"></app-filter-table-text>
          </div>
        </th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell data-cell" [class.excluded]="employee.employee.status == Status.EXCLUDED">
            <div class="view-field">{{employee.employee.diploma}}</div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="depot">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Bureau Logistique</div>
            <app-filter-table-text [dataSource]="dataSource" [filteredValues]="filteredValues" [typeToFilter]="'depot'"></app-filter-table-text>
          </div>
        </th>
        <td mat-cell class="view-cell data-cell" *matCellDef="let employee">
          <div class="view-field" *ngIf="employee.depot?.name">
            {{employee.depot.name}}
          </div>
        </td>
        <td class="cell-button" mat-footer-cell *matFooterCellDef>
        </td>
      </ng-container>

      <ng-container matColumnDef="assignment">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Affectation</div>
            <app-filter-table-enum [dataSource]="dataSource" [filteredValues]="filteredValues"
                                   [typeToFilter]="'assignment'" [elements]="['Ambu et TAP', 'TAP', 'Ambu']"></app-filter-table-enum>
          </div>
        </th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell data-cell" [class.excluded]="employee.employee.status == Status.EXCLUDED">
            <div class="view-field assignment-cell">{{toStringService.employeeAssignment(employee.employee)}}</div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="depot-return">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Retour au depot</div>
            <app-filter-table-enum [dataSource]="dataSource" [filteredValues]="filteredValues"
                                   [typeToFilter]="'depot-return'" [elements]="['Oui', 'Non']">
            </app-filter-table-enum>
          </div>
        </th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell data-cell" [class.excluded]="employee.employee.status == Status.EXCLUDED">
            <div style="margin-left: 20px">
              <img *ngIf="employee.employee.planDepotReturn" src="assets/images/circle-check.svg" width="22" height="22">
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="service-priority">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Priorité</div>
            <app-filter-table-text [dataSource]="dataSource" [filteredValues]="filteredValues" [typeToFilter]="'service-priority'"></app-filter-table-text>
          </div>
        </th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell data-cell" [class.excluded]="employee.employee.status == Status.EXCLUDED">
            <hr *ngIf="!employee.employee.servicePriority">
            <div style="margin-left: 20px" *ngIf="employee.employee.servicePriority" class="view-field">
              {{employee.employee.servicePriority}}
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="employee-service-first-pickup">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-sort" mat-sort-header>Heure au plus tôt de la première prise en charge</div>
        </th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell data-cell" [class.excluded]="employee.employee.status == Status.EXCLUDED">
            <hr *ngIf="!employee.employee.serviceFirstPickup">
            <div *ngIf="employee.employee.serviceFirstPickup" class="view-field">
              {{employee.employee.serviceFirstPickup | duration:"hh:mm"}}
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="employee-service-last-dropoff">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-sort" mat-sort-header>Heure au plus tard de la dernière dépose</div>
        </th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell data-cell" [class.excluded]="employee.employee.status == Status.EXCLUDED">
            <hr *ngIf="!employee.employee.serviceLastDropoff">
            <div *ngIf="employee.employee.serviceLastDropoff" class="view-field">
              {{employee.employee.serviceLastDropoff | duration:"hh:mm"}}
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Statut</div>
            <app-filter-table-enum [dataSource]="dataSource" [filteredValues]="filteredValues"
                                   [typeToFilter]="'status'" [elements]="['Inclu', 'Exclu']">
            </app-filter-table-enum>
          </div>
        </th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell data-cell" [class.excluded]="employee.employee.status == Status.EXCLUDED">
            <div class="view-field">
              {{getStatusString(employee.employee.status)}}
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="solution-earliest-service-start">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-sort" mat-sort-header>Heure au plus tôt du début de service</div>
        </th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell data-cell" [class.excluded]="employee.employee.status == Status.EXCLUDED">
            <hr *ngIf="!employee.employee.serviceStart">
            <div *ngIf="employee.employee.serviceStart" class="view-field">
              {{employee.employee.serviceStart | duration:"hh:mm"}}
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="solution-service-start">
        <th mat-header-cell class="solution-cell" mat-sort-header *matHeaderCellDef>Début de service</th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell solution-cell" [class.excluded]="employee.employee.status == Status.EXCLUDED">
            <hr *ngIf="!hasSolution(employee)">
            <div *ngIf="hasSolution(employee)" class="view-field">
              {{getNonNullPipeDateString(employee.routeSolWithMeasures?.routeMeasures?.start, "HH:mm:ss")}}
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="solution-service-end">
        <th mat-header-cell class="solution-cell" *matHeaderCellDef>
          <div class="header-sort" mat-sort-header>Fin de service</div>
        </th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell solution-cell"
              [ngClass]="{
                'excluded': employee.employee.status == Status.EXCLUDED,
                'warning': employee.employee.status != Status.EXCLUDED && isLate(employee)
              }"
          >
            <hr *ngIf="!hasSolution(employee)">
            <div *ngIf="hasSolution(employee)" class="view-field">
              <span [class.uncertain]="!employee.employee.planDepotReturn">
                {{getNonNullPipeDateString(employee.routeSolWithMeasures?.routeMeasures?.end, "HH:mm:ss")}}
              </span>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="vehicle">
        <th mat-header-cell class="solution-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Véhicule</div>
            <app-filter-table-text [dataSource]="dataSource" [filteredValues]="filteredValues" [typeToFilter]="'vehicle'"></app-filter-table-text>
          </div>
        </th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell solution-cell" [class.excluded]="employee.employee.status == Status.EXCLUDED">
            <hr *ngIf="!hasSolution(employee)">
            <div *ngIf="hasSolution(employee)" class="view-field">
              {{toStringService.vehicleName(employee.routeSolWithMeasures?.vehicle)}}
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef>
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr [class.footer-no-data]="noData()" *matNoDataRow>
        <td class="mat-cell" [colSpan]="displayedColumns.length">
          <span *ngIf="!noData()">Aucune donnée ne correspond au filtre</span>
          <app-no-data-card *ngIf="noData()"></app-no-data-card>
        </td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[50, 100, 500, 1000]"
                   [pageSize]="1000"
                   showFirstLastButtons>
    </mat-paginator>

  </section>
</mat-card>

