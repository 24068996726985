import { Component, OnInit, OnDestroy } from '@angular/core';
import {Subscription} from "rxjs";
import {UrlService} from "../url.service";
import {Router} from "@angular/router";
import {environment} from '../../environments/environment';
import {CurrentProjectService} from "../current-project.service";
import {BaseMeasuresService} from "../base-measures.service";
import {HttpClient} from "@angular/common/http";
import {BaseProjectService} from "../base-project.service";
import {ProjectData} from "../data-definitions";
import {CheckboxDialogData, defaultDialogConfig} from "../dialog-data";
import {CustomDialogComponent} from "../custom-dialog/custom-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-data-selection',
  templateUrl: './data-selection.component.html',
  styleUrls: ['./data-selection.component.css',
    '../../assets/buttons.css']
})
export class DataSelectionComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();

  project?: ProjectData;
  lastDataDateString: string = "";

  selected: string;

  hasSolution = false;
  jsonDownloading = false;
  projectUpdating = false;

  constructor(private urlService: UrlService,
              private router: Router,
              public dialog: MatDialog,
              private currentProjectService: CurrentProjectService,
              private baseProjectService: BaseProjectService,
              private baseMeasuresService: BaseMeasuresService,
              private http: HttpClient) {
    this.selected = router.url.split("/").pop()!;
  }

  ngOnInit(): void {
    this.subscriptions.add(this.baseProjectService.listen().subscribe(project => {
      this.project = project;
      if (project)
        this.lastDataDateString = this.createLastDateDateString(project);
    }));
    this.subscriptions.add(this.baseMeasuresService.listen().subscribe(measures => {
      this.hasSolution = measures != undefined && measures.routeMeasures.length > 0;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  changeDataDisplayed() {
    let url = this.computeUrl(this.selected);
    this.router.navigate(url);
  }

  computeUrl(urlType: string, withProject: boolean = true) {
    return this.urlService.computeUrl([urlType], withProject);
  }

  async downloadJsonFile() {
    let environmentId = this.currentProjectService.getEnvironmentId();
    let projectId = this.currentProjectService.getProjectId();
    let complete = true;
    if (this.project?.type === "AMBU") {
      let uncompleteUrl = `${environment.apiUrl}/environments/${environmentId}/projects/${projectId}/crews/uncomplete`;
      let response = await this.http.get(uncompleteUrl, {responseType: 'json', withCredentials: true}).toPromise();
      if (response != 0) {
        complete = false;
        let dialogConfig = defaultDialogConfig();
        let customDialogConfig = {...dialogConfig, data: {
            warning: true,
            title: "Équipages incomplets",
            msg: "L'export est impossible car certains équipages sont incomplets. Cliquez sur Continuer pour être rédiriger vers la page Équipages",
            actions: [ { name: "Annuler" }, { name: "Continuer", important: true, returnValue: true } ]
        }};
        const dialogRef = this.dialog.open(CustomDialogComponent, customDialogConfig);
          dialogRef.afterClosed().subscribe(async (result) => {
            if(!result || !result.returnValue) return;
            this.router.navigateByUrl(`environment/${environmentId}/project/${projectId}/crews`);
        });
      }
    }
    if (complete === true) {
      let dialogConfig = defaultDialogConfig();
      let customDialogConfig = {...dialogConfig, data: {
          title: "Confirmer l'export vers LOMACO",
          msg: "Êtes-vous sûr de vouloir exporter la solution actuelle vers LOMACO ?",
          actions: [ { name: "Annuler" }, { name: "Continuer", important: true, returnValue: true } ]
      }};
      const dialogRef = this.dialog.open(CustomDialogComponent, customDialogConfig);
      dialogRef.afterClosed().subscribe(async (result) => {
        if (!result || !result.returnValue) return;
        this.jsonDownloading = true;
        document.body.style.cursor = "progress"
        let downloadUrl = `${environment.apiUrl}/environments/${environmentId}/projects/${projectId}/json-export`;
        let response = await this.http.get(downloadUrl, {responseType: 'json', withCredentials: true}).toPromise();
        let json = JSON.stringify(response);
        var blob = new Blob([json], {type: "application/json"});
        let url = URL.createObjectURL(blob);
        this.triggerDownload(url, `solution_${this.project!.name}.json`)
        URL.revokeObjectURL(url);
        document.body.style.cursor = "auto"
        this.jsonDownloading = false;
      })
    }
  }

  triggerDownload(url: string, filename: string) {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  async updateProject() {
    let environmentId = this.currentProjectService.getEnvironmentId();
    let projectId = this.currentProjectService.getProjectId();
    let urlDiff = `${environment.apiUrl}/environments/${environmentId}/projects/${projectId}/differences`;
    const projectUpdateDifferences = await this.http.get<string[]>(urlDiff, {withCredentials: true}).toPromise();
    let dialogConfig = defaultDialogConfig();
    let customDialogConfig = {...dialogConfig, data: {
        title: "Confirmer la mise à jour",
        msg: "La mise à jour des données entraînera la suppression de la solution courante.",
        submsg: projectUpdateDifferences,
        actions: [ { name: "Annuler" }, { name: "Continuer", important: true, returnValue: true } ],
        checkboxes: [ { label: "Conserver les contraintes horaires des missions", isChecked: true }, 
                      { label: "Conserver les contraintes horaires des employés", isChecked: true } ]
      }}
    const dialogRef = this.dialog.open(CustomDialogComponent, customDialogConfig);
    dialogRef.afterClosed().subscribe(async (result: { returnValue: boolean; checkboxes: CheckboxDialogData[] } | undefined) => {
      if (!result || !result.returnValue) return;
      this.projectUpdating = true;
      const updateProjectRequestData = {
        keepTransportsTimeConstraints: result.checkboxes[0]?.isChecked || false,
        keepEmployeesTimeConstraints: result.checkboxes[1]?.isChecked || false
      };
      let url = `${environment.apiUrl}/environments/${environmentId}/projects/${projectId}`;
      await this.http.put<ProjectData>(url, updateProjectRequestData, {responseType: 'json', withCredentials: true}).toPromise();
      await this.baseProjectService.reload();
      await this.baseProjectService.updateLastModificationDate(this.project!);
      this.projectUpdating = false;
    });
  }

  createLastDateDateString(project: ProjectData): string {
    const options: Intl.DateTimeFormatOptions = { day: "numeric", month: "numeric", year: "numeric"};
    let date: Date = new Date(project.lastDataDate);
    let minutes = date.getMinutes();
    let minutesStr = minutes < 10 ? "0" + date.getMinutes().toString() : date.getMinutes().toString();
    return "Données du " + date.toLocaleDateString("fr", options) + " à " + date.getHours()
      + "h" + minutesStr;
  }
}
