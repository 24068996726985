import { Injectable } from '@angular/core';
import { BaseService, DalConfiguration } from './base-service';
import { HttpDalService } from './http-dal.service';
import { DefaultAssociationRuleData} from "./data-definitions";

@Injectable({
  providedIn: 'root'
})
export class BaseDefaultAssociationRuleService extends BaseService<DefaultAssociationRuleData> {

  sectorApiId = ""

  constructor(
    http: HttpDalService) {
    super(http);

    this._addStoreDefinition(
      'global',
      parameter => [3]
    );
  }

  _extractParentUrl(context: any) {
    return `${DalConfiguration.url}/sectors/${this.sectorApiId}/default-association-rules`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url}/sectors/${this.sectorApiId}/default-association-rules/${context.defaultAssociationRuleId}`;
  }

  _extractElementId(defaultAssociationRule: DefaultAssociationRuleData) {
    return defaultAssociationRule.id;
  }

  setSectorApiId(sectorApiId: string) {
    this.sectorApiId = sectorApiId;
  }

  async add(defaultAssociationRule: DefaultAssociationRuleData) {
    return this._addElement({
    }, defaultAssociationRule);
  }

  async update(defaultAssociationRule: DefaultAssociationRuleData) {
    await this._updateElement({
      defaultAssociationRuleId: defaultAssociationRule.id
    }, defaultAssociationRule);
  }

  async remove(defaultAssociationRule: DefaultAssociationRuleData) {
    await this._removeElement({
      defaultAssociationRuleId: defaultAssociationRule.id
    }, defaultAssociationRule);
  }

  load(defaultAssociationRuleId: number) {
    return this._loadElement({
      defaultAssociationRuleId: defaultAssociationRuleId
    });
  }

  loadAll() {
    return this._loadElements({
    });
  }

  clearAndReloadAll() {
    return this._clearAndReloadElements({
    });
  }

  listen(defaultAssociationRuleId: number) {
    return this._listenElement(defaultAssociationRuleId);
  }

  listenAll() {
    return this._listenElements('global', [3]);
  }
}
