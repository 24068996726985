import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { SolveStatusService } from '../solve-status.service';

@Component({
  selector: 'app-modal-progress-bar',
  templateUrl: './modal-progress-bar.component.html',
  styleUrls: ['./modal-progress-bar.component.css']
})
export class ModalProgressBarComponent implements OnInit {
  timeLimit: number = 0;
  isSolving: boolean = false;
  isCanceled: boolean = false;

  progressBarValue: number = 0;
  curSec: number = 0;

  message = "";

  constructor(private solveStatusService: SolveStatusService) {
  }

  ngOnInit(): void {
    this.solveStatusService.getTimeLimit().subscribe(
      timeLimit => { this.timeLimit = timeLimit; }
    );
    this.solveStatusService.isSolving().subscribe(
      isSolving => { this.isSolving = isSolving; }
    );
    this.solveStatusService.isCancelled().subscribe(
      isCanceled => { this.isCanceled = isCanceled; }
    );
  }

  startTimer() {
    this.progressBarValue = 0
    const timer$ = interval(1000);
    this.message = "Lancement de la résolution...";
    const sub = timer$.subscribe((sec) => {
      if (!this.isCanceled) {
        this.progressBarValue = Math.min(100, sec * 100 / (this.timeLimit));
        this.curSec = sec;
        this.message = "En cours de résolution...";
      }
      if (this.curSec === (this.timeLimit) || !this.isSolving) {
        sub.unsubscribe();
      }
    });
  }

  cancel() {
    if (!this.isSolving) return;
    this.message = "Annulation de la résolution...";
    this.solveStatusService.cancel();
  }

}
