import { NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatGridListModule } from '@angular/material/grid-list';

import { NgSelectModule } from '@ng-select/ng-select';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MessageComponent } from './message/message.component';
import { MenuComponent } from './menu/menu.component';
import { ModalErrorComponent } from './modal-error/modal-error.component';
import { LocationTableComponent } from './location-table/location-table.component';
import { DepotTableComponent } from './depot-table/depot-table.component';
import { VehicleTableComponent } from './vehicle-table/vehicle-table.component';
import { DatePipe, registerLocaleData } from '@angular/common';
import { NgxMatDateAdapter, NgxMatDatetimePickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { ModalProgressBarComponent } from './modal-progress-bar/modal-progress-bar.component';
import { DurationInputComponent } from './duration-input/duration-input.component';
import { BandErrorComponent } from './band-error/band-error.component';
import { PageErrorComponent } from './page-error/page-error.component'
import { CustomDialogComponent } from './custom-dialog/custom-dialog.component';
import { TransportTableComponent } from './transport-table/transport-table.component';
import { MapComponent } from './map/map.component';
import { SnackBarErrorComponent } from './snack-bar-error/snack-bar-error.component';
import { DurationPipe } from './duration.pipe';
import { ProjectPickerComponent } from './project-picker/project-picker.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { AdminUserComponent } from './admin-user/admin-user.component';
import { AdminAuthorizationComponent } from './admin-authorization/admin-authorization.component';
import { CookieService } from 'ngx-cookie-service';
import { DistancePipe } from './distance.pipe';
import { IndicatorsComponent } from './indicators/indicators.component';
import { NoDataCardComponent } from './no-data-card/no-data-card.component';
import { AuthGuard } from './auth-guard';
import { FilterTableTextComponent } from './filter-table-text/filter-table-text.component';
import { MapViewComponent } from './map-view/map-view.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CustomDateAdapter } from './custom-date-adapter';
import { MapRouteSelectionComponent } from './map-route-selection/map-route-selection.component';
import { MapRouteIndicatorsComponent } from './map-route-indicators/map-route-indicators.component';
import { EmployeeTableComponent } from './employee-table/employee-table.component';
import { ParameterViewComponent } from './parameter-view/parameter-view.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { ProjectViewComponent } from './project-view/project-view.component';
import { DataSelectionComponent } from './data-selection/data-selection.component';
import { MapCurrentRouteComponent } from './map-current-route/map-current-route.component';

import { CustomPaginator } from "./paginator-configuration";

import {LOCALE_ID } from '@angular/core';
import localeFr from '@angular/common/locales/fr';
import { SolveModalComponent } from './solve-modal/solve-modal.component';
import { MapRouteSelectionElementComponent } from './map-route-selection-element/map-route-selection-element.component';
import { ProjectCreationModalComponent } from './project-creation-modal/project-creation-modal.component';
import { ProjectCopyModalComponent } from './project-copy-modal/project-copy-modal.component';
import { SuccessSnackBarComponent } from './success-snack-bar/success-snack-bar.component';
import { ParameterModificationComponent } from './parameter-modification/parameter-modification.component';
import { PageInternalErrorComponent } from './page-internal-error/page-internal-error.component';
import { AssociationRulesViewComponent } from './association-rules-view/association-rules-view.component';
import { AssociationRulesElementComponent } from './association-rules-element/association-rules-element.component';
import { AssociationRulesTransportComponent } from './association-rules-transport/association-rules-transport.component';
import { AssociationRulesCreationModalComponent } from './association-rules-creation-modal/association-rules-creation-modal.component';
import { AssociationRulesVehicleComponent } from './association-rules-vehicle/association-rules-vehicle.component';
import { AssociationRulesLockComponent } from './association-rules-lock/association-rules-lock.component';
import { AssociationRulesForceAllOutComponent } from './association-rules-force-all-out/association-rules-force-all-out.component';
import { AdminDefaultAssociationRulesComponent } from './admin-default-association-rules/admin-default-association-rules.component';
import { AdminDefaultAssociationRulesCreationModalComponent } from './admin-default-association-rules-creation-modal/admin-default-association-rules-creation-modal.component';
import { FilterTableTimeComponent } from './filter-table-time/filter-table-time.component';
import { FilterTableEnumComponent } from './filter-table-enum/filter-table-enum.component';
import { TransportModificationModalComponent } from './transport-modification-modal/transport-modification-modal.component';
import { EmployeeModificationModalComponent } from './employee-modification-modal/employee-modification-modal.component';
import { NgVar } from './ng-var.directive';
import { DepotModificationModalComponent } from './depot-modification-modal/depot-modification-modal.component';
import { ButtonsSelectComponent } from './buttons-select/buttons-select.component';
import { ProfileParameterModificationComponent } from './profile-parameter-modification/profile-parameter-modification.component';
import { ProfileModalComponent } from './profile-modal/profile-modal.component';
import { TrafficModificationComponent } from './traffic-modification/traffic-modification.component';
import { TrafficViewComponent } from './traffic-view/traffic-view.component';
import { CrewTableComponent } from './crew-table/crew-table.component';
import { AdminDefaultCrewRulesComponent } from './admin-default-crew-rules/admin-default-crew-rules.component';
import { AdminDefaultCrewRulesCreationModalComponent } from './admin-default-crew-rules-creation-modal/admin-default-crew-rules-creation-modal.component';
registerLocaleData(localeFr);

export const CUSTOM_MOMENT_FORMATS  = {
  parse: {
    dateInput: "DD-MM-yyyyTHH:mm:ss"
  },
  display: {
    dateInput: "DD/MM/yyyy - HH:mm:ss",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@NgModule({
  declarations: [
    AppComponent,
    MessageComponent,
    MenuComponent,
    ModalErrorComponent,
    LocationTableComponent,
    DepotTableComponent,
    VehicleTableComponent,
    CrewTableComponent,
    ButtonsSelectComponent,
    ModalProgressBarComponent,
    DurationInputComponent,
    BandErrorComponent,
    PageErrorComponent,
    CustomDialogComponent,
    TransportTableComponent,
    MapComponent,
    SnackBarErrorComponent,
    DurationPipe,
    ProjectPickerComponent,
    LoginComponent,
    AdminComponent,
    AdminUserComponent,
    AdminAuthorizationComponent,
    DistancePipe,
    IndicatorsComponent,
    NoDataCardComponent,
    FilterTableTextComponent,
    MapViewComponent,
    NotFoundComponent,
    MapRouteSelectionComponent,
    MapRouteIndicatorsComponent,
    EmployeeTableComponent,
    ParameterViewComponent,
    TrafficViewComponent,
    NavigationBarComponent,
    ProjectViewComponent,
    DataSelectionComponent,
    MapCurrentRouteComponent,
    SolveModalComponent,
    MapRouteSelectionElementComponent,
    ProjectCreationModalComponent,
    ProjectCopyModalComponent,
    ProfileModalComponent,
    DepotModificationModalComponent,
    SuccessSnackBarComponent,
    ProfileParameterModificationComponent,
    ParameterModificationComponent,
    TrafficModificationComponent,
    PageInternalErrorComponent,
    AssociationRulesViewComponent,
    AssociationRulesElementComponent,
    AssociationRulesTransportComponent,
    AssociationRulesCreationModalComponent,
    AssociationRulesVehicleComponent,
    AssociationRulesLockComponent,
    AssociationRulesForceAllOutComponent,
    AdminDefaultAssociationRulesComponent,
    AdminDefaultCrewRulesComponent,
    AdminDefaultAssociationRulesCreationModalComponent,
    AdminDefaultCrewRulesCreationModalComponent,
    FilterTableTimeComponent,
    FilterTableEnumComponent,
    TransportModificationModalComponent,
    EmployeeModificationModalComponent,
    NgVar,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatTabsModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatGridListModule,
    NgxMatMomentModule,
    NgxMatDatetimePickerModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatDialogModule,
    MatCheckboxModule,
    MatMenuModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatTreeModule,
    MatListModule,
    DragDropModule,
    MatBadgeModule,
    NgSelectModule
  ],
  providers: [
    DatePipe,
    DurationPipe,
    DistancePipe,
    { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: "fr-FR" },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
    { provide: NgxMatDateAdapter, useClass: CustomDateAdapter },
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
    { provide: LOCALE_ID, useValue: 'fr-FR'},
    CookieService,
    AuthGuard
  ],
  bootstrap: [AppComponent],
  exports: [
    DurationInputComponent
  ],
})
export class AppModule {
}
