import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BaseMeasuresService } from './base-measures.service';
import { BaseRouteSolutionService } from './base-route-solution.service';
import { BaseService, DalConfiguration } from './base-service';
import { CurrentProjectService } from './current-project.service';
import { VehicleData } from './data-definitions';
import { HttpDalService } from './http-dal.service';

@Injectable({
  providedIn: 'root'
})
export class BaseVehicleService extends BaseService<VehicleData> {
  constructor(
      http: HttpDalService,
      routeSolutionService: BaseRouteSolutionService,
      private currentProjectService: CurrentProjectService,
      private measuresService: BaseMeasuresService) {
    super(http);

    this._addStoreDefinition(
      'by-project-id',
       vehicle => [vehicle.projectId]
    );

    this._addDeleteCascadeRelation(
      routeSolutionService,
      r => r.vehicleId
    );
  }

  _extractParentUrl(context: any): string {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/vehicles`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/vehicles/${context.vehicleId}`;
  }

  _extractElementId(vehicle: VehicleData) {
    return vehicle.id;
  }

  async update(vehicle: VehicleData) {
    await this._updateElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      vehicleId: vehicle.id
    }, vehicle);
  }

  async updateMultiple(vehicles: VehicleData[]) {
    await this._updateElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    }, vehicles);
  }


  async remove(vehicle: VehicleData) {
    await this._removeElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      vehicleId: vehicle.id
    }, vehicle);
    // Ignore errors coming from the measures.
    await this.measuresService.reload().catch(_ => {});
  }

  add(vehicle: VehicleData) {
    return this._addElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    }, vehicle);
  }

  clone(vehicle: VehicleData) {
    return this._clone(vehicle);
  }

  load(vehicleId: number) {
    return this._loadElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      vehicleId: vehicleId
    });
  }

  loadAll() {
    return this._loadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  clearAndReloadAll() {
    return this._clearAndReloadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  listen(vehicleId: number) {
    return this._listenElement(vehicleId);
  }

  listenAll() {
    return this.currentProjectService.findProjectId().pipe(
      mergeMap(projectId => projectId === undefined ? EMPTY : this._listenElements('by-project-id', [projectId]))
    );
  }
}
