import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MessageService } from '../message.service';
import { UrlService } from '../url.service';

@Component({
  selector: 'app-snack-bar-error',
  templateUrl: './snack-bar-error.component.html',
  styleUrls: ['./snack-bar-error.component.css',
              '../../assets/buttons.css']
})
export class SnackBarErrorComponent implements OnInit {

  constructor(private messageService: MessageService, @Inject(MAT_SNACK_BAR_DATA) public data: string,
    private urlService: UrlService) { }

  ngOnInit(): void {
  }

  clearError(): void {
    this.messageService.add("Clear error");
    this.messageService.clearError();
  }

  computeUrl() {
    return this.urlService.computeUrl(["internalerror"]);
  }
}
