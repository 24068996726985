import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DepotInfo } from "../data-definitions";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { Subscription } from "rxjs";
import { BaseDepotInfoService } from "../base-depot-info.service";
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from "../message.service";
import { BaseProjectService } from "../base-project.service";

@Component({
    selector: 'app-depot-table',
    templateUrl: './depot-table.component.html',
    styleUrls: ['./depot-table.component.css',
                '../../assets/tables.css',
                '../../assets/standard-page.css',
                '../../assets/buttons.css',
                '../../assets/tooltip.css']
})
export class DepotTableComponent implements OnInit, AfterViewInit, OnDestroy {    
    dataSource = new MatTableDataSource(<DepotInfo[]>[]);
    displayedColumns: string[] = [];
    @ViewChild(MatSort) sort = new MatSort();
    @ViewChild(MatPaginator) paginator?: MatPaginator;

    filteredValues: Map<string, any> = new Map([
      ["name", ""],
      ["territory", ""],
      ["regulation", ""],
      ["sector",""],
    ])

    subscriptions: Subscription = new Subscription();

    constructor(private baseInfoService: BaseDepotInfoService, 
                private baseProjectService: BaseProjectService,
                private messageService: MessageService) {
    }

    ngOnInit(): void {
      this.subscriptions.add(this.baseProjectService.listen().subscribe(project => {
        if (project) {
          if (project.type === "TAP") {
            this.displayedColumns = ['name', 'address', 'vehicle', 'transport', 'employee',
              'territory', 'regulation', 'sector'];
          } else {
            this.displayedColumns = ['name', 'address', 'vehicle', 'transport', 'employee', 
            'unassigned', 'territory', 'regulation', 'sector'];
          }
          this.loadData();
        }
      }));
      this.subscriptions.add(this.baseInfoService.listenAll().subscribe(infos => {
          this.dataSource.data = infos;
      }));
      this.dataSource.sortingDataAccessor = (item, property) => {
          switch(property) {
            case 'name': return item.depotName.toLowerCase();
            default: return 0;
          }
      }

      this.dataSource.filterPredicate = this.customFilterPredicate();
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch(property) {
          case 'name': return item.depotName.trim().toLowerCase();
          case 'employee': return item.nbEmployees;
          case 'unassigned': return item.nbUnassigned;
          case 'vehicle': return item.nbVehicles;
          case 'transport': return item.nbTransports;
          case 'territory': return item.territoryName.trim().toLocaleLowerCase();
          case 'regulation': return item.regulationName.trim().toLowerCase();
          case 'sector': return item.sectorName.trim().toLowerCase();
          default: return 0;
        }
      }
    }

    customFilterPredicate() {
      return (data: DepotInfo, _: string): boolean => {
        let nameCondition = this.filteredValues.get("name")
        ? data.depotName.trim().toLocaleLowerCase().indexOf(this.filteredValues.get("name")!) !== -1
        : true;
        let territoryCondition = this.filteredValues.get("territory")
        ? data.territoryName.trim().toLocaleLowerCase().indexOf(this.filteredValues.get("territory")!) !== -1
        : true;
        let regulationCondition = this.filteredValues.get("regulation")
        ? data.regulationName.trim().toLocaleLowerCase().indexOf(this.filteredValues.get("regulation")!) !== -1
        : true;
        let sectorCondition = this.filteredValues.get("sector")
        ? data.sectorName.trim().toLocaleLowerCase().indexOf(this.filteredValues.get("sector")!) !== -1
        : true;
        return nameCondition && territoryCondition && regulationCondition && sectorCondition;
      }
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator!;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    async loadData(){
        try {
          await Promise.all([this.baseInfoService.loadAll()]);
        } catch(error) {
          if (error instanceof HttpErrorResponse) {
            this.messageService.addHttpError(error);
          } else {
            this.messageService.addErrorMessage("Unknown error");
          }
        }
    }

    noData() {
        return this.dataSource.data.length == 0;
    }
}