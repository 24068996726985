import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from '../message.service';
import { BaseUserService } from '../user.service';
import * as formUtils from '../form-utils';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';
import {confirmDelete, defaultDialogConfig} from '../dialog-data';
import { Subscription } from 'rxjs';
import { UserData, UserRole } from '../data-definitions';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.css',
              '../../assets/tables.css',
              '../../assets/buttons.css']
})
export class AdminUserComponent implements OnInit, OnDestroy, AfterViewInit  {
  subscriptions: Subscription = new Subscription();

  dataSource = new MatTableDataSource(<UserData[]>[]);
  displayedColumns = ['name', 'hashed-password', 'admin', 'buttons'];

  userToModify?: UserData;
  userToModifyIsAdmin = false;
  isAdding: boolean = false;

  @ViewChild(MatSort) sort = new MatSort();

  constructor(private userService: BaseUserService,
              private messageService: MessageService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.userService.listenAll().subscribe(users => {
      this.dataSource.data = users;
    }));
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch(property) {
        case 'name': return item.name.toLowerCase();
        case 'admin': return item.roles.includes(UserRole.ADMINISTRATION) ? 1 : 0;
        default: return 0;
      }
    };
    this.loadData();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  isModifying(): boolean {
    return this.userToModify !== undefined;
  }

  isUserToModify(user: UserData): boolean {
    if (this.userToModify === undefined) return false;
    return this.userToModify.id === user.id;
  }

  async loadData() {
    try {
      await this.userService.loadAll();
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  async deleteUser(user: UserData) {
    let dialogConfig = defaultDialogConfig();
    const dialogRef = this.dialog.open(CustomDialogComponent, { ...dialogConfig, data: confirmDelete("l'utilisateur " + user.name)});
    try {
      let result = await dialogRef.afterClosed().toPromise();
      if (result && result.returnValue) {
        await this.userService.remove(user);
      }
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  modifyUser(user: UserData): void {
    this.userToModify = this.userService.clone(user);
    this.userToModify.passwordChanged = false;
    this.userToModifyIsAdmin = this.isAdmin(this.userToModify);
  }

  async saveModification() {
    if (this.userToModify !== undefined) {
      try {
        this.userToModify.roles = this.userToModifyIsAdmin ? [UserRole.ADMINISTRATION]: [];
        await this.userService.update(this.userToModify);
        this.endChanges();
      } catch(error) {
        if (error instanceof HttpErrorResponse) {
          this.messageService.addHttpError(error);
        } else {
          this.messageService.addErrorMessage("Unknown error");
        }
      }
    } else {
      this.endChanges();
    }
  }

  addNewUser() {
    this.userToModifyIsAdmin = false;
    this.userToModify = {id: -1, name: "", password: "", passwordChanged: false, environmentId: -1, roles: []};
    this.isAdding = true;
  }

  async saveAdd() {
    if (this.userToModify !== undefined) {
      try {
        this.userToModify.roles = this.userToModifyIsAdmin ? [UserRole.ADMINISTRATION]: [];
        await this.userService.add(this.userToModify);
        this.endChanges();
      } catch(error) {
        if (error instanceof HttpErrorResponse) {
          this.messageService.addHttpError(error);
        } else {
          this.messageService.addErrorMessage("Unknown error");
        }
      }
    } else {
      this.endChanges();
    }
  }

  isAdmin(user: UserData) {
    return user.roles.includes(UserRole.ADMINISTRATION);
  }

  saveChanges() {
    if (this.isAdding) this.saveAdd();
    else this.saveModification();
  }

  endChanges() {
    this.userToModify = undefined;
    this.userToModifyIsAdmin = false;
    this.isAdding = false;
  }

  canModifyUser(): boolean {
    if (this.userToModify == undefined) return false;
    let nameValid = formUtils.isNameValid(this.userToModify.name);
    let nameUnique = this.isNameUnique(this.userToModify.name);
    let passwordModified = this.userToModify.passwordChanged!;
    let passwordValid = this.userToModify.password !== null && this.userToModify.password! != "";
    let nameChanged = nameValid && nameUnique;
    let passwordChanged = passwordModified && passwordValid;
    return nameChanged || passwordChanged;
  }

  private isNameUnique(name: string): boolean {
    return this.dataSource.data.findIndex(user => user.name === name) === -1;
  }

  getRequiredErrorMessage(): string {
    return formUtils.getRequiredErrorMessage();
  }

}
