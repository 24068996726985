import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LocationData, LocationDataWithLinks, TransportData } from '../data-definitions';
import { MessageService } from '../message.service';
import { BaseLocationService } from '../base-location.service';
import { Subscription } from 'rxjs';
import { CurrentProjectService } from '../current-project.service';
import { HttpErrorResponse } from '@angular/common/http';
import {BaseTransportService} from "../base-transport.service";
import {MatPaginator} from "@angular/material/paginator";
import {ToStringService} from "../to-string.service";

@Component({
  selector: 'app-location-table',
  templateUrl: './location-table.component.html',
  styleUrls: ['./location-table.component.css',
              '../../assets/tables.css',
              '../../assets/standard-page.css',
              '../../assets/buttons.css',
              '../../assets/tooltip.css']
})
export class LocationTableComponent implements OnInit, OnDestroy, AfterViewInit {
  locations: LocationData[] = [];
  transports: TransportData[] = [];

  dataSource = new MatTableDataSource(<LocationDataWithLinks[]>[]);
  displayedColumns = ['address', 'tasks'];

  @ViewChild(MatSort) sort = new MatSort();
  @ViewChild(MatPaginator) paginator?: MatPaginator;

  subscriptions: Subscription = new Subscription();

  constructor(private baseLocationService: BaseLocationService,
              private baseTransportService: BaseTransportService,
              private currentProjectService: CurrentProjectService,
              public toStringService: ToStringService,
              private messageService: MessageService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.baseLocationService.listenAll().subscribe(locations => {
      this.locations = locations;
      this.buildDataSource();
    }));
    this.subscriptions.add(this.baseTransportService.listenAll().subscribe(transports => {
      this.transports = transports;
      this.buildDataSource();
    }));
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch(property) {
        case 'name': return item.location.name.toLowerCase();
        case 'address': return item.location.address;
        case 'tasks': return this.nbTransports(item);
        default: return 0;
      }
    }
    this.currentProjectService.findProjectId().subscribe(projectId => {
      if (projectId) this.loadData();
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator!;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  buildDataSource() {
    this.dataSource.data = this.locations.map(loc => {
      return <LocationDataWithLinks>{
        id: loc.id,
        location: loc,
        transports: this.transports.filter(t => t.originId === loc.id || t.destinationId === loc.id),
      };
    });
  }

  async loadData(){
    try {
      await Promise.all([this.baseLocationService.loadAll(), this.baseTransportService.loadAll()]);
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  nbTransports(location: LocationDataWithLinks): number {
    return location.transports.length;
  }

  nbTransportsDeliveredAtLocation(location: LocationDataWithLinks): number {
    return this.getTransportsDeliveredAtLocation(location).length;
  }

  nbTransportsPickedUpAtLocation(location: LocationDataWithLinks): number {
    return this.getTransportsPickedUpAtLocation(location).length;
  }

  getTransportsDeliveredAtLocation(location: LocationDataWithLinks): TransportData[] {
    return location.transports.filter(t => t.originId == location.location.id);
  }

  getTransportsPickedUpAtLocation(location: LocationDataWithLinks): TransportData[] {
    return location.transports.filter(t => t.destinationId == location.location.id);
  }

  noData() {
    return this.dataSource.data.length == 0;
  }
}
