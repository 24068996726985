import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from '../message.service';
import { DateService } from '../date.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  VehicleData,
  VehicleDataWithSolution,
  ViolationMeasures,
  RouteSolution,
  RouteSolutionWithMeasures,
  LocationData,
  Status, ProjectData, TransportData, RequirementData
} from '../data-definitions';
import { BaseVehicleService } from '../base-vehicle.service';
import { Subscription } from 'rxjs';
import { DataMerger } from '../data-merger';
import { BaseLocationService } from '../base-location.service';
import { BaseMeasuresService } from '../base-measures.service';
import { map } from 'rxjs/operators';
import { BaseRouteSolutionService } from '../base-route-solution.service';
import { HttpErrorResponse } from '@angular/common/http';
import {MatPaginator} from "@angular/material/paginator";
import {SelectionModel} from "@angular/cdk/collections";
import {BaseProjectService} from "../base-project.service";
import {ToStringService} from "../to-string.service";
import {FilterService} from "../filter.service";
import { BaseDepotService } from '../base-depot.service';
import { BaseRequirementService } from '../base-requirement.service';
import { defaultDialogConfig } from '../dialog-data';
import { DepotModificationModalComponent } from '../depot-modification-modal/depot-modification-modal.component';
import { BaseDepotInfoService } from '../base-depot-info.service';

@Component({
  selector: 'app-vehicle-table',
  templateUrl: './vehicle-table.component.html',
  styleUrls: ['./vehicle-table.component.css',
              '../../assets/tables.css',
              '../../assets/standard-page.css',
              '../../assets/buttons.css',
              '../../assets/tooltip.css']
})
export class VehicleTableComponent implements OnInit, AfterViewInit, OnDestroy  {
  Status = Status;
  routeDataMerger: DataMerger<RouteSolution, RouteSolutionWithMeasures>;

  project?: ProjectData;
  requirements: RequirementData[] = [];

  dataMerger: DataMerger<VehicleData, VehicleDataWithSolution>;
  violationMeasures: ViolationMeasures[] = [];

  locationMapper: Map<number, LocationData> = new Map<number, LocationData>();

  subscriptions: Subscription = new Subscription();

  dataSource = new MatTableDataSource(<VehicleDataWithSolution[]>[]);
  selection = new SelectionModel<VehicleDataWithSolution>(true, []);
  nbSelected = 0;

  displayedColumns = ['select', 'license-plate', 'type', 'depot', 'status', 'start', 'duration'];

  filteredValues: Map<string, any> = new Map([
    ["license-plate", ""],
    ["type", ""],
    ["depot", ""],
    ["status", ""]
  ]);

  matDialogDepotRef?: MatDialogRef<DepotModificationModalComponent>;

  @ViewChild(MatSort) sort = new MatSort();
  @ViewChild(MatPaginator) paginator?: MatPaginator;

  constructor(private baseVehicleService: BaseVehicleService,
              private baseRequirementService: BaseRequirementService,
              private baseDepotService: BaseDepotService,
              private baseDepotInfoService: BaseDepotInfoService,
              private baseLocationService: BaseLocationService,
              private baseMeasuresService: BaseMeasuresService,
              private baseRouteService: BaseRouteSolutionService,
              private baseProjectService: BaseProjectService,
              private messageService: MessageService,
              private dateService: DateService,
              private filterService: FilterService,
              public toStringService: ToStringService,
              public dialog: MatDialog) {
    this.routeDataMerger = new DataMerger("routeSolution", this.baseRouteService.listenAll());
    this.routeDataMerger.addReverseMapper("routeMeasures",
      this.baseMeasuresService.listen().pipe(map(measures => measures!.routeMeasures)),
        r => r.routeId);

    this.dataMerger = new DataMerger("vehicle", this.baseVehicleService.listenAll());
    this.dataMerger.addSubMapper("depot", this.baseDepotService.listenAll(), v => v.depotId);
    this.dataMerger.addReverseMapper("routeSolWithMeasures", this.routeDataMerger.listen(), route => route.routeSolution.vehicleId);
  }

  ngOnDestroy(): void {
    this.dataMerger.unsubscribe();
    this.routeDataMerger.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.dataMerger.listen().subscribe(vehiclesWithSolution => {
      this.dataSource.data = vehiclesWithSolution;
      this.nbSelected = vehiclesWithSolution.filter(v => v.vehicle.status == Status.SELECTED).length;
    });
    this.subscriptions.add(this.baseProjectService.listen().subscribe(project => {
      this.project = project;
    }));
    this.subscriptions.add(this.baseLocationService.listenAll().subscribe(locations => {
      this.locationMapper.clear();
      for (let location of locations) {
        this.locationMapper.set(location.id, location)
      }
    }));
    this.subscriptions.add(this.baseRequirementService.listenAll().subscribe(requirements => {
      this.requirements = requirements;
    }));
    this.subscriptions.add(this.baseMeasuresService.listen().subscribe(measures => {
      this.violationMeasures = measures!.violationMeasures;
    }));
    this.dataSource.sortingDataAccessor = this.customSortingDataAccessor()
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator!;
  }

  customSortingDataAccessor() {
    return (item: VehicleDataWithSolution, property: string) => {
      switch(property) {
        case 'license-plate': return item.vehicle.licensePlate.toLowerCase();
        case 'depot': return item.depot!.name.trim().toLowerCase();
        case 'start': return item.routeSolWithMeasures && item.routeSolWithMeasures.routeMeasures!.start ? this.dateService.iso8601StringOrDateToDate(item.routeSolWithMeasures.routeMeasures!.start!).getTime() : "ZZZ";
        case 'duration': return item.routeSolWithMeasures && item.routeSolWithMeasures.routeMeasures!.totalDuration ? item.routeSolWithMeasures.routeMeasures!.totalDuration : "ZZZ";
        default: return item.vehicle[property as keyof VehicleData];
      }
    };
  }

  typeFilter(data: VehicleData, key: string) {
    switch (key) {
      case "Taxi": return data.type == 'TAXI';
      case "VSL": return data.type == 'VSL';
      case "AMBU": return data.type == 'AMBU';
      default: return false;
    }
  }

  customFilterPredicate() {
    return (data: VehicleDataWithSolution, _: string): boolean => {
      let licensePlateCondition = this.filteredValues.get("license-plate")
        ? data.vehicle.licensePlate.trim().toLowerCase().indexOf(this.filteredValues.get("license-plate")!) !== -1
        : true;
      let typeCondition = this.filteredValues.get("type")
        ? this.filterService.checkFilterMatch(data.vehicle, this.filteredValues.get("type"), this.typeFilter)
        : true;
      let depotCondition = this.filteredValues.get("depot")
        ? data.depot?.name.trim().toLowerCase()
          .indexOf(this.filteredValues.get("depot")!.toLowerCase()) !== -1
        : true;
      let statusCondition = this.filteredValues.get("status")
        ? this.filterService.checkFilterMatch(data.vehicle, this.filteredValues.get("status"), this.filterService.statusFilter)
        : true;
      return licensePlateCondition && typeCondition && depotCondition && statusCondition;
    };
  }

  async loadData() {
    try {
      await Promise.all([this.baseVehicleService.loadAll(),
        this.baseDepotService.loadAll(),
        this.baseLocationService.loadAll(),
        this.baseRouteService.loadAll(),
        this.baseMeasuresService.load(),
        this.baseRequirementService.loadAll()]);
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.filteredData);
  }

  areSelectedTransportSomeSelected() {
    return this.selection.selected.filter(e => e.vehicle.status == Status.SELECTED).length > 0;
  }

  areSelectedTransportSomeExcluded() {
    return this.selection.selected.filter(e => e.vehicle.status == Status.EXCLUDED).length > 0;
  }

  async modifyCurrentSelectionStatus(newStatus: Status) {
    let selectedElements = this.selection.selected;
    let vehicles = [];
    for (let element of selectedElements.map(e => e.vehicle)) {
      let modifiedElement = {...element};
      modifiedElement.status = newStatus;
      vehicles.push(modifiedElement);
      if (newStatus == Status.SELECTED)
        this.project!.nbVehicles++;
      else if (newStatus == Status.EXCLUDED)
        this.project!.nbVehicles--;
    }
    let promise = this.baseVehicleService.updateMultiple(vehicles);
    await promise;
    this.selection.clear();
  }

  hasRequirements(requirementsIds: string) {
    if (requirementsIds.trim().length == 0) return false;
    return true;
  }

  countRequirements(requirementsIds: string) {
    return requirementsIds.split(";").length;
  }

  getRequirement(requirementId: number) {
    const requirement = this.requirements.find(r => r.apiId == requirementId);
    return requirement?.name;
  }

  getStatusString(status: Status) {
    switch (status) {
      case Status.EXCLUDED: return "Exclu";
      case Status.ERROR: return "Erreur";
      default: return "";
    }
  }

  hasSolution(vehicle: VehicleDataWithSolution) {
    if (!vehicle.routeSolWithMeasures || !vehicle.routeSolWithMeasures.routeMeasures) return false;
    return vehicle.routeSolWithMeasures.routeMeasures.routeActionMeasures.length !== 0;
  }

  getNonNullPipeDateString(date: Date | string, format: string) {
    return this.dateService.getNonNullPipeDateString(date, format);
  }

  noData(): boolean {
    return this.dataSource.data.length == 0;
  }

  openEditDepotModal() {
    let dialogConfig = defaultDialogConfig();
    let data = {
      vehicles: this.selection.selected.map(v => v.vehicle),
    }
    dialogConfig.data = data;
    this.matDialogDepotRef = this.dialog.open(DepotModificationModalComponent, dialogConfig);
    this.matDialogDepotRef.componentInstance.closeModal.subscribe(
      value => {
        if (value)
          this.matDialogDepotRef!.close();
          this.baseDepotInfoService.clearAndReloadAll();
      });
  }
}
