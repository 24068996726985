import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class HttpDalService {
  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
  };

  constructor(private http: HttpClient) {
  }

  put<T>(url: string, data: T) {
    return this.http.put<T>(url, data, this.httpOptions).toPromise();
  }

  post<T>(url: string, data: T) {
    return this.http.post<T>(url, data, this.httpOptions).toPromise();
  }

  get<T>(url: string) {
    return this.http.get<T>(url, this.httpOptions).toPromise();
  }

  delete(url: string) {
    return this.http.delete(url, this.httpOptions).toPromise();
  }
}
