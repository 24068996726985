import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {DefaultCrewRuleData, EventhubEmployeeData, EventhubSectorData, VehicleData} from "../data-definitions";
import {ToStringService} from "../to-string.service";
import {BaseDefaultCrewRuleService} from "../base-default-crew-rules.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-admin-default-crew-rules-creation-modal',
  templateUrl: './admin-default-crew-rules-creation-modal.component.html',
  styleUrls: ['./admin-default-crew-rules-creation-modal.component.css',
              '../../assets/standard-page.css',
              '../../assets/buttons.css',
              '../../assets/forms.css',
              '../../assets/modal.css',
              '../../assets/select.css']
})
export class AdminDefaultCrewRulesCreationModalComponent {

  dea?: EventhubEmployeeData
  crewMan?: EventhubEmployeeData
  rule?: DefaultCrewRuleData

  sector: EventhubSectorData;
  employees: EventhubEmployeeData[] = [];

  deasInTeam: Set<string> = new Set();
  crewMansInTeam: Set<string> = new Set();

  deas: EventhubEmployeeData[] = [];
  possibleCrewMansPerDepot: Map<string, EventhubEmployeeData[]> = new Map();

  creatingRule = false;
  edit = false;

  @Output() closeModal = new EventEmitter<boolean>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public toStringService: ToStringService,
              private baseDefaultCrewRuleService: BaseDefaultCrewRuleService) {
    this.sector = data.sector;
    this.employees = data.employees;
    this.deasInTeam = data.deasInTeam
    this.crewMansInTeam = data.crewMansInTeam;
    this.employees.sort((e1,e2) => {
      return e1.lastName.localeCompare(e2.lastName)
    })
    this.edit = data.rule != undefined;
    this.rule = data.rule;
    if (data.rule != undefined) {
      this.dea = this.employees.find(e => e.apiId == data.rule.deaApiId);
      this.crewMan = this.employees.find(e => e.apiId == data.rule.crewManApiId);
    }
    this.employees.forEach(employee => {
      if (!employee.serviceAssignments.includes('AMBU')) return;
      if (employee.diploma === "DEA" && (employee == this.dea || (!this.deasInTeam.has(employee.apiId) && !this.crewMansInTeam.has(employee.apiId)))) {
        this.deas.push(employee);
      }
      if ((employee == this.crewMan || (!this.deasInTeam.has(employee.apiId) && !this.crewMansInTeam.has(employee.apiId)))) {
          if (!this.possibleCrewMansPerDepot.has(employee.depotId)) this.possibleCrewMansPerDepot.set(employee.depotId, []);
          this.possibleCrewMansPerDepot.get(employee.depotId)?.push(employee);
      }
    });

  }


  isRuleComplete() {
    if (!this.dea) return false;
    if (!this.crewMan) return false;
    return true;
  }

  getPossibleCrewMans() {
    if (this.dea === undefined) return [];
    else if (!this.possibleCrewMansPerDepot.has(this.dea.depotId)) return [];
    else return this.possibleCrewMansPerDepot.get(this.dea.depotId)?.filter(e => e.apiId != this.dea?.apiId);
  }

  async createAssociationRule() {
    this.creatingRule = true
    let crewRule: DefaultCrewRuleData = {
      id: -1,
      sectorApiId: this.sector!.apiId,
      deaApiId: this.dea!.apiId,
      crewManApiId: this.crewMan!.apiId,
    }
    await this.baseDefaultCrewRuleService.add(crewRule);
    this.close();
  }

  async updateAssociationRule() {
    this.creatingRule = true
    let crewRule: DefaultCrewRuleData = {
      id: this.rule!.id,
      sectorApiId: this.sector!.apiId,
      deaApiId: this.dea!.apiId,
      crewManApiId: this.crewMan!.apiId,
    }
    await this.baseDefaultCrewRuleService.update(crewRule);
    this.close();
  }

  close() {
      this.closeModal.next(true);
  }

}
