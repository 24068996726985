import {Component, Input, OnInit} from '@angular/core';
import {AssociationRuleData, Status, TransportData} from "../data-definitions";
import {DateService} from "../date.service";
import {ToStringService} from "../to-string.service";
import {BaseAssociationRuleService} from "../base-association-rule.service";

@Component({
  selector: 'app-association-rules-transport[transport][rule]',
  templateUrl: './association-rules-transport.component.html',
  styleUrls: ['./association-rules-transport.component.css',
              '../../assets/buttons.css',
              '../../assets/containers.css']
})
export class AssociationRulesTransportComponent implements OnInit {
  Status = Status;

  @Input() transport?: TransportData
  @Input() rule?: AssociationRuleData

  patientName = "";
  constraintHour = ""

  constructor(private dateService: DateService,
              private toStringService: ToStringService,
              private baseAssociationRuleService: BaseAssociationRuleService) {
  }

  ngOnInit(): void {
    this.patientName = this.toStringService.transportPatientName(this.transport);
    this.constraintHour = this.toStringService.transportConstraintTimeRange(this.transport);
  }

  async deleteRule() {
    await this.baseAssociationRuleService.remove(this.rule!);
  }
}
