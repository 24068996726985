<app-project-view></app-project-view>
<app-data-selection></app-data-selection>

<mat-card class="page-container">
  <section class="content-container">

    <div class="overview">
      <div *ngIf="selection.hasValue()">
        <div class="nb-selected">
          <span>{{selection.selected.length}}&nbsp;</span>
          <span *ngIf="selection.selected.length == 1">équipage sélectionné</span>
          <span *ngIf="selection.selected.length > 1">équipages sélectionnés</span>
        </div>
        <button *ngIf="selection.selected.length >= 1"
                (click)="modifyCrew()"
                 class="important-button exclude-button">
          Sauvegarder les équipages
          <img src="assets/images/sync.svg">
        </button>
      </div>
      <div *ngIf="selection.isEmpty()">
        <span>{{nbSelected}}&nbsp;</span>
        <span>équipage</span>
        <span *ngIf="nbSelected > 1">s</span>
        <span>&nbsp;au total</span>
      </div>
    </div>

    <table mat-table class="table-container" [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        color="primary">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        color="primary">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Nom</div>
            <app-filter-table-text [dataSource]="dataSource" [filteredValues]="filteredValues" [typeToFilter]="'name'"></app-filter-table-text>
          </div>
        </th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell data-cell" [class.excluded]="employee.employee.status == Status.EXCLUDED">
            <div class="patient-div">
              <div class="patient-name"
                [ngClass]="{'team-to-complete': !existingTeams.has(employee.employee.id)}"
              >
                {{toStringService.employeeName(employee.employee)}}
              </div>
              <div *ngIf="hasRequirements(employee.employee.requirementIds)" class="patient-requirements tooltip-activator">
                <img src="assets/images/document.svg" />
                <span class="requirements-count">&nbsp;{{countRequirements(employee.employee.requirementIds)}}</span>
                <div class="tooltip">
                  <span>Exigences :</span>
                  <ul>
                    <li *ngFor="let requirement of employee.employee.requirementIds.trim().split(';')">
                      {{getRequirement(requirement)}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="depot">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Bureau Logistique</div>
            <app-filter-table-text [dataSource]="dataSource" [filteredValues]="filteredValues" [typeToFilter]="'depot'"></app-filter-table-text>
          </div>
        </th>
        <td mat-cell class="view-cell data-cell" *matCellDef="let employee">
          <div class="view-field" *ngIf="employee.depot?.name">
            {{employee.depot.name}}
          </div>
        </td>
        <td class="cell-button" mat-footer-cell *matFooterCellDef>
        </td>
      </ng-container>

      <ng-container matColumnDef="assignment">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Affectation</div>
            <app-filter-table-enum [dataSource]="dataSource" [filteredValues]="filteredValues"
                                   [typeToFilter]="'assignment'" [elements]="['Ambu et TAP', 'TAP', 'Ambu']"></app-filter-table-enum>
          </div>
        </th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell data-cell" [class.excluded]="employee.employee.status == Status.EXCLUDED">
            <div class="view-field assignment-cell">{{toStringService.employeeAssignment(employee.employee)}}</div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="depot-return">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Retour au depot</div>
            <app-filter-table-enum [dataSource]="dataSource" [filteredValues]="filteredValues"
                                   [typeToFilter]="'depot-return'" [elements]="['Oui', 'Non']">
            </app-filter-table-enum>
          </div>
        </th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell data-cell" [class.excluded]="employee.employee.status == Status.EXCLUDED">
            <div style="margin-left: 20px">
              <img *ngIf="employee.employee.planDepotReturn" src="assets/images/circle-check.svg" width="22" height="22">
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="solution-service-start">
        <th mat-header-cell class="solution-cell" mat-sort-header *matHeaderCellDef>Début de service</th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell solution-cell" [class.excluded]="employee.employee.status == Status.EXCLUDED">
            <hr *ngIf="!hasSolution(employee)">
            <div *ngIf="hasSolution(employee)" class="view-field">
              {{getNonNullPipeDateString(employee.routeSolWithMeasures?.routeMeasures?.start, "HH:mm:ss")}}
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="solution-service-end">
        <th mat-header-cell class="solution-cell" *matHeaderCellDef>
          <div class="header-sort" mat-sort-header>Fin de service</div>
        </th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell solution-cell"
              [ngClass]="{
                'excluded': employee.employee.status == Status.EXCLUDED,
                'warning': employee.employee.status != Status.EXCLUDED && isLate(employee)
              }"
          >
            <hr *ngIf="!hasSolution(employee)">
            <div *ngIf="hasSolution(employee)" class="view-field">
              <span [class.uncertain]="!employee.employee.planDepotReturn">
                {{getNonNullPipeDateString(employee.routeSolWithMeasures?.routeMeasures?.end, "HH:mm:ss")}}
              </span>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="vehicle">
        <th mat-header-cell class="solution-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Véhicule</div>
            <app-filter-table-text [dataSource]="dataSource" [filteredValues]="filteredValues" [typeToFilter]="'vehicle'"></app-filter-table-text>
          </div>
        </th>
        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell solution-cell" [class.excluded]="employee.employee.status == Status.EXCLUDED">
            <hr *ngIf="!hasSolution(employee)">
            <div *ngIf="hasSolution(employee)" class="view-field">
              {{toStringService.vehicleName(employee.routeSolWithMeasures?.vehicle)}}
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="crew">
        <th mat-header-cell class="solution-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort crew-title">Équipier</div>
            <app-filter-table-enum [dataSource]="dataSource" [filteredValues]="filteredValues"
                                   [typeToFilter]="'crew'" [elements]="['Complet', 'Incomplet']">
            </app-filter-table-enum>
          </div>
        </th>

        <ng-container *matCellDef="let employee">
          <td mat-cell class="view-cell data-cell" [class.excluded]="employee.employee.status == Status.EXCLUDED">
            <ng-select
              class="planning-pool-select team"
              placeholder="Equipier"
              [searchFn]="filterCrewFn"
              [ngModel]="getTeam(employee.employee.id)"
              [items]="getEmployeesInDepot(employee.employee.id)"
              (change)="updateTeam($event, employee)"
            >
              <ng-template ng-label-tmp let-item="item">
                <span >{{ item.firstName + ' ' + item.lastName + ' - ' + item.diploma}}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                <span >{{ item.firstName + ' ' + item.lastName + ' - ' + item.diploma }}</span>
              </ng-template>
            </ng-select>
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr [class.footer-no-data]="noData()" *matNoDataRow>
        <td class="mat-cell" [colSpan]="displayedColumns.length">
          <span *ngIf="!noData()">Aucune donnée ne correspond au filtre</span>
          <app-no-data-card *ngIf="noData()"></app-no-data-card>
        </td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[50, 100, 500, 1000]"
                   [pageSize]="1000"
                   showFirstLastButtons>
    </mat-paginator>

  </section>
</mat-card>

