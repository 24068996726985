import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BaseService, DalConfiguration } from './base-service';
import { CurrentProjectService } from './current-project.service';
import { CurrentUserService } from './current-user.service';
import { UserProjectAuthorization } from './data-definitions';
import { HttpDalService } from './http-dal.service';

@Injectable({
  providedIn: 'root'
})
export class BaseAuthorizationService extends BaseService<UserProjectAuthorization> {
  constructor(
      http: HttpDalService,
      private currentProjectService: CurrentProjectService,
      private currentUserService: CurrentUserService) {
    super(http);

    this._addStoreDefinition(
      'by-environment-id',
      userProjectAuthorization => [userProjectAuthorization.environmentId]
    );

    this._addStoreDefinition(
      'by-user-id',
      userProjectAuthorization => [userProjectAuthorization.userId]
    );
  }

  _extractParentUrl(context: any): string {
    let url = `${DalConfiguration.url }/environments/${context.environmentId}/authorizations`;
    if (context.userId) url += `/users/${context.userId}`;
    return url;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url }/environments/${context.environmentId}/authorizations/users/${context.userId}/projects/${context.projectId}`;
  }

  _extractElementId(user: UserProjectAuthorization) {
    return user.id;
  }

  async remove(user: UserProjectAuthorization) {
    await this._removeElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: user.projectId,
      userId: user.userId
    }, user);
  }

  add(user: UserProjectAuthorization) {
    return this._addElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
    }, user);
  }

  reloadAll() {
    return this._reloadElements({
      environmentId: this.currentProjectService.getEnvironmentId()
    });
  }

  reloadAllFromUser() {
    return this._reloadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      userId: this.currentUserService.getUserId()
    });
  }

  listenAll() {
    return this.currentProjectService.findEnvironmentId().pipe(
      mergeMap(environmentId => environmentId === undefined ? EMPTY : this._listenElements('by-environment-id', [environmentId]))
    );
  }

  listenAllFromUser() {
    return this.currentUserService.findUserId().pipe(
      mergeMap(userId => userId === undefined ? EMPTY : this._listenElements('by-user-id', [userId]))
    );
  }
}
