import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BaseService, DalConfiguration } from './base-service';
import { CurrentProjectService } from './current-project.service';
import { CrewData } from './data-definitions';
import { HttpDalService } from './http-dal.service';

@Injectable({
    providedIn: 'root'
})
export class BaseCrewRuleService extends BaseService<CrewData> {
    constructor(
        http: HttpDalService,
        private currentProjectService: CurrentProjectService) {
        super(http);

        this._addStoreDefinition(
          'by-project-id',
          crew => [crew.projectId]
        );
    }

    _extractParentUrl(context: any): string {
        return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/crews/rules`;
    }

    _extractElementUrl(context: any) {
        return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/crews/rules/${context.crewId}`;
    }

    _extractElementId(crew: CrewData) {
        return crew.id;
    }

    load(crewId: number) {
        return this._loadElement({
            environmentId: this.currentProjectService.getEnvironmentId(),
            projectId: this.currentProjectService.getProjectId(),
            crewId: crewId
        });
    }

    loadAll() {
        return this._loadElements({
            environmentId: this.currentProjectService.getEnvironmentId(),
            projectId: this.currentProjectService.getProjectId()
        });
    }

    clearAndReloadAll() {
        return this._clearAndReloadElements({
            environmentId: this.currentProjectService.getEnvironmentId(),
            projectId: this.currentProjectService.getProjectId()
        });
    }

    listen(crewId: number) {
        return this._listenElement(crewId);
    }

    listenAll() {
        return this.currentProjectService.findProjectId().pipe(
            mergeMap(projectId => projectId === undefined ? EMPTY : this._listenElements('by-project-id', [projectId]))
        );
    }
}