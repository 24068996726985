<button class="rounded-button first-type-button close-button" (click)="close()">
    <img src="assets/images/cross.svg">
  </button>

  <div class="container">
    <h1>Modifier les contraintes</h1>


    <table class="form-container">
        <form id="employee-time-form"></form>
        <thead>
            <tr class="row-header">
                <th class="patient-name">Nom</th>
                <th>Priorité</th>
                <th>Première prise en charge</th>
                <th>Dernière dépose</th>
                <th>Début service</th>
                <th>Fin service</th>
                <th>Amplitude minimum</th>
                <th>Amplitude maximum</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let employee of modifiedEmployees" class="row-value">
                <td class="patient-name">
                    {{toStringService.employeeName(employee)}}
                </td>
                <td>
                  <input form="employee-time-form" type="number" min="0" max="100" required
                        [ngModel]="employee.servicePriority"
                        (ngModelChange)="changePriority(employee, $event)"
                        class="number__field">
                </td>
                <td>
                    <input form="employee-time-form" type="time" min="00:00" max="23:59" required
                        [ngModel]="employee.serviceFirstPickup"
                        (ngModelChange)="changeFirstPickupTime(employee, $event)"
                        [class.invalid]="!validServiceEmployees.get(employee.id)"
                        class="input__field input_time_xs">
                </td>
                <td>
                <input form="employee-time-form" type="time" min="00:00" max="23:59" required
                        [ngModel]="employee.serviceLastDropoff"
                        (ngModelChange)="changeLastDropoffTime(employee, $event)"
                        [class.invalid]="!validServiceEmployees.get(employee.id)"
                        class="input__field input_time_xs">
                </td>
                <td>
                    <input form="employee-time-form" type="time" min="00:00" max="23:59" required
                        [ngModel]="employee.serviceStart"
                        (ngModelChange)="changeServiceStartTime(employee, $event)"
                        [class.invalid]="!validWishEmployees.get(employee.id)"
                        class="input__field input_time_xs">
                </td>
                <td>
                    <input form="employee-time-form" type="time" min="00:00" max="23:59" required
                        [ngModel]="employee.serviceEnd"
                        (ngModelChange)="changeServiceEndTime(employee, $event)"
                        [class.invalid]="!validWishEmployees.get(employee.id)"
                        class="input__field input_time_xs">
                </td>
                <td>
                    <input form="employee-time-form" type="time" min="00:00" max="23:59" required
                        [ngModel]="employee.serviceAmplitudeMin"
                        (ngModelChange)="changeAmplitudeMin(employee, $event)"
                        [class.invalid]="!validAmplitudeEmployees.get(employee.id)"
                        class="input__field input_time_xs">
                </td>
                <td>
                    <input form="employee-time-form" type="time" min="00:00" max="23:59" required
                        [ngModel]="employee.serviceAmplitudeMax"
                        (ngModelChange)="changeAmplitudeMax(employee, $event)"
                        [class.invalid]="!validAmplitudeEmployees.get(employee.id)"
                        class="input__field input_time_xs">
                </td>
            </tr>
        </tbody>
    </table>

    <div *ngIf="!areAllEmployeesWishValid()" style="color: red; text-align:center">
      Certains employés ont un souhait de début de service plus tardif que le souhait de fin. Veuillez vous assurez
      que chacun fin de service souhaitée soit après chaque souhait de début.
    </div>

    <div *ngIf="!areAllEmployeesServiceValid()" style="color: red; text-align:center">
      Certains employés ont une première prise plus tardive que la dernière dépose. Veuillez vous assurez que chaque
      dernière dépose se déroule après la première prise.
    </div>

    <div *ngIf="!areAllEmployeesAmplitudeValid()" style="color: red; text-align:center">
      Certains employés ont une amplitude maximale inférieure à l'amplitude minimale. Veuillez vous assurez que chaque amplitude
      maximale est supérieure à l'amplitude minimale.
    </div>

    <div class="button-container">
      <button class="rectangular-button first-type-button" (click)="close()">
        Annuler
      </button>
      <button class="rectangular-button important-button validate-button"
              (click)="applyModifications()"
              [disabled]="!areAllEmployeesValid()">
        <div class="start-button-label">
          Valider
        </div>
        <img src="assets/images/edit.svg">
      </button>
    </div>

  </div>
