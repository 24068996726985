import { Component, OnDestroy, OnInit } from '@angular/core';
import {ErrorData, InternalError} from '../data-definitions';
import { MessageService } from '../message.service';
import { UrlService } from '../url.service';

@Component({
  selector: 'app-band-error',
  templateUrl: './band-error.component.html',
  styleUrls: ['./band-error.component.css',
              '../../assets/buttons.css']
})
export class BandErrorComponent implements OnInit, OnDestroy {

  error?: InternalError;

  constructor(private messageService: MessageService,
              private urlService: UrlService) {
  }

  ngOnInit(): void {
    this.messageService.startListeningToError();
    this.messageService.getErrorMessage().subscribe(
      error => {
        this.error = error;
        var element = document.getElementById("band-error-start-id");
        if (element) element.scrollIntoView({block: "end", behavior: "smooth", });
      }
    )
  }

  ngOnDestroy(): void {
    if (this.error) {
      this.error = undefined;
    }
    this.messageService.stopListeningToError();
  }

  clearError(): void {
    this.messageService.clearError();
    this.error = undefined;
  }

  computeUrl() {
    return this.urlService.computeUrl(["internalerror"]);
  }
}
