<form id="user-form"></form>

<div>
  <table mat-table class="table-container" [dataSource]="dataSource" matSort>

    <ng-template #inputName>
      <mat-form-field *ngIf="isModifying()" floatLabel="never">
        <mat-label for="type-name">nom d'utilisateur</mat-label>
        <input matInput id="type-name" type="text" [(ngModel)]="userToModify!.name" form="user-form" required>
        <mat-error> {{getRequiredErrorMessage()}} </mat-error>
      </mat-form-field>
    </ng-template>

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Utilisateur</th>
      <ng-container *matCellDef="let user">
        <td mat-cell class="view-cell" *ngIf="!isUserToModify(user)">
          <div class="view-field">
            {{user.name}}
          </div>
        </td>
        <td mat-cell *ngIf="isUserToModify(user)">
          <ng-template [ngTemplateOutlet]="inputName"></ng-template>
        </td>
      </ng-container>
      <td mat-footer-cell *matFooterCellDef>
        <ng-template *ngIf="isAdding" [ngTemplateOutlet]="inputName"></ng-template>
      </td>
    </ng-container>

    <ng-template>
      <mat-form-field *ngIf="isModifying()" floatLabel="never">
        <mat-label for="type-password">mot de passe</mat-label>
        <span matPrefix>
          <mat-checkbox class="password-checkbox" [(ngModel)]="userToModify!.passwordChanged"></mat-checkbox>
        </span>
        <input matInput id="type-password" type="text" [(ngModel)]="userToModify!.password"
               form="user-form" [disabled]="!userToModify!.passwordChanged" required>
        <mat-error> {{getRequiredErrorMessage()}} </mat-error>
      </mat-form-field>
    </ng-template>

    <ng-container matColumnDef="hashed-password">
      <th mat-header-cell *matHeaderCellDef>Mot de passe</th>
      <ng-container *matCellDef="let user">
        <td mat-cell class="view-cell" *ngIf="!isUserToModify(user)">
          <div class="view-field">
            **********
          </div>
        </td>
        <td mat-cell class="input-with-checkbox-cell" *ngIf="isUserToModify(user)">
          <mat-form-field *ngIf="isModifying()" floatLabel="never">
            <mat-label for="type-password">mot de passe</mat-label>
            <span matPrefix><mat-checkbox class="password-checkbox" [(ngModel)]="userToModify!.passwordChanged"></mat-checkbox></span>
            <input matInput id="type-password" type="text" [(ngModel)]="userToModify!.password"
                   form="user-form" [disabled]="!userToModify!.passwordChanged" required>
            <mat-error> {{getRequiredErrorMessage()}} </mat-error>
          </mat-form-field>
        </td>
      </ng-container>
      <td mat-footer-cell *matFooterCellDef>
        <mat-form-field *ngIf="isAdding" floatLabel="never">
          <mat-label for="type-password">mot de passe</mat-label>
          <input matInput id="type-password" type="text" [(ngModel)]="userToModify!.password"
                 form="user-form" [disabled]="!userToModify!.passwordChanged" required>
          <mat-error> {{getRequiredErrorMessage()}} </mat-error>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-template #inputAdmin>
      <mat-checkbox class="keep-connected-checkbox" [(ngModel)]="userToModifyIsAdmin"></mat-checkbox>
    </ng-template>

    <ng-container matColumnDef="admin">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Administrateur</th>
      <ng-container *matCellDef="let user">
        <td mat-cell class="view-cell" *ngIf="!isUserToModify(user)">
          <div class="view-field" *ngIf="isAdmin(user)">
            <mat-icon>
              done
            </mat-icon>
          </div>
        </td>
        <td mat-cell class="view-cell" *ngIf="isUserToModify(user)">
          <ng-template [ngTemplateOutlet]="inputAdmin"></ng-template>
        </td>
      </ng-container>
      <td mat-footer-cell *matFooterCellDef>
        <ng-template *ngIf="isAdding" [ngTemplateOutlet]="inputAdmin"></ng-template>
      </td>
    </ng-container>

    <ng-template #formActions>
      <button class="simple-button" title="Sauvegarder" (click)="saveChanges()" type="submit"
          [disabled]="!canModifyUser()" [class.disabled]="!canModifyUser()"
          form="user-form">
        <mat-icon>check</mat-icon>
      </button>
      <button class="simple-button" title="Annuler" (click)="endChanges()" type="button">
        <mat-icon>close</mat-icon>
      </button>
    </ng-template>

    <ng-container matColumnDef="buttons">
      <th class="cell-button" mat-header-cell *matHeaderCellDef>
      </th>
      <ng-container *matCellDef="let user">
        <td mat-cell class="cell-button" *ngIf="!isUserToModify(user)">
          <button class="simple-button" title="Modifier" [disabled]="isModifying()" [class.disabled]="isModifying()"
              (click)="modifyUser(user)">
            <mat-icon>edit</mat-icon>
          </button>
          <button class="simple-button" title="Supprimer" [disabled]="isModifying()" [class.disabled]="isModifying()"
              (click)="deleteUser(user)">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </td>
        <td mat-cell class="cell-button" *ngIf="isUserToModify(user)">
          <ng-template [ngTemplateOutlet]="formActions"></ng-template>
        </td>
      </ng-container>
      <td class="cell-button" mat-footer-cell *matFooterCellDef>
        <ng-template *ngIf="isAdding" [ngTemplateOutlet]="formActions"></ng-template>

        <button *ngIf="!isAdding" class="simple-button" title="Créer un utilisateur"
                [disabled]="isModifying()" [class.disabled]="isModifying()" (click)="addNewUser()">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>

  </table>
</div>
