import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(private datePipe: DatePipe) { }

  dateToIso8601String(date: Date): string {
    let tmpDate: Date = new Date(date);
    return tmpDate.getFullYear().toString() + '-' +
           this.numberToDateStringNumber(tmpDate.getMonth() + 1) + '-' +
           this.numberToDateStringNumber(tmpDate.getDate()) + 'T' +
           this.numberToDateStringNumber(tmpDate.getHours()) + ':' +
           this.numberToDateStringNumber(tmpDate.getMinutes()) + ':' +
           this.numberToDateStringNumber(tmpDate.getSeconds());
  }

  iso8601StringToDate(dateString: string): Date {
    let output: Date = new Date();
    let arrayDate = dateString.split('T')[0].split('-');
    let arrayTime = dateString.split('T')[1].split(':');
    output.setFullYear(Number(arrayDate[0]));
    output.setMonth(Number(arrayDate[1]) - 1);
    output.setDate(Number(arrayDate[2]));
    output.setHours(Number(arrayTime[0]));
    output.setMinutes(Number(arrayTime[1]));
    output.setSeconds(Number(arrayTime[2]));
    return output;
  }

  iso8601StringOrDateToDate(date: Date | string): Date {
    return date instanceof Date ? new Date(date) : this.iso8601StringToDate(date);
  }

  timeStringToDate(time: string): Date {
    return new Date('1970-01-01T' + time + 'Z');
  }

  numberToDateStringNumber(dateNumber: number): string {
    if (dateNumber >= 10) return dateNumber.toString();
    return '0' + dateNumber.toString();
  }

  getNonNullPipeDateString(date: Date | string, format: string): string {
    if (!date) return "";
    let pipedDate = this.datePipe.transform(this.iso8601StringOrDateToDate(date), format);
    return pipedDate ? pipedDate : "";
  }

  extractDayTimeFromDate(date : Date | string) : string {
    if (!date) return ""
    let pipedDate = this.datePipe.transform(this.iso8601StringOrDateToDate(date), "HH:mm");
    return pipedDate ? pipedDate : "";
  }

  dayTimeToSQLTime(time: string) : string {
    if (time.split(':').length < 3)
      return  time + ":00";
    return time;
  }
  
  extractDateStrFromDate(date: Date) : string {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let dayStr = day.toString().padStart(2, '0');
    let monthStr = month.toString().padStart(2, '0');
    return `${year}-${monthStr}-${dayStr}`
  }

  changeTimeOfDate(date: Date | string, time: string) : Date {
    let dateObj = this.iso8601StringOrDateToDate(date)
    let arrayTime = time.split(':');
    if (arrayTime.length >= 1)
      dateObj.setHours(parseInt(arrayTime[0]));
    if (arrayTime.length >= 2)
      dateObj.setMinutes(parseInt(arrayTime[1]));
    if (arrayTime.length >= 3)
      dateObj.setSeconds(parseInt(arrayTime[2]));
    return dateObj
  }
}
