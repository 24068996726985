import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  private userId = new BehaviorSubject<number | undefined>(undefined);

  constructor() { }

  login(userId: number) {
    this.userId.next(userId);
  }

  logout() {
    this.userId.next(undefined);
  }

  findUserId() {
    return this.userId.asObservable();
  }

  getUserId() {
    return this.userId.getValue();
  }
}
