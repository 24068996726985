<button class="rounded-button first-type-button close-button" (click)="close()">
    <img src="assets/images/cross.svg">
  </button>

  <div class="container">
    <h1>Modifier un bureau logistique</h1>


    <table class="form-container">
        <ng-container *ngIf="initialEmployees.length > 0">
          <form id="employee-depot-form"></form>
          <thead>
              <tr class="row-header">
                  <th class="patient-name">Nom</th>
                  <th>Bureau Logistique</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let employee of modifiedEmployees" class="row-value">
                  <td class="patient-name">
                      {{employee.firstName}} {{employee.lastName}}
                  </td>
                  <td>
                      <ng-select class="planning-pool-select required"
                          [items]="depots"
                          bindValue="id"
                          bindLabel="name"
                          [(ngModel)]="employee.depotId"
                          [clearable]="false"
                          appendTo="body"
                          placeholder="Bureau Logistique"
                      ></ng-select>
                  </td>
                  
              </tr>
          </tbody>
        </ng-container>
        <ng-container *ngIf="initialVehicles.length > 0">
          <form id="vehicle-depot-form"></form>
          <thead>
              <tr class="row-header">
                  <th class="patient-name">Plaque d'immatriculation</th>
                  <th>Bureau Logistique</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let vehicle of modifiedVehicles" class="row-value">
                  <td class="patient-name">
                      {{vehicle.licensePlate}}
                  </td>
                  <td>
                      <ng-select class="planning-pool-select required"
                          [items]="depots"
                          bindValue="id"
                          bindLabel="name"
                          [(ngModel)]="vehicle.depotId"
                          appendTo="body"
                          [clearable]="false"
                          placeholder="Bureau Logistique"
                      ></ng-select>
                  </td>
                  
              </tr>
          </tbody>
        </ng-container>
    </table>

    <div class="button-container">
      <button class="rectangular-button first-type-button" (click)="close()">
        Annuler
      </button>
      <button class="rectangular-button important-button validate-button"
              (click)="applyModifications()">
        <div class="start-button-label">
          Valider
        </div>
        <img src="assets/images/edit.svg">
      </button>
    </div>

  </div>
