<div class="container">
    <div class="header">
      <div>
        <img src="assets/images/person.svg" />
        Chef de bord
      </div>
      <div class="vehicle">
        <img src="assets/images/person.svg" />
        Équipier
      </div>
      <div class="button-shift">
      </div>
    </div>
  
    <div class="content" *ngFor="let defaultCrewRuleInformation of defaultCrewRulesInformation">
      <div>
        {{ this.toStringService.employeeName(defaultCrewRuleInformation.dea) }}
      </div>
      <div class="vehicle">
        {{ this.toStringService.employeeName(defaultCrewRuleInformation.crewMan) }} - {{defaultCrewRuleInformation.crewMan.diploma}}
      </div>
      <div class="button-shift">
        <button class="rounded-button first-type-button"
                (click)="editDefaultCrewRule(defaultCrewRuleInformation)">
          <img src="assets/images/edit.svg">
        </button>
      </div>
      <div class="button-shift">
        <button class="rounded-button first-type-button"
                (click)="deleteRule(defaultCrewRuleInformation)">
          <img src="assets/images/cross.svg">
        </button>
      </div>
    </div>
  
    <div class="button-container">
      <button type="button" class="rounded-button secondary-type-button new-planning"
              (click)="createNewDefaultCrewRule()">
        <img src="assets/images/plus.svg">
      </button>
    </div>
  </div>
  