import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment-duration-format';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(duration: number, format: string = "h:mm:ss"): string {
    return moment.duration(duration, "seconds").format(format, { trim: false });
  }

}
