<div class="filter-group">
  <button class="simple-button filter-button" mat-icon-button [matMenuTriggerFor]="filterMenu"
          title="Filter">
    <mat-icon [class.filter-active]="minTime != '' || maxTime != ''">
      {{minTime == '' && maxTime == '' ? 'filter_list' : 'filter_alt'}}
    </mat-icon>
  </button>
  <mat-menu class="filter-menu" #filterMenu="matMenu">
    <div class="main-container">
      <div  class="filter" (click)="$event.stopPropagation()" (keydown.tab)="$event.stopPropagation()" floatLabel="always">
        <div class="hour-container">
          <div>Après</div>
          <input type="time" min="00:00" max="23:59" (keyup)="applyFilter($event)" [(ngModel)]="minTime"
                 class="input__field input_time_xs">
        </div>
      </div>
      <div class="filter" (click)="$event.stopPropagation()" (keydown.tab)="$event.stopPropagation()" floatLabel="always">
        <div class="hour-container">
          <div>Avant</div>
          <input type="time" min="00:00" max="23:59" (keyup)="applyFilter($event)" [(ngModel)]="maxTime"
                 class="input__field input_time_xs">
        </div>
      </div>
      <button mat-icon-button class="simple-button" (click)="suppressFilter()">
        <mat-icon>delete_sweep</mat-icon>
      </button>
    </div>
  </mat-menu>
</div>
