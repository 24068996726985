import {Injectable} from "@angular/core";
import {EmployeeData, EventhubEmployeeData, LocationData, TransportData, VehicleData} from "./data-definitions";
import {DateService} from "./date.service";

@Injectable({
  providedIn: 'root'
})
export class ToStringService {

  constructor(private dateService: DateService) {}

  transportPatientName(transport?: TransportData) {
    if (!transport) return "";
    return transport.patientLastName + " " + transport.patientFirstName;
  }

  transportConstraintTimeRange(transport?: TransportData) {
    if (!transport) return "";
    return this.dateService.extractDayTimeFromDate(transport!.timeWindowStart)
      + " - " + this.dateService.extractDayTimeFromDate(transport!.timeWindowEnd);
  }

  transportExigency(transport?: TransportData) {
    if (!transport) return "";
    if (transport.timeWindowType == "origin") return "Prise en charge";
    else return "Dépose";
  }

  transportDirection(transport?: TransportData) {
    if (!transport) return "";
    switch (transport.direction) {
      case "GO": return "Aller";
      case "RETURN": return "Retour";
      case "TRANSFER": return "Transfert";
    }
  }

  employeeAssignment(employee?: EmployeeData | EventhubEmployeeData) {
    if (!employee) return "";
    let services = employee.serviceAssignments.split(";").map(s => s.toLowerCase());
    if (services.includes("ambu") && services.includes("tap"))
      return "Ambu\nTAP"
    else if (services.includes("ambu"))
      return "Ambu"
    else
      return "TAP"
  }

  employeeName(employee?: EmployeeData | EventhubEmployeeData) {
    if (!employee) return "";
    return employee.lastName.toUpperCase() + " " + employee.firstName;
  }

  vehicleType(vehicle?: VehicleData)  {
    if (!vehicle) return "";
    if (vehicle.type === "TAXI") return "Taxi";
    if (vehicle.type === "AMBU") return "Ambu";
    return vehicle.type;
  }

  vehicleName(vehicle?: VehicleData)  {
    if (!vehicle) return "";
    let prefix = this.vehicleType(vehicle);
    return prefix + " " + vehicle.licensePlate;
  }

  locationAddress(location?: LocationData) {
    if (!location) return "";
    return location.address + " " + location.zipCode + " " + location.city;
  }
}
