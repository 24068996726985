import {Injectable} from "@angular/core";
import {BaseService, DalConfiguration} from "./base-service";
import {EmployeeData, ErrorData} from "./data-definitions";
import {HttpDalService} from "./http-dal.service";
import {CurrentProjectService} from "./current-project.service";
import {mergeMap} from "rxjs/operators";
import {EMPTY} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class BaseErrorService extends BaseService<ErrorData> {
  constructor(
    http: HttpDalService,
    private currentProjectService: CurrentProjectService) {
    super(http);

    this._addStoreDefinition(
      'by-project-id',
      employee => [employee.projectId]
    );
  }

  _extractParentUrl(context: any): string {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/error`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/error/${context.employeeId}`;
  }

  _extractElementId(error: ErrorData) {
    return error.id;
  }

  load(employeeId: number) {
    return this._loadElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      employeeId: employeeId
    });
  }

  loadAll() {
    return this._loadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  listen(employeeId: number) {
    return this._listenElement(employeeId);
  }

  listenAll() {
    return this.currentProjectService.findProjectId().pipe(
      mergeMap(projectId => projectId === undefined ? EMPTY : this._listenElements('by-project-id', [projectId]))
    );
  }
}
