<section class="route-indicators-list">
  <div class="indicator">
    <div class="route-indicator-title">Nombre de missions</div>
    <div class="route-indicator-value" *ngIf="routeWithMeasures">{{ routeWithMeasures.routeMeasures?.nbTasks }}</div>
  </div>
  <div class="indicator">
    <div class="route-indicator-title">Début - fin de service</div>
    <div class="route-indicator-value" *ngIf="routeWithMeasures">
      {{ routeWithMeasures.routeMeasures?.nbTasks !== 0 ? getNonNullPipeDateString(routeWithMeasures.routeMeasures?.start!, 'HH:mm:ss') : '-' }} -
      {{ routeWithMeasures.routeMeasures?.nbTasks !== 0 ? getNonNullPipeDateString(getRouteEnd(), 'HH:mm:ss') : '-' }}
    </div>
  </div>
  <div class="indicator">
    <div class="route-indicator-title">Distance</div>
    <div class="route-indicator-value" *ngIf="routeWithMeasures">
      {{ routeWithMeasures!.routeMeasures!.distance | distance }}
    </div>
  </div>
  <div class="indicator">
    <div class="route-indicator-title">Retard missions</div>
    <div class="route-indicator-value" *ngIf="routeWithMeasures"
         [class.violation]="routeWithMeasures!.routeMeasures!.taskLateness! > 0">
      {{ routeWithMeasures!.routeMeasures!.taskLateness! | duration }}
    </div>
  </div>
  <div class="indicator">
    <div class="route-indicator-title">Productivité horaire</div>
    <div class="route-indicator-value" *ngIf="routeWithMeasures">
      {{ routeWithMeasures!.routeMeasures!.hourlyProductivity | number: '1.0-2' }} mission/h
    </div>
  </div>
  <div class="indicator">
    <div class="route-indicator-title">Vitesse opérationnelle</div>
    <div class="route-indicator-value" *ngIf="routeWithMeasures">
      {{ routeWithMeasures!.routeMeasures!.operatingSpeed | number : '1.0-1' }} km/h
    </div>
  </div>
  <div class="indicator">
    <div class="route-indicator-title">Taux de haut-le-pied</div>
    <div class="route-indicator-value" *ngIf="routeWithMeasures">
      {{ routeWithMeasures.routeMeasures?.deadMileageRate! | percent: '1.0-1' }}
    </div>
  </div>
  <div class="indicator">
    <div class="route-indicator-title">Dépassement service</div>
    <div class="route-indicator-value" *ngIf="routeWithMeasures"
         [class.violation]="routeWithMeasures.routeMeasures?.returnLateness! > 0"
         >{{ routeWithMeasures.routeMeasures?.returnLateness! | duration }}
    </div>
  </div>
  <div class="indicator duration-indicator">
    <div class="route-indicator-title">Durées</div>
    <div class="route-indicator-value" *ngIf="routeWithMeasures">
      {{ routeWithMeasures.routeMeasures?.totalDuration! | duration: "h:mm:ss" }} - total
    </div>
    <div class="route-indicator-value" *ngIf="routeWithMeasures"
         [class.violation]="routeWithMeasures.routeMeasures?.returnLateness! > 0" >
      {{ routeWithMeasures.routeMeasures?.effectiveWorkTime! | duration }} - effectif
    </div>
    <div class="route-indicator-value" *ngIf="routeWithMeasures">
      {{ routeWithMeasures.routeMeasures?.travelTime! | duration }} - conduite
    </div>
    <div class="route-indicator-value" *ngIf="routeWithMeasures">
      {{ routeWithMeasures.routeMeasures?.taskTime! | duration }} - service
    </div>
    <div class="route-indicator-value" *ngIf="routeWithMeasures">
      {{ routeWithMeasures.routeMeasures?.waitingTime! | duration }} - attente
    </div>
  </div>
</section>
