import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {BaseLocationService} from './base-location.service';
import {BaseDepotService} from './base-depot.service';
import {BaseMeasuresService} from './base-measures.service';
import {BaseTransportService} from './base-transport.service';
import {BaseRouteSolutionService} from './base-route-solution.service';
import {BaseVehicleService} from './base-vehicle.service';
import {ColorService} from './color.service';
import {
  LocationData,
  DepotData,
  RouteActionMeasures,
  RouteActionType,
  RouteMissionMeasuresWithLinks,
  RouteSolution,
  RouteSolutionWithMeasures,
  RouteTaskActionSolution,
  TransportData
} from './data-definitions';
import {
  EdgeLayer,
  MapElement,
  MapElementType,
  MapLocation,
  MapMarker,
  MapRoute,
  MapSource,
  VisibilityType
} from "./map-types";
import {DataMerger} from './data-merger';
import {DateService} from './date.service';
import {MessageService} from './message.service';
import {GeoJSONSourceSpecification, LayerSpecification, Marker, Popup} from 'maplibre-gl';
import * as polyline from '@mapbox/polyline';
import {BaseEmployeeService} from "./base-employee.service";
import {ToStringService} from "./to-string.service";

@Injectable({
  providedIn: 'root'
})
export class MapService {
  subscriptions: Subscription = new Subscription();
  routeDataMerger: DataMerger<RouteSolution, RouteSolutionWithMeasures>;

  selectedRoute = new BehaviorSubject<MapRoute | undefined>(undefined);
  selectedElement = new BehaviorSubject<MapElement | undefined>(undefined);
  routes = new BehaviorSubject<MapRoute[]>([]);

  selectedLocation = new BehaviorSubject<LocationData | undefined>(undefined);

  locations = new BehaviorSubject<Map<number, LocationData>>(new Map<number, LocationData>());
  depots = new BehaviorSubject<Map<number, DepotData>>(new Map<number, DepotData>());
  transports = new BehaviorSubject<Map<number, TransportData>>(new Map<number, TransportData>());

  constructor(private baseRouteService: BaseRouteSolutionService,
              private baseVehicleService: BaseVehicleService,
              private baseEmployeeService: BaseEmployeeService,
              private baseLocationService: BaseLocationService,
              private baseDepotService: BaseDepotService,
              private baseTransportService: BaseTransportService,
              private baseMeasuresService: BaseMeasuresService,
              private dateService: DateService,
              private colorService: ColorService,
              private toStringService: ToStringService,
              private messageService: MessageService) {
    this.routeDataMerger = new DataMerger("routeSolution", this.baseRouteService.listenAll());
    this.routeDataMerger.addSubMapper("vehicle", this.baseVehicleService.listenAll(), r => r.vehicleId);
    this.routeDataMerger.addSubMapper("employee", this.baseEmployeeService.listenAll(), r => r.employeeId);
    this.routeDataMerger.addSubMapper("routeMeasures", this.baseMeasuresService.listen().pipe(
        map(measures => measures!.routeMeasures)), rs => rs.id);
    combineLatest([this.routeDataMerger.listen(), this.locations, this.depots, this.transports]).subscribe(
      ([routes, locations, depots, transports]) => {
        let mapRoutes: MapRoute[] = [];
        if (routes.length === 0 ||
            !routes[0].routeMeasures ||
            !routes[0].vehicle ||
            locations.size === 0 ||
            transports.size === 0) {
          if (this.routes.value.length > 0) this.routes.next(mapRoutes);
          if (this.selectedRoute.value) this.selectedRoute.next(undefined);
          return;
        }
        routes.forEach(
          route => {
            mapRoutes.push({route: route,
                mapElements: this.createMapElements(route, locations, transports),
                mapLocations: this.createMapLocations(route, locations, depots, transports),
                missions: this.createMissionMeasures(route, locations, transports)})
          }
        )
        this.routes.next(mapRoutes);
        if (mapRoutes.length === 0) {
          if (this.selectedRoute.value) this.selectedRoute.next(undefined)
        } else {
          let previousSelectedRoute = mapRoutes.find(r => this.selectedRoute.getValue() && r.route.id === this.selectedRoute.getValue()?.route.id);
          this.selectedRoute.next(previousSelectedRoute ? previousSelectedRoute : undefined);
        }
      }
    );
    this.baseLocationService.listenAll().subscribe(
      locations => {
        let map: Map<number, LocationData> = new Map<number, LocationData>();
        locations.forEach(
          location => map.set(location.id, location)
        )
        this.locations.next(map);
      }
    );
    this.baseDepotService.listenAll().subscribe(
      depots => {
        let map: Map<number, DepotData> = new Map<number, DepotData>();
        depots.forEach(
          depot => map.set(depot.id, depot)
        )
        this.depots.next(map);
      }
    );
    this.baseTransportService.listenAll().subscribe(
      missions => {
        let map: Map<number, TransportData> = new Map<number, TransportData>();
        missions.forEach(
          transport => map.set(transport.id, transport)
        )
        this.transports.next(map);
      }
    );
    this.loadData();
  }

  async loadData() {
    try {
      await Promise.all([this.baseTransportService.loadAll(), this.baseMeasuresService.load(), this.baseLocationService.loadAll(),
        this.baseDepotService.loadAll(), this.baseVehicleService.loadAll(), this.baseEmployeeService.loadAll(),
        this.baseRouteService.loadAll()]);
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  createMissionMeasures(route: RouteSolutionWithMeasures, locations: Map<number, LocationData>,
                        missions: Map<number, TransportData>): RouteMissionMeasuresWithLinks[] {
    let output: RouteMissionMeasuresWithLinks[] = [];
    if (route.routeMeasures == undefined
      || route.routeMeasures.routeMissionMeasures == undefined
      || route.routeMeasures.routeMissionMeasures.length === 0
      || locations.size === 0
      || missions.size === 0) return output;
    route.routeMeasures.routeMissionMeasures.forEach(
      missionMeasures => {
        output.push({
          id: missionMeasures.id,
          routeMissionMeasures: missionMeasures,
          mission: missions.get(missionMeasures.missionId),
          pickupLocation: locations.get(missionMeasures.pickupLocationId),
          deliveryLocation: locations.get(missionMeasures.deliveryLocationId)
        })
      }
    );
    return output;
  }

  createMapLocations(route: RouteSolutionWithMeasures,
                     locations: Map<number, LocationData>,
                     depots: Map<number, DepotData>,
                     transports: Map<number, TransportData>) {
    let mapLocations: MapLocation[] = [];
    if (route.routeMeasures == undefined
      || route.routeMeasures.routeActionMeasures == undefined
      || route.routeMeasures.routeActionMeasures.length === 0
      || locations.size === 0
      || transports.size === 0) return mapLocations;
    route.routeSolution.taskActions.forEach(
      action => {
        let location = this.extractLocationFromAction(action, locations, transports);
        if (!location) return;
        const el = document.createElement('div');
        el.className = 'marker clickable-marker';
        let marker =  new Marker(el)
          .setLngLat([location.longitude, location.latitude]);
        let popup = new Popup()
          .setText(this.toStringService.locationAddress(location))
          .setOffset(10);
        marker.setPopup(popup);
        mapLocations.push({location: location, mapObject: marker});
      }
    );
    if (route.vehicle && depots.has(route.vehicle.depotId)) {
      let depot = depots.get(route.vehicle.depotId)!;
      let location = locations.get(depot.locationId);
      if (location) {
        const start = document.createElement("img")
        start.src = "assets/images/map_start.svg"
        start.className = 'clickable-marker';
        let marker =  new Marker(start)
          .setLngLat([location.longitude, location.latitude]);
        let popup = new Popup()
          .setText(this.toStringService.locationAddress(location))
          .setOffset(10);
        marker.setPopup(popup);
        mapLocations.push({location: location, mapObject: marker});
      }
    }
    return mapLocations;
  }

  extractLocationFromAction(action: RouteTaskActionSolution,
                            locations: Map<number, LocationData>,
                            transports: Map<number, TransportData>) {
    if (action.pickupTransportId) {
      if (!transports.has(action.pickupTransportId)) return;
      let transport = transports.get(action.pickupTransportId);
      if (!transport || !locations.has(transport?.originId)) return;
      return locations.get(transport?.originId);
    }
    if (action.deliveryTransportId) {
      if (!transports.has(action.deliveryTransportId)) return;
      let transport = transports.get(action.deliveryTransportId);
      if (!transport || !locations.has(transport?.destinationId)) return;
      return locations.get(transport?.destinationId);
    }
    return undefined;
  }

  createMapElements(routeSolutionWithMeasures: RouteSolutionWithMeasures,
                    locationMap: Map<number, LocationData>,
                    transportMap: Map<number, TransportData>) {
    let mapElements: MapElement[] = [];
    if (routeSolutionWithMeasures.routeMeasures == undefined
      || routeSolutionWithMeasures.routeMeasures.routeActionMeasures == undefined
      || routeSolutionWithMeasures.routeMeasures.routeActionMeasures.length === 0) return mapElements;
    let elementIndex = 0;
    let actionsBuffer: RouteActionMeasures[]  = [];
    let currentNbTransportedPatients = 0;
    routeSolutionWithMeasures.routeMeasures.routeActionMeasures
        .sort((am1, am2) =>
          this.dateService.iso8601StringOrDateToDate(am1.start).getTime()
          - this.dateService.iso8601StringOrDateToDate(am2.start).getTime())
    for (let actionMeasures of routeSolutionWithMeasures.routeMeasures.routeActionMeasures) {
        if (MapService.isPointActionType(actionMeasures.type)) {
          let transport: TransportData | undefined = undefined;
          if (actionMeasures.type == RouteActionType.PICKUP) {
            currentNbTransportedPatients++;
            let taskAction = routeSolutionWithMeasures.routeSolution.taskActions
              .filter(ta => ta.id == actionMeasures.routeTaskActionId)[0];
            transport = transportMap.get(taskAction.pickupTransportId!)!;
          }
          else if (actionMeasures.type == RouteActionType.DROPOFF) {
            currentNbTransportedPatients--;
            let taskAction = routeSolutionWithMeasures.routeSolution.taskActions
              .filter(ta => ta.id == actionMeasures.routeTaskActionId)[0];
            transport = transportMap.get(taskAction.deliveryTransportId!)!;
          }
          let location: LocationData | undefined = undefined;
          if (mapElements.length >= 1) {
            let previousMapElement = mapElements[mapElements.length-1];
            location = previousMapElement.locations![previousMapElement.locations!.length-1];
          } else {
            for (let otherAction of routeSolutionWithMeasures.routeMeasures.routeActionMeasures) {
              if (otherAction.type != RouteActionType.MOVE) {
                console.assert(otherAction.type == RouteActionType.PICKUP
                  || otherAction.type == RouteActionType.SERVICE_START);
                continue;
              }
              let locations = MapService.extractLocations([otherAction], locationMap)
              location = locations[0];
              break;
            }
          }
          console.assert(location !== undefined);
          mapElements.push({
              locations: [location!],
              associatedActions: [actionMeasures],
              type: MapElementType.POINT,
              markers: this.createPointMarkers(actionMeasures, location!, transport),
              patientFirstName: transport?.patientFirstName,
              patientLastName: transport?.patientLastName,
              nbTransportedPatient: currentNbTransportedPatients
            });
      } else {
        let encodedPolyline = actionMeasures.polyline;
        if (encodedPolyline === null || encodedPolyline === "" || encodedPolyline == undefined) continue;
        let data = polyline.decode(encodedPolyline, 5);
        let coordinatesArray = data.map((e) => [e[1], e[0]]);
        let geojson = MapService.createGeoJSONSource(coordinatesArray);
        let sourceId = `source-route-${routeSolutionWithMeasures.id}-move-${elementIndex}`;
        let lineLayers = MapService.createLayers(routeSolutionWithMeasures.id, sourceId, actionMeasures, elementIndex);
        let symbolLayer = MapService.createSymbolLayer(routeSolutionWithMeasures.id, sourceId, actionMeasures, elementIndex);
        let source: MapSource = {
          id: sourceId,
          source: geojson,
          layers: [...lineLayers, symbolLayer]
        }
        actionsBuffer.push(actionMeasures);
        let locations = MapService.extractLocations(actionsBuffer, locationMap)
        let pickupLocation = actionsBuffer[0].type == RouteActionType.PICKUP ? locations[0] : undefined;
        mapElements.push({
          locations: locations,
          associatedActions: actionsBuffer,
          type: MapElementType.EDGE,
          source: source,
          markers: MapService.createEdgeMarkers(pickupLocation),
          nbTransportedPatient: currentNbTransportedPatients
        });
        actionsBuffer = [];
        ++elementIndex;
      }
    }
    return mapElements;
  }

  static isPointActionType(actionType: RouteActionType) {
    switch (actionType) {
      case RouteActionType.PICKUP:
      case RouteActionType.DROPOFF:
      case RouteActionType.MEAL_BREAK:
      case RouteActionType.REGULATORY_BREAK:
      case RouteActionType.WAIT:
      case RouteActionType.SERVICE_START:
      case RouteActionType.SERVICE_END:
      case RouteActionType.PARKING_ACCESS:
        return true;
      default:
        return false;
    }
  }

  static extractLocations(actions: RouteActionMeasures[], locationMap: Map<number, LocationData>) {
    let result: LocationData[] = [];
    for (let action of actions) {
      if (action.originLocationId) result.push(locationMap.get(action.originLocationId!)!);
      if (action.destinationLocationId) result.push(locationMap.get(action.destinationLocationId!)!);
    }
    return result;
  }

  createPointMarkers(action: RouteActionMeasures, location: LocationData, transport?: TransportData) {
    let result: MapMarker[] = [];
    const el = document.createElement("img");
    let target: string;
    switch (action.type) {
      case RouteActionType.MEAL_BREAK:
      case RouteActionType.REGULATORY_BREAK:
        target = "assets/images/map_break.svg";
        break;
      case RouteActionType.SERVICE_START:
      case RouteActionType.SERVICE_END:
        target = "assets/images/map_service_start_end.svg";
        break;
      case RouteActionType.WAIT:
        target = "assets/images/map_wait.svg";
        break;
      case RouteActionType.PICKUP:
        target = "assets/images/map_pickup.svg";
        break;
      case RouteActionType.PARKING_ACCESS:
        target = "assets/images/map_parking.svg";
        break;
      case RouteActionType.DROPOFF:
        target = "assets/images/map_delivery.svg";
        break;
      default:
        console.assert(false)
        target = "assets/images/map_delivery.svg";
    }
    el.src = target
    let marker = new Marker(el).setLngLat([location.longitude, location.latitude]).setOffset([0, -20]);
    if (action.type == RouteActionType.PICKUP || action.type == RouteActionType.DROPOFF) {
      console.assert(transport !== undefined);
      let popup = new Popup({closeButton: false})
        .setText(this.toStringService.transportPatientName(transport))
        .setOffset(34)
      marker.setPopup(popup);
      el.className = "clickable-marker"
    }
    result.push({marker: marker, visibility: VisibilityType.FOCUSED})
    return result;
  }

  static createEdgeMarkers(location?: LocationData) {
    let result: MapMarker[] = [];
    if (!location)
      return result;
    const el = document.createElement("img");
    el.src = "assets/images/map_pickup_normal.svg"
    let marker = new Marker(el).setLngLat([location.longitude, location.latitude]);
    marker.setOffset([0, -5]);
    result.push({marker: marker, visibility: VisibilityType.FOCUSED})
    result.push({visibility: VisibilityType.NORMAL})
    result.push({visibility: VisibilityType.REDUCED})
    return result;
  }

  static createGeoJSONSource(coordinatesArray: number[][])  {
    let geojson: GeoJSONSourceSpecification = {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: coordinatesArray,
        }
      },
    }
    return geojson;
  }

  static createLayers(routeId: number, sourceId: string, actionMeasures: RouteActionMeasures, elementIndex: number) {
    let layers: EdgeLayer[] = [];
    for (const layerTypeString in VisibilityType) {
      const layerType: VisibilityType = VisibilityType[layerTypeString as keyof typeof VisibilityType];
      let layerId = `layer-line-route-${routeId}-move-${elementIndex}-${layerTypeString}`;
      let layer = this.createLayer(sourceId, layerId);
      layer.paint = {
        'line-width': this.getLayerLineWidth(layerType),
        'line-color': this.getLayerLineColor(layerType, actionMeasures)
      }
      let edgeLayer: EdgeLayer = {
        visibility: layerType,
        layer: layer
      }
      layers.push(edgeLayer);
    }
    return layers;
  }

  static createSymbolLayer(routeId: number, sourceId: string, actionMeasures: RouteActionMeasures,
                           elementIndex: number): EdgeLayer {
    let iconImage = actionMeasures.deadMileageMove ? 'map-direction-blue' : 'map-direction-white';
    let symbolLayer: LayerSpecification = {
      id: `layer-symbol-route-${routeId}-move-${elementIndex}`,
      type: 'symbol',
      source: sourceId,
      paint: {},
      layout: {
        'symbol-placement': 'line-center',
        'symbol-spacing': 50,
        'icon-image': iconImage,
        'icon-size': 1,
        'icon-rotate': 90,
        'icon-rotation-alignment': 'map',
        'icon-allow-overlap': true,
        'icon-ignore-placement': true
      }
    };
    return {
      visibility: VisibilityType.FOCUSED,
      layer: symbolLayer
    };
  }

  static getLayerLineWidth(visibility: VisibilityType) {
    switch (visibility) {
      case VisibilityType.FOCUSED: return 6;
      case VisibilityType.NORMAL: return 4;
      case VisibilityType.REDUCED: return 2;
    }
  }

  static getLayerLineColor(visibility: VisibilityType, actionMeasures: RouteActionMeasures) {
    if (actionMeasures.deadMileageMove) {
      switch (visibility) {
        case VisibilityType.FOCUSED:
        case VisibilityType.NORMAL:
        case VisibilityType.REDUCED:
          return "#8ECCFF";
      }
    } else {
      switch (visibility) {
        case VisibilityType.FOCUSED:
        case VisibilityType.NORMAL:
          return "#204180"
        case VisibilityType.REDUCED:
          return "#858EAF";
      }
    }
  }

  static createLayer(sourceId: string, layerId: string) {
    let layer: LayerSpecification = {
      id: layerId,
      type: 'line',
      source: sourceId
    }
    return layer;
  }

  getRoutes() {
    return this.routes.asObservable();
  }

  getSelectedRoute() {
    return this.selectedRoute.asObservable();
  }

  getSelectedElement() {
    return this.selectedElement.asObservable();
  }

  getSelectedLocation() {
    return this.selectedLocation.asObservable();
  }

  selectRoute(route?: MapRoute) {
    this.selectedRoute.next(route);
  }

  unselectRoute() {
    this.selectedRoute.next(undefined);
  }

  selectElement(element?: MapElement) {
    this.selectedElement.next(element);
  }
}
