import { Injectable } from '@angular/core';
import { BaseService, DalConfiguration } from './base-service';
import { CurrentProjectService } from './current-project.service';
import { HttpDalService } from './http-dal.service';
import {mergeMap} from "rxjs/operators";
import {EMPTY} from "rxjs";
import { TrafficData } from './data-definitions';

@Injectable({
  providedIn: 'root'
})
export class BaseTrafficService extends BaseService<TrafficData> {
  constructor(
    http: HttpDalService,
    private currentProjectService: CurrentProjectService) {
    super(http);

    this._addStoreDefinition(
      'by-project-id',
      traffic => [traffic.projectId!]
    );
  }

  _extractParentUrl(context: any) {
    return `${DalConfiguration.url}/environments/${context.environmentId}/projects/${context.projectId}/traffic`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url}/environments/${context.environmentId}/projects/${context.projectId}/traffic/${context.trafficId}`;
  }

  _extractElementId(traffic: TrafficData) {
    return traffic.id;
  }

  async update(traffic: TrafficData) {
    await this._updateElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      trafficId: traffic.id
    }, traffic);
  }

  load(trafficId: number) {
    return this._loadElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      trafficId: trafficId
    });
  }

  loadAll() {
    return this._loadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  listen(parameterId: number) {
    return this._listenElement(parameterId);
  }

  listenAll() {
    return this.currentProjectService.findProjectId().pipe(
      mergeMap(projectId => projectId === undefined ? EMPTY : this._listenElements('by-project-id', [projectId]))
    );
  }
}
