<div class="container-first-type"
  [ngClass]="{
   'light-color': rule === undefined && !disabled,
   'dark-color': rule !== undefined && !disabled,
   'disabled-color': disabled
  }">
  <span>{{patientName}}</span>
  <span>-</span>
  <img *ngIf="transport!.timeWindowType == 'origin'" src="assets/images/ambulance-aller.svg">
  <img *ngIf="transport!.timeWindowType == 'destination'" src="assets/images/ambulance-retour.svg">
  <span>{{constraintHour}}</span>
  <button *ngIf="!  rule" (click)="addRule()" [disabled]="disabled">
    <img src="assets/images/plus.svg">
  </button>
  <button *ngIf="rule" (click)="deleteRule()">
    <img src="assets/images/cross.svg">
  </button>
</div>

