import { Injectable } from '@angular/core';
import { BaseService, DalConfiguration } from './base-service';
import { HttpDalService } from './http-dal.service';
import { DefaultCrewRuleData} from "./data-definitions";

@Injectable({
  providedIn: 'root'
})
export class BaseDefaultCrewRuleService extends BaseService<DefaultCrewRuleData> {

  sectorApiId = ""

  constructor(
    http: HttpDalService) {
    super(http);

    this._addStoreDefinition(
      'global',
       crewRule => [6]
    );
  }

  _extractParentUrl(context: any) {
    return `${DalConfiguration.url}/sectors/${this.sectorApiId}/default-crew-rules`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url}/sectors/${this.sectorApiId}/default-crew-rules/${context.defaultCrewRuleId}`;
  }

  _extractElementId(defaultCrewRule: DefaultCrewRuleData) {
    return defaultCrewRule.id;
  }

  setSectorApiId(sectorApiId: string) {
    this.sectorApiId = sectorApiId;
  }

  async add(defaultCrewRule: DefaultCrewRuleData) {
    return this._addElement({
    }, defaultCrewRule);
  }

  async update(defaultCrewRule: DefaultCrewRuleData) {
    await this._updateElement({
        defaultCrewRuleId: defaultCrewRule.id
    }, defaultCrewRule);
  }

  async remove(defaultCrewRule: DefaultCrewRuleData) {
    await this._removeElement({
        defaultCrewRuleId: defaultCrewRule.id
    }, defaultCrewRule);
  }

  load(defaultCrewRuleId: number) {
    return this._loadElement({
        defaultCrewRuleId: defaultCrewRuleId
    });
  }

  loadAll() {
    return this._loadElements({
    });
  }

  clearAndReloadAll() {
    return this._clearAndReloadElements({
    });
  }

  listen(defaultCrewRuleId: number) {
    return this._listenElement(defaultCrewRuleId);
  }

  listenAll() {
    return this._listenElements('global', [6]);
  }
}
