import { Injectable } from '@angular/core';
import { BaseService, DalConfiguration } from './base-service';
import { CurrentProjectService } from './current-project.service';
import { Parameter} from './parameter-configuration';
import { HttpDalService } from './http-dal.service';
import {mergeMap} from "rxjs/operators";
import {EMPTY} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BaseParameterService extends BaseService<Parameter> {
  constructor(
    http: HttpDalService,
    private currentProjectService: CurrentProjectService) {
    super(http);

    this._addStoreDefinition(
      'by-project-id',
      parameter => [parameter.projectId]
    );
  }

  _extractParentUrl(context: any) {
    return `${DalConfiguration.url}/environments/${context.environmentId}/projects/${context.projectId}/parameters`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url}/environments/${context.environmentId}/projects/${context.projectId}/parameters/${context.parameterId}`;
  }

  _extractElementId(parameter: Parameter) {
    return parameter.id;
  }

  async update(parameter: Parameter) {
    await this._updateElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      parameterId: parameter.id
    }, parameter);
  }

  load(parameterId: number) {
    return this._loadElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      parameterId: parameterId
    });
  }

  loadAll() {
    return this._loadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  listen(parameterId: number) {
    return this._listenElement(parameterId);
  }

  listenAll() {
    return this.currentProjectService.findProjectId().pipe(
      mergeMap(projectId => projectId === undefined ? EMPTY : this._listenElements('by-project-id', [projectId]))
    );
  }
}
