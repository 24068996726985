
import { Injectable } from '@angular/core';
import {DateService} from "./date.service";
import moment from "moment/moment";
import {EmployeeData, Status} from "./data-definitions";

interface DataWithStatus {
  status: Status
}

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor(private dateService: DateService) { }

  checkTimeWindowFilter(dates: (Date| string)[], timeWindow: [string, string]) {
    let lower = timeWindow[0] ? moment(timeWindow[0], [moment.ISO_8601, 'HH:mm']) : undefined;
    let upper = timeWindow[1] ? moment(timeWindow[1], [moment.ISO_8601, 'HH:mm']) : undefined;
    if (!lower && !upper) return true;
    for (let date of dates) {
      let dayTime = this.dateService.extractDayTimeFromDate(date);
      let comparableDate = moment(dayTime, [moment.ISO_8601, 'HH:mm']);
      if (lower && comparableDate.isBefore(lower)) return false;
      if (upper && comparableDate.isAfter(upper)) return false;
    }
    return true;
  }

  checkFilterMatch<T>(data: T, checked: string[],
                   filterFunction: (item: T, key: string) => boolean) {
    for (let element of checked) {
      if (filterFunction(data, element)) return true;
    }
    return false;
  }

  statusFilter(data: DataWithStatus, key: string) {
    switch (key) {
      case "Inclu":
      case "Incluse":
        return data.status == Status.SELECTED;
      case "Exclu":
      case "Exclue":
        return data.status != Status.SELECTED;
      default: return false;
    }
  }

  assignmentFilter(data: EmployeeData, key: string) {
    let services = data.serviceAssignments.split(";").map(s => s.toLowerCase());
    switch (key) {
      case "Ambu et TAP":
        return services.includes("tap") && services.includes("ambu");
      case "Ambu":
        return services.length == 1 && services[0] == "ambu";
      case "TAP":
        return services.length == 1 && services[0] == "tap";
      default: return false;
    }
  }
}

