import { Injectable } from '@angular/core';
import { CurrentProjectService } from './current-project.service';
import { UserData, UserProjectAuthorization, UserRole } from './data-definitions';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationCheckService {

  constructor(private currentProjectService: CurrentProjectService) { }

  checkAuthorization(userProjectAuthorizations: UserProjectAuthorization[]): boolean {
    let environmentId = this.currentProjectService.getEnvironmentId();
    if (environmentId === undefined) return false;
    if (userProjectAuthorizations.filter(upa => upa.environmentId === environmentId).length === 0) {
      return false;
    }
    let projectId = this.currentProjectService.getProjectId();
    if (projectId === undefined) return false;
    if (userProjectAuthorizations.filter(upa => upa.projectId === projectId).length === 0) {
      return false;
    }
    return true;
  }

  checkAdminAuthorization(user: UserData): boolean {
    if (user === undefined || user.id === -1) {
      return false;
    }
    if (!user.roles.includes(UserRole.ADMINISTRATION)) {
      return false;
    }
    return true;
  }
}
