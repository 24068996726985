<div class="indicators-box">
  <div class="indicator">
    <div class="indicator-title">K-score</div>
    <div class="indicator-value">
      {{ measures ? (measures.indicatorMeasures.kScore | number: '1.0-2')?.toString() : '-' }}
      <span class="indicator-unit">€/h</span>
    </div>
  </div>
  <div class="indicator">
    <div class="indicator-title">Productivité horaire</div>
    <div class="indicator-value">
      {{ measures ? (measures.indicatorMeasures.hourlyProductivity | number: '1.0-2')?.toString() : '-' }}
      <span class="indicator-unit">mission/h</span>
    </div>
  </div>
  <div class="indicator">
    <div class="indicator-title">Vitesse opérationelle</div>
    <div class="indicator-value">
      {{measures ? (measures.indicatorMeasures.operatingSpeed | number: '1.0-1')  : '-'}}
      <span class="indicator-unit">km/h</span>
    </div>
  </div>
  <div class="indicator">
    <div class="indicator-title">Taux de simultanée</div>
    <div class="indicator-value">
      {{ measures ? (measures.indicatorMeasures.mutualisationRate * 100 | number: '1.0-1') : '-'}}
      <span class="indicator-unit">%</span>
    </div>
  </div>
  <div class="indicator">
    <div class="indicator-title">Taux de haut-le-pied</div>
    <div class="indicator-value">
      {{ measures ? (measures.indicatorMeasures.deadMileageRate * 100 | number: '1.0-1')?.toString() : '-' }}
      <span class="indicator-unit">%</span>
    </div>
  </div>
  <div class="indicator">
    <div class="indicator-title">Nombre de véhicules utilisés</div>
    <div class="indicator-value">{{ measures?.indicatorMeasures?.nbUsedVehicles?.toString() ?? '-'}}</div>
  </div>
  <div class="indicator">
    <div class="indicator-title">Retards cumulés</div>
    <div class="indicator-value">{{ measures? (measures.indicatorMeasures.totalTaskLateness | duration)?.toString() : '-'}}</div>
  </div>
  <div class="indicator">
    <div class="indicator-title">Nombre de retards autorisés</div>
    <div class="indicator-value">{{ measures?.indicatorMeasures?.totalTaskAuthorisedLateness?.toString() ?? '-'}}</div>
  </div>
  <div class="indicator">
    <div class="indicator-title">Nombre de retards interdits</div>
    <div class="indicator-value">{{ measures?.indicatorMeasures?.totalTaskUnauthorisedLateness?.toString() ?? '-'}}</div>
  </div>
</div>
