import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { CurrentProjectService } from "../current-project.service";
import { Router } from "@angular/router";

@Component({
    selector: 'app-buttons-select',
    templateUrl: './buttons-select.component.html',
    styleUrls: ['./buttons-select.component.css',
                '../../assets/standard-page.css']
})
export class ButtonsSelectComponent implements OnInit {
    @Input() inChoices: string[] = [];
    @Input() firstChoice: string = "";
    @Output() outChoiceEvent = new EventEmitter<string>();
    
    selectedChoice: string = "AMBU";

    constructor(private router: Router,
                private currentProjectService: CurrentProjectService
    ) {}

    ngOnInit(): void {
        this.selectedChoice = this.firstChoice;
    }

    choose(choice: string) : void {
        this.selectedChoice = choice;
        this.outChoiceEvent.emit(this.selectedChoice);
    }
}