import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentProjectService } from '../current-project.service';
import { MessageService } from '../message.service';
import * as formUtils from '../form-utils';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';
import {confirmDelete, defaultDialogConfig} from '../dialog-data';
import { ProjectData} from '../data-definitions';
import { Subscription} from 'rxjs';
import { CurrentUserService } from '../current-user.service';
import { BaseProjectService } from '../base-project.service';
import { HttpErrorResponse } from '@angular/common/http';
import {ProjectInformation, ProjectInformationService} from "../project-information.service";
import { ProjectCopyModalComponent } from '../project-copy-modal/project-copy-modal.component';
import { CurrentTypeService } from '../current-type.service';

@Component({
  selector: 'app-project-picker',
  templateUrl: './project-picker.component.html',
  styleUrls: ['./project-picker.component.css',
              '../../assets/standard-page.css',
              '../../assets/select.css',
              '../../assets/buttons.css']
})
export class ProjectPickerComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  projects: ProjectInformation[] = [];
  filteredProjects: ProjectInformation[] = [];
  matDialogRef?: MatDialogRef<ProjectCopyModalComponent>;
  environmentId?: number;

  selectedType: string = "AMBU";
  projectTypes = ["TAP", "AMBU"];

  constructor(private baseProjectService: BaseProjectService,
              private currentProjectService: CurrentProjectService,
              private projectInformationService: ProjectInformationService,
              private currentTypeService: CurrentTypeService, 
              private currentUserService: CurrentUserService,
              private messageService: MessageService,
              private router: Router,
              public matDialog: MatDialog) { }

  ngOnInit(): void {
    if (this.currentTypeService.getProjectType() != undefined) {
        this.selectedType = this.currentTypeService.getProjectType()!;
    }

    this.currentProjectService.findEnvironmentId().subscribe(
      environmentId => {
        this.environmentId = environmentId;
        if (environmentId != undefined) {
          this.subscriptions.add(this.projectInformationService.getProjectInformationArray().subscribe(projects => {
            this.projects = projects.sort((a, b) => { return b.project.id - a.project.id; })
            this.filteredProjects = this.projects.filter((project: ProjectInformation) => 
              ((this.selectedType === "TAP" && project.project.type === "TAP") || (this.selectedType === "AMBU" && project.project.type === "AMBU")));
          }));
          this.loadData();
        }
      }
    );
    this.currentUserService.findUserId().subscribe(_ => {
      if (this.environmentId) this.loadData();
    });
  }

  setChoice(event: any): void {
    this.selectedType = event;
    this.filterProjects();
  }

  filterProjects(): void {
    this.filteredProjects = this.projects.filter((project: ProjectInformation) => 
      ((this.selectedType === "TAP" && project.project.type === "TAP") || (this.selectedType === "AMBU" && project.project.type === "AMBU")));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async loadData() {
    try {
      await Promise.all([this.baseProjectService.reloadAll(), this.projectInformationService.loadAll()]);
      this.filterProjects();
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  async deleteProject(project: ProjectData) {
    let dialogConfig = defaultDialogConfig();
    const dialogRef = this.matDialog.open(CustomDialogComponent, {...dialogConfig, data: confirmDelete(" la planification " + project.name)});
    try {
      let result = await dialogRef.afterClosed().toPromise();
      if (result && result.returnValue) {
        await this.baseProjectService.remove(project);
        if (project.id === this.currentProjectService.getProjectId()) {
          this.navigateToForcePick();
        }
      }
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  navigateToForcePick() {
    let newUrl = "/environment/" + String(this.currentProjectService.getEnvironmentId()) + "/project-pick"
    this.router.navigateByUrl(newUrl);
  }

  async navigateToProject(projectId: number) {
    let currentUrl = this.router.url;
    let newUrl = currentUrl.replace("/project-pick",
      "/project/" + String(projectId) + "/transports");
    this.currentProjectService.switchProject(projectId);
    this.router.navigateByUrl(newUrl);
  }

  openCopyModal(projectInfo: ProjectInformation) {
    let dialogConfig = defaultDialogConfig();
    dialogConfig.width = '700px';
    dialogConfig.data = {
      project: projectInfo.project
    }
    this.matDialogRef = this.matDialog.open(ProjectCopyModalComponent, dialogConfig);
    this.matDialogRef.componentInstance.closeModal.subscribe(
      value => {
        if (value) this.matDialogRef!.close();
      }
    )
    this.matDialogRef.componentInstance.project.subscribe(
      project => {
        if (project) {
          this.matDialogRef!.close();
          this.navigateToProject(project.id);
        }
      }
    )
  }

  isCurrentProject(projectId: number) {
    return projectId === this.currentProjectService.getProjectId();
  }

  getRequiredErrorMessage(): string {
    return formUtils.getRequiredErrorMessage();
  }
}
