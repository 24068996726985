<mat-card class="page-container">
  <section class="page-title-container">
    <h1 class="page-title">404 ERROR: Page not found</h1>
  </section>

  <section class="content-container">
    <app-band-error></app-band-error>
    Please enter a valid URL.
  </section>
</mat-card>
<div class="layer"></div>