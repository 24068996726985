import { Injectable } from '@angular/core';
import { BaseService, DalConfiguration } from './base-service';
import { HttpDalService } from './http-dal.service';
import { ProfileData } from './data-definitions';

@Injectable({
  providedIn: 'root'
})
export class BaseProfileService extends BaseService<ProfileData> {

  sector = ""
  profileType = ""
  projectType = ""

  constructor(
    http: HttpDalService) {
    super(http);

    this._addStoreDefinition(
      'global',
      profile => [4]
    );
  }

  _extractParentUrl(context: any) {
    return `${DalConfiguration.url}/sectors/${this.sector}/profiles/${this.profileType}/${this.projectType}`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url}/sectors/${this.sector}/profiles/${context.profileId}`;
  }

  _extractElementId(profile: ProfileData) {
    return profile.id;
  }

  setSectorApiId(sectorApiId: string) {
    this.sector = sectorApiId;
  }

  setProjectType(type: string) {
    this.projectType = type;
  }

  setProfileType(type: string) {
    this.profileType = type;
  }

  async update(profile: ProfileData) {
    await this._updateElement({
      profileId: profile.id
    }, profile);
  }

  load(profileId: number) {
    return this._loadElement({
      profileId: profileId
    });
  }

  loadAll() {
    return this._loadElements({
    });
  }

  clearAndReloadAll() {
    return this._clearAndReloadElements({
    });
  }

  listen(profileId: number) {
    return this._listenElement(profileId);
  }

  listenAll() {
    return this._listenElements('global', [4]);
  }
}
