<app-project-view></app-project-view>
<app-data-selection></app-data-selection>

<mat-card class="page-container">
  <section class="content-container">

    <div class="overview">
      <div *ngIf="selection.hasValue()">
        <div class="nb-selected">
          <span>{{selection.selected.length}}&nbsp;</span>
          <span *ngIf="selection.selected.length == 1">véhicule sélectionné</span>
          <span *ngIf="selection.selected.length > 1">véhicules sélectionnés</span>
        </div>
        <button *ngIf="selection.selected.length >= 1 && areSelectedTransportSomeSelected()"
                class="important-button exclude-button" (click)="modifyCurrentSelectionStatus(Status.EXCLUDED)">
          Exclure de la planification
          <img src="assets/images/cross.svg">
        </button>
        <button *ngIf="selection.selected.length >= 1 && areSelectedTransportSomeExcluded()"
                class="important-button exclude-button" (click)="modifyCurrentSelectionStatus(Status.SELECTED)">
          Inclure dans planification
          <img src="assets/images/plus.svg">
        </button>
        <button *ngIf="selection.selected.length >= 1"
          class="first-type-button exclude-button" (click)="openEditDepotModal()">
          Modifier le bureau logistique
          <img src="assets/images/edit.svg">
        </button>
      </div>
      <div *ngIf="selection.isEmpty()">
        <span>{{nbSelected}}&nbsp;</span>
        <span>véhicule</span>
        <span *ngIf="nbSelected > 1">s</span>
        <span>&nbsp;au total</span>
      </div>
    </div>

    <table mat-table class="table-container" [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        color="primary">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        color="primary">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="license-plate">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Plaque d'immatriculation</div>
            <app-filter-table-text [dataSource]="dataSource" [filteredValues]="filteredValues" [typeToFilter]="'license-plate'"></app-filter-table-text>
          </div>
        </th>
        <ng-container *matCellDef="let vehicle">
          <td mat-cell class="view-cel data-cell" [class.excluded]="vehicle.vehicle.status == Status.EXCLUDED">
            <div class="license-div">
              <div class="view-field">
                {{vehicle.vehicle.licensePlate}}
              </div>
              <div *ngIf="hasRequirements(vehicle.vehicle.requirementIds)" class="license-requirements tooltip-activator">
                <img src="assets/images/document.svg" />
                <span class="requirements-count">&nbsp;{{countRequirements(vehicle.vehicle.requirementIds)}}</span>
                <div class="tooltip">
                  <span>Exigences : </span>
                  <ul>
                    <li *ngFor="let requirement of vehicle.vehicle.requirementIds.trim().split(';')">
                      {{getRequirement(requirement)}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Type</div>
            <app-filter-table-enum [dataSource]="dataSource" [filteredValues]="filteredValues"
                                   [typeToFilter]="'type'" [elements]="['Taxi', 'VSL', 'AMBU']">
            </app-filter-table-enum>
          </div>
        </th>
        <ng-container *matCellDef="let vehicle">
          <td mat-cell class="view-cell data-cell" [class.excluded]="vehicle.vehicle.status == Status.EXCLUDED">
            <div class="view-field">{{toStringService.vehicleType(vehicle.vehicle)}}</div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="depot">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Depot</div>
            <app-filter-table-text [dataSource]="dataSource" [filteredValues]="filteredValues" [typeToFilter]="'depot'"></app-filter-table-text>
          </div>
        </th>
        <ng-container *matCellDef="let vehicle">
          <td mat-cell class="view-cel data-cell" [class.excluded]="vehicle.vehicle.status == Status.EXCLUDED">
            <div class="view-field" *ngIf="vehicle.depot?.name">
              {{ vehicle.depot.name }}
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Statut</div>
            <app-filter-table-enum [dataSource]="dataSource" [filteredValues]="filteredValues"
                                   [typeToFilter]="'status'" [elements]="['Inclu', 'Exclu']">
            </app-filter-table-enum>
          </div>
        </th>
        <ng-container *matCellDef="let vehicle">
          <td mat-cell class="view-cell data-cell" [class.excluded]="vehicle.vehicle.status == Status.EXCLUDED">
            <div class="view-field">
              {{getStatusString(vehicle.vehicle.status)}}
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="start">
        <th mat-header-cell class="solution-cell" mat-sort-header *matHeaderCellDef>Début</th>
        <ng-container *matCellDef="let vehicle">
        <td mat-cell class="view-cell solution-cell" [class.excluded]="vehicle.vehicle.status == Status.EXCLUDED">
            <hr *ngIf="!hasSolution(vehicle)">
            <div class="view-field" *ngIf="hasSolution(vehicle)">
              {{getNonNullPipeDateString(vehicle.routeSolWithMeasures?.routeMeasures?.start, "HH:mm:ss")}}
            </div>
        </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="duration">
        <th mat-header-cell class="solution-cell" mat-sort-header *matHeaderCellDef>Durée</th>
        <ng-container *matCellDef="let vehicle">
        <td mat-cell class="view-cell solution-cell" [class.excluded]="vehicle.vehicle.status == Status.EXCLUDED">
          <hr *ngIf="!hasSolution(vehicle)">
          <div class="view-field" *ngIf="hasSolution(vehicle)">
            {{vehicle.routeSolWithMeasures?.routeMeasures?.totalDuration | duration}}
          </div>
        </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr [class.footer-no-data]="noData()" *matNoDataRow>
        <td class="mat-cell" [colSpan]="displayedColumns.length">
          <app-no-data-card *ngIf="noData()"></app-no-data-card>
        </td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[50, 100, 500, 1000]"
                   [pageSize]="1000"
                   showFirstLastButtons>
    </mat-paginator>

  </section>
</mat-card>

