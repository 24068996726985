import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { BaseAuthorizationService } from '../authorization.service';
import { BaseProjectService } from '../base-project.service';
import { CurrentProjectService } from '../current-project.service';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';
import { ProjectData, UserData, UserProjectAuthorization, UserProjectAuthorizationWithLinks } from '../data-definitions';
import { DataMerger } from '../data-merger';
import {confirmDelete, defaultDialogConfig} from '../dialog-data';
import * as formUtils from '../form-utils';
import { MessageService } from '../message.service';
import { BaseUserService } from '../user.service';

@Component({
  selector: 'app-admin-authorization',
  templateUrl: './admin-authorization.component.html',
  styleUrls: ['./admin-authorization.component.css',
              '../../assets/tables.css',
              '../../assets/buttons.css']
})
export class AdminAuthorizationComponent implements OnInit, OnDestroy, AfterViewInit  {
  dataMerger: DataMerger<UserProjectAuthorization, UserProjectAuthorizationWithLinks>;
  subscriptions: Subscription = new Subscription();

  users: UserData[] = [];
  projects: ProjectData[] = [];

  dataSource = new MatTableDataSource(<UserProjectAuthorizationWithLinks[]>[]);
  displayedColumns = ['user', 'project', 'buttons'];

  projectIdToName = new Map<number, string>();
  authorizationToAdd?: UserProjectAuthorization;

  @ViewChild(MatSort) sort = new MatSort();

  constructor(private authorizationService: BaseAuthorizationService,
              private userService: BaseUserService,
              private baseProjectService: BaseProjectService,
              private currentProjectService: CurrentProjectService,
              private messageService: MessageService,
              public dialog: MatDialog) {
    this.dataMerger = new DataMerger("userProjectAuthorization", this.authorizationService.listenAll());
    this.dataMerger.addSubMapper("user", this.userService.listenAll(), upa => upa.userId);
    this.dataMerger.addSubMapper("project", this.baseProjectService.listenAll(), upa => upa.projectId);
  }

  ngOnInit(): void {
    this.dataMerger.listen().subscribe(upa => {
      this.dataSource.data = upa;
    });
    this.subscriptions.add(this.userService.listenAll().subscribe(users => {
      this.users = users;
    }));
    this.subscriptions.add(this.baseProjectService.listenAll().subscribe(projects => {
      this.projects = projects;
    }));
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch(property) {
        case 'user': return item.user.name.toLowerCase();
        case 'project': return item.project.name.toLowerCase();
        default: return 0;
      }
    };
    this.loadData();
  }

  ngOnDestroy() {
    this.dataMerger.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  isAdding(): boolean {
    return this.authorizationToAdd !== undefined;
  }

  async loadData() {
    try {
      await Promise.all([this.authorizationService.reloadAll(), this.userService.loadAll(), this.baseProjectService.loadAll()]);
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  async deleteAuthorization(authorization: UserProjectAuthorizationWithLinks) {
    let authorizationName = "l'autorisation de " + authorization.user.name + " pour le projet " + authorization.project.name;
    let dialogConfig = defaultDialogConfig();
    const dialogRef = this.dialog.open(CustomDialogComponent, { ...dialogConfig, data: confirmDelete(authorizationName)});
    try {
      let result = await dialogRef.afterClosed().toPromise();
      if (result && result.returnValue) {
        await this.authorizationService.remove(authorization.userProjectAuthorization);
      }
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  addAuthorization() {
    this.authorizationToAdd = {
      id: -1,
      userId: this.users.length > 0 ? this.users[0].id : -1,
      projectId: this.projects.length > 0 ? this.projects[0].id : -1,
      environmentId: this.currentProjectService.getEnvironmentId() ! -1
    };
  }

  async saveAdd() {
    if (this.authorizationToAdd !== undefined) {
      try {
        await this.authorizationService.add(this.authorizationToAdd);
        this.endAdd();
      } catch(error) {
        if (error instanceof HttpErrorResponse) {
          this.messageService.addHttpError(error);
        } else {
          this.messageService.addErrorMessage("Unknown error");
        }
      }
    } else {
      this.endAdd();
    }
  }

  endAdd() {
    this.authorizationToAdd = undefined;
  }

  canAdd() {
    if (!this.authorizationToAdd) return false;
    return this.dataSource.data.findIndex(upa => upa.userProjectAuthorization.projectId == this.authorizationToAdd!.projectId
        && upa.userProjectAuthorization.userId == this.authorizationToAdd!.userId) == -1;
  }

  getRequiredErrorMessage(): string {
    return formUtils.getRequiredErrorMessage();
  }

}
