import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BaseService, DalConfiguration } from './base-service';
import { CurrentProjectService } from './current-project.service';
import { Measures } from './data-definitions';
import { HttpDalService } from './http-dal.service';

@Injectable({
  providedIn: 'root'
})
export class BaseMeasuresService extends BaseService<Measures> {
  constructor(
      http: HttpDalService,
      private currentProjectService: CurrentProjectService) {
    super(http);
  }

  _extractParentUrl(_: any): string {
    throw "None";
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/measures`;
  }

  _extractElementId(measures: Measures) {
    return measures.projectId;
  }

  load() {
    return this._loadElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  reload() {
    return this._reloadElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  listen() {
    return this.currentProjectService.findProjectId().pipe(
      mergeMap(projectId => projectId === undefined ? EMPTY : this._listenElement(projectId))
    );
  }
}
