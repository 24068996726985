import {Injectable} from "@angular/core";
import {EmployeeData, EventhubEmployeeData, VehicleData} from "./data-definitions";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {DateService} from "./date.service";
import {MessageService} from "./message.service";
import {environment} from "../environments/environment";

export interface EventHubData {
  vehicles: VehicleData[]
  employees: EmployeeData[]
}

@Injectable({
  providedIn: 'root'
})
export class EventHubDataService {

  static nbHistoricalDays = 14;

  constructor(private http: HttpClient,
              private dateService: DateService,
              private messageService: MessageService) {
  }

  async loadData(sectorApiId: string) {
    let eventHubData: EventHubData = {
      vehicles: [],
      employees: []
    }
    let parentUrl = `${environment.apiUrl}/sectors/${sectorApiId}`;
    try {
      let vehicleResponse = this.http.get<VehicleData[]>(parentUrl + "/vehicles",
        {
          withCredentials: true,
          params: {
            start: this.dateService.extractDateStrFromDate(this.startDate()),
            end: this.dateService.extractDateStrFromDate(this.endDate())
          }
        }).toPromise();
      let employeeResponse = this.http.get<EmployeeData[]>(parentUrl + "/employees",
        {
          withCredentials: true,
          params: {
            start: this.dateService.extractDateStrFromDate(this.startDate()),
            end: this.dateService.extractDateStrFromDate(this.endDate())
          }
        }).toPromise();
      eventHubData.vehicles = await vehicleResponse;
      eventHubData.employees = await employeeResponse;
      return eventHubData;
    } catch (error) {
      if (error instanceof HttpErrorResponse)
        this.messageService.addHttpError(error);
    }
    return eventHubData
  }

  async loadEmployees(sectorApiId: string) {
    let employees: EventhubEmployeeData[] = [];
    let parentUrl = `${environment.apiUrl}/sectors/${sectorApiId}`;
    try {
      let employeeResponse = this.http.get<EventhubEmployeeData[]>(parentUrl + "/employees",
        {
          withCredentials: true,
          params: {
            start: this.dateService.extractDateStrFromDate(this.startDate()),
            end: this.dateService.extractDateStrFromDate(this.endDate())
          }
        }).toPromise();
      return await employeeResponse;
    } catch (error) {
      if (error instanceof HttpErrorResponse)
        this.messageService.addHttpError(error);
    }
    return employees;
  }

  startDate() {
    let start = new Date(Date.now());
    start.setDate(start.getDate() - EventHubDataService.nbHistoricalDays)
    return start;
  }

  endDate() {
    return new Date(Date.now());
  }
}
