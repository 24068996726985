import {Injectable} from "@angular/core";
import {Version} from "./data-definitions";
import {DalConfiguration} from "./base-service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";
import packageJson from '../../package.json';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  private versionSubject: BehaviorSubject<string> = new BehaviorSubject("");

  constructor(private http: HttpClient) {
    this.http.get<Version>(`${DalConfiguration.url }/version`, this.httpOptions).subscribe(v => {
      this.versionSubject.next(v.version);
    });
  }

  backEndVersion() {
    return this.versionSubject.asObservable();
  }

  frontEndVersion() {
    return packageJson.version;
  }
}
