import {Component} from '@angular/core';

@Component({
  selector: 'app-traffic-view',
  templateUrl: './traffic-view.component.html',
  styleUrls: ['./traffic-view.component.css']
})
export class TrafficViewComponent {

  constructor() { }
}
