import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  allColors = ["#D32F2F", "#303F9F", "#00796B", "#689F38", "#7B1FA2", "#5D4037",
      "#FBC02D", "#4FC3F7", "#F06292", "#607D8B", "#26C6DA", "#C51162"];

  constructor() { }

  getColor(index: number): string {
    return this.allColors[index % this.allColors.length];
  }
}
