import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs'
import {ErrorData, InternalError} from './data-definitions';

@Injectable({
  providedIn: 'root',
})
export class MessageService {

  errorMessage = new BehaviorSubject<InternalError | undefined>(undefined);
  messages: string[] = [];
  nbListeningToError = new BehaviorSubject<number>(0);

  constructor() {}

  getErrorMessage(): Observable<InternalError | undefined> {
    return this.errorMessage.asObservable();
  }

  getNbListeningToError(): Observable<number> {
    return this.nbListeningToError.asObservable();
  }

  add(message: string) {
    this.messages.push(message);
  }

  clear() {
    this.messages = [];
  }

  addHttpError(error: HttpErrorResponse) {
    this.addError(error.error);
  }

  addError(error: InternalError) {
    this.errorMessage.next(error);
    if (error.message) this.add(error.message);
  }

  addErrorMessage(errorMessage: string) {
    let error: InternalError = {
      message: errorMessage,
      type: "",
      cause: "",
      trace: "",
      innerTrace: "",
      operation: "",
    }
    this.errorMessage.next(error);
    if (error.message) this.add(error.message);
  }

  clearError() {
    this.errorMessage.next(undefined);
  }

  startListeningToError() {
    this.nbListeningToError.next(this.nbListeningToError.getValue() + 1)
  }

  stopListeningToError() {
    this.nbListeningToError.next(this.nbListeningToError.getValue() - 1)
  }
}
