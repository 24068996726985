import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BaseService, DalConfiguration } from './base-service';
import { CurrentProjectService } from './current-project.service';
import { AssociationRuleData } from './data-definitions';
import { HttpDalService } from './http-dal.service';

@Injectable({
  providedIn: 'root'
})
export class BaseAssociationRuleService extends BaseService<AssociationRuleData> {
  constructor(
    http: HttpDalService,
    private currentProjectService: CurrentProjectService) {
    super(http);

    this._addStoreDefinition(
      'by-project-id',
      associationRule => [associationRule.projectId]
    );
  }

  _extractParentUrl(context: any): string {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/association-rules`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/association-rules/${context.associationRuleId}`;
  }

  _extractElementId(associationRule: AssociationRuleData) {
    return associationRule.id;
  }

  async add(associationRule: AssociationRuleData) {
    associationRule.projectId = this.currentProjectService.getProjectId()!;
    return this._addElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    }, associationRule);
  }

  async addAll(associationRules: AssociationRuleData[]) {
    let projectId = this.currentProjectService.getProjectId()!;
    for (let associationRule of associationRules) associationRule.projectId = projectId;
    return this._addElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    }, associationRules);
  }

  async update(associationRule: AssociationRuleData) {
    await this._updateElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      associationRuleId: associationRule.id
    }, associationRule);
  }

  async remove(associationRule: AssociationRuleData) {
    await this._removeElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      associationRuleId: associationRule.id
    }, associationRule);
  }

  async removeAll(associationRules: AssociationRuleData[]) {
    await this._removeElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
    }, associationRules);
  }

  load(associationRuleId: number) {
    return this._loadElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      associationRuleId: associationRuleId
    });
  }

  loadAll() {
    return this._loadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  clearAndReloadAll() {
    return this._clearAndReloadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  listen(associationRuleId: number) {
    return this._listenElement(associationRuleId);
  }

  listenAll() {
    return this.currentProjectService.findProjectId().pipe(
      mergeMap(projectId => projectId === undefined ? EMPTY : this._listenElements('by-project-id', [projectId]))
    );
  }
}
