import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-filter-table-text',
  templateUrl: './filter-table-text.component.html',
  styleUrls: ['./filter-table-text.component.css',
              '../../assets/tables.css',
              '../../assets/buttons.css',
              '../../assets/filter.css']
})
export class FilterTableTextComponent implements OnInit {

  @Input() dataSource = new MatTableDataSource<any>();
  @Input() filteredValues: Map<string, string | string[]> = new Map();
  @Input() typeToFilter = "";

  filter = "";

  constructor() {
  }

  ngOnInit(): void {
  }

  applyFilter(event: Event) {
    this.filter = (event.target as HTMLInputElement).value;
    this.filteredValues.set(this.typeToFilter, this.filter.trim().toLowerCase());
    this.buildFilter();
  }

  suppressFilter() {
    this.filter = "";
    this.filteredValues.set(this.typeToFilter, "");
    this.buildFilter();
  }

  private buildFilter() {
    let datafilter = "";
    for(let value of this.filteredValues.values()) {
      if (typeof value == "string") {
        datafilter += value + ","
      } else {
        for(let element of value) {
          datafilter += element + ","
        }
      }
    }
    this.dataSource.filter = datafilter;
  }
}
