<app-project-view></app-project-view>
<app-data-selection></app-data-selection>

<mat-card class="page-container">
  <section class="content-container">

    <div class="overview">
      <span>{{dataSource.data.length}}&nbsp;</span>
      <span>bureau</span>
      <span *ngIf="dataSource.data.length > 1">x</span>
      <span>&nbsp;au total</span>
    </div>

    <table mat-table class="table-container" [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="name">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Nom</div>
            <app-filter-table-text [dataSource]="dataSource" [filteredValues]="filteredValues" [typeToFilter]="'name'"></app-filter-table-text>
          </div>
        </th>
        <ng-container *matCellDef="let info">
          <td mat-cell class="view-cell data-cell">
            <div class="view-field">{{info.depotName}}</div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell mat-sort-header class="data-cell" *matHeaderCellDef>Adresse</th>
        <td mat-cell class="view-cell data-cell" *matCellDef="let info">
          <div class="view-field">{{info.address + " " + info.zipcode + " " + info.city}}</div>
        </td>
        <td class="cell-button" mat-footer-cell *matFooterCellDef>
        </td>
      </ng-container>

      <ng-container matColumnDef="vehicle">
        <th mat-header-cell mat-sort-header class="data-cell" *matHeaderCellDef>
          <div class="tooltip-activator">
            <img src="assets/images/ambulance.svg">
            <div class="tooltip tooltip-custom tooltip-custom-1">
              Nombre de véhicules
            </div>
          </div>
        </th>
        <td mat-cell class="view-cell data-cell" *matCellDef="let info">
          <div class="view-field">{{info.nbVehicles}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="transport">
        <th mat-header-cell mat-sort-header class="data-cell" *matHeaderCellDef>
          <div class="tooltip-activator">
            <img src="assets/images/route.svg">
            <div class="tooltip tooltip-custom tooltip-custom-1">
              Nombre de missions
            </div>
          </div>
        </th>
        <td mat-cell class="view-cell data-cell" *matCellDef="let info">
          <div class="view-field">{{info.nbTransports}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="employee">
        <th mat-header-cell mat-sort-header class="data-cell" *matHeaderCellDef>
          <div class="tooltip-activator">
            <img src="assets/images/person.svg">
            <div class="tooltip tooltip-custom tooltip-custom-1">
              Nombre d'employés
            </div>
          </div>
        </th>
        <td mat-cell class="view-cell data-cell" *matCellDef="let info">
          <div class="view-field">{{info.nbEmployees}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="unassigned">
        <th mat-header-cell mat-sort-header class="data-cell" *matHeaderCellDef>
          <div class="tooltip-activator">
            <img src="assets/images/question.svg">
            <div class="tooltip tooltip-custom tooltip-custom-2">
              Nombre d'employés non assignés
            </div>
          </div>
          
          
        </th>
        <td mat-cell class="view-cell data-cell" *matCellDef="let info">
          <div class="view-field">{{info.nbUnassigned}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="territory">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Territoire</div>
            <app-filter-table-text [dataSource]="dataSource" [filteredValues]="filteredValues" [typeToFilter]="'territory'"></app-filter-table-text>
          </div>
        </th>
        <ng-container *matCellDef="let info">
          <td mat-cell class="view-cell data-cell">
            <div class="view-field">{{info.territoryName}}</div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="regulation">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Régulation</div>
            <app-filter-table-text [dataSource]="dataSource" [filteredValues]="filteredValues" [typeToFilter]="'regulation'"></app-filter-table-text>
          </div>
        </th>
        <ng-container *matCellDef="let info">
          <td mat-cell class="view-cell data-cell">
            <div class="view-field">{{info.regulationName}}</div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="sector">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Secteur</div>
            <app-filter-table-text [dataSource]="dataSource" [filteredValues]="filteredValues" [typeToFilter]="'sector'"></app-filter-table-text>
          </div>
        </th>
        <ng-container *matCellDef="let info">
          <td mat-cell class="view-cell data-cell">
            <div class="view-field">{{info.sectorName}}</div>
          </td>
        </ng-container>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr [class.footer-no-data]="noData()" *matNoDataRow>
        <td class="mat-cell" [colSpan]="displayedColumns.length">
          <span *ngIf="!noData()">Aucune donnée ne correspond au filtre</span>
          <app-no-data-card *ngIf="noData()"></app-no-data-card>
        </td>
      </tr>

    </table>

    <mat-paginator [pageSizeOptions]="[50, 100, 500, 1000]"
                   [pageSize]="1000"
                   showFirstLastButtons>
    </mat-paginator>

  </section>
</mat-card>
