import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataMerger} from "../data-merger";
import {
  CrewData,
  EmployeeData, EmployeeDataWithSolution,
  LocationData, ProjectData, RequirementData, RouteSolution, RouteSolutionWithMeasures, Status, TransportDataWithSolution
} from "../data-definitions";
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {CurrentProjectService} from "../current-project.service";
import {MessageService} from "../message.service";
import {DateService} from "../date.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {HttpErrorResponse} from "@angular/common/http";
import {BaseEmployeeService} from "../base-employee.service";
import {map} from "rxjs/operators";
import {BaseRouteSolutionService} from "../base-route-solution.service";
import {BaseMeasuresService} from "../base-measures.service";
import {BaseVehicleService} from "../base-vehicle.service";
import {MatPaginator} from "@angular/material/paginator";
import {BaseProjectService} from "../base-project.service";
import {SelectionModel} from "@angular/cdk/collections";
import {ToStringService} from "../to-string.service";
import {FilterService} from "../filter.service";
import {
  EmployeeModificationModalComponent
} from "../employee-modification-modal/employee-modification-modal.component";
import { BaseDepotService } from '../base-depot.service';
import { DepotModificationModalComponent } from '../depot-modification-modal/depot-modification-modal.component';
import { BaseRequirementService } from '../base-requirement.service';
import { BaseCrewService } from '../base-crew.service';

@Component({
  selector: 'app-crew-table',
  templateUrl: './crew-table.component.html',
  styleUrls: ['./crew-table.component.css',
    '../../assets/tables.css',
    '../../assets/standard-page.css',
    '../../assets/buttons.css',
    '../../assets/tooltip.css',
    '../../assets/select.css']
})
export class CrewTableComponent implements OnInit, OnDestroy, AfterViewInit {
  Status = Status;

  project?: ProjectData;
  requirements: RequirementData[] = [];

  subscriptions: Subscription = new Subscription();
  routeDataMerger: DataMerger<RouteSolution, RouteSolutionWithMeasures>;
  dataMerger: DataMerger<EmployeeData, EmployeeDataWithSolution>;

  locations: LocationData[] = [];

  dataSource = new MatTableDataSource(<EmployeeDataWithSolution[]>[]);
  deasPerDepot: Map<string, EmployeeData[]> = new Map();
  employeesPerDea: Map<number, EmployeeData[]> = new Map();
  employees: EmployeeData[] = [];

  selection = new SelectionModel<EmployeeDataWithSolution>(true, []);
  updatedTeams: Map<number, number> = new Map();
  existingTeams: Map<number, CrewData> = new Map();

  nbSelected = 0;

  displayedColumns = ['select', 'name', 'depot', 'depot-return', 'solution-service-start', 
    'solution-service-end', 'vehicle', 'crew'];

  @ViewChild(MatSort) sort = new MatSort();
  @ViewChild(MatPaginator) paginator?: MatPaginator;

  filteredValues: Map<string, any> = new Map([
    ["name", ""],
    ["depot-return", ""],
    ["crew", ""],
    ["vehicle", ""],
    ["depot", ""]
  ]);

  matDialogEmployeeRef?: MatDialogRef<EmployeeModificationModalComponent>;
  matDialogDepotRef?: MatDialogRef<DepotModificationModalComponent>;

  constructor(private baseEmployeeService: BaseEmployeeService,
              private baseRequirementService: BaseRequirementService,
              private baseVehicleService: BaseVehicleService,
              private baseDepotService: BaseDepotService,
              private baseCrewService: BaseCrewService,
              private currentProjectService: CurrentProjectService,
              private messageService: MessageService,
              private baseMeasuresService: BaseMeasuresService,
              private baseRouteService: BaseRouteSolutionService,
              private baseProjectService: BaseProjectService,
              private dateService: DateService,
              private filterService: FilterService,
              public toStringService: ToStringService,
              public dialog: MatDialog) {
    this.routeDataMerger = new DataMerger("routeSolution", this.baseRouteService.listenAll());
    this.routeDataMerger.addSubMapper("employee", baseEmployeeService.listenAll(), r => r.employeeId)
    this.routeDataMerger.addSubMapper("vehicle", baseVehicleService.listenAll(), r => r.vehicleId)
    this.routeDataMerger.addReverseMapper("routeMeasures",
      this.baseMeasuresService.listen().pipe(map(measures => measures!.routeMeasures)),
      r => r.routeId);

    this.dataMerger = new DataMerger("employee", this.baseEmployeeService.listenAll());
    this.dataMerger.addSubMapper("depot", this.baseDepotService.listenAll(), e => e.depotId);
    this.dataMerger.addReverseMapper("routeSolWithMeasures", this.routeDataMerger.listen(), route => route.routeSolution.employeeId);
  }

  ngOnInit(): void {
    this.dataMerger.listen().subscribe(employeeDataWithSolution => {
      this.dataSource.data = employeeDataWithSolution.filter(e => this.hasSolution(e));
      this.deasPerDepot = new Map();
      this.employeesPerDea = new Map();
      employeeDataWithSolution.forEach(e => {
        if (e.depot == undefined) return;
        this.employees.push(e.employee);
        if (this.hasSolution(e)) {
          if (!this.deasPerDepot.has(e.depot.apiId)) this.deasPerDepot.set(e.depot.apiId, []);
          this.deasPerDepot.get(e.depot.apiId)?.push(e.employee);
        }
      })
      employeeDataWithSolution.forEach(e => {
        if (e.depot == undefined) return;
        if (!this.hasSolution(e) && e.employee.status === Status.SELECTED) {
          this.deasPerDepot.get(e.depot.apiId)?.forEach(dea => {
            if (!this.employeesPerDea.has(dea.id)) this.employeesPerDea.set(dea.id, []);
            this.employeesPerDea.get(dea.id)?.push(e.employee);
          });
        }
      })
      if (this.existingTeams != undefined) this.filterSelect();
      this.nbSelected = this.dataSource.data.length;
    });
    this.subscriptions.add(this.baseProjectService.listen().subscribe(project => {
      this.project = project;
    }));
    this.subscriptions.add(this.baseCrewService.listenAll().subscribe(teams => {
      teams.forEach(team => {
        this.existingTeams.set(team.deaId, team);
      })
      if (this.employees != undefined && this.dataSource.data != undefined) {
        this.filterSelect();
      }
    }))
    this.dataSource.sortingDataAccessor = this.customSortingDataAccessor();
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.currentProjectService.findProjectId().subscribe(projectId => {
      if (projectId) this.loadData();
    });
    this.subscriptions.add(this.baseRequirementService.listenAll().subscribe(requirements => {
      this.requirements = requirements;
    }));
  }

  filterSelect() {
    // Remove already assigned employees to other select
    this.existingTeams.forEach(team => {
      const deaId = team.deaId;
      const depotApiId = this.dataSource.data.find(e => e.employee.id == deaId)!.depot.apiId;
      const deas = this.deasPerDepot.get(depotApiId);
      const employeeToRemove = this.employees.find(e => e.id == team.crewManId);
      deas?.forEach(dea => {
        if (dea.id != deaId) {
          this.employeesPerDea.set(dea.id, this.employeesPerDea.get(dea.id)!.filter(e => e != employeeToRemove));
        }
      })
    })
  }

  filterCrewFn(term: string, item: EmployeeData) {
    return item.firstName.toLocaleLowerCase().includes(term.toLocaleLowerCase()) 
           || item.lastName.toLocaleLowerCase().includes(term.toLocaleLowerCase());
  }

  getEmployeesInDepot(deaId: number) : EmployeeData[] {
    if (!this.employeesPerDea.has(deaId)) {
        return [];
    } else {
      return this.employeesPerDea.get(deaId)!;
    }
  }

  getTeam(deaId: number | undefined) : EmployeeData | undefined {
    if (deaId == undefined) return undefined;
    if(this.existingTeams.has(deaId)) {
      return this.employees.find(e => e.id == this.existingTeams.get(deaId)!.crewManId);
    }
    return undefined;
  }


  updateTeam($event: any, dea: EmployeeDataWithSolution) {
    let employeeToAdd: EmployeeData | undefined;
    let employeeToRemove: EmployeeData | undefined;

    if (this.updatedTeams.has(dea.employee.id)) employeeToAdd = this.employees.find(e => e.id == this.updatedTeams.get(dea.employee.id));
    else if (this.existingTeams.has(dea.employee.id)) employeeToAdd = this.employees.find(e => e.id == this.existingTeams.get(dea.employee.id)?.crewManId);

    if ($event == undefined) {
      this.updatedTeams.delete(dea.employee.id);
      if (this.existingTeams.has(dea.employee.id)) {
        this.selection.select(dea);
      } else {
        this.selection.deselect(dea);
      }
    } else {
      employeeToRemove = $event;
      this.updatedTeams.set(dea.employee.id, $event.id);;
      this.selection.select(dea);
    }


    if (employeeToAdd != undefined) {
      const deasInDepot = this.deasPerDepot.get(dea.depot.apiId);
      deasInDepot?.forEach(deaInDepot => {
          if (deaInDepot.id != dea.employee.id) {
            const existingEmployees = this.employeesPerDea.get(deaInDepot.id)!;
            existingEmployees.push(employeeToAdd!);
            this.employeesPerDea.set(deaInDepot.id, [...existingEmployees]);
          }
      })
    }
    if (employeeToRemove != undefined) {
      const deasInDepot = this.deasPerDepot.get(dea.depot.apiId);
      deasInDepot?.forEach(deaInDepot => {
        if (deaInDepot.id != dea.employee.id) {
          const filteredEmployees = this.employeesPerDea.get(deaInDepot.id)!.filter(e => e.id != employeeToRemove!.id);
          this.employeesPerDea.set(deaInDepot.id, [...filteredEmployees]);
        }
      })
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.filteredData);
  }

  ngOnDestroy() {
    this.dataMerger.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  hasRequirements(requirementsIds: any) {
    if (requirementsIds.trim().length == 0) return false;
    return true;
  }

  countRequirements(requirementsIds: string) {
    return requirementsIds.split(";").length;
  }

  getRequirement(requirementId: number) {
    const requirement = this.requirements.find(r => r.apiId == requirementId);
    return requirement?.name;
  }

  customSortingDataAccessor() {
    return (item: EmployeeDataWithSolution, property: string) => {
      switch(property) {
        case 'name': return item.employee.lastName.toLowerCase();
        case 'diploma': return item.employee.diploma.trim().toLowerCase();
        case 'depot': return item.depot.name.trim().toLowerCase();
        case 'assignment': return this.toStringService.employeeAssignment(item.employee)
        case 'depot-return': return item.employee.planDepotReturn ? "Oui" : "Non;"
        case 'employee-service-first-pickup': return item.employee.serviceFirstPickup ?
          this.dateService.timeStringToDate(item.employee.serviceFirstPickup).getTime() : 0
        case 'employee-service-last-dropoff': return item.employee.serviceLastDropoff ?
          this.dateService.timeStringToDate(item.employee.serviceLastDropoff).getTime() : 0
        case 'status': return item.employee.status
        case 'solution-service-start': return item.routeSolWithMeasures?.routeMeasures?.start.toString() ?? "ZZZ";
        case 'solution-service-end': return item.routeSolWithMeasures?.routeMeasures?.end.toString() ?? "ZZZ";
        case 'vehicle': return item.routeSolWithMeasures?.vehicle?.licensePlate ?? "ZZZ"  ;
        default: return "";
      }
    };
  }

  filterExigencyMatchFunction(data: EmployeeDataWithSolution, checked: string[]) {
    let exigencyAssociator: ((item: EmployeeDataWithSolution, key: string) => boolean)
      = (item: EmployeeDataWithSolution, key: string) => {
      switch (key) {
        case "Oui": return item.employee.planDepotReturn;
        case "Non": return !item.employee.planDepotReturn;
        default: return false;
      }
    }

    for (let element of checked) {
      if (exigencyAssociator(data, element)) return true;
    }
    return false;
  }

  filterCrewMatchFunction(data: EmployeeDataWithSolution, checked: string[]) {
    let exigencyAssociator: ((item: EmployeeDataWithSolution, key: string) => boolean)
      = (item: EmployeeDataWithSolution, key: string) => {
      switch (key) {
        case "Incomplet": return !this.updatedTeams.has(item.employee.id) || this.updatedTeams.get(item.employee.id) == undefined;
        case "Complet": return this.updatedTeams.has(item.employee.id);
        default: return false;
      }
    }

    for (let element of checked) {
      if (exigencyAssociator(data, element)) return true;
    }
    return false;
  }

  customFilterPredicate() {
    return (data: EmployeeDataWithSolution, _: string): boolean => {
      let nameCondition = this.filteredValues.get("name")
        ? this.toStringService.employeeName(data.employee).trim().toLowerCase()
          .indexOf(this.filteredValues.get("name")!.toLowerCase()) !== -1
        : true;
      let depotCondition = this.filteredValues.get("depot")
        ? data.depot.name.trim().toLowerCase()
          .indexOf(this.filteredValues.get("depot")!.toLowerCase()) !== -1
        : true;
      let assignmentCondition = this.filteredValues.get("assignment")
        ? this.filterService.checkFilterMatch(data.employee, this.filteredValues.get("assignment"), this.filterService.assignmentFilter)
        :true;
      let planDepotCondition = this.filteredValues.get("depot-return") ?
        this.filterExigencyMatchFunction(data, this.filteredValues.get("depot-return"))
        : true;
      let vehicleCondition = this.filteredValues.get("vehicle")
        ? data.routeSolWithMeasures?.vehicle !== undefined && (data.routeSolWithMeasures!.vehicle!.licensePlate.trim().toLowerCase()
            .indexOf(this.filteredValues.get("vehicle")!.toLowerCase()) !== -1)
        : true;
      let crewCondition = this.filteredValues.get("crew") ?
        this.filterCrewMatchFunction(data, this.filteredValues.get("crew")) : true;
      return nameCondition && depotCondition && assignmentCondition && planDepotCondition && crewCondition && vehicleCondition;
    };
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator!;
  }

  async loadData() {
    try {
      await Promise.all([this.baseEmployeeService.loadAll(), this.baseVehicleService.loadAll(),
        this.baseRouteService.loadAll(), this.baseMeasuresService.load(), this.baseDepotService.loadAll(),
        this.baseRequirementService.loadAll(), this.baseCrewService.loadAll()]);
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  async modifyCrew() {
    let selectedDea = this.selection.selected;
    let crewsToUpdate: CrewData[] = [];
    let crewsToCreate: CrewData[] = [];
    let crewsToDelete: CrewData[] = [];
    selectedDea.forEach(dea => {
      if (this.existingTeams.has(dea.employee.id)) {
        let crew: CrewData = this.existingTeams.get(dea.employee.id)!;
        if (!this.updatedTeams.has(dea.employee.id)) {
          crewsToDelete.push(crew);
        } else {
          crew.crewManId = this.updatedTeams.get(dea.employee.id)!;
          crewsToUpdate.push(crew);
        }
      } else if (this.updatedTeams.has(dea.employee.id)) {
        let crew: CrewData = {
          id: 0,
          deaId: dea.employee.id,
          crewManId: this.updatedTeams.get(dea.employee.id)!,
          projectId: this.project!.id
        }
        crewsToCreate.push(crew);
      }
    })
    if (crewsToUpdate.length > 0) {
      let promiseUpdate = this.baseCrewService.updateMultiple(crewsToUpdate);
      await promiseUpdate;
    }
    if (crewsToDelete.length > 0) {
      let promiseDelete = this.baseCrewService.deleteMultiple(crewsToDelete);
      await promiseDelete;
      crewsToDelete.forEach(crew => this.existingTeams.delete(crew.deaId));
    }

    if (crewsToCreate.length > 0) {
      let promiseCreate = this.baseCrewService.addMultiple(crewsToCreate);
      await promiseCreate;
    }
    this.selection.clear();
  }

  getNonNullPipeDateString(date: Date | string, format: string) {
    return this.dateService.getNonNullPipeDateString(date, format);
  }

  noData(): boolean {
    return this.dataSource.data.length == 0;
  }

  isLate(employee: EmployeeDataWithSolution): boolean {
    if (!employee.routeSolWithMeasures?.routeMeasures?.employeeServiceLateness) return false;
    return employee.routeSolWithMeasures.routeMeasures?.employeeServiceLateness > 0;
  }

  hasSolution(employee: EmployeeDataWithSolution): boolean {
    return employee.routeSolWithMeasures !== undefined;
  }

}
