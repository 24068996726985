import { Injectable } from '@angular/core';
import { BaseService, DalConfiguration } from './base-service';
import { Parameter} from './parameter-configuration';
import { HttpDalService } from './http-dal.service';

@Injectable({
  providedIn: 'root'
})
export class BaseDefaultParameterService extends BaseService<Parameter> {

  profileId = 0;

  constructor(
    http: HttpDalService) {
    super(http);

    this._addStoreDefinition(
      'global',
      parameter => [2]
    );
  }

  _extractParentUrl(context: any) {
    return `${DalConfiguration.url}/default-parameters/profile/${this.profileId}`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url}/default-parameters/${context.parameterId}`;
  }

  _extractElementId(parameter: Parameter) {
    return parameter.id;
  }

  setProfileId(id: number) {
    this.profileId = id;
  }

  async update(parameter: Parameter) {
    await this._updateElement({
      parameterId: parameter.id
    }, parameter);
  }

  load(parameterId: number) {
    return this._loadElement({
      parameterId: parameterId
    });
  }

  loadAll() {
    return this._loadElements({
    });
  }

  clearAndReloadAll() {
    return this._clearAndReloadElements({
    });
  }

  listen(parameterId: number) {
    return this._listenElement(parameterId);
  }

  listenAll() {
    return this._listenElements('global', [2]);
  }
}
