<div class="container-first-type light-color"
     [ngClass]="transport !== undefined && transport!.status == Status.EXCLUDED ? 'disabled-color' : 'light-color'">
  <span>{{patientName}}</span>
  <span>-</span>
  <img *ngIf="transport!.timeWindowType == 'origin'" src="assets/images/ambulance-aller.svg">
  <img *ngIf="transport!.timeWindowType == 'destination'" src="assets/images/ambulance-retour.svg">
  <span>{{constraintHour}}</span>
  <button (click)="deleteRule()">
    <img src="assets/images/cross.svg">
  </button>
</div>
