<div class="container"
  [ngClass]="{'container-default': profile != undefined,
              'container-normal': profile == undefined}"
>
  <div class="parameter-settings">
    <div class="parameter-main-categories">
      <div *ngFor="let parameterCategory of parametersConfiguration"
           [class.enabled]="parameterCategory == selectedCategory"
           (click)="selectedCategory = parameterCategory">
        {{parameterCategory.title}}
      </div>
    </div>
    <div *ngIf="selectedCategory !== undefined" class="parameter-values">
      <div class="parameter-sub-category" *ngFor="let subCategory of selectedCategory.subCategories">
        <div class="title">{{subCategory.title}}</div>
        <div *ngFor="let parameter of subCategory.parameters">
          <div class="parameter-container"
               [ngClass]="{'first': parameter == subCategory.parameters[0],
                           'middle': parameter != subCategory.parameters[0]
                                     && parameter != subCategory.parameters[subCategory.parameters.length - 1],
                           'last': parameter == subCategory.parameters[subCategory.parameters.length - 1] }">

            <div *ngIf="!isEnumParameter(parameter)">
              <div *ngVar="castAsParameterEntry(parameter); let parameterEntry" class="parameter-box">
                <label *ngIf="parameterEntry.valueType != ParameterValueType.DURATION_WITH_STRING">{{parameterEntry.name}}</label>
                <label *ngIf="parameterEntry.valueType == ParameterValueType.DURATION_WITH_STRING">{{parameterEntry.name + " " + getDelayValue(parameterEntry)}}</label>
                <input *ngIf="parameterEntry.valueType == ParameterValueType.DOUBLE || parameterEntry.valueType == ParameterValueType.INTEGER"
                       type="number" [step]="parameterEntry.valueType == ParameterValueType.DOUBLE ? 0.01 : 1"
                       [disabled]="this.pendingParameterMap === undefined"
                       [ngModel]="getParameterValue(parameterEntry)"
                       (ngModelChange)="setParameterValue(parameterEntry, $event)"
                       [ngModelOptions]="{ updateOn: 'blur' }"
                />
                <input *ngIf="parameterEntry.valueType == ParameterValueType.DURATION || parameterEntry.valueType == ParameterValueType.DURATION_WITH_STRING" type="text"
                       pattern="[0-9]{2}:[0-9]{2}"
                       [disabled]="this.pendingParameterMap === undefined"
                       [ngModel]="getParameterValue(parameterEntry)"
                       (ngModelChange)="setParameterValue(parameterEntry, $event)"
                       [ngModelOptions]="{ updateOn: 'blur' }"
                />
                <input *ngIf="parameterEntry.valueType == ParameterValueType.BOOLEAN" type="checkbox"
                       [disabled]="this.pendingParameterMap === undefined"
                       [ngModel]="getParameterValue(parameterEntry)"
                       (ngModelChange)="setParameterValue(parameterEntry, $event)"
                       [ngModelOptions]="{ updateOn: 'blur' }"
                />
                <span>{{parameterEntry.unit}}</span>
              </div>
            </div>

            <div *ngIf="isEnumParameter(parameter)">
              <div *ngVar="castAsParameterEnum(parameter); let parameterEnum" class="parameter-box">
                <label>{{parameterEnum.name}}</label>
                <ng-select class="required"
                           [ngModel]="getParameterEnumValue(parameterEnum)"
                           (ngModelChange)="setParameterValue(parameter, $event)"
                           [clearable]="false" [searchable]="false" >
                  <ng-option *ngFor="let parameterEnumPossibility of parameterEnum.possibilities" [value]="parameterEnumPossibility.value">
                    {{parameterEnumPossibility.title}}
                  </ng-option>
                </ng-select>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="action-buttons">
    <button class="rectangular-button first-type-button" (click)="revertChanges()">Annuler les modifications</button>
    <button class="rectangular-button important-button" (click)="applyChanges()">Enregistrer</button>
  </div>
</div>
