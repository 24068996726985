import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-filter-table-enum',
  templateUrl: './filter-table-enum.component.html',
  styleUrls: ['./filter-table-enum.component.css',
              '../../assets/tables.css',
              '../../assets/buttons.css',
              '../../assets/filter.css']
})
export class FilterTableEnumComponent implements OnInit {

  @Input() dataSource = new MatTableDataSource<any>();
  @Input() filteredValues: Map<string, string | string[]> = new Map();
  @Input() typeToFilter = "";
  @Input() elements: string[] = [];
  checkedElements: Set<string> = new Set<string>()

  constructor() { }

  ngOnInit(): void {
    this.elements.forEach(e => this.checkedElements.add(e));
  }

  changeFunction(element: string) {
    if (this.checkedElements.has(element)) {
      this.checkedElements.delete(element)
    } else {
      this.checkedElements.add(element)
    }
    this.applyFilter()
  }

  applyFilter() {
    this.filteredValues.set(this.typeToFilter, Array.from(this.checkedElements.values()));
    this.buildFilter();
  }

  suppressFilter() {
    this.elements.forEach(e => this.checkedElements.add(e));
    this.filteredValues.set(this.typeToFilter, [...this.elements]);
    this.buildFilter();
  }

  hasUnchecked() {
    return this.checkedElements.size < this.elements.length;
  }

  private buildFilter() {
    let datafilter = "";
    for(let value of this.filteredValues.values()) {
      if (typeof value == "string") {
        datafilter += value + ","
      } else {
        for(let element of value) {
          datafilter += element + ","
        }
      }
    }
    this.dataSource.filter = datafilter;
  }
}
