<button class="rounded-button first-type-button close-button" (click)="close()">
  <img src="assets/images/cross.svg">
</button>

<div class="container">
  <h1>Créer un nouvel équipage</h1>

  <div class="input__inner">
    <ng-select class="dea required"
               [(ngModel)]="dea"
               placeholder="Employé"
               [clearable]="false"
               appendTo="body">
      <ng-option *ngFor="let possibleEmployee of deas" [value]="possibleEmployee">
        {{toStringService.employeeName(possibleEmployee)}}
      </ng-option>
    </ng-select>
  </div>

  <div class="input__inner">
    <ng-select class="crewman required"
               [disabled]="dea === undefined"
               [(ngModel)]="crewMan"
               placeholder="Équipier"
               appendTo="body">
      <ng-option *ngFor="let possibleEmployee of getPossibleCrewMans()" [value]="possibleEmployee">
          {{ toStringService.employeeName(possibleEmployee) }} - {{possibleEmployee.diploma}}
      </ng-option>
    </ng-select>
  </div>

  <div class="button-container">
    <button class="rectangular-button first-type-button" (click)="close()">
      Annuler
    </button>
    <button *ngIf="edit === false"
            class="rectangular-button important-button create-button"
            (click)="createAssociationRule()"
            [disabled]="!isRuleComplete()">
      <div class="start-button-label">
        Créer
      </div>
      <img src="assets/images/arrow-right.svg">
    </button>
    <button *ngIf="edit === true"
            class="rectangular-button important-button create-button"
            (click)="updateAssociationRule()"
            [disabled]="!isRuleComplete()">
      <div class="start-button-label">
        Modifier
      </div>
      <img src="assets/images/arrow-right.svg">
    </button>
  </div>
</div>
