import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MapService} from "../map.service";
import {MapElement, MapElementType, MapRoute} from '../map-types'
import {RouteActionType} from "../data-definitions";
import {DateService} from "../date.service";
import {ToStringService} from "../to-string.service";

enum AggregatedActionType {
  MOVE_EMPTY,
  PICKUP,
  DELIVERY,
  MOVE,
  WAIT,
  REGULATORY_BREAK,
  MEAL_BREAK,
  SERVICE_START,
  SERVICE_END, 
  PARKING_ACCESS
};

interface AggregatedAction {
  start: Date,
  end: Date,
  type: AggregatedActionType,
  mapElement?: MapElement
};

@Component({
  selector: 'app-map-route-selection-element[route]',
  templateUrl: './map-route-selection-element.component.html',
  styleUrls: ['./map-route-selection-element.component.css']
})
export class MapRouteSelectionElementComponent implements OnInit, OnChanges, AfterViewChecked {
  AggregatedActionType = AggregatedActionType;

  @Input() route?: MapRoute; // required
  @Input() isSelected: boolean = false;
  @Input() wasPreviouslySelected: boolean = false;

  @ViewChild('container') container: ElementRef = new ElementRef(null);
  maxHeightUpdateRequest = false;

  vehicleString = "";
  vehicleCrew = "";
  nbMissions = 0;
  returnToDepot= false;

  hasViolation = false;

  selectedAction?: AggregatedAction;
  aggregatedActions: AggregatedAction[] = [];

  constructor(private mapService: MapService,
              private toStringService: ToStringService,
              private dateService: DateService) { }

  ngOnInit(): void {
    this.vehicleString = this.toStringService.vehicleName(this.route?.route?.vehicle)
    if (this.route?.route?.employee) {
      this.vehicleCrew = this.toStringService.employeeName(this.route?.route?.employee);
      this.returnToDepot = this.route?.route?.employee.planDepotReturn;
    }
    for (let mapElement of this.route!.mapElements) {
      let actions = mapElement.associatedActions;
      let start = new Date(Math.min(...actions.map(a => this.dateService.iso8601StringOrDateToDate(a.start).getTime())));
      let end = new Date(Math.max(...actions.map(a => this.dateService.iso8601StringOrDateToDate(a.end).getTime())));
      let type = this.extractAggregatedType(mapElement);
      let aggregatedAction: AggregatedAction = {
        start: start,
        end: end,
        type: type!,
        mapElement: mapElement
      };
      this.aggregatedActions.push(aggregatedAction);
    }
    this.nbMissions = this.aggregatedActions.filter(aa => aa.type == AggregatedActionType.PICKUP).length;
    if (this.route?.route?.routeMeasures) {
      let routeMeasure = this.route!.route!.routeMeasures;
      this.hasViolation ||= (routeMeasure!.returnLateness > 0);
      this.hasViolation ||= routeMeasure!.taskLateness > 0;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let selectedChange = changes["isSelected"];
    if (selectedChange) {
      let isUnselecting = selectedChange.previousValue && !selectedChange.currentValue;
      if (isUnselecting) {
        this.container.nativeElement.style.maxHeight = "82px";
      }
      else {
        this.maxHeightUpdateRequest = true;
      }
    }
  }

  ngAfterViewChecked() {
    if (!this.maxHeightUpdateRequest) return;
    this.container.nativeElement.style.maxHeight = this.container.nativeElement.scrollHeight + "px";
    this.maxHeightUpdateRequest = false;
  }

  ngOnDestroy() {
    this.unselectActions();
  }

  extractAggregatedType(mapElement: MapElement) {
    let actions = mapElement.associatedActions;
    if (mapElement.type == MapElementType.POINT) {
      console.assert(actions.length == 1);
      switch (actions[0].type) {
        case RouteActionType.REGULATORY_BREAK: return AggregatedActionType.REGULATORY_BREAK
        case RouteActionType.MEAL_BREAK: return AggregatedActionType.MEAL_BREAK
        case RouteActionType.WAIT: return AggregatedActionType.WAIT
        case RouteActionType.SERVICE_START: return AggregatedActionType.SERVICE_START
        case RouteActionType.SERVICE_END: return AggregatedActionType.SERVICE_END
        case RouteActionType.PICKUP: return AggregatedActionType.PICKUP
        case RouteActionType.DROPOFF: return AggregatedActionType.DELIVERY
        case RouteActionType.PARKING_ACCESS: return AggregatedActionType.PARKING_ACCESS
        default:
          console.assert(false)
          return AggregatedActionType.REGULATORY_BREAK;
      }
    } else {
      return actions[0].deadMileageMove ? AggregatedActionType.MOVE_EMPTY : AggregatedActionType.MOVE;
    }
  }

  onHeaderClick() {
    let currentSelectedRouteId = this.mapService.selectedRoute.value?.route.id;
    this.unselectActions();
    if (currentSelectedRouteId !== this.route?.route.id) {
      this.mapService.selectRoute(this.route);
    } else {
      this.mapService.unselectRoute();
    }
  }

  selectionAction(action: AggregatedAction) {
    if (!action.mapElement) return;
    this.mapService.selectElement(action.mapElement!);
    if (!this.selectedAction || this.selectedAction != action)
      this.selectedAction = action;
    else
      this.selectedAction = undefined;
  }

  unselectActions() {
    this.mapService.selectElement(undefined);
    this.selectedAction = undefined;
  }
}

