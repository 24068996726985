<app-project-view></app-project-view>
<app-data-selection></app-data-selection>

<mat-card class="page-container">
  <section class="content-container">

    <div class="overview">
      <div *ngIf="selection.hasValue()">
        <div class="nb-selected">
          <span>{{selection.selected.length}}&nbsp;</span>
          <span *ngIf="selection.selected.length == 1">mission sélectionnée</span>
          <span *ngIf="selection.selected.length > 1">missions sélectionnées</span>
        </div>
        <button *ngIf="selection.selected.length >= 1 && areSelectedTransportSomeSelected()"
                class="important-button exclude-button" (click)="modifyCurrentSelectionStatus(Status.EXCLUDED)">
          Exclure de la planification
          <img src="assets/images/cross.svg">
        </button>
        <button *ngIf="selection.selected.length >= 1 && areSelectedTransportSomeExcluded()"
                class="important-button exclude-button" (click)="modifyCurrentSelectionStatus(Status.SELECTED)">
          Inclure dans planification
          <img src="assets/images/plus.svg">
        </button>
        <button *ngIf="selection.selected.length >= 1"
                class="first-type-button exclude-button" (click)="openEditConstraintModal()">
            Modifier les contraintes
          <img src="assets/images/edit.svg">
        </button>
      </div>
      <div *ngIf="selection.isEmpty()">
        <span>{{nbSelected}}&nbsp;</span>
        <span>mission</span>
        <span *ngIf="nbSelected > 1">s</span>
        <span>&nbsp;au total</span>
      </div>
    </div>

    <table mat-table class="table-container" [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        color="primary">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        color="primary">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="patient">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Patient</div>
            <app-filter-table-text [dataSource]="dataSource" [filteredValues]="filteredValues" [typeToFilter]="'patient'"></app-filter-table-text>
          </div>
        </th>
        <ng-container *matCellDef="let transport">
          <td mat-cell class="view-cell data-cell" [class.excluded]="transport.transport.status == Status.EXCLUDED">
            <div class="patient-div">
              <div class="patient-name">
                {{toStringService.transportPatientName(transport.transport)}}
              </div>
              <div *ngIf="hasRequirements(transport.transport.requirementsIds)" class="patient-requirements tooltip-activator">
                <img src="assets/images/document.svg" />
                <span class="requirements-count">&nbsp;{{countRequirements(transport.transport.requirementsIds)}}</span>
                <div class="tooltip">
                  <span>Exigences :</span>
                  <ul>
                    <li *ngFor="let requirement of transport.transport.requirementsIds.trim().split(';')">
                      {{getRequirement(requirement)}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="locations">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Adresse de prise en charge - adresse de dépose</div>
            <app-filter-table-text [dataSource]="dataSource" [filteredValues]="filteredValues" [typeToFilter]="'locations'"></app-filter-table-text>
          </div>
        </th>
        <ng-container *matCellDef="let transport">
          <td mat-cell class="view-cell data-cell" [class.excluded]="transport.transport.status == Status.EXCLUDED">
            <div class="location-cell">
              <img src="assets/images/origin-destination.svg">
              <div class="origin-destination">
                <div id="origin" class="view-field">{{toStringService.locationAddress(transport.pickupLocation)}}</div>
                <div id="destination" class="view-field">{{toStringService.locationAddress(transport.deliveryLocation)}}</div>
              </div>
            </div>

          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="direction">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Direction</div>
            <app-filter-table-enum [dataSource]="dataSource" [filteredValues]="filteredValues"
                                   [typeToFilter]="'direction'" [elements]="['Aller', 'Transfert', 'Retour']">
            </app-filter-table-enum>
          </div>
        </th>
        <ng-container *matCellDef="let transport">
          <td mat-cell class="view-cell data-cell" [class.excluded]="transport.transport.status == Status.EXCLUDED">
            {{toStringService.transportDirection(transport.transport)}}
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="time-window-location">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Action</div>
            <app-filter-table-enum [dataSource]="dataSource" [filteredValues]="filteredValues"
                                   [typeToFilter]="'exigency'" [elements]="['Prise en charge', 'Dépose']">
            </app-filter-table-enum>
          </div>
        </th>
        <ng-container *matCellDef="let transport">
          <td mat-cell class="view-cell data-cell" [class.excluded]="transport.transport.status == Status.EXCLUDED">
            <div *ngIf="transport.transport.timeWindowType == 'origin'" class="view-field">
              Prise en<br> charge
            </div>
            <div *ngIf="transport.transport.timeWindowType == 'destination'" class="view-field">
              Dépose
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="time-window">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Contrainte</div>
            <app-filter-table-time [dataSource]="dataSource" [filteredValues]="filteredValues"
                                   [typeToFilter]="'constraint'">
            </app-filter-table-time>
          </div>
        </th>
        <ng-container *matCellDef="let transport">
          <td mat-cell class="view-cell data-cell" [class.excluded]="transport.transport.status == Status.EXCLUDED">
            <div class="view-field">
              {{getTimeWindowString(transport.transport.timeWindowStart, transport.transport.timeWindowEnd)}}
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="accuracy">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Certitude</div>
            <app-filter-table-enum [dataSource]="dataSource" [filteredValues]="filteredValues"
                                   [typeToFilter]="'accuracy'" [elements]="['Certain', 'Incertain']">
            </app-filter-table-enum>
          </div>
        </th>
        <ng-container *matCellDef="let transport">
          <td mat-cell class="view-cell data-cell" [class.excluded]="transport.transport.status == Status.EXCLUDED">
            <div class="view-field">
              <div style="margin-left: 20px">
                <img *ngIf="!transport.transport.timeWindowAccuracy" src="assets/images/question.svg" width="22" height="22">
              </div>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell class="data-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Statut</div>
            <app-filter-table-enum [dataSource]="dataSource" [filteredValues]="filteredValues"
                                   [typeToFilter]="'status'" [elements]="['Incluse', 'Exclue']">
            </app-filter-table-enum>
          </div>
        </th>
        <ng-container *matCellDef="let transport">
          <td mat-cell class="view-cell data-cell" [class.excluded]="transport.transport.status == Status.EXCLUDED">
            <div class="view-field">
              {{getStatusString(transport.transport.status)}}
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="pickup-start">
        <th mat-header-cell class="solution-cell" mat-sort-header *matHeaderCellDef>
          Heure de prise en charge
        </th>
        <ng-container *matCellDef="let transport">
          <td mat-cell class="view-cell solution-cell"
              [ngClass]="{
                'excluded': transport.transport.status == Status.EXCLUDED,
                'warning': transport.transport.status != Status.EXCLUDED && isPickupConstraintViolated(transport)
              }"
          >
            <hr *ngIf="!transport.pickupAction">
            <div *ngIf="transport.pickupAction" class="view-field">
              {{getNonNullPipeDateString(transport.pickupAction?.routeActionMeasures?.start, "HH:mm:ss")}}
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="delivery-start">
        <th mat-header-cell class="solution-cell" *matHeaderCellDef>
          <div class="header-sort" mat-sort-header>Heure de dépose</div>
        </th>
        <ng-container *matCellDef="let transport">
          <td mat-cell class="view-cell solution-cell"
              [ngClass]="{
                'excluded': transport.transport.status == Status.EXCLUDED,
                'warning': transport.transport.status != Status.EXCLUDED && isDeliveryConstraintViolated(transport)
              }"
          >
            <hr *ngIf="!transport.deliveryAction">
            <div *ngIf="transport.deliveryAction" class="view-field">
              {{getNonNullPipeDateString(transport.deliveryAction?.routeActionMeasures?.end, "HH:mm:ss")}}
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="employee">
        <th mat-header-cell class="solution-cell" *matHeaderCellDef>
          <div class="header-with-filter">
            <div class="header-sort" mat-sort-header>Employé</div>
            <app-filter-table-text [dataSource]="dataSource" [filteredValues]="filteredValues" [typeToFilter]="'employee'"></app-filter-table-text>
          </div>
        </th>
        <ng-container *matCellDef="let transport">
          <td mat-cell class="view-cell solution-cell" [class.excluded]="transport.transport.status == Status.EXCLUDED">
            <hr *ngIf="!transport.deliveryAction">
            <div *ngIf="transport.deliveryAction" class="view-field">
              {{toStringService.employeeName(transport.deliveryAction?.routeWithLinks?.employee)}}
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef>
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr [class.footer-no-data]="noData()" *matNoDataRow>
        <td class="mat-cell" [colSpan]="displayedColumns.length">
          <span *ngIf="!noData()">Aucune donnée ne correspond au filtre</span>
          <app-no-data-card *ngIf="noData()"></app-no-data-card>
        </td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[50, 100, 500, 1000]"
                   [pageSize]="1000"
                   showFirstLastButtons>
    </mat-paginator>

  </section>
</mat-card>

