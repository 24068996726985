import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from "rxjs";
import {CurrentProjectService} from "../current-project.service";
import {MessageService} from "../message.service";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {
  Parameter,
  ParameterTable,
} from "../parameter-configuration"
import {EventhubSectorData, ProfileData} from "../data-definitions";
import { BaseProfileService } from '../base-profile.service';
import { defaultDialogConfig } from '../dialog-data';
import { ProfileModalComponent } from '../profile-modal/profile-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-parameter-modification',
  templateUrl: './profile-parameter-modification.component.html',
  styleUrls: ['./profile-parameter-modification.component.css',
              '../../assets/standard-page.css',
              '../../assets/buttons.css',
              '../../assets/select.css']
})
export class ProfileParameterModificationComponent implements OnInit, OnDestroy, OnChanges {
  ParameterTable = ParameterTable;

  @Input() sector?: EventhubSectorData;
  @Input() profileType?: string;
  @Input() projectType?: string;

  profiles: ProfileData[] = [];
  selectedProfile?: ProfileData;

  subscriptions: Subscription = new Subscription();

  matDialogProfileRef?: MatDialogRef<ProfileModalComponent>;

  constructor(private baseProfileService: BaseProfileService,
              private messageService: MessageService,
              public dialog: MatDialog,
              private http: HttpClient) {
  }

  ngOnInit(): void {
    console.assert(this.sector != undefined)
    this.baseProfileService.setSectorApiId(this.sector!.apiId)
    console.assert(this.projectType != undefined)
    this.baseProfileService.setProjectType(this.projectType!.toLowerCase());
    console.assert(this.profileType != undefined) 
    this.baseProfileService.setProfileType(this.profileType!);
    this.subscriptions.add(this.baseProfileService.listenAll().subscribe(profiles => {
      this.profiles = profiles;
      this.profiles.forEach(profile => {
        if (profile.default) this.selectedProfile = profile;
      });
    }));
    this.loadData();
  }

  selectProfile(profile: ProfileData) {
    this.selectedProfile = profile;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.baseProfileService.sector || !this.baseProfileService.profileType
        || !this.baseProfileService.projectType) return;
    let sectorChange = changes["sector"]
    let typeChange = changes["projectType"]
    let profileChange = changes["profileType"]
    if (sectorChange || typeChange || profileChange) {
      if (sectorChange) {
        this.baseProfileService.setSectorApiId(sectorChange.currentValue.apiId);
      }
      if (typeChange) {
        this.baseProfileService.setProjectType(typeChange.currentValue.toLowerCase());
      }
      if (profileChange) {
        this.baseProfileService.setProfileType(profileChange.currentValue);
      }
      await this.baseProfileService.clearAndReloadAll();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async loadData() {
    try {
      await this.baseProfileService?.loadAll();
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  openCreateModal(isEdit: boolean) {
    let dialogConfig = defaultDialogConfig();
    let data = {
      name: isEdit ? this.selectedProfile?.name : "",
      id: isEdit ? this.selectedProfile?.id : undefined,
      sectorApiId: this.sector?.apiId,
      projectType: this.projectType,
      profileType: this.profileType,
      default: isEdit ? this.selectedProfile?.default : false
    }
    dialogConfig.data = data;
    this.matDialogProfileRef = this.dialog.open(ProfileModalComponent, dialogConfig);
    this.matDialogProfileRef.componentInstance.closeModal.subscribe(
      async value => {
        if (value) {
          this.matDialogProfileRef!.close();
          await this.baseProfileService.clearAndReloadAll();
        }
    });
  }

  async deleteProfile() {
    let dialogConfig = defaultDialogConfig();
    let customDialogConfig = {...dialogConfig, data: {
        title: "Confirmer la suppression",
        msg: "Êtes vous sur de vouloir supprimer ce profil ?",
        actions: [ { name: "Annuler" }, { name: "Continuer", important: true, returnValue: true } ]
      }}
    const dialogRef = this.dialog.open(CustomDialogComponent, customDialogConfig);
    dialogRef.afterClosed().subscribe(async (result) => {
      if (!result || !result.returnValue) return;
      let url = `${environment.apiUrl}/sectors/${this.sector?.apiId}/profiles/${this.selectedProfile?.id}`
      await this.http.delete(url, {withCredentials: true}).toPromise();
      await this.baseProfileService.clearAndReloadAll();
    });
  }
}
