import {DialogConfig} from "@angular/cdk/dialog";
import {MatDialogConfig} from "@angular/material/dialog";

export interface ButtonDialogData {
  name: string;
  important?: boolean;
  returnValue?: any;
}

export interface CheckboxDialogData {
  label: string;
  isChecked?: boolean;
}

export interface DialogData {
  title?: string;
  warning?: boolean;
  msg: string;
  submsg?: string[];
  actions: ButtonDialogData[];
  checkboxes?: CheckboxDialogData[];
}

export function defaultDialogConfig() {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.width = '600px'
  dialogConfig.panelClass = "modal-content"
  dialogConfig.backdropClass = "modal-backdrop"
  return dialogConfig;
}

export function confirmDelete(customMsg?: string) : DialogData {
  return {
    title: "Confirmer la suppression",
    msg: "Supprimer " + (customMsg ? customMsg : "cet élément") + " ?",
    actions: [ { name: "Annuler" }, { name: "Supprimer", important: true, returnValue: true } ]
  };
}
