export function isPositiveInteger(a: number): boolean {
  return !isEmptyNumber(a) && Number.isInteger(a) && a >= 0;
}

export function getPositiveIntegerMessage(type: string): string {
  return type + " must be a positive integer";
}

export function isStrictlyPositiveInteger(a: number): boolean {
  return !isEmptyNumber(a) && Number.isInteger(a) && a >= 1;
}

export function getStrictlyPositiveIntegerMessage(type: string): string {
  return type + " must be a strictly positive integer";
}

export function isNumberNotUndefinedBetween(a: number | undefined, lb: number, ub: number): boolean {
  return a !== undefined && isNumberBetween(a, lb, ub);
}

export function isNumberBetween(a: number, lb: number, ub: number): boolean {
  return !isEmptyNumber(a) && a >= lb && a <= ub
}

export function isNameValid(name: string): boolean {
  return !isEmptyString(name);
}

export function isEmptyString(name: string): boolean {
  return name == null || name.length == 0;
}

export function isEmptyNumber(n: number): boolean {
  return n == null || Number.isNaN(n);
}

export function getRequiredErrorMessage(): string {
  return "Vous devez renseigner une valeur valide";
}
