import { Component } from '@angular/core';
import {CurrentUserService} from "./current-user.service";
import {CurrentProjectService} from "./current-project.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'routing-solver-ui';

  constructor(private currentUserService: CurrentUserService,
              private currentProjectService: CurrentProjectService) {
  }

  shouldNavigationBeDisplayed() {
    return this.isConnected();
  }

  shouldMenuBeDisplayed() {
    return this.isConnected() && this.isProjectSelected();
  }

  isConnected() {
    return this.currentUserService.getUserId() !== undefined;
  }

  isProjectSelected() {
    return this.currentProjectService.getProjectId() !== undefined;
  }
}
