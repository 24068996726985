import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {DefaultCrewRuleData, EventhubEmployeeData, EventhubSectorData} from "../data-definitions";
import {MessageService} from "../message.service";
import {HttpErrorResponse} from "@angular/common/http";
import {EventHubDataService} from "../event-hub-data.service";
import {defaultDialogConfig} from "../dialog-data";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ToStringService} from "../to-string.service";
import { BaseDefaultCrewRuleService } from '../base-default-crew-rules.service';
import { AdminDefaultCrewRulesCreationModalComponent } from '../admin-default-crew-rules-creation-modal/admin-default-crew-rules-creation-modal.component';
import { Subscription } from 'rxjs';

interface DefaultCrewRuleInformation {
  dea: EventhubEmployeeData,
  crewMan: EventhubEmployeeData,
}

@Component({
  selector: 'app-admin-default-crew-rules',
  templateUrl: './admin-default-crew-rules.component.html',
  styleUrls: ['./admin-default-crew-rules.component.css',
              '../../assets/containers.css',
              '../../assets/buttons.css']
})
export class AdminDefaultCrewRulesComponent implements OnInit, OnChanges, OnDestroy {

  @Input() sector?: EventhubSectorData;

  subscriptions = new Subscription();

  employees: EventhubEmployeeData[] = [];
  deaMapper: Map<string, EventhubEmployeeData> = new Map<string, EventhubEmployeeData>();
  crewManMapper: Map<string, EventhubEmployeeData> = new Map<string, EventhubEmployeeData>();

  defaultCrewRulesInformation: DefaultCrewRuleInformation[] = [];
  defaultCrewRules: DefaultCrewRuleData[] = [];
  deasInTeam: Set<string> = new Set();
  crewMansInTeam: Set<string> = new Set();
  matDialogRef?: MatDialogRef<AdminDefaultCrewRulesCreationModalComponent>;

  constructor(private baseDefaultCrewRuleService: BaseDefaultCrewRuleService,
              private eventHubDataService: EventHubDataService,
              private messageService: MessageService,
              public toStringService: ToStringService,
              public dialog: MatDialog) { }

  async ngOnInit() {
    console.assert(this.sector != undefined)
    this.baseDefaultCrewRuleService.setSectorApiId(this.sector!.apiId)
    await this.loadAndInitEventHubData(this.sector!.apiId);
    this.subscriptions.add(this.baseDefaultCrewRuleService.listenAll().subscribe(defaultCrewRules => {
      this.defaultCrewRules = defaultCrewRules;
      this.defaultCrewRulesInformation = []
      this.crewMansInTeam.clear()
      this.deasInTeam.clear()
      for (let rule of defaultCrewRules) {
        let dea = this.deaMapper.get(rule.deaApiId)
        let crewMan = this.crewManMapper.get(rule.crewManApiId)
        if (!dea || !crewMan) continue
        let ruleInformation: DefaultCrewRuleInformation = {
            dea: dea,
            crewMan: crewMan
        }
        this.defaultCrewRulesInformation.push(ruleInformation)
        this.deasInTeam.add(dea.apiId);
        this.crewMansInTeam.add(crewMan.apiId);
      }
    }));
    await this.loadData();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.baseDefaultCrewRuleService) return
    let sectorChange = changes["sector"]
    if (sectorChange && sectorChange.currentValue) {
      this.baseDefaultCrewRuleService.setSectorApiId(sectorChange.currentValue.apiId)
      await this.loadAndInitEventHubData(sectorChange.currentValue.apiId)
      await this.baseDefaultCrewRuleService.clearAndReloadAll()
    }
  }

  async loadData() {
    try {
      await this.baseDefaultCrewRuleService!.loadAll();
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  async loadAndInitEventHubData(sectorApiId: string) {
    this.employees = await this.eventHubDataService.loadEmployees(sectorApiId);
    this.deaMapper.clear();
    this.crewManMapper.clear();
    for (let employee of this.employees) {
        if (!employee.serviceAssignments.includes('AMBU')) continue;
        if (employee.diploma === "DEA") {
            this.deaMapper.set(employee.apiId, employee);
        } 
        this.crewManMapper.set(employee.apiId, employee);
    }
  }

  createNewDefaultCrewRule() {
    let dialogConfig = defaultDialogConfig();
    let data = {
      sector: this.sector,
      employees: this.employees,
      deasInTeam: this.deasInTeam,
      crewMansInTeam: this.crewMansInTeam,
    }
    dialogConfig.data = data;
    this.matDialogRef = this.dialog.open(AdminDefaultCrewRulesCreationModalComponent, dialogConfig);
    this.matDialogRef.componentInstance.closeModal.subscribe(
      value => {
        if (value)
          this.matDialogRef!.close();
      });
  }

  editDefaultCrewRule(ruleInformation: DefaultCrewRuleInformation) {
    let dialogConfig = defaultDialogConfig();
    const ruleToUpdate = this.defaultCrewRules.find(rule => rule.deaApiId == ruleInformation.dea.apiId);
    let data = {
      sector: this.sector,
      employees: this.employees,
      deasInTeam: this.deasInTeam,
      crewMansInTeam: this.crewMansInTeam,
      rule: ruleToUpdate
    }
    dialogConfig.data = data;
    this.matDialogRef = this.dialog.open(AdminDefaultCrewRulesCreationModalComponent, dialogConfig);
    this.matDialogRef.componentInstance.closeModal.subscribe(
      async value => {
        if (value) {
          this.matDialogRef!.close();
        }
      });
  }

  async deleteRule(defaultCrewRuleInfo: DefaultCrewRuleInformation) {
    const defaultCrewRule: DefaultCrewRuleData = this.defaultCrewRules.find(rule => rule.deaApiId = defaultCrewRuleInfo.dea.apiId)!;
    await this.baseDefaultCrewRuleService.remove(defaultCrewRule);
  }
}
