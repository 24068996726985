import { Injectable } from '@angular/core';
import { CurrentProjectService } from './current-project.service';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor(private currentProjectService: CurrentProjectService) { }

  computeUrl(urlType: string[], withProject: boolean = true) {
    if (withProject) return ["/environment", this.currentProjectService.getEnvironmentId(),
            "project", this.currentProjectService.getProjectId()].concat(urlType);
    return ["/environment", this.currentProjectService.getEnvironmentId()].concat(urlType);
  }
}
