<div id="band-error-start-id"></div>
<mat-card class="band-error" *ngIf="error!==undefined">
  <p class="content">
    Error : {{error.message}}
    <button class="simple-button" [routerLink]="computeUrl()">
      <mat-icon>more_horiz</mat-icon>
    </button>
    <button class="simple-button" (click)="clearError()">
      <mat-icon>close</mat-icon>
    </button>
  </p>
</mat-card>
