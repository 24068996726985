import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { DurationPipe } from '../duration.pipe';

@Component({
  selector: 'app-duration-input',
  templateUrl: './duration-input.component.html',
  styleUrls: ['./duration-input.component.css'],
})
export class DurationInputComponent implements OnInit {

  @Input() duration?: number;
  @Output() durationChange: EventEmitter<number> = new EventEmitter<number>();

  strHours: string = "00";
  strMinutes: string = "00";
  strSeconds: string = "00";

  private maxDuration = 23 * 60 * 60 + 59 * 60 + 59;

  constructor(private durationPipe: DurationPipe) { }

  ngOnInit() {
    this.splitDuration();
  }

  private splitDuration(): void {
    if (this.duration) {
      let durationString = this.durationPipe.transform(this.duration);
      let splitDuration = durationString.split(':');
      this.strHours = splitDuration[0];
      this.strMinutes = splitDuration[1];
      this.strSeconds = splitDuration[2];
    }
  }

  check(): void {
    let newDuration = parseInt(this.strHours) * 60 * 60 + parseInt(this.strMinutes) * 60 + parseInt(this.strSeconds);
    this.duration = Math.min(newDuration, this.maxDuration);
    this.splitDuration();
    this.durationChange.emit(this.duration);
  }

}
