import { Component, OnInit } from '@angular/core';
import {CurrentProjectService} from "../current-project.service";
import {CurrentUserService} from "../current-user.service";
import {BaseUserService} from "../user.service";
import {UrlService} from "../url.service";
import {Router} from "@angular/router";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {MessageService} from "../message.service";
import {CookieService} from "ngx-cookie-service";
import {CustomDialogComponent} from "../custom-dialog/custom-dialog.component";
import {UserData, UserRole} from "../data-definitions";
import {Subscription} from "rxjs";
import {VersionService} from "../version.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ProjectCreationModalComponent} from "../project-creation-modal/project-creation-modal.component";
import {defaultDialogConfig} from "../dialog-data";


@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css',
              '../../assets/buttons.css',
              '../../assets/modal-creator.css']
})
export class NavigationBarComponent implements OnInit {
  subscriptions = new Subscription();
  currentUser?: UserData;

  frontEndVersionNumber: string;
  backEndVersionNumber: string = "";

  matDialogRef?: MatDialogRef<ProjectCreationModalComponent>;

  constructor(private currentProjectService: CurrentProjectService,
              private currentUserService: CurrentUserService,
              private userService: BaseUserService,
              private urlService: UrlService,
              private router: Router,
              public dialog: MatDialog,
              private messageService: MessageService,
              private cookieService: CookieService,
              private versionService: VersionService) {
    this.frontEndVersionNumber = versionService.frontEndVersion();
  }

  async ngOnInit() {
    this.subscriptions.add(this.currentProjectService.findEnvironmentId().subscribe(_ => {
    }));
    this.subscriptions.add(this.userService.listen().subscribe(currentUser => {
      this.currentUser = currentUser;
    }));
    this.subscriptions.add(this.versionService.backEndVersion().subscribe(version => {
      this.backEndVersionNumber = version
    }));
    this.loadData();
  }

  async loadData() {
    try {
      await Promise.all([this.userService.load()]);
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  disconnect() {
    let dialogConfig = defaultDialogConfig();
    let customDialogConfig = {...dialogConfig, data: {
        title: "Confirmer la déconnexion",
        msg: "Vous allez vous déconnecter.",
        actions: [ { name: "Annuler" }, { name: "Continuer", important: true, returnValue: true } ]
      }}
    const dialogRef = this.dialog.open(CustomDialogComponent, customDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (!result || !result.returnValue) return;
      this.cookieService.set("jwtToken", "", -1, "/");
      this.currentProjectService.logout();
      this.currentUserService.logout();
      this.currentUser = undefined;
      this.router.navigate(["/login"]);
    });
  }

  computeUrl(urlType: string, withProject: boolean = true) {
    return this.urlService.computeUrl([urlType], withProject);
  }

  isAdmin() {
    return this.currentUser ? this.currentUser.roles.includes(UserRole.ADMINISTRATION) : false;
  }

  closeCurrentProject() {
    this.currentProjectService.closeProject();
  }

  openProjectCreationModal() {
    let dialogConfig = defaultDialogConfig();
    dialogConfig.width = '1050px';
    dialogConfig.height = '715px';
    this.matDialogRef = this.dialog.open(ProjectCreationModalComponent, dialogConfig);
    this.matDialogRef.componentInstance.closeModal.subscribe(
      value => {
        if (value)
          this.matDialogRef!.close();
      });
    this.matDialogRef.componentInstance.project.subscribe(
      project => {
        if (project) {
          this.matDialogRef!.close();
          this.openProject(project.id);
        }
      }
    )
    // NOTE NB: should we store the subscription and cancel it ?
    // https://stackoverflow.com/questions/47952678/how-to-communicate-from-mat-dialog-component-to-the-component-where-mat-dialog-i
  }

  async openProject(projectId: number) {
    let newUrl = `/environment/${this.currentProjectService.getEnvironmentId()}/project/${projectId}/transports`
    this.currentProjectService.switchProject(projectId);
    this.router.navigateByUrl(newUrl);
  }
}
