import {
  LocationData,
  RouteActionMeasures,
  RouteMissionMeasuresWithLinks,
  RouteSolutionWithMeasures
} from "./data-definitions";
import {LayerSpecification, Marker, SourceSpecification} from "maplibre-gl";

export interface MapLocation {
  location: LocationData;
  mapObject: Marker;
}

export enum MapElementType {
  EDGE,
  POINT
}

export enum VisibilityType {
  NORMAL = "NORMAL",
  FOCUSED = "FOCUSED",
  REDUCED = "REDUCED"
}

export interface EdgeLayer {
  visibility:  VisibilityType,
  layer: LayerSpecification
}

export interface MapMarker {
  visibility: VisibilityType,
  marker?: Marker
}

export interface MapSource {
  id: string,
  source: SourceSpecification,
  layers: EdgeLayer[]
}

export interface MapElement {
  locations: LocationData[],
  associatedActions: RouteActionMeasures[],
  type: MapElementType,
  markers: MapMarker[],
  source?: MapSource,
  patientFirstName?: string,
  patientLastName?: string,
  nbTransportedPatient: number
}

export interface MapRoute {
  route: RouteSolutionWithMeasures,
  mapElements: MapElement[],
  mapLocations: MapLocation[],
  missions: RouteMissionMeasuresWithLinks[]
}
