<div class="container" #container [class.highlighted]="isSelected" *ngIf="employeeWithRules">
  <div class="header" (click)="onHeaderClick()">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
         class="collapse-icon" [class.collapse-rotation]="isSelected">
      <path d="M17.5607 12.3107C18.1464 11.7249 18.1464 10.7751 17.5607 10.1893C16.9749 9.60355 16.0251 9.60355 15.4393 10.1893L13.5 12.1287L12 13.5L10.5 12.1287L8.56066 10.1893C7.97487 9.60355 7.02513 9.60355 6.43934 10.1893C5.85355 10.7751 5.85355 11.7249 6.43934 12.3107L10.9393 16.8107C11.5251 17.3964 12.4749 17.3964 13.0607 16.8107L17.5607 12.3107Z" fill="#53649F"/>
    </svg>
    <div class="name">
      {{employeeName}}
    </div>
    <div class="information">
      <div *ngIf="nbAssociationRules > 0">
        <span>{{nbAssociationRules}}&nbsp;</span>
        <img src="assets/images/flag.svg">
      </div>
      <div *ngIf="nbLocks > 0">
        <span>{{nbLocks}}&nbsp;</span>
        <img src="assets/images/lock.svg">
      </div>
    </div>
  </div>
  <div class="detail" *ngIf="isSelected || wasPreviouslySelected">

    <p>Véhicule imposé :</p>
    <div class="element-list">
      <app-association-rules-vehicle *ngIf="employeeWithRules.imposedVehicleRule"
                                     [rule]="employeeWithRules.imposedVehicleRule"
                                     [vehicle]="associationRulesService.vehicles.get(employeeWithRules.imposedVehicleRule.vehicleId!)!">
      </app-association-rules-vehicle>
      <button type="button" class="rounded-button secondary-type-button new-planning" (click)="createNewVehicleRule(RuleType.FORCE_IN)"
              *ngIf="!employeeWithRules.imposedVehicleRule">
        <img src="assets/images/plus.svg">
      </button>
    </div>

    <p>Véhicules interdits :</p>
    <div class="element-list">
      <app-association-rules-vehicle *ngFor="let rule of employeeWithRules.forbiddenVehicleRules"
                                     [rule]="rule"
                                     [vehicle]="associationRulesService.vehicles.get(rule.vehicleId!)!">
      </app-association-rules-vehicle>
      <button type="button" class="rounded-button secondary-type-button new-planning" (click)="createNewVehicleRule(RuleType.FORCE_OUT)">
        <img src="assets/images/plus.svg">
      </button>
    </div>

    <p>Missions imposées :</p>
    <div class="element-list">
      <app-association-rules-transport *ngFor="let rule of employeeWithRules.imposedTransportRules"
                                       [rule]="rule"
                                       [transport]="associationRulesService.transports.get(rule.transportId!)!">
      </app-association-rules-transport>
      <button type="button" class="rounded-button secondary-type-button new-planning" (click)="createNewTransportRule(RuleType.FORCE_IN)">
        <img src="assets/images/plus.svg">
      </button>
    </div>

    <p>Missions interdites :</p>
    <div class="element-list">
      <app-association-rules-transport *ngFor="let rule of employeeWithRules.forbiddenTransportRules"
                                       [rule]="rule"
                                       [transport]="associationRulesService.transports.get(rule.transportId!)!">
      </app-association-rules-transport>
      <app-association-rules-force-all-out *ngIf="employeeWithRules.forbidAllOtherTransportsRule != null"
        [rule]="employeeWithRules.forbidAllOtherTransportsRule">
      </app-association-rules-force-all-out>

      <button type="button" class="rounded-button secondary-type-button new-planning" (click)="createNewTransportRule(RuleType.FORCE_OUT)">
        <img src="assets/images/plus.svg">
      </button>
    </div>

    <ng-container *ngIf="crewMan !== undefined">
      <p>Équipier imposé :</p>
      <div class="element-list">
        <div class="container-first-type light-color">
          <span>{{crewMan.firstName}} {{crewMan.lastName}}</span>
        </div>
      </div>
    </ng-container>

    <p *ngIf="associationRulesService.lockableTransportByEmployee.size > 0
             && associationRulesService.lockableTransportByEmployee.get(employeeWithRules.employee.id)">
      Verrouillages :
    </p>
    <div class="element-list" *ngIf="associationRulesService.lockableTransportByEmployee.size > 0">
      <app-association-rules-lock *ngFor="let transport of associationRulesService.lockableTransportByEmployee.get(employeeWithRules.employee.id)"
                                  [employee]="employee"
                                  [transport]="transport">
      </app-association-rules-lock>
    </div>
  </div>
</div>
