<button class="rounded-button first-type-button close-button" (click)="close()">
  <img src="assets/images/cross.svg">
</button>

<div class="container">
  <h1>Modifier les contraintes</h1>


  <table class="form-container">
    <form id="transport-time-form"></form>
    <thead>
      <tr class="row-header">
        <th class="patient-name">Patient</th>
        <th>Sens</th>
        <th>Exigence</th>
        <th>Début</th>
        <th>Fin</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let transport of modifiedTransports" class="row-value">
        <td class="patient-name">
          {{toStringService.transportPatientName(transport)}}
        </td>

        <td>
          {{toStringService.transportDirection(transport)}}
        </td>

        <td>
          {{toStringService.transportExigency(transport)}}
        </td>

        <!--
        TODO NB: min/max have no effect on ng-invalid class. How to replace the custom validation system by a built-in version?
        -->
        <td>
          <input form="transport-time-form" type="time" min="10:00" [max]="dateService.extractDayTimeFromDate(transport.timeWindowEnd)" required
                [ngModel]="dateService.extractDayTimeFromDate(transport.timeWindowStart)"
                (ngModelChange)="changeStartTime(transport, $event)"
                [class.invalid]="!validTransports.get(transport.id)"
                class="input__field input_time_xs">
        </td>
        <td>
          <input form="transport-time-form" type="time" [min]="dateService.extractDayTimeFromDate(transport.timeWindowStart)" max="23:59" required
                [ngModel]="dateService.extractDayTimeFromDate(transport.timeWindowEnd)"
                (ngModelChange)="changeEndTime(transport, $event)"
                [class.invalid]="!validTransports.get(transport.id)"
                class="input__field input_time_xs">
        </td>
      </tr>
    </tbody>
  </table>

  <div *ngIf="!areAllTransportValid()" style="color: red; text-align:center">
    Certains transports ont un début de fenêtre plus tardif que la fin. Veuillez vous assurez que chaque transport
    a une fenêtre avec un début avant la fin.
  </div>

  <div class="button-container">
    <button class="rectangular-button first-type-button" (click)="close()">
      Annuler
    </button>
    <button class="rectangular-button important-button validate-button"
            (click)="applyModifications()"
            [disabled]="!areAllTransportValid()">
      <div class="start-button-label">
        Valider
      </div>
      <img src="assets/images/edit.svg">
    </button>
  </div>

</div>
