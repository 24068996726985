import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {LocationData, RouteSolution, RouteSolutionWithMeasures} from '../data-definitions';
import {MessageService} from '../message.service';
import {EdgeLayer, MapElement, MapElementType, MapRoute, VisibilityType} from '../map-types';
import {MapService} from "../map.service"
import {BaseLocationService} from '../base-location.service';
import {BaseRouteSolutionService} from '../base-route-solution.service';
import {BaseVehicleService} from '../base-vehicle.service';
import {BaseMeasuresService} from '../base-measures.service';
import {DataMerger} from '../data-merger';
import {map} from 'rxjs/operators';
import {CurrentProjectService} from '../current-project.service';
import {HttpErrorResponse} from '@angular/common/http';
import {LngLatBoundsLike, Map, NavigationControl, StyleSpecification} from 'maplibre-gl';
import {styleUrl} from '../app.configuration'
/* import * as positronStyle from '../../assets/styles/positron/style.json' */
import {style} from '../map-style'

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css',
              '../../assets/buttons.css']
})
export class MapComponent implements OnInit, OnDestroy, AfterViewInit {
  subscriptions: Subscription = new Subscription();
  routeDataMerger: DataMerger<RouteSolution, RouteSolutionWithMeasures>;

  locations = new BehaviorSubject<LocationData[]>([]);
  routesWithMeasures = new BehaviorSubject<RouteSolutionWithMeasures[]>([]);

  currentRoute: MapRoute | undefined = undefined;
  currentElement: MapElement | undefined = undefined;

  @ViewChild("map") public mapElement: ElementRef = new ElementRef(null);
  private map: Map | undefined;
  private pendingRoutes: RouteSolutionWithMeasures[] = [];
  private pendingRouteModifications: boolean = false;

  public constructor(private mapService: MapService,
                     private baseLocationService: BaseLocationService,
                     private baseRouteService: BaseRouteSolutionService,
                     private baseVehicleService: BaseVehicleService,
                     private baseMeasuresService: BaseMeasuresService,
                     private currentProjectService: CurrentProjectService,
                     private messageService: MessageService,
                     private changeDetector: ChangeDetectorRef) {
    this.routeDataMerger = new DataMerger("routeSolution", this.baseRouteService.listenAll());
    this.routeDataMerger.addSubMapper("vehicle", this.baseVehicleService.listenAll(), r => r.vehicleId);
    this.routeDataMerger.addSubMapper("routeMeasures", this.baseMeasuresService.listen().pipe(
      map(measures => measures!.routeMeasures)), rs => rs.id);
  }

  async ngOnInit() {
    this.subscriptions.add(this.routeDataMerger.listen().subscribe(routeWithMeasures => {
      this.pendingRoutes = routeWithMeasures;
      if(!this.pendingRouteModifications) {
        this.pendingRouteModifications = true;
        window.setTimeout(() => {
          this.pendingRouteModifications = false;
          this.routesWithMeasures.next(this.pendingRoutes);
        }, 0);
      }
    }));
    this.currentProjectService.findProjectId().subscribe(projectId => {
      if (projectId) this.loadData();
    });
  }

  ngAfterViewInit() {
    this.map = new Map({
      container: this.mapElement.nativeElement,
      style: styleUrl,
      //style: customStyle as StyleSpecification,
      //style: style,
      center: [2.3488, 48.85341],
      zoom: 8,
      attributionControl: false,
      logoPosition: 'bottom-right'
    });

    this.map.addControl(new NavigationControl({}));
    this.map!.once('render', () => {
      // https://stackoverflow.com/questions/57166761/mapbox-gl-height-100
      this.map!.resize();
    })
    document.addEventListener("DOMContentLoaded", () => this.map!.resize());

    this.map.once("load", () => {
      const whiteDirection = new Image(24, 14);
      whiteDirection.src = 'assets/images/map-direction-white.svg';
      whiteDirection.onload = () => { this.map!.addImage("map-direction-white", whiteDirection); }

      const blueDirection = new Image(24, 14);
      blueDirection.src = 'assets/images/map-direction-blue.svg';
      blueDirection.onload = () => { this.map!.addImage("map-direction-blue", blueDirection); }

      const lightBlueDirection = new Image(24, 14);
      lightBlueDirection.src = 'assets/images/map-direction-light-blue.svg';
      lightBlueDirection.onload = () => { this.map!.addImage("map-direction-light-blue", lightBlueDirection); }
    });

    this.map.on("load", () => {
      this.subscriptions.add(this.baseLocationService.listenAll().subscribe(locations => {
        // Move map directly on concerned zone
        if (locations.length == 0) return;
        // bounding box seems to large
        //let boundingBox = this.computeBoundingBox(locations);
        //this.map!.fitBounds(boundingBox);
        this.map!.jumpTo({center: [locations[0].longitude, locations[0].latitude]})
      }))
      this.subscriptions.add(this.mapService.getSelectedRoute().subscribe(route => this.displayRoute(route)));
      this.subscriptions.add(this.mapService.getSelectedElement().subscribe(element => this.displayElement(element)));
    })
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.routeDataMerger.unsubscribe();
  }

  async loadData() {
    try {
      await Promise.all([this.baseLocationService.loadAll(), this.baseVehicleService.loadAll(), this.baseMeasuresService.load(),
        this.baseRouteService.loadAll()]);
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  displayRoute(route?: MapRoute) {
    if (this.currentRoute && this.currentRoute.mapElements) {
      for (let element of this.currentRoute.mapElements) {
        if (element.type != MapElementType.EDGE) continue;
        for (let layer of element.source!.layers) {
          if (this.map!.getLayer(layer.layer.id))
            this.map!.removeLayer(layer.layer.id);
        }
        this.map!.removeSource(element.source!.id)
      }
    }
    let deadMilagesLayers = [];
    let loadedLayers = [];
    if (route && route.mapElements) {
      for (let element of route.mapElements) {
        if (element.type != MapElementType.EDGE) continue;
        this.map!.addSource(element.source!.id, element.source!.source)
        let normalLayer = element.source!.layers.filter(l => l.visibility == VisibilityType.NORMAL)[0];
        if (this.isDeadMileageElement(element))
          deadMilagesLayers.push(normalLayer.layer);
        else
          loadedLayers.push(normalLayer.layer);
      }
      // The order is important to make sure that dead mileage moves appear behind loaded moves
      deadMilagesLayers.forEach(l => this.map!.addLayer(l));
      loadedLayers.forEach(l => this.map!.addLayer(l));
    }
    if (this.currentRoute) this.currentRoute.mapLocations.forEach(o => o.mapObject.remove());
    if (route) route.mapLocations.forEach(o => o.mapObject.addTo(this.map as Map));
    this.currentRoute = route;
    this.fitBoundsToCurrentRoute();
  }

  fitBoundsToCurrentRoute() {
    let locations: LocationData[] = [];
    if (this.currentRoute) {
      this.currentRoute!.mapLocations.map(l => l.location).forEach(l => locations.push(l));
    }
    if (this.currentRoute) this.map!.fitBounds(this.computeBoundingBox(locations));
  }

  computeBoundingBox(locations: LocationData[]): LngLatBoundsLike {
    if (locations.length === 0) return [[-180, -90], [180, 90]];
    let minLatitude = Math.min(...locations.map(l => l.latitude));
    let maxLatitude = Math.max(...locations.map(l => l.latitude));
    let minLongitude = Math.min(...locations.map(l => l.longitude));
    let maxLongitude = Math.max(...locations.map(l => l.longitude));
    if (locations.length === 1) {
      minLatitude -= 0.1 / 111;
      maxLatitude += 0.1 / 111;
      minLongitude -= 0.1 / 70;
      maxLongitude += 0.1 / 70;
    }
    let toleranceLatitude = 0.5 * (maxLatitude - minLatitude);
    let toleranceLongitude = 0.35 * (maxLongitude - minLongitude);
    return [[minLongitude - toleranceLongitude, minLatitude - toleranceLatitude],
      [maxLongitude + toleranceLongitude, maxLatitude + toleranceLatitude]];
  }

  displayElement(element? : MapElement) {
    if (!this.currentRoute) return;
    if (!element || this.currentElement === element) {
      this.restoreNormalDisplay();
      this.currentElement = undefined;
    } else {
      this.focusOnElement(element!);
      this.currentElement = element;
    }
  }

  restoreNormalDisplay() {
    let deadMilagesLayers = [];
    let loadedLayers = [];
    for (let element of this.currentRoute!.mapElements) {
      if (element.type == MapElementType.POINT) {
        element.markers.filter(m => m.marker !== undefined)
          .forEach(m => m.marker!.remove());
      } else {
        for (let layer of element.source!.layers) {
          if (this.map!.getLayer(layer.layer.id))
            this.map!.removeLayer(layer.layer.id);
        }
        let normalLayer = element.source!.layers.filter(l => l.visibility == VisibilityType.NORMAL)[0];
        if (this.isDeadMileageElement(element))
          deadMilagesLayers.push(normalLayer.layer);
        else
          loadedLayers.push(normalLayer.layer);
      }
    }
    deadMilagesLayers.forEach(l => this.map!.addLayer(l));
    loadedLayers.forEach(l => this.map!.addLayer(l));
    this.fitBoundsToCurrentRoute();
  }

  focusOnElement(elementToFocus: MapElement) {
    let deadMileageTripLayers = [];
    let loadedTripLayers = [];
    let layersToFocus: EdgeLayer[] = [];
    let markerToFocus;
    for (let element of this.currentRoute!.mapElements) {
      let focusOnCurrentElement = element === elementToFocus;
      if (element.type == MapElementType.POINT) {
        let focusMarker = element.markers.filter(m => m.visibility == VisibilityType.FOCUSED)[0];
        if (focusOnCurrentElement) {
          markerToFocus = focusMarker;
        } else if (focusMarker) {
          focusMarker.marker!.remove();
        }
      } else if (element.type == MapElementType.EDGE) {
        for (let layer of element.source!.layers) {
          if (this.map!.getLayer(layer.layer.id))
            this.map!.removeLayer(layer.layer.id);
        }
        let layer: EdgeLayer;
        if (focusOnCurrentElement) {
          layersToFocus = element.source!.layers.filter(l => l.visibility == VisibilityType.FOCUSED);
        } else {
          layer = element.source!.layers.filter(l => l.visibility == VisibilityType.REDUCED)[0];
          if (this.isDeadMileageElement(element))
            deadMileageTripLayers.push(layer.layer);
          else
            loadedTripLayers.push(layer.layer);
        }
      }
    }
    deadMileageTripLayers.forEach(l => this.map!.addLayer(l));
    loadedTripLayers.forEach(l => this.map!.addLayer(l));
    if (layersToFocus.length > 0) {
      layersToFocus.forEach(f => this.map!.addLayer(f.layer))
      this.map!.fitBounds(this.computeBoundingBox(elementToFocus.locations));
    }
    if (markerToFocus) {
      markerToFocus.marker!.addTo(this.map!);
      markerToFocus.marker!.togglePopup();
      //this.map!.fitBounds(this.computeBoundingBox(elementToFocus.locations));
      // flyTo center évite le changement de zoom, ce qui fait moins de travail pour la carte
      this.map!.flyTo({center: [elementToFocus.locations[0].longitude, elementToFocus.locations[0].latitude]});
    }
  }

  isDeadMileageElement(element: MapElement) {
    return element.associatedActions.filter(a => a.deadMileageMove).length > 0
  }
}

