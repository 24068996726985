import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { AuthGuard } from './auth-guard';
import { LocationTableComponent } from './location-table/location-table.component';
import { LoginComponent } from './login/login.component';
import { MapViewComponent } from './map-view/map-view.component';
import { MessageComponent } from './message/message.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PageErrorComponent } from './page-error/page-error.component';
import { ProjectPickerComponent } from './project-picker/project-picker.component';
import { TransportTableComponent } from './transport-table/transport-table.component';
import { EmployeeTableComponent } from "./employee-table/employee-table.component";
import { VehicleTableComponent } from "./vehicle-table/vehicle-table.component";
import { ParameterViewComponent } from "./parameter-view/parameter-view.component";
import { PageInternalErrorComponent } from "./page-internal-error/page-internal-error.component";
import { AssociationRulesViewComponent} from "./association-rules-view/association-rules-view.component";
import { DepotTableComponent } from './depot-table/depot-table.component';
import { TrafficViewComponent } from './traffic-view/traffic-view.component';
import { CrewTableComponent } from './crew-table/crew-table.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'environment/:environmentId/project',
    redirectTo: 'environment/:environmentId',
    pathMatch: 'full'
  },
  {
    path: 'environment/:environmentId',
    canActivate: [AuthGuard],
    data: {isAdminRequired: false},
    children: [
      { path: 'project-pick', component: ProjectPickerComponent }
    ]
  },
  {
    path: 'environment/:environmentId/project/:projectId',
    canActivate: [AuthGuard],
    data: {isAdminRequired: false},
    children: [
      { path: 'transports', component: TransportTableComponent },
      { path: 'employees', component: EmployeeTableComponent },
      { path: 'depots', component: DepotTableComponent },
      { path: 'fleet', component: VehicleTableComponent },
      { path: 'crews', component: CrewTableComponent },
      { path: 'locations', component: LocationTableComponent },
      { path: 'map', component: MapViewComponent },
      { path: 'parameters', component: ParameterViewComponent },
      { path: 'traffic', component: TrafficViewComponent },
      { path: 'rules', component: AssociationRulesViewComponent },
      { path: 'messages', component: MessageComponent },
      { path: 'errors', component: PageErrorComponent },
      { path: 'internalerror', component: PageInternalErrorComponent }
    ]
  },
  {
    path: 'environment/:environmentId',
    canActivate: [AuthGuard],
    data: {isAdminRequired: true},
    children: [
      { path: 'admin', component: AdminComponent }
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: '**', pathMatch: 'full', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
