import { Injectable } from '@angular/core';
import { BaseService, DalConfiguration } from './base-service';
import { HttpDalService } from './http-dal.service';
import { TrafficData } from './data-definitions';

@Injectable({
  providedIn: 'root'
})
export class BaseDefaultTrafficService extends BaseService<TrafficData> {

  profileId = 0;

  constructor(
    http: HttpDalService) {
    super(http);

    this._addStoreDefinition(
      'global',
      traffic => [5]
    );
  }

  _extractParentUrl(context: any) {
    return `${DalConfiguration.url}/default-traffic/profile/${this.profileId}`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url}/default-traffic/${context.trafficId}`;
  }

  _extractElementId(traffic: TrafficData) {
    return traffic.id;
  }

  setProfileId(id: number) {
    this.profileId = id;
  }

  async update(traffic: TrafficData) {
    await this._updateElement({
      trafficId: traffic.id
    }, traffic);
  }

  load(trafficId: number) {
    return this._loadElement({
      trafficId: trafficId
    });
  }

  loadAll() {
    return this._loadElements({
    });
  }

  clearAndReloadAll() {
    return this._clearAndReloadElements({
    });
  }

  listen(trafficId: number) {
    return this._listenElement(trafficId);
  }

  listenAll() {
    return this._listenElements('global', [5]);
  }
}
