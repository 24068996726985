import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BaseMeasuresService } from './base-measures.service';
import { BaseService, DalConfiguration } from './base-service';
import { CurrentProjectService } from './current-project.service';
import { RouteTaskActionSolution } from './data-definitions';
import { HttpDalService } from './http-dal.service';

@Injectable({
  providedIn: 'root'
})
export class BaseRouteTaskActionService extends BaseService<RouteTaskActionSolution> {
  constructor(
      http: HttpDalService,
      private currentProjectService: CurrentProjectService,
      private measuresService: BaseMeasuresService) {
    super(http);

    this._addStoreDefinition(
      'by-project-id',
       taskAction => [taskAction.projectId]
    );

    this._addStoreDefinition(
      'by-route-id',
       taskAction => [taskAction.routeId]
    );
  }

  _extractParentUrl(context: any): string {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/route-actions`;
  }

  _extractElementUrl(context: any) {
    return `${DalConfiguration.url }/environments/${context.environmentId}/projects/${context.projectId}/routes/${context.routeId}/route-actions/${context.taskActionId}`;
  }

  _extractElementId(taskAction: RouteTaskActionSolution) {
    return taskAction.id;
  }

  async update(taskAction: RouteTaskActionSolution) {
    await this._updateElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      routeId: taskAction.routeId,
      taskActionId: taskAction.id
    }, taskAction);
    // Ignore errors coming from the measures.
    await this.measuresService.reload().catch(_ => {});
  }

  async remove(taskAction: RouteTaskActionSolution) {
    await this._removeElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      routeId: taskAction.routeId,
      taskActionId: taskAction.id
    }, taskAction);
    // TODO: reload measures
    // await this.measuresService.reload().catch(_ => {});
  }

  async add(taskAction: RouteTaskActionSolution) {
    await this._addElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      routeId: taskAction.routeId
    }, taskAction);    
    // Ignore errors coming from the measures.
    await this.measuresService.reload().catch(_ => {});
  }

  clone(taskAction: RouteTaskActionSolution) {
    return this._clone(taskAction);
  }

  load(routeId: number, taskActionId: number) {
    return this._loadElement({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId(),
      routeId: routeId,
      taskActionId: taskActionId
    });
  }

  loadAll() {
    return this._loadElements({
      environmentId: this.currentProjectService.getEnvironmentId(),
      projectId: this.currentProjectService.getProjectId()
    });
  }

  listen(taskActionId: number) {
    return this._listenElement(taskActionId);
  }

  listenAll() {
    return this.currentProjectService.findProjectId().pipe(
      mergeMap(projectId => projectId === undefined ? EMPTY : this._listenElements('by-project-id', [projectId]))
    );
  }

  listenWithRouteId(routeId: number) {
    return this._listenElements('by-route-id', [routeId]);
  }
}
