import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ErrorData } from '../data-definitions';
import { MessageService } from '../message.service';
import {BaseErrorService} from "../base-error.service";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.css']
})
export class PageErrorComponent implements OnInit {

  errors: ErrorData[] = [];

  constructor(private messageService: MessageService,
              private errorBaseService: BaseErrorService) {
  }

  ngOnInit(): void {
    this.errorBaseService.listenAll().subscribe(
      errors => {
        this.errors = errors;
      }
    )
    this.loadData();
  }

  async loadData() {
    try {
      await this.errorBaseService.loadAll();
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }
}
