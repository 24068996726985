import { ReplaySubject, Subject } from "rxjs";
import { HttpDalService } from "./http-dal.service";
import { environment } from '../environments/environment';

type OneListener<TData extends object> = (id: number, prevElement: TData|undefined, newElement: TData|undefined) => void;
type StoreListener<TData extends object> = (storeIndex: BaseStoreIndex, prevElements: TData[], newElements: TData[]) => void;
type StoreIndexSelector<TData extends object> = (obj: TData) => number[]|undefined

class BaseStoreDefinition<TData extends object> {
  name: string;
  selector: StoreIndexSelector<TData>;

  constructor(name: string, selector: StoreIndexSelector<TData>) {
    this.name = name;
    this.selector = selector;
  }
}

class BaseStoreIndex {
  definitionName: string;
  indexes: number[];

  constructor(definitionName: string, indexes: number[]) {
    this.definitionName = definitionName;
    this.indexes = indexes;
  }

  get key() {
    return this.definitionName + '-' + this.indexes.join('-');
  }
}

class BaseServiceStore<TData extends object> {
  index: BaseStoreIndex;
  elements: TData[];
  subject: Subject<TData[]>;
  mapElementPositions: Map<number, number>;

  constructor(storeIndex: BaseStoreIndex) {
    this.index = storeIndex;
    this.elements = [];
    this.subject = new ReplaySubject<TData[]>(1);
    this.subject.next(this.elements);
    this.mapElementPositions = new Map<number, number>();
  }
}

class BaseServiceOne<TData extends object> {
  id: number;
  element: TData|undefined;
  subject: Subject<TData|undefined>;

  constructor(id: number) {
    this.id = id;
    this.element = undefined;
    this.subject = new ReplaySubject<TData|undefined>(1);
  }
}

export abstract class BaseService<TData extends object> {
  abstract _extractParentUrl(context: any) : string;
  abstract _extractElementUrl(context: any): string;
  abstract _extractElementId(element: TData): number;

  private _http: HttpDalService;
  private _storeDefinitions: BaseStoreDefinition<TData>[];
  private _mapStoresByKey: Map<string, BaseServiceStore<TData>>;
  private _mapOnes: Map<number, BaseServiceOne<TData>>;
  private _oneListeners: OneListener<TData>[];
  private _storeListeners: StoreListener<TData>[];
  private _loadedUrls: Set<string>;

  protected constructor(http: HttpDalService) {
    this._http = http;
    this._storeDefinitions = [];
    this._mapStoresByKey = new Map<string, BaseServiceStore<TData>>();
    this._mapOnes = new Map<number, BaseServiceOne<TData>>();
    this._oneListeners = [];
    this._storeListeners = [];
    this._loadedUrls = new Set<string>();
  }

  _onOne(listener: OneListener<TData>) {
    this._oneListeners.push(listener);
  }

  _onStore(listener: StoreListener<TData>) {
    this._storeListeners.push(listener);
  }

  _addStoreDefinition(name: string, selector: StoreIndexSelector<TData>) {
    this._storeDefinitions.push(new BaseStoreDefinition<TData>(name, selector));
  }

  _addTreeCascadeRelation<TData2 extends object>(
        service2: BaseService<TData2>,
        storeDefinitionName: string,
        storeIndexSelector: (storeIndex: number[]) => number,
        subElementsSelector: (obj: TData) => TData2[],
        updater: (obj: TData, collection: TData2[]) => TData) {

    this._oneListeners.push((_, prevElement, newElement) => {
      let prevSubElements = prevElement ? subElementsSelector(prevElement) : [];
      let newSubElements = newElement ? subElementsSelector(newElement) : [];

      let mappedPrevSubElements = new Map<number, TData2>();
      let mappedNewSubElements = new Map<number, TData2>();
      newSubElements.forEach(e => mappedNewSubElements.set(service2._extractElementId(e), e));
      prevSubElements.forEach(e => mappedPrevSubElements.set(service2._extractElementId(e), e));

      let removedSubElements = [];
      let modifiedOrAddedSubElements = [];

      for(let prevSubElement of prevSubElements) {
        let subElementId = service2._extractElementId(prevSubElement);
        if(mappedNewSubElements.has(subElementId)) continue;
        removedSubElements.push(prevSubElement);
      }

      for(let newSubElement of newSubElements) {
        let subElementId = service2._extractElementId(newSubElement);
        if(!mappedPrevSubElements.has(subElementId)) {
          modifiedOrAddedSubElements.push(newSubElement);
        } else if(mappedPrevSubElements.get(subElementId) !== mappedNewSubElements.get(subElementId)) {
          modifiedOrAddedSubElements.push(newSubElement);
        }
      }

      if(prevElement) service2._takeOff(removedSubElements);
      if(newElement) service2._fill(modifiedOrAddedSubElements);
    });

    service2._storeListeners.push((storeIndex, _, newSubElements) => {
      if(storeIndex.definitionName !== storeDefinitionName) return;

      let parentId = storeIndexSelector(storeIndex.indexes);
      let parentOne = this._mapOnes.get(parentId);
      if(!parentOne || !parentOne.element) return;
      if(subElementsSelector(parentOne.element) === newSubElements) return;
      let newParent = updater(parentOne.element, newSubElements);
      this._fill([newParent]);
    });
  }

  _addDeleteCascadeRelation<TData2 extends object>(
        service2: BaseService<TData2>,
        keySelector: (obj: TData2) => number) {
    this._oneListeners.push((id, prevElement, newElement) => {
      if(newElement || !prevElement) return;
      service2._takeOffFilter((_, e) => keySelector(e) === id);
    });
  }

  _modify(mapper: (obj: TData) => TData) {
    let modifiedOnes: BaseServiceOne<TData>[] = [];
    this._mapOnes.forEach(one => {
        if(!one.element) return;
        let newElement = mapper(one.element);
        if(newElement === undefined) return;
        if(JSON.stringify(newElement) === JSON.stringify(one.element)) return;
        let prevElement = one.element;
        one.element = newElement;
        this._deepFreeze(newElement);
        modifiedOnes.push(one);
        this._fireOneListeners(one.id, prevElement, one.element);
        one.subject.next(one.element);
    });

    this._mapStoresByKey.forEach(store => {
      if(!modifiedOnes.some(o => store.mapElementPositions.has(o.id))) return;
      let prevElements = store.elements;
      store.elements = store.elements.slice();
      for(let modifiedOne of modifiedOnes) {
        let elementPos = store.mapElementPositions.get(modifiedOne.id);
        if(elementPos === undefined) continue;
        if(modifiedOne.element === undefined) continue;
        store.elements[elementPos] = modifiedOne.element;
      }
      this._deepFreeze(store.elements);
      this._fireStoreListeners(store.index, prevElements, store.elements);
      store.subject.next(store.elements);
    });
  }

  _fill(elements: TData[]) {
    if(elements.length === 0) return;
    let modifiedElements = new Map<BaseServiceOne<TData>, TData|undefined>();
    let modifiedStores = new Map<BaseServiceStore<TData>, TData[]>();

    for(let element of elements) {
      this._deepFreeze(element);
      let id = this._extractElementId(element);
      let one = this._autoCreateOne(id);
      // TODO: better comparison
      if(JSON.stringify(one.element) === JSON.stringify(element)) continue;
      let prevElement = one.element;
      one.element = element;
      modifiedElements.set(one, prevElement);
    }

    for(let storeDefinition of this._storeDefinitions) {
      for(let element of elements) {
        let id = this._extractElementId(element);
        let indexes = storeDefinition.selector(element);
        if(!indexes) continue;
        let store = this._autoCreateStore(storeDefinition.name, indexes);
        let elemPosition = store.mapElementPositions.get(id);
        if(elemPosition !== undefined && JSON.stringify(store.elements[elemPosition]) === JSON.stringify(element)) continue;

        if(!modifiedStores.has(store)) {
          modifiedStores.set(store, store.elements);
          store.elements = store.elements.slice();
        }

        if(elemPosition !== undefined) {
          store.elements[elemPosition] = element;
        } else {
          store.mapElementPositions.set(id, store.elements.length);
          store.elements.push(element);
        }
      }
    }

    // Then we update the content of the stores and elements
    modifiedElements.forEach((prevElement, one) => {
      this._fireOneListeners(one.id, prevElement, one.element);
      one.subject.next(one.element);
    });

    modifiedStores.forEach((prevElements, store) => {
      this._deepFreeze(store.elements);
      this._fireStoreListeners(store.index, prevElements, store.elements);
      store.subject.next(store.elements);
    });
  }

  _takeOff(elements: TData[]) {
    let modifiedElements = new Map<BaseServiceOne<TData>, TData>();
    let modifiedStores = new Map<BaseServiceStore<TData>, TData[]>();

    for(let element of elements) {
      let id = this._extractElementId(element);
      let one = this._autoCreateOne(id);
      if(!one.element) continue;
      let prevElement = one.element;
      one.element = undefined;
      modifiedElements.set(one, prevElement);
    }

    for(let storeDefinition of this._storeDefinitions) {
      for(let element of elements) {
        let id = this._extractElementId(element);
        let indexes = storeDefinition.selector(element);
        if(!indexes) continue;
        let store = this._autoCreateStore(storeDefinition.name, indexes);
        if(!store.mapElementPositions.has(id)) continue;

        if(!modifiedStores.has(store)) {
          modifiedStores.set(store, store.elements);
          store.elements = store.elements.slice();
        }

        let elementPos = store.elements.findIndex(e => this._extractElementId(e) === id);
        store.elements.splice(elementPos, 1);
      }
    }

    // Then we update the content of the stores and elements
    modifiedElements.forEach((prevElement, one) => {
      this._fireOneListeners(one.id, prevElement, undefined);
      one.subject.next(undefined);
    });

    modifiedStores.forEach((prevElements, store) => {
      store.mapElementPositions.clear();
      store.elements.forEach((e, pos) => store.mapElementPositions.set(this._extractElementId(e), pos));
      this._deepFreeze(store.elements);
      this._fireStoreListeners(store.index, prevElements, store.elements);
      store.subject.next(store.elements);
    });
  }

  _takeOffFilter(filter: (id: number, elem: TData) => boolean) {
    let filteredElements: TData[] = [];
    this._mapOnes.forEach(one => {
      if(!one.element || !filter(one.id, one.element)) return;
      filteredElements.push(one.element);
    });

    this._takeOff(filteredElements);
  }

  _fireOneListeners(id: number, prevElement: TData|undefined, newElement: TData|undefined) {
    for(let listener of this._oneListeners)
      listener(id, prevElement, newElement);
  }

  _fireStoreListeners(storeIndex: BaseStoreIndex, prevElements: TData[], newElements: TData[]) {
    for(let listener of this._storeListeners)
      listener(storeIndex, prevElements, newElements);
  }

  _deepFreeze(_: any) {
    return;
    // Object.freeze(obj);
    // if (obj === undefined || obj === null) return;

    // Object.getOwnPropertyNames(obj).forEach(prop => {
    //   if(obj[prop] === undefined || obj[prop] === null) return;
    //   if(Object.isFrozen(obj[prop])) return;
    //   if(typeof obj[prop] !== "object" && typeof obj[prop] !== "function") return;
    //   this._deepFreeze(obj[prop]);
    // });
  }

  _clone(element: TData): TData {
    // TODO: Not really good
    return JSON.parse(JSON.stringify(element));
  }

  _autoCreateStore(storeDefinitionName: string, indexes: number[]): BaseServiceStore<TData> {
    let key = storeDefinitionName + '-' + indexes.join('-');
    let store = this._mapStoresByKey.get(key);
    if(!store) {
      store = new BaseServiceStore<TData>(new BaseStoreIndex(storeDefinitionName, indexes));
      this._mapStoresByKey.set(key, store);
    }
    return store;
  }

  _autoCreateOne(id: number): BaseServiceOne<TData> {
    let one = this._mapOnes.get(id);
    if(!one) {
      one = new BaseServiceOne<TData>(id);
      this._mapOnes.set(id, one);
    }
    return one;
  }

  protected _listenElement(id: number) {
    let one = this._autoCreateOne(id);
    return one.subject.asObservable();
  }

  protected _listenElements(storeDefinitionName: string, storeIndexes: number[]) {
    let store = this._autoCreateStore(storeDefinitionName, storeIndexes);
    return store.subject.asObservable();
  }

  protected _findElement(id: number) {
    return this._mapOnes.get(id)?.element;
  }

  protected async _loadElement(context: any) {
    let url = this._extractElementUrl(context);
    if(this._loadedUrls.has(url)) return;
    await this._reloadElement(context);
  }

  protected async _loadElements(context: any) {
    let url = this._extractParentUrl(context);
    if(this._loadedUrls.has(url)) return;
    await this._reloadElements(context);
  }

  protected async _reloadElement(context: any) {
    let url = this._extractElementUrl(context);
    let result = await this._http.get<TData>(url);
    this._loadedUrls.add(url);
    this._fill([result]);
    return result;
  }

  protected async _reloadElements(context: any) {
    let url = this._extractParentUrl(context);
    let elements = await this._http.get<TData[]>(url);
    this._loadedUrls.add(url);
    this._fill(elements);
    return elements;
  }

  protected async _clearAndReloadElements(context: any) {
    let elements = await this._reloadElements(context);
    this._takeOffFilter((id, _) => elements.findIndex(e => this._extractElementId(e) === id) === -1);
    return elements;
  }

  protected async _addElement(context: any, element: TData) {
    let parentUrl = this._extractParentUrl(context);
    let result = await this._http.post(parentUrl, element);
    this._fill([result]);
    return result;
  }

  protected async _addElements(context: any, elements: TData[]) {
    let parentUrl = this._extractParentUrl(context) + "/add-batch";
    let result = await this._http.post(parentUrl, elements);
    this._fill(result);
    return result;
  }

  protected async _updateElement(context: any, element: TData) {
    let one = this._mapOnes.get(this._extractElementId(element));
    if(!one || !one.element)
      throw "The given element was not loaded by the service and can't be modified";

    let result = await this._http.put(this._extractElementUrl(context), element);
    this._fill([result]);
  }

  protected async _updateElements(context: any, elements: TData[]) {
    let parentUrl = this._extractParentUrl(context) + "/batch";
    let result = await this._http.put(parentUrl, elements);
    this._fill(result);
  }


  protected async _updateElementProperty(url: string, element: TData, elementProperty: any) {
    let one = this._mapOnes.get(this._extractElementId(element));
    if(!one || !one.element)
      throw "The given element was not loaded by the service and can't be modified";

    await this._http.put(url, elementProperty);
    this._fill([element]);
  }

  async _removeElement(context: any, element: TData) {
    let one = this._mapOnes.get(this._extractElementId(element));
    if(!one || !one.element) {
      throw "The given element was not loaded by the service and can't be removed";
    }

    await this._http.delete(this._extractElementUrl(context));
    this._takeOff([one.element]);
  }

  async _removeElements(context: any, elements: TData[]) {
    for(let element of elements) {
      let one = this._mapOnes.get(this._extractElementId(element));
      if(!one || !one.element) {
        throw "The given elements were not loaded by the service and can't be removed";
      }
    }
    let ids = elements.map(e => this._extractElementId(e));
    await this._http.post(this._extractParentUrl(context) + "/remove-batch", ids);
    this._takeOff(elements);
  }
}

export let DalConfiguration = {
  url: environment.apiUrl
};
