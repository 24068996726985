export enum ParameterType {
	TAXI_FORFAIT_COST,
	TAXI_KM_COST,
	TAXI_DOUBLE_SIMULTANEOUS_DEDUCTION,
	TAXI_TRIPLE_SIMULTANEOUS_DEDUCTION,
	VSL_FORFAIT_COST,
	VSL_KM_COST,
	VSL_DOUBLE_SIMULTANEOUS_DEDUCTION,
	VSL_TRIPLE_SIMULTANEOUS_DEDUCTION,
  AMBU_FORFAIT_COST,
  AMBU_KM_COST,
  MARGIN,
  SERVICE_START_TIME,
	SERVICE_END_TIME,
  MAX_WORKING_TIME,
  MIN_WORKING_TIME,
	MAX_TIME_BEFORE_BREAK,
	MIN_BREAK_LENGTH,
	MAX_BREAK_LENGTH,
	LUNCH_BEGIN,
	LUNCH_END,
	DINER_BEGIN,
	DINER_END,
	MIN_MEAL_LENGTH,
	MAX_MEAL_LENGTH,
  MAX_DAILY_BREAK_LENGTH,
  MAX_MUTUALISATION_BILLABLE_DISTANCE_DETOUR,
  MAX_MUTUALISATION_ABSOLUTE_TIME_DETOUR,
  MAX_MUTUALISATION_RELATIVE_TIME_DETOUR,
  MIN_MUTUALISATION_PICKUP_DROPOFF_DISTANCE,
  INCLUDE_TRAFIC,
  COMPUTATION_TIME,
  SIMULTANEOUS_CONFIGURATION,
  TRIPLE_SIMULTANEOUS_CONFIGURATION,
  MIN_SIMULTANEOUS_DISTANCE_PERCENTAGE,
  MAX_SIMULTANEOUS_LONGER_SHORTER_DIFFERENCE_PERCENTAGE,
  PRIORITY_PENALTY,
  OVER_LONG_DELAY_IN,
	OVER_LONG_DELAY_OUT,
	UNDER_LONG_DELAY_IN,
	UNDER_LONG_DELAY_OUT,
	UNDER_MEDIUM_DELAY_IN,
	UNDER_MEDIUM_DELAY_OUT,
	UNDER_SHORT_DELAY_IN,
	UNDER_SHORT_DELAY_OUT,
	DELAY_PENALTY_IN,
	DELAY_PENALTY_OUT,
  LONG_DELAY,
  MEDIUM_DELAY,
  SHORT_DELAY
}

export type ParameterTypeString = keyof typeof ParameterType;

export interface Parameter {
  id: number;
  projectId: number;
  profileId: number;
  name: ParameterTypeString,
  intValue?: number,
  stringValue?: string,
  doubleValue?: number
  boolValue?: boolean
}

export enum ParameterTable {
  PROJECT,
  DEFAULT
}

export enum ParameterValueType {
  STRING,
  DOUBLE,
  INTEGER,
  BOOLEAN,
  DURATION,
  DURATION_WITH_STRING
}

export interface ParameterCategory {
  title: string
  subCategories: ParameterSubCategory[]
}

export interface ParameterSubCategory {
  title: string
  parameters: (ParameterEntry | ParameterEnum)[]
}

export interface ParameterEntry {
  name: string,
  type: ParameterType,
  valueType: ParameterValueType,
  unit: string
  scaling?: number
}

export interface ParameterEnum {
  name: string
  type: ParameterType
  possibilities: ParameterEnumPossibility[]
}

export interface ParameterEnumPossibility {
  title: string,
  value: string
}

export interface DurationElements {
  hours: number,
  minutes: number,
  seconds: number
}

export let tapParametersConfiguration: ParameterCategory[] = [
  {
    title: "Objectifs",
    subCategories: [
      {
        title: "K-score VSL",
        parameters: [
          {name: "Forfait de prise en charge", type: ParameterType.VSL_FORFAIT_COST, valueType: ParameterValueType.DOUBLE, unit: "€"},
          {name: "Tarif kilométrique", type: ParameterType.VSL_KM_COST, valueType: ParameterValueType.DOUBLE, unit: "€"},
          {name: "Abattement pour double simultanée", type: ParameterType.VSL_DOUBLE_SIMULTANEOUS_DEDUCTION, valueType: ParameterValueType.DOUBLE, unit: "%", scaling: 100},
          {name: "Abattement pour triple simultanée", type: ParameterType.VSL_TRIPLE_SIMULTANEOUS_DEDUCTION, valueType: ParameterValueType.DOUBLE, unit: "%", scaling: 100},
        ]
      },
      {
        title: "K-score Taxi",
        parameters: [
          {name: "Forfait de prise en charge", type: ParameterType.TAXI_FORFAIT_COST, valueType: ParameterValueType.DOUBLE, unit: "€"},
          {name: "Tarif kilométrique", type: ParameterType.TAXI_KM_COST, valueType: ParameterValueType.DOUBLE, unit: "€"},
          {name: "Abattement pour double simultanée", type: ParameterType.TAXI_DOUBLE_SIMULTANEOUS_DEDUCTION, valueType: ParameterValueType.DOUBLE, unit: "%", scaling: 100},
          {name: "Abattement pour triple simultanée", type: ParameterType.TAXI_TRIPLE_SIMULTANEOUS_DEDUCTION, valueType: ParameterValueType.DOUBLE, unit: "%", scaling: 100},
        ]
      },
      {
        title: "Temps de calcul",
        parameters: [
          {name: "Temps de calcul pour 10 missions", type: ParameterType.COMPUTATION_TIME, valueType: ParameterValueType.INTEGER, unit: "s"}
        ]
      }
    ]
  },
  {
    title: "Simultané",
    subCategories: [
      {
        title: "Possibilités",
        parameters: [
          {name: "Configuration du double simultané", type: ParameterType.SIMULTANEOUS_CONFIGURATION, possibilities:
              [
                {title: "Autorisé", value: "ALLOWED"},
                {title: "Dans le même sens uniquement", value: "ALLOWED_IF_SAME_DIRECTION"},
                {title: "Interdit", value: "FORBIDDEN"}
              ]
          },
          {name: "Configuration du triple simultané (si double autorisé)", type: ParameterType.TRIPLE_SIMULTANEOUS_CONFIGURATION, possibilities:
              [
                {title: "Autorisé", value: "ALLOWED"},
                {title: "Dans le même sens uniquement", value: "ALLOWED_IF_SAME_DIRECTION"},
                {title: "Interdit", value: "FORBIDDEN"}
              ]
          },
        ]
      },
      {
        title: "Limites du simultanée",
        parameters: [
          {name: "Distance facturable maximum du détour", type: ParameterType.MAX_MUTUALISATION_BILLABLE_DISTANCE_DETOUR, valueType: ParameterValueType.INTEGER, unit: "km", scaling: 0.001},
          {name: "Temps maximum du détour", type: ParameterType.MAX_MUTUALISATION_ABSOLUTE_TIME_DETOUR, valueType: ParameterValueType.DURATION, unit: "min"},
          {name: "Augmentation maximum du trajet par rapport au trajet direct", type: ParameterType.MAX_MUTUALISATION_RELATIVE_TIME_DETOUR, valueType: ParameterValueType.DOUBLE, unit: "%",  scaling: 100},
          {name: "Distance minimum entre la dernière montée et la première dépose", type: ParameterType.MIN_MUTUALISATION_PICKUP_DROPOFF_DISTANCE, valueType: ParameterValueType.INTEGER, unit: "m"},
          {name: "Écart maximal entre la mission la plus courte et la plus longue (par rapport à la missions la plus longue, s'ajoutant à une base de 3km)", type: ParameterType.MAX_SIMULTANEOUS_LONGER_SHORTER_DIFFERENCE_PERCENTAGE, valueType: ParameterValueType.DOUBLE, unit: "%", scaling: 100},
          {name: "Distance minimum avec deux ou trois personnes à bord (par rapport à la missions la plus longue)", type: ParameterType.MIN_SIMULTANEOUS_DISTANCE_PERCENTAGE, valueType: ParameterValueType.DOUBLE, unit: "%", scaling: 100},
        ]
      }
    ]
  },
  {
    title: "Autorisations de retards",
    subCategories: [
      {
        title: "Différence entre l’horodatage de création de la mission et son début",
        parameters: [
          {name: "Différence maximale", type: ParameterType.LONG_DELAY, valueType: ParameterValueType.INTEGER, unit: "h"},
          {name: "Différence moyenne", type: ParameterType.MEDIUM_DELAY, valueType: ParameterValueType.INTEGER, unit: "h"},
          {name: "Différence minimale", type: ParameterType.SHORT_DELAY, valueType: ParameterValueType.INTEGER, unit: "h"},
        ]
      },
      {
        title: "Retard entrée autorisé",
        parameters: [
          {name: "+ de ", type: ParameterType.OVER_LONG_DELAY_IN, valueType: ParameterValueType.DURATION_WITH_STRING, unit: "min"},
          {name: "- de ", type: ParameterType.UNDER_LONG_DELAY_IN, valueType: ParameterValueType.DURATION_WITH_STRING, unit: "min"},
          {name: "- de ", type: ParameterType.UNDER_MEDIUM_DELAY_IN, valueType: ParameterValueType.DURATION_WITH_STRING, unit: "min"},
          {name: "- de ", type: ParameterType.UNDER_SHORT_DELAY_IN, valueType: ParameterValueType.DURATION_WITH_STRING, unit: "min"},
          {name: "Pénalité", type: ParameterType.DELAY_PENALTY_IN, valueType: ParameterValueType.DOUBLE, unit: "€/min"},
        ]
      },
      {
        title: "Retard sortie autorisé",
        parameters: [
          {name: "+ de ", type: ParameterType.OVER_LONG_DELAY_OUT, valueType: ParameterValueType.DURATION_WITH_STRING, unit: "min"},
          {name: "- de ", type: ParameterType.UNDER_LONG_DELAY_OUT, valueType: ParameterValueType.DURATION_WITH_STRING, unit: "min"},
          {name: "- de ", type: ParameterType.UNDER_MEDIUM_DELAY_OUT, valueType: ParameterValueType.DURATION_WITH_STRING, unit: "min"},
          {name: "- de ", type: ParameterType.UNDER_SHORT_DELAY_OUT, valueType: ParameterValueType.DURATION_WITH_STRING, unit: "min"},
          {name: "Pénalité", type: ParameterType.DELAY_PENALTY_OUT, valueType: ParameterValueType.DOUBLE, unit: "€/min"},
        ]
      }
    ]
  },
  {
    title: "Règles RH",
    subCategories: [
      {
        title: "Pauses",
        parameters: [
          {name: "Durée totale maximale des pauses sur une journée", type: ParameterType.MAX_DAILY_BREAK_LENGTH, valueType: ParameterValueType.DURATION, unit: "h"},
        ]
      },
      {
        title: "Pause réglementaire",
        parameters: [
          {name: "Temps de travail maximal avant pause", type: ParameterType.MAX_TIME_BEFORE_BREAK, valueType: ParameterValueType.DURATION, unit: "h"},
          {name: "Durée minimale de la pause", type: ParameterType.MIN_BREAK_LENGTH, valueType: ParameterValueType.DURATION, unit: "h"},
          {name: "Durée maximale de la pause", type: ParameterType.MAX_BREAK_LENGTH, valueType: ParameterValueType.DURATION, unit: "h"}
        ]
      },
      {
        title: "Pauses repas",
        parameters: [
          {name: "Début de la pause du midi", type: ParameterType.LUNCH_BEGIN, valueType: ParameterValueType.DURATION, unit: "h"},
          {name: "Fin de la pause du midi", type: ParameterType.LUNCH_END, valueType: ParameterValueType.DURATION, unit: "h"},
          {name: "Début de la pause du soir", type: ParameterType.DINER_BEGIN, valueType: ParameterValueType.DURATION, unit: "h"},
          {name: "Fin de la pause du soir", type: ParameterType.DINER_END, valueType: ParameterValueType.DURATION, unit: "h"},
          {name: "Durée minimale de la pause", type: ParameterType.MIN_MEAL_LENGTH, valueType: ParameterValueType.DURATION, unit: "h"},
          {name: "Durée maximale de la pause", type: ParameterType.MAX_MEAL_LENGTH, valueType: ParameterValueType.DURATION, unit: "h"}
        ]
      },
      {
        title: "Temps de travail",
        parameters: [
          {name: "Temps de travail journalier minimum (pour le calcul du K-score)", type: ParameterType.MIN_WORKING_TIME, valueType: ParameterValueType.DURATION, unit: "h"},
          {name: "Temps de travail journalier maximum (contrainte)", type: ParameterType.MAX_WORKING_TIME, valueType: ParameterValueType.DURATION, unit: "h"}
        ]
      },
      {
        title: "Temps de service",
        parameters: [
          {name: "Temps de début de service", type: ParameterType.SERVICE_START_TIME, valueType: ParameterValueType.DURATION, unit: "min"},
          {name: "Temps de fin de service", type: ParameterType.SERVICE_END_TIME, valueType: ParameterValueType.DURATION, unit: "min"}
        ]
      }, 
      {
        title: "Priorités des services",
        parameters: [
          {name: "Pénalité en cas de non respect de la priorité", type: ParameterType.PRIORITY_PENALTY, valueType: ParameterValueType.DURATION, unit: "min"}
        ]
      }

    ]
  }
]

export let ambuParametersConfiguration: ParameterCategory[] = [
  {
    title: "Objectifs",
    subCategories: [
      {
        title: "K-score Ambu",
        parameters: [
          {name: "Forfait de prise en charge", type: ParameterType.AMBU_FORFAIT_COST, valueType: ParameterValueType.DOUBLE, unit: "€"},
          {name: "Tarif kilométrique", type: ParameterType.AMBU_KM_COST, valueType: ParameterValueType.DOUBLE, unit: "€"},
        ]
      },
      {
        title: "Temps de calcul",
        parameters: [
          {name: "Temps de calcul pour 10 missions", type: ParameterType.COMPUTATION_TIME, valueType: ParameterValueType.INTEGER, unit: "s"}
        ]
      }
    ]
  },
  {
    title: "Autorisations de retards",
    subCategories: [
      {
        title: "Différence entre l’horodatage de création de la mission et son début",
        parameters: [
          {name: "Différence maximale", type: ParameterType.LONG_DELAY, valueType: ParameterValueType.INTEGER, unit: "h"},
          {name: "Différence moyenne", type: ParameterType.MEDIUM_DELAY, valueType: ParameterValueType.INTEGER, unit: "h"},
          {name: "Différence minimale", type: ParameterType.SHORT_DELAY, valueType: ParameterValueType.INTEGER, unit: "h"},
        ]
      },
      {
        title: "Retard entrée autorisé",
        parameters: [
          {name: "+ de ", type: ParameterType.OVER_LONG_DELAY_IN, valueType: ParameterValueType.DURATION_WITH_STRING, unit: "min"},
          {name: "- de ", type: ParameterType.UNDER_LONG_DELAY_IN, valueType: ParameterValueType.DURATION_WITH_STRING, unit: "min"},
          {name: "- de ", type: ParameterType.UNDER_MEDIUM_DELAY_IN, valueType: ParameterValueType.DURATION_WITH_STRING, unit: "min"},
          {name: "- de ", type: ParameterType.UNDER_SHORT_DELAY_IN, valueType: ParameterValueType.DURATION_WITH_STRING, unit: "min"},
          {name: "Pénalité", type: ParameterType.DELAY_PENALTY_IN, valueType: ParameterValueType.DOUBLE, unit: "€/min"},
        ]
      },
      {
        title: "Retard sortie autorisé",
        parameters: [
          {name: "+ de ", type: ParameterType.OVER_LONG_DELAY_OUT, valueType: ParameterValueType.DURATION_WITH_STRING, unit: "min"},
          {name: "- de ", type: ParameterType.UNDER_LONG_DELAY_OUT, valueType: ParameterValueType.DURATION_WITH_STRING, unit: "min"},
          {name: "- de ", type: ParameterType.UNDER_MEDIUM_DELAY_OUT, valueType: ParameterValueType.DURATION_WITH_STRING, unit: "min"},
          {name: "- de ", type: ParameterType.UNDER_SHORT_DELAY_OUT, valueType: ParameterValueType.DURATION_WITH_STRING, unit: "min"},
          {name: "Pénalité", type: ParameterType.DELAY_PENALTY_OUT, valueType: ParameterValueType.DOUBLE, unit: "€/min"},
        ]
      }
    ]
  },
  {
    title: "Règles RH",
    subCategories: [
      {
        title: "Pauses",
        parameters: [
          {name: "Durée totale maximale des pauses sur une journée", type: ParameterType.MAX_DAILY_BREAK_LENGTH, valueType: ParameterValueType.DURATION, unit: "h"},
        ]
      },
      {
        title: "Pause réglementaire",
        parameters: [
          {name: "Temps de travail maximal avant pause", type: ParameterType.MAX_TIME_BEFORE_BREAK, valueType: ParameterValueType.DURATION, unit: "h"},
          {name: "Durée minimale de la pause", type: ParameterType.MIN_BREAK_LENGTH, valueType: ParameterValueType.DURATION, unit: "h"},
          {name: "Durée maximale de la pause", type: ParameterType.MAX_BREAK_LENGTH, valueType: ParameterValueType.DURATION, unit: "h"}
        ]
      },
      {
        title: "Pauses repas",
        parameters: [
          {name: "Début de la pause du midi", type: ParameterType.LUNCH_BEGIN, valueType: ParameterValueType.DURATION, unit: "h"},
          {name: "Fin de la pause du midi", type: ParameterType.LUNCH_END, valueType: ParameterValueType.DURATION, unit: "h"},
          {name: "Début de la pause du soir", type: ParameterType.DINER_BEGIN, valueType: ParameterValueType.DURATION, unit: "h"},
          {name: "Fin de la pause du soir", type: ParameterType.DINER_END, valueType: ParameterValueType.DURATION, unit: "h"},
          {name: "Durée minimale de la pause", type: ParameterType.MIN_MEAL_LENGTH, valueType: ParameterValueType.DURATION, unit: "h"},
          {name: "Durée maximale de la pause", type: ParameterType.MAX_MEAL_LENGTH, valueType: ParameterValueType.DURATION, unit: "h"}
        ]
      },
      {
        title: "Temps de travail",
        parameters: [
          {name: "Temps de travail journalier minimum (pour le calcul du K-score)", type: ParameterType.MIN_WORKING_TIME, valueType: ParameterValueType.DURATION, unit: "h"},
          {name: "Temps de travail journalier maximum (contrainte)", type: ParameterType.MAX_WORKING_TIME, valueType: ParameterValueType.DURATION, unit: "h"}
        ]
      },
      {
        title: "Temps de service",
        parameters: [
          {name: "Temps de début de service", type: ParameterType.SERVICE_START_TIME, valueType: ParameterValueType.DURATION, unit: "min"},
          {name: "Temps de fin de service", type: ParameterType.SERVICE_END_TIME, valueType: ParameterValueType.DURATION, unit: "min"}
        ]
      },
      {
        title: "Priorités des services",
        parameters: [
          {name: "Pénalité en cas de non respect de la priorité", type: ParameterType.PRIORITY_PENALTY, valueType: ParameterValueType.DURATION, unit: "min"}
        ]
      }

    ]
  }
]
