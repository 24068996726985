import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-filter-table-time',
  templateUrl: './filter-table-time.component.html',
  styleUrls: ['./filter-table-time.component.css',
              '../../assets/tables.css',
              '../../assets/buttons.css',
              '../../assets/filter.css',
              '../../assets/forms.css']
})
export class FilterTableTimeComponent implements OnInit {

  @Input() dataSource = new MatTableDataSource<any>();
  @Input() filteredValues: Map<string, string | string[]> = new Map();
  @Input() typeToFilter = "";

  minTime = "";
  maxTime = "";

  constructor() {
  }

  ngOnInit(): void {
  }

  applyFilter(_: Event) {
    this.filteredValues.set(this.typeToFilter, [this.minTime, this.maxTime]);
    this.buildFilter();
  }

  suppressFilter() {
    this.minTime = "";
    this.maxTime = "";
    this.filteredValues.set(this.typeToFilter, []);
    this.buildFilter();
  }

  private buildFilter() {
    let datafilter = "";
    for(let value of this.filteredValues.values()) {
      if (typeof value == "string") {
        datafilter += value + ","
      } else {
        for(let element of value) {
          datafilter += element + ","
        }
      }
    }
    this.dataSource.filter = datafilter;
  }
}
