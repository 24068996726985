<button class="rounded-button first-type-button close-button" (click)="close()">
    <img src="assets/images/cross.svg">
  </button>
  
  <div class="container">
    <h1>Dupliquer une planification</h1>
    <div class="input__inner">
      <input id="planning-name" name="planning-name" type="text"
             class="input__field" [class.input__field--has-value]="name"
             [(ngModel)]="name">
      <label for="planning-name" class="input__label">
        <img src="assets/images/document.svg">
        <span>&nbsp;Nom de la nouvelle planification</span>
      </label>
    </div>
    <div class="profiles">
      <div>
        <label class="profile-type">Profil de paramètres</label>
        <div class="input-profile">
          <ng-select class="planning-pool-select required"
                      [items]="defaultProfiles"
                      bindValue="id"
                      bindLabel="name"
                      [(ngModel)]="selectedDefaultProfile"
                      placeholder="Profil de paramètres"
                      appendTo="body"
                      [clearable]="false"
                      (ngModelChange)="onParameterProfileChange($event)"
          >
          </ng-select>
        </div>
      </div>
      <div>
        <label class="profile-type">Profil de trafic </label>
        <div class="input-profile">
          <ng-select class="planning-pool-select required"
                      [items]="trafficProfiles"
                      bindValue="id"
                      bindLabel="name"
                      [(ngModel)]="selectedTrafficProfile"
                      placeholder="Profil de trafic"
                      appendTo="body"
                      [clearable]="false"
                      (ngModelChange)="onTrafficProfileChange($event)"
          >
          </ng-select>
        </div>
      </div>
    </div>
    <div class="button-container">
      <button class="rectangular-button first-type-button" (click)="close()">
        Annuler
      </button>
      <button class="rectangular-button important-button start-button"
              (click)="copyProject()"
              [disabled]="creating">
        <div class="start-button-label">
          Dupliquer
        </div>
        <img src="assets/images/arrow-right.svg">
      </button>
    </div>
    <app-modal-progress-bar></app-modal-progress-bar>
  </div>
  