<div class="container"
    [ngClass]="{'container-default': profile != undefined,
              'container-normal': profile == undefined}"
>
    <div class="traffic-settings"> 
        <div class="title">Trafic</div>
        <div class="traffic-values">
            <div *ngFor="let traffic of traffics; let last = last" 
                class="traffic"
                [ngClass]="{'last-child': last}"
            >
                <div>
                    {{hourFormat(traffic.startTime)}} - {{hourFormat(traffic.endTime)}}
                </div>
                <div>
                    <input 
                        class="traffic-value" 
                        type="number"
                        min="-100"
                        max="100"
                        [ngModel]="getTrafficValue(traffic)"
                        (ngModelChange)="setTrafficValue(traffic, $event)"
                    /> % 
                </div>
            </div>
         </div>
    </div>
    <div class="action-buttons">
        <button class="rectangular-button first-type-button" (click)="revertChanges()">Annuler les modifications</button>
        <button class="rectangular-button important-button" (click)="applyChanges()">Enregistrer</button>
    </div>
</div>