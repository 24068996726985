import { MatPaginatorIntl } from '@angular/material/paginator';

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Nombre d\'élements par page :'
  customPaginatorIntl.firstPageLabel = "Première page"
  customPaginatorIntl.previousPageLabel = "Page précédente"
  customPaginatorIntl.nextPageLabel = "Page suivante"
  customPaginatorIntl.lastPageLabel = "Dernière page"
  customPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
    let start = page * pageSize + 1;
    let end = Math.min((page + 1) * pageSize, length);
    return `${start} - ${end} sur ${length}`;
  }

  return customPaginatorIntl;
}
