import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfileData, ProjectData } from '../data-definitions';
import {MessageService} from "../message.service";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CurrentProjectService } from '../current-project.service';
import { environment } from 'src/environments/environment';
import { BaseSectorService } from '../base-sector.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-project-copy-modal',
    templateUrl: './project-copy-modal.component.html',
    styleUrls: ['./project-copy-modal.component.css',
                '../../assets/standard-page.css',
                '../../assets/buttons.css',
                '../../assets/forms.css',
                '../../assets/modal.css',
                '../../assets/select.css']
  })
export class ProjectCopyModalComponent implements OnInit {

    @Output() closeModal = new EventEmitter<boolean>();
    @Output() project = new EventEmitter<ProjectData>();

    subscriptions: Subscription = new Subscription();

    creating = false;

    name: string;
    projectToCopy: ProjectData;

    selectedDefaultProfile: number = 0;
    selectedTrafficProfile: number = 0;
    selectedTrafficProfileName: string = "";
    selectedParameterProfileName: string = "";

    defaultProfiles: ProfileData[] = [];
    trafficProfiles: ProfileData[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public data :any,
                private currentProjectService: CurrentProjectService,
                private baseSectorService: BaseSectorService,
                private messageService: MessageService,
                private http: HttpClient) {
        this.name = data.project.name;
        this.projectToCopy = data.project;
    }

    async ngOnInit(): Promise<void> {
        let defaultProfiles: ProfileData[]= [];
        let trafficProfiles: ProfileData[] = [];

        let environmentId = this.currentProjectService.getEnvironmentId();
        let projectId = this.projectToCopy.id;
        let url = `${environment.apiUrl}/environments/${environmentId}/projects/${projectId}/profiles/`;
        const profiles = await this.http.get<ProfileData[]>(url, {withCredentials: true}).toPromise();

        defaultProfiles.push(...profiles.filter(p => p.profileType === "DEFAULT"));
        trafficProfiles.push(...profiles.filter(p => p.profileType === "TRAFFIC"));

        this.selectedTrafficProfile = trafficProfiles.filter(traffic => traffic.default)[0].id;
        this.selectedTrafficProfileName = trafficProfiles.filter(traffic => traffic.default)[0].name;
        this.selectedDefaultProfile = defaultProfiles.filter(def => def.default)[0].id;
        this.selectedParameterProfileName = defaultProfiles.filter(def => def.default)[0].name;
        this.defaultProfiles = [...defaultProfiles];
        this.trafficProfiles = [...trafficProfiles]
    }

    onParameterProfileChange(selectedId: any): void {
        const selectedParameterProfile = this.defaultProfiles.find(profile => profile.id === selectedId);
        if (selectedParameterProfile) this.selectedParameterProfileName = selectedParameterProfile.name;
    }

    onTrafficProfileChange(selectedId: any): void {
        const selectedTrafficProfile = this.trafficProfiles.find(profile => profile.id === selectedId);
        if (selectedTrafficProfile) this.selectedTrafficProfileName = selectedTrafficProfile.name;
    }

    close() {
        this.subscriptions.unsubscribe();
        this.closeModal.next(true);
    }

    async copyProject() {
        let projectName = this.name.trim();
        if (projectName === "" || projectName === "(Copie)") {
            projectName = "(Copie)";
        }
        this.creating = true;
        document.body.style.cursor = "progress"
        let environmentId = this.currentProjectService.getEnvironmentId();
        let url = `${environment.apiUrl}/environments/${environmentId}/projects/copy/${this.projectToCopy.id}`;
        try {
            let response = await this.http.post<ProjectData>(url, 
                {
                    name: projectName,
                    defaultProfileId: this.selectedDefaultProfile,
                    trafficProfileName: this.selectedTrafficProfileName,
                    parameterProfileName: this.selectedParameterProfileName,
                    trafficProfileId: this.selectedTrafficProfile
                }, {withCredentials:true}).toPromise();
            this.project.next(response);
        } catch (error) {
            if (error instanceof HttpErrorResponse)
                this.messageService.addHttpError(error);
        } finally {
            document.body.style.cursor = "auto"
            this.creating = false;
        }
    }
}