<app-project-view></app-project-view>
<div class="container">
  <div *ngIf="associationRulesService.lockableTransportByEmployee.size > 0">
    <button *ngIf="nbLocks < nbTransports / 2" class="first-type-button rectangular-button" (click)="lockCurrentSolution()">
      Verrouiller la solution
    </button>
    <button *ngIf="nbLocks >= nbTransports / 2" class="first-type-button rectangular-button" (click)="unlockCurrentSolution()">
      Déverrouiller la solution
    </button>
  </div>
  <div class="search-form input__inner">
    <input id="employee-search" class="input__field search-input" type="search" [formControl]="searchInput"
           [class.input__field--has-value]="searchInput.value">
    <label for="employee-search" class="input__label">
      <img src="assets/images/search.svg"  class="input__label__icon">
      Rechercher un employé
    </label>
  </div>
  <app-association-rules-element *ngFor="let employee of filteredEmployees" [employee]="employee"
                                 (employeeSelection)="changeSelection($event)"
                                 [isSelected]="employee === selectedEmployee"
                                 [crewMan]="getCrewMan(employee.id)"
                                 [wasPreviouslySelected]="employee === previouslySelectedEmployee">
  </app-association-rules-element>
</div>
