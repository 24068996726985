<div class="container">
  <button (click)="navigateToProjectPick()" class="rounded-button first-type-button">
    <img src="assets/images/arrow-left.svg">
  </button>
  <div class="project-information" *ngIf="projectInformation">
    <h1>{{ projectInformation.title }} 
      <ng-container *ngIf="projectInformation.project.regulationNames !== ''"> 
        - {{projectInformation.project.regulationNames }}
      </ng-container> 
        - {{projectInformation.project.type}}
      <ng-container *ngIf="projectInformation.project.name !== ''"> 
        - {{projectInformation.project.name }}
      </ng-container> 
    </h1>
    <div class="modification-date" *ngIf="projectInformation">
      {{projectInformation.lastModificationString}}
    </div>
  </div>
  <button class="rectangular-button important-button solve-button" (click)="openSolveModal()">Optimiser la planification</button>
</div>
<app-menu></app-menu>
