import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CurrentProjectService } from '../current-project.service';
import { CurrentUserService } from '../current-user.service';
import { MessageService } from '../message.service';
import { BaseMeasuresService} from "../base-measures.service";
import { UrlService } from '../url.service';
import { BaseUserService } from '../user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css',
              '../../assets/menu.css']
})
export class MenuComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();

  hasSolution = false;

  constructor(private currentProjectService: CurrentProjectService,
              private currentUserService: CurrentUserService,
              private baseMeasuresService: BaseMeasuresService,
              private userService: BaseUserService,
              private urlService: UrlService,
              private router: Router,
              public dialog: MatDialog,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.subscriptions.add(this.currentProjectService.findEnvironmentId().subscribe(environmentId => {
      if(environmentId && this.currentUserService.getUserId()) this.loadData();
    }));
    this.subscriptions.add(this.baseMeasuresService.listen().subscribe(measures => {
      this.hasSolution = measures != undefined && measures.routeMeasures.length > 0;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async loadData() {
    try {
      await this.userService.load();
    } catch(error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  computeUrl(urlType: string, withProject: boolean = true) {
    return this.urlService.computeUrl([urlType], withProject);
  }
}
