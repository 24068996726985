<app-project-view></app-project-view>
<div class="global-indicators">
  <app-indicators></app-indicators>
</div>
<div class="container">
  <div class="route-selector">
    <app-map-route-selection></app-map-route-selection>
  </div>
  <app-map-current-route class="current-route-view"></app-map-current-route>
</div>
