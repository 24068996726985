<div class="selection-route-box">
  <div class="search-form input__inner">
    <input id="route-search" class="input__field search-input" type="search" [formControl]="searchInput"
           [class.input__field--has-value]="searchInput.value">
    <label for="route-search" class="input__label">
      <img src="assets/images/search.svg"  class="input__label__icon">
      Rechercher un véhicule, un équipage
    </label>
  </div>

  <app-map-route-selection-element *ngFor="let route of filteredRoutes" [route]="route"
                                   [isSelected]="route === selectedRoute"
                                   [wasPreviouslySelected]="route === previouslySelectedRoute">
  </app-map-route-selection-element>
</div>
