<div class="container">
  <div class="header">
    <div>
      <img src="assets/images/flag.svg" />
      Type
    </div>
    <div>
      <img src="assets/images/person.svg" />
      Employé
    </div>
    <div class="vehicle">
      <img src="assets/images/ambulance.svg" />
      Véhicule
    </div>
    <div class="button-shift">
    </div>
  </div>

  <div class="content" *ngFor="let defaultAssociationRuleInformation of defaultAssociationRulesInformation">
    <div>
      {{ defaultAssociationRuleInformation.ruleTypeStr }}
    </div>
    <div>
      {{ this.toStringService.employeeName(defaultAssociationRuleInformation.employee) }}
    </div>
    <div class="vehicle">
      {{ this.toStringService.vehicleName(defaultAssociationRuleInformation.vehicle) }}
    </div>
    <div class="button-shift">
      <button class="rounded-button first-type-button" (click)="deleteRule(defaultAssociationRuleInformation.rule)">
        <img src="assets/images/cross.svg">
      </button>
    </div>
  </div>

  <div class="button-container">
    <button type="button" class="rounded-button secondary-type-button new-planning"
            (click)="createNewDefaultAssociationRule()">
      <img src="assets/images/plus.svg">
    </button>
  </div>
</div>
