import { Component, OnInit } from '@angular/core';
import { RouteSolutionWithMeasures } from '../data-definitions';
import { DateService } from '../date.service';
import { MapService } from '../map.service';

@Component({
  selector: 'app-map-route-indicators',
  templateUrl: './map-route-indicators.component.html',
  styleUrls: ['./map-route-indicators.component.css']
})
export class MapRouteIndicatorsComponent implements OnInit {

  routeWithMeasures: RouteSolutionWithMeasures | undefined

  constructor(private mapService: MapService,
              private dateService: DateService) { }

  ngOnInit(): void {
    this.mapService.getSelectedRoute().subscribe(
      route => {
        if (!route) this.routeWithMeasures = undefined;
        else {
          this.routeWithMeasures = route.route;
        }
      }
    )
  }

  getNonNullPipeDateString(date: Date | string, format: string) {
    return this.dateService.getNonNullPipeDateString(date, format);
  }

  getRouteEnd() {
    return !(this.routeWithMeasures) || !(this.routeWithMeasures.routeMeasures) ? "" : new Date(
      this.dateService.iso8601StringOrDateToDate(this.routeWithMeasures.routeMeasures.start).getTime() + this.routeWithMeasures.routeMeasures.totalDuration * 1000
    );
  }


}
