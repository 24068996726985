import { Component, OnInit } from '@angular/core';
import {CurrentProjectService} from "../current-project.service";
import {BaseProjectService} from "../base-project.service";
import {Subscription} from "rxjs";
import {ProjectData} from "../data-definitions";
import {HttpErrorResponse} from "@angular/common/http";
import {MessageService} from "../message.service";
import {Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SolveModalComponent} from "../solve-modal/solve-modal.component";
import {ProjectInformation, ProjectInformationService} from "../project-information.service";
import {defaultDialogConfig} from "../dialog-data";
import { CurrentTypeService } from '../current-type.service';

@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.css',
              "../../assets/standard-page.css",
              "../../assets/buttons.css",
              "../../assets/modal-creator.css"]
})
export class ProjectViewComponent implements OnInit {
  subscriptions: Subscription = new Subscription();

  project?: ProjectData;
  projectInformation?: ProjectInformation;

  matDialogRef?: MatDialogRef<SolveModalComponent>;

  constructor(private currentProjectService: CurrentProjectService,
              private baseProjectService: BaseProjectService,
              private messageService: MessageService,
              private currentTypeService: CurrentTypeService,
              private projectInformationService: ProjectInformationService,
              private router: Router,
              private matDialog: MatDialog) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.baseProjectService.listen().subscribe(project => {
      this.project = project;
      this.currentTypeService.changeType(this.project?.type);
    }));
    this.subscriptions.add(this.projectInformationService.getProjectInformationArray()
      .subscribe(projectInformationArray => {
        if (this.project == undefined) return;
        for (let projectInfo of projectInformationArray) {
          if (projectInfo.project.id == this.project.id)
            this.projectInformation = projectInfo;
        }
      }))
    this.currentProjectService.findProjectId().subscribe(projectId => {
      if (projectId) this.loadData();
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async loadData() {
    try {
      await Promise.all([this.baseProjectService.load(), this.projectInformationService.loadAll()]);
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        this.messageService.addHttpError(error);
      } else {
        this.messageService.addErrorMessage("Unknown error");
      }
    }
  }

  navigateToProjectPick() {
    let newUrl = "/environment/" + String(this.currentProjectService.getEnvironmentId()) + "/project-pick"
    this.router.navigateByUrl(newUrl);
  }

  openSolveModal() {
    const dialogConfig = defaultDialogConfig();
    this.matDialogRef = this.matDialog.open(SolveModalComponent, dialogConfig);
    this.matDialogRef.componentInstance.closeModal.subscribe(
      value => {
        if (value)
          this.matDialogRef!.close();
      });
    // NOTE NB: should we store the subscription and cancel it ?
    // https://stackoverflow.com/questions/47952678/how-to-communicate-from-mat-dialog-component-to-the-component-where-mat-dialog-i
  }
}
