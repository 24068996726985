
import {ApplicationRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {EventhubDepotData, EventhubRegulationData, EventhubSectorData, EventhubTerritoryData, ProfileData, ProjectData, ProjectInfo} from "../data-definitions";
import {Subscription} from "rxjs";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {MessageService} from "../message.service";
import {CurrentProjectService} from "../current-project.service";
import {environment} from "../../environments/environment";
import { BaseProfileService } from '../base-profile.service';

@Component({
  selector: 'app-project-creation-modal',
  templateUrl: './project-creation-modal.component.html',
  styleUrls: ['./project-creation-modal.component.css',
              '../../assets/standard-page.css',
              '../../assets/buttons.css',
              '../../assets/forms.css',
              '../../assets/modal.css',
              '../../assets/select.css']
})
export class ProjectCreationModalComponent implements OnInit {

  @Output() closeModal = new EventEmitter<boolean>();
  @Output() project = new EventEmitter<ProjectData>();

  creating = false;
  confirmation = false;

  selectedType: string = "TAP";
  projectTypes = ["TAP", "AMBU"];

  selectedDefaultProfile: number = 0;
  selectedTrafficProfile: number = 0;
  selectedTrafficProfileName: string = "";
  selectedParameterProfileName: string = "";

  selectedSectors: string[] = [];
  selectedRegulations: string[] = [];
  selectedTerritories: string[] = [];
  selectedDepots: string[] = [];

  showableTerritories: EventhubTerritoryData[] = [];
  showableDepots: EventhubDepotData[] = [];

  date?: Date;
  name?: string;
  planningTimeLimit?: string;

  sectors: EventhubSectorData[] = [];
  regulations: EventhubRegulationData[] = [];
  territories: EventhubTerritoryData[] = [];
  depots: EventhubDepotData[] = [];

  defaultProfiles: ProfileData[] = [];
  trafficProfiles: ProfileData[] = [];

  subscriptions: Subscription = new Subscription();

  constructor(private currentProjectService: CurrentProjectService,
              private baseProfileService: BaseProfileService,
              private messageService: MessageService,
              private http: HttpClient) {
    const today = new Date();
    this.date = new Date(today);
    this.date.setDate(this.date.getDate() + 1)
  }

  ngOnInit(): void {
    this.loadData()
  }

  async loadData() {
    let baseUrl = `${environment.apiUrl}/areas`;
    try {
      this.sectors = await this.http.get<EventhubSectorData[]>(baseUrl + "/sectors",
        {withCredentials: true}).toPromise();
      this.regulations= await this.http.get<EventhubRegulationData[]>(baseUrl + "/regulations",
      {withCredentials: true}).toPromise();
      this.territories = await this.http.get<EventhubTerritoryData[]>(baseUrl + "/territories",
      {withCredentials: true}).toPromise();
      this.depots = await this.http.get<EventhubDepotData[]>(baseUrl + "/depots",
      {withCredentials: true}).toPromise();
    } catch (error) {
      if (error instanceof HttpErrorResponse)
        this.messageService.addHttpError(error);
    }
  }

  getSectorName = (regulation: EventhubRegulationData) => {
    const foundSector = this.sectors.find(sector => sector.apiId === regulation.sectorId);
    return foundSector ? foundSector.name : undefined;
  }

  getRegulationName = (territory: EventhubTerritoryData) => {
    const foundRegulation = this.regulations.find(regulation => regulation.apiId === territory.regulationId);
    return foundRegulation ? foundRegulation.name : undefined;
  }

  getTerritoryName = (depot: EventhubDepotData) => {
    const foundTerritory = this.territories.find(territory => territory.apiId === depot.territoryId);
    return foundTerritory ? foundTerritory.name : undefined;
  }

  getRegulationCount = (regulation: EventhubRegulationData) => {
    const totalCountTerritories = this.territories.filter(t => t.regulationId === regulation.apiId);
    let countSelectedTerritories = 0;
    this.selectedTerritories.filter(tApiId => {
        const territory = this.territories.find(t => t.apiId === tApiId);
        if (territory !== undefined && territory.regulationId === regulation.apiId) countSelectedTerritories++;
    });
    return regulation.name + " (" + countSelectedTerritories + "/" + totalCountTerritories.length +")";
  }

  getTerritoryCount = (territory: EventhubTerritoryData) => {
    const totalCountDepots = this.depots.filter(t => t.territoryId === territory.apiId);
    let countSelectedDepots = 0;
    this.selectedDepots.filter(dApiId => {
        const depot = this.depots.find(d => d.apiId === dApiId);
        if (depot !== undefined && depot.territoryId === territory.apiId) countSelectedDepots++;
    });
    return territory.name + " (" + countSelectedDepots + "/" + totalCountDepots.length +")";
  }

  toggleRegulation() {
    let selectedSectors: string[] = [];
    let selectedTerritories: string[] = [];
    this.selectedRegulations?.forEach((r) => {
      const regulationObject = this.regulations.find(regulation => regulation.apiId === r);
      if (!selectedSectors.includes(regulationObject?.sectorId!)) {
        selectedSectors.push(regulationObject!.sectorId);
      }
      const territories = this.territories.filter(t => t.regulationId == r);
      territories.forEach(t => {
        selectedTerritories.push(t.apiId)
      });
    })
    this.selectedTerritories = [...selectedTerritories];
    this.selectedSectors = [...selectedSectors];
    this.toggleTerritory();
  }

  toggleTerritory() {
    // Add or Remove depot
    let selectedDepots: string[] = [];
    this.selectedTerritories?.forEach((t) => {
      const depots = this.depots.filter(d => d.territoryId == t);
      depots.forEach(d => {
        selectedDepots.push(d.apiId);
      });
    })
    this.selectedDepots = [...selectedDepots];

    let remainedRegulations: string[] = [];
    this.selectedTerritories?.forEach((t) => {
      const territoryObject = this.territories.find(territory => territory.apiId === t);
      if (!remainedRegulations.includes(territoryObject!.regulationId)) remainedRegulations.push(territoryObject!.regulationId);
    })
    this.selectedRegulations = [...remainedRegulations];
    this.setShowable();
  }

  toggleDepot() {
    let remainedTerritories: string[] = [];
    this.selectedDepots?.forEach((d) => {
      const depotObject = this.depots.find(depot => depot.apiId === d);
      if (!remainedTerritories.includes(depotObject!.territoryId)) remainedTerritories.push(depotObject!.territoryId);
    })
    this.selectedTerritories = [...remainedTerritories];

    let remainedRegulations: string[] = [];
    this.selectedTerritories?.forEach((t) => {
      const territoryObject = this.territories.find(territory => territory.apiId === t);
      if (!remainedRegulations.includes(territoryObject!.regulationId)) remainedRegulations.push(territoryObject!.regulationId);
    })
    this.selectedRegulations = [...remainedRegulations];
    this.setShowable();
  }

  resetProfiles() {
    this.selectedDefaultProfile = 0;
    this.selectedTrafficProfile = 0;
    this.selectedTrafficProfileName = "";
    this.selectedParameterProfileName = "";
    this.confirmation = false;
  }

  setShowable() {
    this.resetProfiles();

    let showableTerritories: EventhubTerritoryData[] = [];
    let showableDepots: EventhubDepotData[] = [];
    this.selectedRegulations.forEach((regulationApi) => {
      showableTerritories.push(...this.territories.filter(t => t.regulationId === regulationApi));
    })
    showableTerritories.forEach((territory) => {
      showableDepots.push(...this.depots.filter(d => d.territoryId === territory.apiId))
    })
    this.showableTerritories = [...showableTerritories];
    this.showableDepots = [...showableDepots];
  }

  close() {
    this.closeModal.next(true);
  }

  async showProfiles() {
    let defaultProfiles: ProfileData[]= [];
    let trafficProfiles: ProfileData[] = [];
    this.confirmation = true;

    await Promise.all(this.selectedSectors.map(async (sector) => {
      let url = `${environment.apiUrl}/sectors/${sector}/profiles/${this.selectedType.toLocaleLowerCase()}`;
      const profiles = await this.http.get<ProfileData[]>(url, {withCredentials: true}).toPromise();

      const sectorName = this.sectors.find(sectorObj => sectorObj.apiId === sector)?.name;
      profiles.forEach(profile => profile.name = sectorName + " - " + profile.name);
      defaultProfiles.push(...profiles.filter(p => p.profileType === "DEFAULT"));
      trafficProfiles.push(...profiles.filter(p => p.profileType === "TRAFFIC"));

      this.selectedTrafficProfile = trafficProfiles.filter(traffic => traffic.default)[0].id;
      this.selectedTrafficProfileName = trafficProfiles.filter(traffic => traffic.default)[0].name;
      this.selectedDefaultProfile = defaultProfiles.filter(def => def.default)[0].id;
      this.selectedParameterProfileName = defaultProfiles.filter(def => def.default)[0].name;
    }));
    this.defaultProfiles = [...defaultProfiles];
    this.trafficProfiles = [...trafficProfiles]
  }

  onParameterProfileChange(selectedId: any): void {
    const selectedParameterProfile = this.defaultProfiles.find(profile => profile.id === selectedId);
    if (selectedParameterProfile) this.selectedParameterProfileName = selectedParameterProfile.name;
  }

  onTrafficProfileChange(selectedId: any): void {
    const selectedTrafficProfile = this.trafficProfiles.find(profile => profile.id === selectedId);
    if (selectedTrafficProfile) this.selectedTrafficProfileName = selectedTrafficProfile.name;
  }

  async createProject() {
    if (this.name === undefined) this.name = "";
    let regNames = "";
    this.selectedRegulations.forEach(regulationApiId => {
      regNames += this.regulations.find((regulation) => regulation.apiId === regulationApiId)?.name.slice(0,3) + "/";
    });

    let projectInfo: ProjectInfo = {
      areaInfo: {
        sectorApisId: this.selectedSectors,
        regulationApisId: this.selectedRegulations,
        territoryApisId: this.selectedTerritories,
        depotApisId: this.selectedDepots,
      },
      trafficProfileId: this.selectedTrafficProfile,
      trafficProfileName: this.selectedTrafficProfileName,
      parameterProfileName: this.selectedParameterProfileName,
      defaultProfileId: this.selectedDefaultProfile,
      date: this.date!,
      name: this.name,
      planningTimeLimit: this.planningTimeLimit!,
      regulationNames: regNames.slice(0, -1),
      type: this.selectedType === "AMBU" ? "AMBU":"TAP"
    };
    this.creating = true;
    document.body.style.cursor = "progress"
    let environmentId = this.currentProjectService.getEnvironmentId();
    let url = `${environment.apiUrl}/environments/${environmentId}/projects`;
    try {
      let response = await this.http.post<ProjectData>(url, projectInfo,
        {withCredentials: true}).toPromise();
      this.project.next(response);
    } catch (error) {
      if (error instanceof HttpErrorResponse)
        this.messageService.addHttpError(error);
    } finally {
      document.body.style.cursor = "auto"
      this.creating = false;
    }
  }
}