import {Component, Input, OnInit} from '@angular/core';
import {AssociationRuleData} from "../data-definitions";
import {BaseAssociationRuleService} from "../base-association-rule.service";

@Component({
  selector: 'app-association-rules-force-all-out[rule]',
  templateUrl: './association-rules-force-all-out.component.html',
  styleUrls: ['./association-rules-force-all-out.component.css',
              '../../assets/containers.css']
})
export class AssociationRulesForceAllOutComponent implements OnInit {

  @Input() rule?: AssociationRuleData

  constructor(private baseAssociationRuleService: BaseAssociationRuleService) { }

  ngOnInit(): void {
  }

  async deleteRule() {
    await this.baseAssociationRuleService.remove(this.rule!);
  }
}
