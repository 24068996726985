<div class="navigation-bar-view">
  <ul class="navigation-bar-list">
    <li class="navigation-bar-item">
      <img src="assets/images/logo_jussieu.svg">
    </li>
    <li class="navigation-bar-item">
      <button type="button" class="rounded-button secondary-type-button new-planning" (click)="openProjectCreationModal()">
        <img src="assets/images/plus.svg">
      </button>
      Nouvelle planification
    </li>
    <li class="navigation-bar-item">
      <a [routerLink]="computeUrl('project-pick', false)" routerLinkActive="active" (click)="closeCurrentProject()"
          class="planning-rounded-rectangle">
        <img src="assets/images/folder_plannings.svg">
        <div>Mes planifications</div>
      </a>
    </li>
    <li class="navigation-bar-item last-item">
      <a [routerLink]="computeUrl('admin', false)" (click)="closeCurrentProject()" *ngIf="isAdmin()"
          class="rounded-button">
        <img src="assets/images/settings.svg">
      </a>
      <div class="text" *ngIf="isAdmin()">Réglages</div>
      <button class="rounded-button" (click)="disconnect()">
        <img src="assets/images/logout.svg">
      </button>
      <div class="text">Déconnexion</div>
      <p class="version">{{"v " + frontEndVersionNumber + " - " + backEndVersionNumber}}</p>
    </li>
  </ul>
</div>
